import { Button, Switch } from 'antd';
import { useState } from "react";
import { useTranslation } from "react-i18next";

import kspoint from "../assets/img/kspoint.png"

const data = [
    {
        point: 100,
        price: "100", 
        Id: "1"
    },
    {
        point: 500,
        price: "500", 
        Id: "2"
    },
    {
        point: 1000,
        price: "1000", 
        Id: "3"
    },
    { 
        Id: "5",
        custom: true
    },
]
export default function AddFund(props){
    const [hovered, setHovered] = useState("")
    const [clicked, setClicked] = useState("")
    const {t} = useTranslation()
    const onChange = (checked) => {
    };
    return(
        <div className="pt-5">
            <p className='
                    text-center 
                    text-2xl 
                    font-bold 
                    text-primary 
                    dark:text-darkTextColor
                '
            >
                {props.title || t("fund.title")}
            </p>

            <div className="flex justify-center items-center gap-4 pt-8">
                {!props?.noCostCalculator ? <Button type="primary">{t("fund.costCal")} </Button> : null}
                <div className="bg-neutral-100 rounded px-5 py-2 w-[200px]">
                    <p className="text-center text-sm 2xl:text-base font-bold">{t("fund.recurring")}</p>
                    <div className="flex items-center justify-center gap-3">
                        <span className="font-bold">OFF</span>
                        <Switch defaultChecked onChange={onChange} />
                        <span className="font-bold">ON</span>
                    </div>
                </div>
            </div>
            
            <div className="mx-auto gap-2 justify-center pb-5  flex flex-wrap gap-2 justify-center   mt-10">
                {data.map(d => {
                    return(
                        <div key={d.Id}  className=" mt-5 relative">
                            <div 
                                onMouseOver={() => setHovered(d.Id)}
                                onClick={() => {
                                    if (!props.creating) {
                                        if(clicked == d.Id){
                                            setClicked(null);
                                            if(typeof props.setFund === "function"){
                                                props.setFund(null)
                                            }
                                        }else{
                                            setClicked(d.Id);
                                            if(typeof props.setFund === "function"){
                                                props.setFund(d)
                                            }
                                        }
                                        
                                    }    
                                }}
                                className={'w-56 xl:w-60 2xl:w-64 cursor-pointer rounded-xl dark:bg-bgPagedark ' +
                                    ((hovered == d.Id) ? "hover:bg-white  hover:shadow-2xl " : '') + "" + ((clicked == d.Id) ? "bg-white shadow-2xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')}>

                                {(clicked == d.Id) &&
                                    <svg className="div_check checkmark absolute top-0 right-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg>
                                }
                                <div className='pt-4 pb-10 text-center text-primary dark:text-darkTextColor'>
                                    <div className="flex  items-center justify-center mx-auto">
                                        <img src={kspoint} alt="" className='2xl:w-10 2xl:h-10 w-8 h-8' />
                                        <span className='font-bold text-base md:text-xl 2xl:text-xl '> 
                                            {
                                            !d.custom ? d.point + " KsPoint" : t("fund.custom")
                                            } 
                                        </span>
                                    </div>
                                    <p   className='font-bold'>
                                        <i className='-mt-5'>$</i> <span className='text-center text-3xl'>{d.price}</span>{t("fund.perM")}
                                    </p>
                                    <div  className='mx-auto px-28 py-2'>
                                        <div className='border-t-4 rounded-full border-t-primary'/>
                                    </div>
                            
                                    <p style={{visibility: d.custom && "hidden"}} className='font-semibold text-sm 2xl:text-base'> 
                                        {t("fund.getKsPoint", {point: d.point})}
                                    </p>

                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>    
    )
}