const { post } = require("./api")

const END_POINTS = process.env.REACT_APP_VPSSEC_ALGO_API
const API_USER = process.env.REACT_APP_PSSEC_ALGO_USER
const API_PASSWORD = process.env.REACT_APP_PSSEC_ALGO_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(API_USER+":"+API_PASSWORD)}
const data = {
  "password": "gAAAAABkbRR8-DCuysiYCueULGzuTjgQhZkAtiOP6e6LMeAVDsSvYKMe_cYGzgh6sRphM0jiPMHDbKoeTlxN5yFyFSJQCKMPVW-U6d1uZ2OERPQib3muK2M="
}

const generatePassword = async () => {
    try {
      const data2 = new URLSearchParams({
        'password': data.password,
      })
      const tickets = await post(END_POINTS, data2, header, false, false, true)
      console.log('tickets retreived >>', tickets)
      return tickets
    } catch (error) {
      console.error('error wrong when try to retreive tickets >>', error)
      return undefined
    }
};

export default {
    generatePassword
}
