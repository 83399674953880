import { Checkbox } from 'antd';
import { AddKsInfraCard } from 'components/infra/KsSub';
import { useState } from 'react';
import { useSelector } from "react-redux"
import staticpay from "../../../assets/img/staticpay.png"
import growpay from "../../../assets/img/growpay.png"
import AddFund from 'components/AddFund';
import { IpAddressOption } from 'components/infra/IpAddressOption';
import { Typography } from 'antd';
import { Slider } from 'antd';
import Utils from '../../../utils';
const { Title } = Typography;

// const marks = {
//     0: {
//         style:{
//             color: "#011A43",
//         },
//         label: <strong>CPU <br/> 1</strong>,
//     },
//     26: '26°C',
//     37: '37°C',
//     100: {
//       style: {
//         color: '#f50',
//       },
//       label: <strong>100°C <br/> CPU</strong>,
//     },
//   };

const dataIp = [
    {
        ip: "255.255.255.23",
        ipAdressAllocation: 5,
        label: "IP subnet /29",
        id: 1,
    },
    
    {
        ip: "255.255.255.23",
        ipAdressAllocation: 5,
        label: "IP subnet /29",
        id: 2
    },
    {
        ip: "255.255.255.23",
        ipAdressAllocation: 5,
        label: "IP subnet /29",
        id: 3
    },
    {
        ip: "255.255.255.23",
        ipAdressAllocation: 5,
        label: "IP subnet /29",
        id: 4
    },

]
export default function OrderKsSub(){
    const [creating, setCreating] = useState(false)
    const [hoveredInfraCard, setHoveredInfraCard] = useState("")
    const [clickedInfraCard, setClickedInfraCard] = useState("")
    const [hoveredIpCard, setHoveredIpCard] = useState("")
    const [clickedIpCard, setClickedIpCard] = useState("")
    const [vps_params, setVpsParams] = useState({
    })
    const user = useSelector(app => app?.user?.user)
    return(
        <div className='relative'>
            <div className={user && !user?.EmailConfirmed ?'opacity-[20%]'  : ''}>
                <>
                    <div className="px-3 md:px-8  relative  dark:text-darkTextColor">
                        <div className="container max-w-full">
                            <div className='mx-auto'>
                                <p className='
                                        text-center 
                                        text-2xl 
                                        font-bold 
                                        text-primary 
                                        dark:text-darkTextColor
                                    '
                                >
                                    Choose your instance plan
                                    {Utils.isAdmin() ?
                                        <>
                                            <br />
                                            <Checkbox disabled={creating} onChange={(e) =>setVpsParams({...vps_params, isAdminFreeDeployment: e.target.checked}) }>
                                                Deploy as admin
                                            </Checkbox>
                                        </>
                                        : 
                                        ""
                                    }
                                </p>
                                <div className="mx-auto gap-8 justify-center pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center   mt-10">
                                    <AddKsInfraCard
                                        data={{
                                            label: "Resource Pools",
                                            icon: staticpay,
                                            subtitle: "Fixed monthly pricings",
                                            id: "static"
                                        }} 
                                        setHoveredInfraCard={setHoveredInfraCard} 
                                        setClickedInfraCard={setClickedInfraCard} 
                                        clickedInfraCard={clickedInfraCard} 
                                        hoveredInfraCard={hoveredInfraCard} 
                                        creatingInfra={creating}
                                    />
                                    <AddKsInfraCard
                                        data={{
                                            label: "Pay as you grow",
                                            icon: growpay,
                                            subtitle: "Dynamic monthly princings",
                                            id: "grow"
                                        }} 
                                        setHoveredInfraCard={setHoveredInfraCard} 
                                        setClickedInfraCard={setClickedInfraCard} 
                                        clickedInfraCard={clickedInfraCard} 
                                        hoveredInfraCard={hoveredInfraCard} 
                                        creatingInfra={creating}
                                    />
                                </div>
                                <div>
                                    {clickedInfraCard === "grow" ?
                                        
                                        <AddFund creating={creating} />
                                        :
                                        null
                                    }
                                    {clickedInfraCard === "static" ?
                                        <div className='pt-10 text-primary font-bold'>
                                            <p className='text-center text-2xl  dark:text-darkTextColor'>
                                            Infrastructure Informations
                                            </p>

                                            {/* <Title style={{fontWeight: "bold"}} className='font-bold text-primary text-center' level={3}>
                                                Infrastructure Informations
                                            </Title>   */}
                                            <Title className='uppercase pt-10 text-sm' level={5}>SELECT AN IP ADDRESS OPTIONS</Title>  
                                            <div className="mx-auto gap-2 justify-center pb-5 pt-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center  ">
                                                {dataIp && dataIp.map((d, i) =>{
                                                    return (
                                                        <IpAddressOption
                                                            data={d}
                                                            key={i}
                                                            setHoveredIpCard={setHoveredIpCard} 
                                                            setClickedIpCard={setClickedIpCard} 
                                                            clickedIpCard={clickedIpCard} 
                                                            hoveredIpCard={hoveredIpCard} 
                                                            creating={creating}
                                                        />
                                                    )
                                                })
                                                }

                                            </div>
                                            <div className="pt-10">
                                                <Title className='uppercase text-sm' level={5}>SELECT A RESSOURCES POOLS OPTIONS</Title>  
                                            </div>
                                            <div className='rounded-xl bg-gray-100 w-full p-8'>
                                                <div className='rounded-xl p-8 bg-white'>
                                                    <div className=''>
                                                        <Slider defaultValue={37} />
                                                        <div className='flex text-center font-bold justify-between text-primary'>
                                                            <span>
                                                                CPU
                                                                <br/>
                                                                1
                                                            </span>
                                                            <span>
                                                                Memory
                                                                <br/>
                                                                2 GB
                                                            </span>
                                                            <span>
                                                                Storage
                                                                <br/>
                                                                25 GB
                                                            </span>
                                                            <span>
                                                                xxxxxx
                                                                <br/>
                                                                2 T
                                                            </span>
                                                        </div>
                                                        <div className='pt-10'>
                                                            <span className=' text-sm xl:text-base'> 
                                                                Additionnal Storage
                                                            </span>
                                                            <div className='flex justify-between'>
                                                                <Slider className='w-[95%]' defaultValue={37} />
                                                                <span>0 TB</span>
                                                            </div>
                                                        </div>
                                                        <div className='pt-10'>
                                                            <span className=' text-sm xl:text-base'> 
                                                                Additionnal Transfer
                                                            </span>
                                                            <div className='flex justify-between'>
                                                                <Slider className='w-[95%]' defaultValue={37} />
                                                                <span className=''>0 TB</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}