import {AddButton} from "../../../components/AddButton"
import { useEffect } from 'react';
import  VPSapi  from '../../../lib/vps_service';
import { useSelector } from 'react-redux';

import {ServerCard} from "../../../components/VpsSecSection"


import { Block } from '../../../components/Bloc';
import UserMgt from '../../../lib/user_managment';
import CommingSoon from "components/CommingSoon";

export default function VpsSec() {

    const {list} = useSelector(app => app.vps)

    async function loadVps(){
        console.log('start load vps loadVps')
        const vps = await UserMgt.get_user_vps() 
    } 


    useEffect(() => {
         loadVps()
    }, [list])
     
    return <CommingSoon />
    return (
        <>
            
            <div className="px-3 md:px-8 ">
                <div className="container mx-auto max-w-full pt-5">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 px-4 mb-16 gap-4 items-center">

                       <ServerCard server={null} />
                        <AddButton link="/compute/vpssec/deploy" title={"Deploy new instance"} />

                    </div>
                        {!list && 
                            <div className='flex justify-center'>
                                {/* <svg className="animate-spin  -ml-1mr-3 h-12 w-12 text-primary mx-auto"  fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg> */}
                            </div>
                        }
                </div>
            </div>
        </>
    );
}


