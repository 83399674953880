import { CHANGE_DEPLOY_STATE } from "../action/webappAction";
import {  SAVE_CAPROVER_TOKEN, SAVE_CAPROVER_ONE_CLICK_APP, LIST_WEB_APP_SERVERS } from "../action/webappAction";

const defaultState = {
    deployState: null,
    current_server: {},
    list: null,
    state: false
};
  
  
  export default function webapp(state = defaultState, action) {
    switch (action.type) {
      case CHANGE_DEPLOY_STATE:
        return { ...state, deployState: action.data };
      case SAVE_CAPROVER_TOKEN: 
        let servers = state.list
        servers = servers && servers.map(serv => {
          if(serv.Id  === action?.data?.Id){
            console.log('>>  server found', serv.Id)
            return {...action.data}
          }
          return {...serv}
        }) || []
        return { ...state, list: servers, current_server: {...action?.data, caprover_token: action?.data?.caprover_token}};
      case SAVE_CAPROVER_ONE_CLICK_APP:
        return { ...state, one_click_app: action.data };
      case LIST_WEB_APP_SERVERS:
        return { ...state, list: [...action.list] };
      case "@DEPLOYED":
        return { ...state, state: action.state };
      case "CURRENT_SERVER":
        return { ...state, current_server: action.server};
      default:
        return {...state};
    }
  }
  