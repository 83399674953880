import { useState, useEffect } from 'react';
import UserMgt from "../../lib/user_managment"
import { Logo } from '../../components/Logo';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import "./Auth.css"
import { useTranslation } from 'react-i18next';

 function get(name, url = window.location.href) {
	  const name2 = name.replace(/[\[\]]/g, '\\$&')
	  const regex = new RegExp('[?&]' + name2 + '(=([^&#]*)|&|#|$)');
	  const results = regex.exec(url);
	  if (!results) {
	    return null
	  }
	  if (!results[2]) {
	    return ''
	  }
	  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default function AccountVerification() {
    const { t } = useTranslation();
    const [objectActivate, setObject] = useState({})
    const [onEndActivation, setOnEnd] = useState(false)
    const [activResp, setActivResp] = useState('')
    const navigate = useNavigate()

    async function activate(){
    	try{ 
    		const userId = get('userId')
    		const userEmail = get('emailAddr')

    		if(!userId || !userEmail) return navigate('/')

    		const result = await UserMgt.active_account({userId: userId , userEmail})
    		setActivResp(result)
    		console.log('result activation')
    	}catch(e){
    		console.log('error acrivated account', e)
    	}
    	setOnEnd(true)
    }

    useEffect(() => {
        
        activate()
    }, [])


	return (
		<div className='bgCustom'>
            {/* <video autoplay={true} muted={true} loop={true} className="myVideo">
                <source src={require('../../assets/img/keepsecbackgroud.mp4')} type="video/mp4"/>
            </video> */}

            <div className="flex items-center justify-center min-h-screen  content">
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
                    <div>
                        <Logo/>
                        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                            {t("Account verification")}
                        </h2>
                        <p className="mt-2  text-center text-gray-600">


                            <br />

                            {!onEndActivation ?
                            	<div className="flex justify-center">
	                            	<svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-primary mx-auto"  fill="none" viewBox="0 0 24 24">
	                                    <circle className="opacity-25" cx="12" cy="20" r="20" stroke="currentColor" strokeWidth="4"></circle>
	                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
	                                </svg>
                                </div>
                                :
                                <>
                                	{activResp?.message}
                                	{activResp?.status ==="success"&&
                                         <>
                                             <br/>
    		                                <NavLink
    		                                    to="/login"
    		                                    className={"font-medium  text-xl text-primary hover:text-blue-500 cursor-pointer"}
    		                                >
                                                {t("login now")}
    		                                    
    		                                </NavLink>
                                         </>
                                	}
                                </>
                            }
                              
                        </p>
                    </div>

                </div>
            </div>
        </div>
	)
}