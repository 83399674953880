import store from "../reducer"
import UserAPI from './keepsec_auth_service'
const {  get, post } = require("./api")

const SERVICE_URL = process.env.REACT_APP_USER_MANGMENT_API
const  apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}


const createTarget = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const body = {server_id: server_id, custId: store.getState().user.user?.Id, ...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/vullify/target", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const deleteDomain = async (server_id, domain) => {
    try {
        await UserAPI.refrest_token()
        const body = {server_id: server_id, custId: store.getState().user.user?.Id, domain: domain}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/vullify/target/domain/delete", body, header)
        return result
    } catch (error) {
        console.error('ERROR Delete target >>', error)
        return null
    }
};
const createCNAME = async (domain) => {
    try {
        await UserAPI.refrest_token()
        const body = {domain}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/vullify/domain/create_cname", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const verifyDomain = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/vullify/domain/verify", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const startScan = async (data) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/vullify/scan", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getListTarget = async (server_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/vullify/targets", header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getTarget = async (server_id, target_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/vullify/target/"+target_id, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getOutput = async (server_id, scan_id, target_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/vullify/target/"+target_id+"/scan/get_output/"+scan_id, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getScreen = async (server_ip, scan_name, screen_name) => {
    try {//:server_ip/vullify/:scan_name/:screen
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_ip+"/vullify/"+ scan_name+"/"+screen_name, header, true)
        console.log('result result', result)
        return result
        // const response = await axios.get(
        //     `http://${server_ip}:5000/show_screenshot/${scan_name}/${screen_name}`,
        //     {
        //       auth: {
        //         username: "keepsec_vullify",
        //         password: "-_-KeepSec_2023-_-"
        //       },
        //       maxBodyLength: Infinity,
        //     }
        //   );
        //   console.log('RESPONSE.HEADER ', response?.headers)
        // return response?.data
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
}; 

const test2SCreen = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic a2VlcHNlY192dWxsaWZ5Oi1fLUtlZXBTZWNfMjAyMy1fLQ==");

    var raw = "";

    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    };

    return fetch("http://72.10.174.13:5000/show_screenshot/scan_11/https-support.keepsec.ca-.png", requestOptions)
    .then(response => response.text())
    .then(result => console.log("###############################", result))
    .catch(error => console.log('error', error));
}



export default {
    createTarget,
    createCNAME,
    verifyDomain,
    getListTarget,
    startScan,
    getTarget,
    getOutput,
    getScreen,
    deleteDomain
}