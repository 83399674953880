import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { updateHeadLink } from 'reducer/action/coreActions';

import CAPTAIN from '../../lib/libCaprover';
import { useTranslation } from 'react-i18next';
import { Texto } from 'layouts/TextoLayout';
import { Spiner } from 'components/Bloc';

// const CAPTAIN_BASE_DOMAIN = process.env.CAPTAIN_BASE_DOMAIN || "https://oneclickapps.caprover.com"

export default function AddWebApp() {
    const { server_id } = useParams()
    const onClickApps = useSelector(app => app.webapp?.one_click_app)
    const [keyword, setKeyWord] = useState('')
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    async function onFetApp() {
        const resul2 = await CAPTAIN.onClickTemplaceList(server_id)
        console.log('resul2 resul2', resul2)
    }
    const headLinks = [
        {
            link: "/app-platform/kps",
            type: "img",
            item: "kps"
        },
        {label: t("platform.app.titlePage"), link: "/app-platform/kps/"+server_id, arrow: true},
        {label: t("platform.listOneClicApp.listAppPageTitle"), arrow: true}
    ]


    useEffect(() => {
        dispatch(updateHeadLink(headLinks))
        onFetApp()
        return () =>  dispatch(updateHeadLink([]))
    }, [])

    return (
        <div className={" dark:bg-black h-full"+ (onClickApps ? " " : " ")}>
            <div className="px-3 md:px-8 h-[85vh]  webapp">
                <div className="box h-full grid grid-cols-12 border border-primary dark:border-gray-800  rounded-xl ">

                    <div className="col-span-12 h-full">
                        <div className="flex  flex-wrap gap-y-3 py-3 justify-center md:justify-between   items-center px-5  border-b border-primary ">
                            <div>
                                <Texto  className='font-semibold text-primary dark:text-darkTextColor'>
                                    {t("platform.listOneClicApp.selectNewAPP")}
                                </Texto>
                            </div>

                            <div className="xl:w-96">
                                <div className="input-group relative flex flex-wrap items-stretch w-full">
                                    <input 
                                        type="search" 
                                        onChange={(e)=>setKeyWord(e.target.value)} 
                                        className="form-control dark:bg-bgPagedark dark:border-none relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
                                        placeholder={t("platform.listOneClicApp.searchPlaceHolder")}
                                        aria-label="Search" 
                                        aria-describedby="button-addon2" 
                                    />
                                    <button className="btn inline-block px-6 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-primary hover:shadow-lg focus:bg-primary  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center" type="button" id="button-addon2">
                                        <svg aria-hidden="true" focusable="false" dataprefix="fas" dataicon="search" className="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {onClickApps ?
                            <div className="px-5 h-[70vh] py-6 flex flex-wrap overflow-y-auto justify-center gap-3 sm:gap-6 border-b border-slate-200/60">
                                { onClickApps.filter(a => a.displayName.toLowerCase().includes(keyword.toLowerCase())).map((app, id) => (
                                    <div className="w-64 max-h-[360px] rounded  shadow-lg border dark:border-zinc-800 dark:shadow-darkSiderbar transform transition duration-500  hover:scale-110 cursor-pointer " key={id}>
                                            <NavLink
                                                to={
                                                    "/app-platform/kps/"+server_id+"/"
                                                    +app.name+
                                                    `/details`}
                                            >
                                                <img className="h-14 mx-auto mt-5" src={app.logoUrl } alt={app.displayName}/>
                                                <div className="px-6 py-4">
                                                    <div className="font-bold text-sm xl:text-base 2xl:text-xl mb-2"> <span>{app.displayName}</span></div>
                                                    <p className="text-gray-700 text-xs xl:text-sm 2xl:text-sm">
                                                        {i18n.language.includes('fr') ? t("platform.listOneClicApp."+app.name) : app.description}
                                                    </p>
                                                </div>  
                                                <div className="px-6 pt-4 pb-2">
                                                </div>
                                            </NavLink>
                                        </div>

                                ))

                                }
                            </div>
                            :
                            <div className='flex justify-center items-center py-14'>
                                <Spiner fontSize={35} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
