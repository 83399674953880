import { useEffect, useState } from 'react';
import { BsFillLightbulbFill } from "react-icons/bs";
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Spiner } from 'components/Bloc';
import { updateHeadLink } from 'reducer/action/coreActions';

import { Toast } from '../../components/Notify';
import CAPTAIN from '../../lib/libCaprover';
import Utils from '../../utils';
import { DeployProgress } from "./DeployProgress"
// import { GrDeploy } from "react-icons/gr";
import oneClickAppDeployHelper from "./function/StartDeploy"
import { useTranslation } from 'react-i18next';

export const ONE_CLICK_APP_NAME_VAR_NAME = '$$cap_appname'
export const ONE_CLICK_ROOT_DOMAIN_VAR_NAME = '$$cap_root_domain'

export default function AppDetails() {
    const { name } = useParams()
    const { server_id } = useParams()
    const {t} = useTranslation()
    const [app, setApp] = useState(null)
    const [captainInfo, setCaptainInfo] = useState(null)
    const [appEdited, setAppEdited] = useState({})

    const [blurredFields, setBlurredFields] = useState({})
    const dispatch = useDispatch()
    const { messageApi } = useSelector(app => app.core)
    const [deployState, onSetDeploymentState] = useState(null)
    
    function setDeploymentState(state){
        onSetDeploymentState(state)
    }
    //Web-apps //One-click APPS
    
    async function onGetOnClickApp1() {
        console.log('server_id 1 ', server_id)
        const resul = await CAPTAIN.onGetOnClickApp(name, server_id)  
        resul.data?.appTemplate?.caproverOneClickApp?.variables.unshift({ 
            id: ONE_CLICK_APP_NAME_VAR_NAME,
            label: t("platform.appDetails.labelMessageAppNameField"),
            description: t("platform.appDetails.helpMessageAppNameField"),
            validRegex: '/^([a-z0-9]+\\-)*[a-z0-9]+$/', 
        })
        const app = JSON.parse(
            Utils.replaceAllGenRandomForOneClickApp( 
                JSON.stringify(resul?.data?.appTemplate)
            )
        )
        setApp(app)
        const headLinks=[
            {
                link: "/app-platform/kps",
                item: "kps",
                alt: "kps short link"
            },
            {label: t("platform.app.titlePage"), link: "/app-platform/kps/"+server_id, arrow: true},
            {
                label: t("platform.listOneClicApp.listAppPageTitle"),  
                link: "/app-platform/kps/"+server_id+"/add",
                arrow: true,
            },
            {
                label: name, 
                arrow: true,
            },
            {
                label: t("platform.appDetails.deploy"), 
                arrow: true,
            }
    
        ]
        dispatch(updateHeadLink(headLinks))

        let newOb = {}
        app.caproverOneClickApp?.variables.forEach((v) => {
            if (v.defaultValue) {
                newOb = {...newOb, [v.id]: v.defaultValue}
            }
        })
        setAppEdited({...newOb})

        
        console.log('resul resul resul', resul)
    }
    async function getCaptainInfo1() {
        const resul = await CAPTAIN.getCaptainInfo(server_id)
        setCaptainInfo(resul.data)
        console.log('captain infos', resul)
    } 
    useEffect(() => {
        onGetOnClickApp1()
        getCaptainInfo1()
        return () =>  dispatch(updateHeadLink([]))
    }, [])

    function isFieldValueValid(variable) {
        const currVal = appEdited[variable.id] || ''
        //console.log('currVal currVal', currVal, appEdited)
        let isEnteredValueValid = true
        if (variable.validRegex) {
            const parts = /\/(.*)\/(.*)/.exec(variable.validRegex)
            if (
                !parts  ||
                !new RegExp(parts[1], parts[2]).test(currVal)
            ) {
                isEnteredValueValid = false
                //setObjectError({...obJectError, [variable.id]: true})
            }
        }

        return isEnteredValueValid
    }

    function onSaveAndCheckRegex(ev, regex) {
        //const newModel = Utils.copyObject(appEdited)


        setAppEdited({...appEdited, [ev.target.name]: ev.target.value})

        // if (regex) {
        //     const parts = /\/(.*)\/(.*)/.exec(regex)
        //     setObjectError({...obJectError, [ev.target.name]: !new RegExp(parts[1], parts[2]).test(ev.target.value)})
        // }

    }

    function onDeploy(){
        console.log('appEdited appEdited', appEdited)
        const valuesAugmented =  Utils.copyObject(appEdited)

        const blurredFields2 = Utils.copyObject(blurredFields)
        let allFieldValid = true

        //let isValid = true
        app?.caproverOneClickApp?.variables.forEach((v) => {
            blurredFields2[v.id] = true
            if (!isFieldValueValid(v)) {
                allFieldValid = false
            }

            // const valid = isValidField(v)
            // console.log('v.validRegex', v.id, valid, obJectError, v.validRegex)
            // if(v.validRegex && !valid) {
            //     isValid = false
            // }
        })
        setBlurredFields(blurredFields2);

        if (!allFieldValid) {
            return messageApi.error(t("core.constants.MISSING_REQUIRED_FIELD"))
        } 


        const template = Utils.copyObject(app) 

        template.caproverOneClickApp.variables.push(
            {
                id: ONE_CLICK_ROOT_DOMAIN_VAR_NAME,
                label: 'CapRover root domain',
            }
        )
        valuesAugmented[ONE_CLICK_ROOT_DOMAIN_VAR_NAME] = captainInfo?.rootDomain

        oneClickAppDeployHelper.startDeployProcess(template,valuesAugmented, onSetDeploymentState, t)
    }

    
    const displayName =app && app.caproverOneClickApp.displayName ? app.caproverOneClickApp.displayName : name[0].toUpperCase() + name.slice(1)

    return (
        <div className={" dark:bg-black dark:text-darkTextColor  "}> 
            

            <div className="px-3 md:px-10    webapp ">
                <div className="rounded-lg  shadow-lg  w-full py-5 dark:bg-bgPagedark dark:border-2 dark:border-gray-800">
                    {app ?
                        <div>
                            {!deployState ?
                                <div>
                                    <span className='text-xl xl:text-2xl 2xl:text-3xl ml-3 border-b border-primary dark:border-darkTextColor font-bold text-primary dark:text-darkTextColor'>
                                        {displayName}
                                    </span>
                                    <p className='2xl:pt-10 pt-5 px-3 text-[13px] md:text-sm 2xl:text-base' style={{ whiteSpace: 'pre-wrap' }} >
                                        <ReactMarkdown remarkPlugins={[gfm]}>
                                            {app?.caproverOneClickApp?.instructions?.start}
                                        </ReactMarkdown>
                                    </p>

                                    <div className='2xl:pt-6 pt-3'>
                                        <div className="w-3/4  flex flex-col py-5 px-6 2xl:px-8 rounded-lg " action="">
                                            <span className='2xl:text-2xl xl:text-xl text-base font-semibold text-primary dark:text-darkTextColor'>
                                                {t("platform.appDetails.setupAppText", {displayName})}
                                            </span>
                                            {app?.caproverOneClickApp?.variables.map((input, id) =>(
                                                <div className='pt-3' key={id}>
                                                    <p className="text-gray-700 dark:text-darkTextColor font-semibold 2xl:py-2 py-1" htmlFor="">
                                                        {input.label}
                                                    </p>
                                                    {input.description ?
                                                        <p className='text-xs dark:text-sky-300 py-2'>
                                                            <ReactMarkdown remarkPlugins={[gfm]}>
                                                                {input.description}
                                                            </ReactMarkdown>
                                                        </p>
                                                        :
                                                        null
                                                    }
                                                    <input className=" dark:bg-black dark:border-black dark:text-darkTextColor
                                                        text-gray-700 w-full shadow border rounded border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3" 
                                                        type="text" 
                                                        name={input.id}
                                                        defaultValue={input.defaultValue}
                                                        onChange={(e)=>onSaveAndCheckRegex(e, input.validRegex)}
                                                        onBlur={(e) => {
                                                            const blurredFields2 = Utils.copyObject(
                                                                blurredFields
                                                            )
                                                            blurredFields2[input.id] = true
                                                            setBlurredFields(blurredFields2)
                                                        }}

                                                        placeholder={input.defaultValue}
                                                    />
                                                    { !(!blurredFields[input.id] || isFieldValueValid(input)) &&
                                                        <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                                                            <p className="font-bold text-xs">
                                                            {t("platform.appDetails.errorRegexvalidation", {validRegex: input.validRegex})}
                                                            </p>
                                                        </div>
                                                    }

                                                </div>
                                            ))

                                            }

                                            <div className='py-5 pb-10'>
                                                <button
                                                    className="items-center gap-1 px-10 py-3 text-2sm font-bold text-white transition duration-150 ease-in-out bg-primary border border-white rounded focus:outline-none  hover:bg-primary hover:border-primary flex"
                                                    onClick={onDeploy}
                                                >
                                                    <BsFillLightbulbFill/>
                                                    <span>{t("platform.appDetails.deployBtn")}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <DeployProgress server_id={server_id} displayName={displayName} setDeploymentState={setDeploymentState} deploymentState={deployState} app={app} appName={appEdited[ONE_CLICK_APP_NAME_VAR_NAME]} />
                            }
                        </div>
                        :
                        <div className='w-1/2 mx-auto flex justify-center py-32'>
                            <Spiner fontSize={50} />
                        </div>  
                    }
                </div>
            </div>
        </div>
    );
}





