import React, { useState, useEffect } from 'react'
import { Table, Tag, Space, Button, Tooltip, Modal, Alert, Radio, Input  } from 'antd';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import KSPROXY from 'lib/proxy'
import Utils from '../../utils'
import { MdOutlineEditNote } from "react-icons/md";
import { STANDARD_ERROR_MESSAGE } from '../../constants/message.js'

import { useSelector } from 'react-redux';

const { TextArea } = Input

export  function PSettings(props){
	const col1 = [
        {
        title: 'Name',
        dataIndex: 'name', 
        render: (_, obj) => <div> 
          <span className="font-semibold">{obj?.name}</span>
          <br/>
          <span className="text-xs">{obj?.description}</span>
        </div>,
      }, 
      {
        title: 'Value',
        dataIndex: 'value',
        render: (_, obj) => <div> 
          <span className="font-semibold">{
            obj.value === "html" ? 
              "Custom Page"
              :
            obj.value === "redirect" ?
            "Redirect":

            Utils.capitalizeFirstLetter(obj?.value || "") + " Page"
            
          } 
          </span>
        </div>,
      },
      {
        title: '',
        key: 'action',
        render: (_, record) => ( 
            <Button className="flex items-center"
               onClick={()=>{
                if(record.id === 'default-site') {
                    setShowDefaultSite(record.value)
                    setHtml(record?.meta?.html)
                    setValue(record?.value)
                    setRedirectUrl(record?.meta?.redirect)
                }
              }}
              icon={<MdOutlineEditNote className="text-primary text-xl" />}
            >
               Edit
            </Button>
        ),
      },
    ];

  const [settings, setSetttings] = useState(null)
  const [loading, setload] = useState(false) 
  const [eMessage, setErrorMessage] = useState(null) 
  const [defaultSite, setShowDefaultSite] = useState(null) 
  const [value, setValue] = useState(null);

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [errorR, setErrorRedirect] = useState(null);
  const [html, setHtml] = useState(null);
  const [errorHtml, setErrorHtml] = useState(null);
  const [updating, setUpdating] = useState(null);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  async function getAllSettings(){
    setload(true)
    try{
      const result = await KSPROXY.getAllSettings(props?.server?.Id)
      console.log('result result getAllSettings ', result)
      const setts =Object.values(result).filter( arr => typeof arr === "object")
      setSetttings(setts)
    }catch(e){
      console.log('Cant get proxy host ', e) 
    }
    setload(false)
  }
  async function updateDefaultSite(){
    setErrorRedirect(false)
    setErrorMessage(null)
    setErrorHtml(false)
    try{
      if(value === "redirect" && !redirectUrl){
        return setErrorRedirect(true)
      }
      if(value === "html" && !html){
        return setErrorHtml(true)
      }
      setUpdating(true)
      const meta = {}
      if(value === "redirect" && redirectUrl){
        meta.redirect = redirectUrl
      }
      if(value === "html" && html){
        meta.html = html
      }
      const oject = {
        value,
        meta
      }
      const result = await KSPROXY.updateSetting(props?.server?.Id, oject, "default-site")

      if(!result?.error && result?.code === 200){
        return Utils.sleep(2000).then(() => {
                  getAllSettings()
                  setUpdating(false)
                  setShowDefaultSite(null)
              });
      }else{
        setErrorMessage("An error occurs, please try again later or contact us.")
      }
    }catch(e){
      console.log('Cant get proxy host ', e) 
    }
      setUpdating(false)
  }
  useEffect(() => { 
    getAllSettings()
  }, [])

	return(
		<div className="w-full">
      <Modal
        destroyOnClose={true}
        centered
        footer={null}
        closeIcon={false}
        open={defaultSite}
        className='mPadding'
        width={700}
      >
        <div className=''>
          <div className='bg-primary py-3 px-6 text-white'>
            <h4 className="font-semibold text-base xl:text-xl">
                Default Site
            </h4>
          </div>

          <div className='px-6 mt-3' style={{display: !eMessage && "none"}}>
            <Alert  message={eMessage } type="error" />         
          </div>
          <div className='px-6 py-3'>
            <p className="font-semibold">
              What to show when Nginx is hit with an unknown Host
            </p>

            <div className="mt-5">
              <Radio.Group onChange={onChange} value={value || defaultSite}>
                <Space direction="vertical">
                  <Radio  value={"congratulations"}>Congratulations Page</Radio>
                  <Radio value={"404"}>404 Page</Radio>
                  <Radio value={"444"}>No Response (444)</Radio>
                  <Radio value={"redirect"}>Redirect</Radio>
                  <Radio value={"html"}> Custom Page </Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="mt-5" style={{display: value !== "redirect" && "none"}} >
              <span className="font-semibold">
                Redirect to
              </span>
              <Input
                onChange={ev => {
                  setRedirectUrl(ev.target.value)
                }} 
                value={redirectUrl}
                className='rounded w-full mt-1' 
                placeholder="https://"
                status={errorR && "error"}              
              />
            </div>
            <div className="mt-5" style={{display: value !== "html" && "none"}} >
              <span className="font-semibold">
                HTML content
              </span>
              <TextArea
                onChange={ev => {
                  setHtml(ev.target.value)
                }} 
                rows={5} 
                value={html}
                status={errorHtml && "error"}
                className='rounded w-full mt-1' 
                placeholder="<!-- Enter your HTML here -->"              
              />
            </div>
          </div>

          <div className="py-5 flex justify-end gap-2 px-6">
            <Button  onClick={ () =>{
                    setShowDefaultSite(null);
                    setValue(null);
                    setErrorMessage(null);
                }}
            >
                Cancel
            </Button>
            <Button  
              type='primary'
              loading={updating}
              onClick={ () =>{
                    updateDefaultSite();
                }}
            >
                Save
            </Button>
          </div>
        </div>
      </Modal>
			<div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">Settings</h5>
			</div>

			<Table  
                columns={col1} 
                loading={loading}
                dataSource={settings || []} 
            />
		</div>
	)
}


export function PSettingsAdv(props){

  const [settings, setSettings] = useState(null)
  const [loading, setload] = useState(false) 
  const { messageApi } = useSelector(app => app.core)
  async function getAllSettings2(){
    setload(true)
    try{
      const result = await KSPROXY.getAllSettings(props?.server?.Id, true)
      if(result?.string){
        setSettings(Object.values(result?.string).join(''))
      }
      //setSettings(setts)
    }catch(e){
      console.log('Cant get proxy host ', e) 
    }
    setload(false)
  }
  async function upAvSetting(){
    setload(true)
    const result = await KSPROXY.updateSetting(props?.server?.Id, {local_policy: settings}, "advanced-settings")
    setload(false)
    if(result && !result.error){
      messageApi.success('Updated successfull')
    }else{
      messageApi.error(result?.message || STANDARD_ERROR_MESSAGE)
    }
  }
  useEffect(() => { 
    getAllSettings2()
  }, [])

 
  return(
    <div className="w-full">
        <div className="flex justify-between items-center gap-4 pb-4">
          <h5 className="font-bold text-base  xl:text-xl">Advanced Settings</h5>
        </div>

        <div>
          <div className="py-3 text-left text-sm text-primary 2xl:text-base font-semibold" >
            K-WAF Configuration File
           </div>
          <textarea
              className="px-4 text-xs 2xl:text-sm py-2 w-full dark:bg-bgDark2 rounded dark:text-darkTextColor dark:border-gray-800  border-2 h-72" 
              style={{
                  whiteSpace: true
                      ? 'pre-line'
                      : 'pre',
              }}
              value={settings}
              onChange={
                (e)=>setSettings(e.target.value)
              }
          />
        </div>
        <div className="flex justify-between w-full pt-4">
          <Button 
            loading={loading} 
            type="primary" 
            onClick={upAvSetting}
          >
                  Save settings
           </Button>
        </div>
    </div>
  )
}