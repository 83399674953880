import React, { useState, useEffect, useCallback } from 'react'
import { Table, Tag, Space, Button, Tooltip,  Modal, Tabs, Upload , 
					Popconfirm, Dropdown, Badge,  Input, Switch,
					InputNumber,
					Select,
					Alert
				} from 'antd';
import { useSelector } from 'react-redux';
import { PlusOutlined, QuestionCircleOutlined, LockOutlined } from '@ant-design/icons';
import { DownOutlined, SmileOutlined, SettingOutlined, UploadOutlined, DeleteOutlined, EditOutlined, PoweroffOutlined   } from '@ant-design/icons';
import { MdMoreVert } from "react-icons/md"
import { Details } from './section/RedirectHostSection'
import { SSL, Advanced } from './section/HostSection'
import KSPROXY from 'lib/proxy'
import Utils from '../../utils'
import { ReactTags } from 'react-tag-autocomplete'

const { TextArea } = Input;
const dns_providers = require('../../global/certbot-dns-plugins')

function getOption(){
  const arr = []
  for (let [k, v2] of Object.entries(dns_providers)) {
    const v = {}
    v.value = k
    v.label = v2.display_name
    arr.push(v)
  }
  return arr
}
export function PCertificates(props){
	const {messageApi} = useSelector(app => app.core )

	const col1 = [
        { 
        title: 'Name',
        dataIndex: 'nice_name',
        render: (_, obj) => <div> 
          <span>{obj.nice_name}</span>
          <br/> 
          <span>{Utils.formatDate(obj?.created_on, 'Do MMMM YYYY, h:mm a')}</span>
        </div>,
      },
      {
        title: 'Certificate provider',
        render: (_, obj) => <div> 
          {obj?.meta?.dns_provider ?
          	<span>
            	{dns_providers[obj?.meta?.dns_provider].display_name}
            </span>
            : 
            "Custom"
          }
        </div>,
      },
      {
        title: 'Expires',
        render: (_, obj) => <div> 
          <span>{Utils.formatDate(obj?.expires_on, 'Do MMMM YYYY, h:mm a')}</span>
          
        </div>
      },
      {
        title: '',
        key: 'action',
        render: (_, record) => {
        	const items = []
        	if(record?.provider === "letsencrypt"){
        		items.push(
        			{
	              label: (<Popconfirm
	                  title={"Confirm Auto renew "}
	                  description={"Really enable auto renew ?"}
	                  // onConfirm={() => confirmDelete(record)}
	                  // onCancel={cancel}
	                  okText="Yes"
	                  cancelText="No"
	                >
	                  Auto renew
	                </Popconfirm>
	              ),
	              key: '2',
	              danger: false,
	              icon: <DeleteOutlined />,
	            }
        		)
        		items.push(
        			{
	              label: (<div
	                  // onConfirm={() => confirmDelete(record)}
	                  // onCancel={cancel}
	                >
	                  Download
	                </div>
	              ),
	              key: '1',
	              danger: false,
	              icon: <DeleteOutlined />,
	            }
        		)
        	}
        	items.push(
        		{
              label: (<Popconfirm
                  title={"Confirm deletion "}
                  description={"Really delete access list named "+record.nice_name}
                  onConfirm={() => confirmDelete(record)}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  Delete {record.nice_name}
                </Popconfirm>
              ),
              key: '5',
              danger: true,
              icon: <DeleteOutlined />,
            }
        	)
	        return (
	          <Space size="middle">
	            <Dropdown 
	              menu={{
	                items: items 
	              }}
	            >                
	              <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
	            </Dropdown>
	          </Space>
	        )
	      }
      },
  	];

		const confirmDelete = async (certif) => {
	    const result = await KSPROXY.createCert({Odelete: true, id: certif?.id}, props.server?.Id)
	    if(result && !result.error){
	      messageApi.success('Certificate named '+certif?.nice_name+'  deleted !');
	      getCerts()
	    }else{
	      messageApi.error(result?.message || 'An error occurs, please try again later.');

	    }
	  }

    const onMenuClick = (e) => {
		  console.log('click', e, e.key);
		  if(e.key === "2") setShowCustomModal(true)
		  if(e.key === "1") setShowLetsModal(true)
		};

		const [open, setOpen] = useState(false) 
		const [certs, setCert] = useState(null) 
  	const [loading, setload] = useState(false) 
  	const [customM, setShowCustomModal] = useState(false) 
  	const [letsM, setShowLetsModal] = useState(false) 

		async function getCerts(){
	    setload(true)
	    try{
	    	const result = await KSPROXY.getCertificates(props?.server?.Id)
	    	console.log('result getCerts ', result)
	    	result && setCert(Object.values(result).filter( arr => typeof arr === "object"))
	     // !result && props.setProxyHost([])
	    }catch(e){
	      console.log('Cant get proxy host ', e)
	    }
	    setload(false)
	  }
		useEffect(() => { 
	    // if(accessList){
	    //   let acc0 = {}
	    //   accessList.map((acc, id) =>{
	    //     acc0 = {...acc0, [acc.id]: acc.name}
	    //   })
	    //   setAccessKey({...acessKey, ...acc0})
	    // }
	    getCerts();
	  }, [])

    

    return(
		<div className="w-full">
			<CustomModal
        open={customM} 
        setOpen={setShowCustomModal}
        server={props?.server}
        getCerts={getCerts}

        // host404={onGoingEdit} 
        // setOngoingToEdit={setOngoingToEdit}
      />
			<LetsEncriptModal
        open={letsM} 
        setOpen={setShowLetsModal}
        server={props?.server}
        getCerts={getCerts}
        // get404Host={get404Host}

        // host404={onGoingEdit} 
        // setOngoingToEdit={setOngoingToEdit}
      />
			<div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">SSL Certificates</h5>
				<div className="flex justify-end items-center gap-4">
					<Tooltip 
						overlayStyle={{ lineBreak: 'pre-line' }} 
						title={() =>  <div>
								<p className="font-bold">SSL Certificates</p>
								<p className="mt-2">
									SSL certificates (correctly known as TLS Certificates) are a form of encryption key which allows your site to be encrypted for the end user.
								</p> 
								<p className="mt-2">
									The proxy uses a service called Let's Encrypt to issue SSL certificates for free.
								</p> 
								<p className="mt-2">
									If you have any sort of personal information, passwords, or sensitive data behind the proxy, it's probably a good idea to use a certificate.
								</p>
								<p className="mt-2">
									The proxy also supports DNS authentication for if you're not running your site facing the internet, or if you just want a wildcard certificate.
								</p>

						    </div>
						}>
						<QuestionCircleOutlined className="xl:text-2xl text-xl cursor-pointer text-primary"/>
					  </Tooltip>

					{/*<Button type="primary" onClick={()=> setOpen(true)} icon={<PlusOutlined />}>
			            New Certificate
			        </Button>*/}
			        <Dropdown
			        	type="primary"
					    menu={{
					        items: [
							  {
							    key: '1',
							    label: "Let's Encrypt",
							  },
							  {
							    key: '2',
							    label: 'Custom',
							  }
							],
					        onClick: onMenuClick,
					    }}
				    >
				      	<Button type="primary" icon={<PlusOutlined />}>
			            	Certificate
			     		</Button>
				    </Dropdown>
				</div>
			</div>

			<Table  
                columns={col1} 
                dataSource={certs} 
                loading={!certs || loading}
            />
		</div>
	)
}

function CustomModal(props){
  const [cert, setCert] = useState({})
  const [creation, setCreation] = useState(false)
  const [eMessage, setErrorMessage] = useState(null)
  const [certKey, setCertKey] = useState([]);
  const [cer, setCer] = useState([]);
  const [certIn, setCertIn] = useState([]);
  const {messageApi} = useSelector(app => app.core )



 const propsFile = (fun, varr) => {
 	return {
	  	onRemove: (file) => {
	      fun([]);
	    },
	    beforeUpload: (file) => {
	      fun([file]);
	      return false;
	    },
	    varr,
 	}
};

  async function onCreate(){
  	const data = {
  		provider: "other",
  		...cert
  	}
  	if(!data?.nice_name){
        return setErrorMessage("The name field is required.")
  	}
  	if(!certKey?.[0]){
        return setErrorMessage("Certificate key field is required.")
  	}
  	if(!cer?.[0]){
        return setErrorMessage("Certificate field is required.")
  	}
  	if(!certIn?.[0]){
        return setErrorMessage("Intermediate certificate field is required.")
  	}
    const form_data = new FormData();
    form_data.append('certificate_key', certKey[0])
    form_data.append('certificate', cer[0])
    form_data.append('intermediate_certificate', certIn[0])

    setCreation(true)
    setErrorMessage(false)
    try{
      const result = await KSPROXY.sslFormData(form_data, props?.server?.Id)
      if(result?.error){
      	setCreation(false)
        return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
      }else{
      	const createR = await KSPROXY.createCert(data, props?.server?.Id)
      	if(createR?.code === 201 &&  createR?.id){ 
      		const final = await KSPROXY.sslFormData(form_data, props?.server?.Id, createR?.id)
      		if(final?.error){
		        setCreation(false)
		        return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")

      		}
      	}else{
	        setCreation(false)
        	return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
      	}
      }
      setCreation(false)
      return Utils.sleep(1000).then(() => {
          setCert({})
          props.setOpen(false)
          props.getCerts()
          messageApi.open({
              type: 'success',
              content: 'Done !'
          });
      });
    }catch(e){
      console.log('ON CREATE PROXY HOST ERROR ', e)
    }
    setCreation(true)
    setErrorMessage(false)
  }

  return(
    <Modal
      destroyOnClose={true}
      centered
      footer={null}
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={600}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
             { "Add Custom Certificate" }
          </h4>
        </div>

        {eMessage?
        	<div className='py-3 px-6 w-full'>
	          <Alert  
	          	message={eMessage} 
	          	type="error" 
	          	showIcon
	          	className="w-full overflow-x-auto"
	          />         
	        </div>:
	        null
        }

        <div className='px-6 py-3'>
          <div>
	        <h5 className="font-semibold text-primary">
	        	 Key files protected with a passphrase are not supported.
	        </h5>
            <div className="">
	            <div className="w-full mt-5">
	              <h5 className="font-semibold">Name <span className="text-red-500">*</span></h5>
	              <Input 
	                  onChange={ev => {
	                      setCert({...cert, nice_name: ev.target.value})
	                  }} 

	                  value={cert.nice_name}
	                  className='rounded w-full'
	                  placeholder="Enter the name" 
	              />
	            </div>

	            <div className="w-full mt-5">
	              <h5 className="font-semibold">Certificate Key <span className="text-red-500">*</span></h5>
		            <Upload maxCount={1} {...propsFile(setCertKey, certKey)} className="w-full flex items-center gap-4">
		            	<Button className="w-full" icon={<UploadOutlined />}>Upload</Button>
		            </Upload>
	            </div>
	            <div className="w-full mt-5">
	              <h5 className="font-semibold">Certificate <span className="text-red-500">*</span></h5>

	              <Upload maxCount={1} {...propsFile(setCer, cer)} className="w-full flex items-center gap-4">
		            	<Button className="w-full" icon={<UploadOutlined />}>Upload</Button>
		            </Upload>
	            </div>
	            <div className="w-full mt-5">
	              <h5 className="font-semibold">Intermediate Certificate <span className="text-red-500">*</span></h5>

	              <Upload maxCount={1} {...propsFile(setCertIn, certIn)} className="w-full flex items-center gap-4">
		            	<Button className="w-full" icon={<UploadOutlined />}>Upload</Button>
		            </Upload>
	            </div>
            </div>

          </div>
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                  props.setOpen(false);
                  // props.setEditDetails(null);
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              // loading={action}
              onClick={() =>onCreate()}
              loading={creation}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

function LetsEncriptModal(props){
  const [creation, setCreation] = useState(false)
  const [eMessage, setErrorMessage] = useState(null)
  const {messageApi} = useSelector(app => app.core )
  const [objectEncrypt, setEncrypt] = useState({
  	meta: {
  		letsencrypt_email: "admin@keepsec.info",
  		letsencrypt_agree: false,
  		dns_challenge: false,
  	}
  });

  useEffect(() => {
      // editUser && setUser(editUser)
  }, [])


 const propsFile = (fun, varr) => {
 	return {
	  	onRemove: (file) => {
	      fun([]);
	    },
	    beforeUpload: (file) => {
	      fun([file]);
	      return false;
	    },
	    varr,
 	}
};

  async function onCreate(){
  	const data = {
  		...objectEncrypt,
  		provider: "letsencrypt",
  		domain_names: objectEncrypt?.domain_names?.map(d => d.value)
  	}
  	if(!objectEncrypt?.domain_names?.length){
  		return setErrorMessage({
  			type: "Validation error",
  			message: "Domain name fields is required !"
  		})
  	}
  	if(!objectEncrypt?.meta?.letsencrypt_agree){
  		return setErrorMessage({
  			type: "Validation error",
  			message: "You have to agreee letsencrypt terms and service!"
  		})
  	}
  	if(!objectEncrypt?.meta?.letsencrypt_email){
  		return setErrorMessage({
  			type: "Validation error",
  			message: "LetsEncript email field is required!"
  		})
  	}
  	if(objectEncrypt?.meta?.dns_challenge){
  		if(!objectEncrypt?.meta?.dns_provider){
	  		return setErrorMessage({
	  			type: "Validation error",
	  			message: "Dns provider field is required!"
	  		})
  		}
  		if(!objectEncrypt?.meta?.dns_provider_credentials){
	  		return setErrorMessage({
	  			type: "Validation error",
	  			message: "Creadentials file content field is required!"
	  		})
  		}
  	}

    try{
	    setCreation(true)
	    setErrorMessage(false)
      const createR = await KSPROXY.createCert(data, props?.server?.Id)
      setCreation(false)
      console.log('RESULY CREATE CERT ', createR)
      if(createR?.error){
      	return setErrorMessage({
      		type: createR?.message, 
      		stack: createR?.debug?.stack //() => <pre class="mt-3">{createR?.debug?.stack?.join("\n")}</pre> 
      	})
      }else{
	      return Utils.sleep(1000).then(() => {
	          setEncrypt({})
	          props.setOpen(false)
	          props.getCerts()
	          messageApi.open({
	              type: 'success',
	              content: 'Done !'
	          });
	      });
      }
    }catch(e){
      console.log('ON CREATE PROXY HOST ERROR ', e)
      setCreation(false)
    }
  }
  const onValidate = useCallback((value) => true, [])

  const onAdd = useCallback(
    (newTag) => {
      const d = objectEncrypt.domain_names ||[]
      d.push(newTag)
      objectEncrypt.domain_names = d
      setEncrypt({...objectEncrypt})
    },
    [objectEncrypt?.domain_names]
  )

  const onDelete = useCallback(
    (tagIndex) => {
      setEncrypt({
        ...objectEncrypt, 
        domain_names: objectEncrypt?.domain_names.filter((_, i) => i !== tagIndex)
      })
    }
  )

  return(
    <Modal
      destroyOnClose={true}
      centered
      footer={null} 
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={700}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
             { "Add Let's Encrypt Certificate" }
          </h4>
        </div>


        <div className='px-6 py-3 max-h-[77vh] overflow-y-auto'>
		        {eMessage?
		        	<div className='pb-3 w-full'>
  		          <Alert  
  		          	message={eMessage?.type} 
  		          	description={eMessage?.stack ? 
  		          		<pre className="w-full ">{eMessage?.stack?.join("\n")}</pre>
  		          		:
  		          		eMessage?.message || ""
  		          	} 
  		          	type="error" 
  		          	showIcon
  		          	className="w-full overflow-x-auto"
  		          />         
  		        </div>:
  		        null
		        }
          <div> 
          	<div>
			        <h5 className="font-semibold">Domain Names <span className="text-red-500">*</span></h5>
			        <ReactTags
			          allowNew
			          ariaDescribedBy="custom-tags-description"
			          collapseOnSelect
			          id="custom-tags-demo"
			          placeholderText ="Typ a domain"
			          onAdd={onAdd}
			          onDelete={onDelete}
			          onValidate={onValidate}
			          selected={objectEncrypt?.domain_names}
			          suggestions={[]}
			        />
			      </div>
		        <h5 className="font-semibold text-primary mt-1">
		        	  These domains must be already configured to point to this installation
		        </h5>

            <div className="py-5">
            	<Button  onClick={ () =>{
	                  
	              }}
	              className="w-full font-bold"
		          >
		             Test Server Reachability
		          </Button>
		          <div className="mt-2">
			          <Alert
						      message=""
						      description=" Test whether the domains are reachable from the public internet using Site24x7. This is not necessary when using the DNS Challenge."
						      type="info"
						      showIcon
						    />
			        </div>
		        </div>

            <div className="">
              <div  className="flex flex-wrap justify-between mt-2">
				        <h5 className="font-semibold">Email Address for Let's Encrypt <span className="text-red-500">*</span></h5>
				        <Input 
				            onChange={ev => {
				                setEncrypt({...objectEncrypt, meta: {...objectEncrypt.meta, letsencrypt_email: ev.target.value}})
				            }} 
				            value={objectEncrypt?.meta?.letsencrypt_email}
				            // type="email"
				            className='rounded w-full '  
				            placeholder="Exp: admin@keepsec.info" 
				            // prefix={<SecurityScanFilled />} 
				            //status={!objectHost.domain && "error"}
				        />
				      </div>

				      <div className="flex flex-wrap justify-between mt-5">
				        <div className="flex gap-2 items-center">
				          <Switch 
				            checked={objectEncrypt?.meta.dns_challenge} 
				            className='bg-gray-200'
				            // disabled={!objectSSL.forceSsl}
				            onChange={(checked)=>{
				                setEncrypt({...objectEncrypt, meta: {...objectEncrypt.meta, dns_challenge: checked}})
				            	}
					          } 
				          />
				          <span className="font-semibold">Use DNS Challenge</span>
				        </div>
				      </div>

				      <div style={{display: !objectEncrypt.meta?.dns_challenge && "none"}} className=" mt-5">
				        <div className="bg-gray-200 p-5">
				          <p className="text-red-500">
				             This section requires some knowledge about Certbot and its DNS plugins. 
				             Please consult the respective plugins documentation.
				          </p>
				          <div className="w-full mt-5">
				              <h5 className="font-semibold">
				                DNS Provider ss
				                <span className="text-red-500">*</span>
				              </h5>
				              <Select
					              value={objectEncrypt?.meta?.dns_provider}
					              style={{
					                width: '100%', 
					              }}
					              onChange={(value) =>{
					                  const credential = dns_providers[value].credentials
					                  const newVlaue = dns_providers[value].display_name
									          setEncrypt({...objectEncrypt, meta: {...objectEncrypt.meta, dns_provider: newVlaue, dns_provider_credentials: credential}})
					              }}
					              options={getOption()}
					            />
				          </div>          

				          <div className="w-full mt-5" style={{display: !objectEncrypt.meta?.dns_provider && "none"}}>
				              <h5 className="font-semibold">
				                Credentials File Content 
				                <span className="text-red-500">*</span>
				              </h5>
				              <TextArea 
				                value={objectEncrypt.meta?.dns_provider_credentials}
				                // placeholder="#Enter your custom nginx configuration here at your own risk !" 
				                onChange= {(ev) =>{
								          setEncrypt({...objectEncrypt, meta: {...objectEncrypt.meta, dns_provider_credentials: ev.target.value}})

				                }}
				                rows={3} 
				              />
				              <p className="text-gray-500 text-xs">
				                This plugin requires a configuration 
				                file containing an API token or other credentials to your provider
				              </p>              
				              <p className="text-red-500 text-xs">
				                 This data will be stored as plaintext in the database and in a file!
				              </p>
				          </div>          

				          <div className="w-full mt-5">
				              <h5 className="font-semibold">
				                Propagation Seconds
				              </h5>
				              <InputNumber 
				                onChange={ev => {
								          setEncrypt({...objectEncrypt, meta: {...objectEncrypt.meta, propagation_seconds: ev+""}})
				                }} 
				                value={objectEncrypt?.meta?.propagation_seconds}
				                className='rounded w-full' 
				                // prefix={<SecurityScanFilled />} 
				                // status={!domain_name && "error"}
				            />
				          </div>
				        </div>
				      </div>

				      <div className="flex flex-wrap justify-between mt-5">
				        <div className="flex gap-2 items-center">
				          <Switch 
				            checked={objectEncrypt?.meta.letsencrypt_agree} 
				            className='bg-gray-200'
				            // disabled={!objectSSL.forceSsl}
				            onChange={(checked)=>{
				                setEncrypt({...objectEncrypt, meta: {...objectEncrypt.meta, letsencrypt_agree: checked}})
				            	}
					          } 
				          />
				          <span className="font-semibold">
				          	I Agree to the Let's Encrypt Terms of Service <span className="text-red-500">*</span>
				          </span>
				        </div>
				      </div>

	            
            </div>

          </div>
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                  props.setOpen(false);
                  // props.setEditDetails(null);
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              // loading={action}
              onClick={() =>onCreate()}
              loading={creation}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}