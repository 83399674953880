import React, { useState, useEffect, useCallback } from 'react'
import { Table, Tag, Space, Button, Tooltip, Modal, Tabs, Input, Switch, InputNumber    } from 'antd';
import { PlusOutlined, QuestionCircleOutlined, LockOutlined, WarningOutlined } from '@ant-design/icons';
import { DownOutlined, SmileOutlined, SettingOutlined, DeleteFilled  } from '@ant-design/icons';
import { Dropdown, Select, Typography } from 'antd'
import Utils from '../../../utils'
import { ReactTags } from 'react-tag-autocomplete'

const dns_providers = require('../../../global/certbot-dns-plugins')
const { Text  } = Typography;

const { TextArea } = Input;


function getOption(){
  const arr = []
  for (let [k, v2] of Object.entries(dns_providers)) {
    const v = {}
    v.value = k
    v.label = v2.display_name
    arr.push(v)
  }
  return arr
}
const SchemeForwardPort = ({func, path, data}) => {
    return(
      <div className="flex mt-5 items-center justify-between w-full">
          <div className="w-full md:w-[32%]">
            <h5 className="font-semibold">Scheme <span className="text-red-500">*</span></h5>
            <Select
              value={data.forward_scheme}
              style={{
                width: '100%',
              }}
              onChange={(value) =>func({...data, forward_scheme: value})}
              options={[
                {
                  value: 'http',
                  label: 'HTTP',
                },
                {
                  value: 'https',
                  label: 'HTTPS',
                }
              ]}
            />

          </div>
          <div className="w-full md:w-[32%]">
            <h5 className="font-semibold">Forward Hostname / IP <span className="text-red-500">*</span></h5>
            <Input 
                onChange={ev => {
                  func({...data, forward_host: ev.target.value})
                }} 
                value={data.forward_host}
                className='rounded w-full' 
                placeholder={`Exp: 192.168.1.1${path? path:""}`} 
                // prefix={<SecurityScanFilled />} 
                // status={!domain_name && "error"}
            />
          </div>
          <div className="w-full md:w-[32%]">
            <h5 className="font-semibold">Forward Port <span className="text-red-500">*</span></h5>
            
            <InputNumber 
                onChange={ev => {
                  func({...data, forward_port: ev})
                }} 
                value={data.forward_port}
                className='rounded w-full' 
                placeholder="Eg: 8080" 
                min="1" 
                max="65535"
                // prefix={<SecurityScanFilled />} 
                // status={!domain_name && "error"}
            />
          </div>
        </div>
    )
}

export const Details = ({setObjectHost, isKwaf, objectHost, accessList, acessKey, access_list_id, setAccessListId}) => {
  const [items, setItems] = useState([]);

  function work(){
    const itemsLocale = []

    const first = {
      key: '0',
      label: (
        <span 
          className="flex gap-2 items-start" 
          onClick={()=>{
            setAccessListId("0")
          }}
        >
          {/*<LockOutlined className="text-yellow-700"/>*/}
          <LockOutlined className="text-[#eab308] mt-1"/>
          <div>
            Publicly Accessible <br/>
            <p className="text-xs -mt-1">
              No access restrictions
            </p>
          </div>
        </span>
      ),
    }
    itemsLocale.push(first)
    if(accessList){
      accessList.map((acc, id) =>{
        const stringV = acc?.items?.length > 1  ? acc.items.length + " Users" : acc?.items?.length+" User," +
                       acc?.clients?.length > 1 ? acc.clients.length + " Rules" : acc.clients.length+" Rule -" +
                       Utils.formatDate(acc?.created_on, 'Do MMMM YYYY, h:mm a')

        const item = {
          key: id+1,
          label: (
            <span className="flex gap-2 items-start" onClick={()=>setAccessListId(acc.id)}>
            <LockOutlined className="text-[#15803d] mt-1"/>
            <div>
              {acc.name} <br/>
              <p className="text-xs -mt-1">
                {/*{acc?.items?.length > 1  ? acc.items.length + " Users" : acc?.items?.length+" User"}
                ,
                {acc?.clients?.length > 1 ? acc.clients.length + " Rules" : acc.clients.length+" Rule"}
                -*/}
                {stringV}
              </p>
            </div>
          </span>
          )
        }
        itemsLocale.push(item)
        // acessKey[acc.id] = acc.name
      })
    }
    setItems([...items, ...itemsLocale])
  }

  useEffect(() => {
    work()
  }, [])

  const onValidate = useCallback((value) => true, [])

  const onAdd = useCallback(
    (newTag) => {
      const d = objectHost.domain_names ||[]
      d.push(newTag)
      objectHost.domain_names = d
      setObjectHost({...objectHost})
    },
    [objectHost?.domain_names]
  )

  const onDelete = useCallback(
    (tagIndex) => {
      setObjectHost({
        ...objectHost, 
        domain_names: objectHost?.domain_names.filter((_, i) => i !== tagIndex)
      })
    }
  )

  return(
    <div>
      <div>
        <h5 className="font-semibold">Domain Names <span className="text-red-500">*</span></h5>
        <ReactTags
          allowNew
          ariaDescribedBy="custom-tags-description"
          collapseOnSelect
          id="custom-tags-demo"
          placeholderText ="Type a domain"
          onAdd={onAdd}
          onDelete={onDelete}
          onValidate={onValidate}
          selected={objectHost?.domain_names}
          suggestions={[]}
        />
      </div>

      <SchemeForwardPort  func={setObjectHost} data={objectHost}/>

      <div className="flex flex-wrap justify-between mt-5">
        <div className="flex gap-2 items-center">
          <Switch 
            checked={objectHost.caching_enabled} 
            className='bg-gray-200'
            onChange={(checked)=>setObjectHost({...objectHost, caching_enabled: checked})} 
          />
          <span className="font-semibold">Cache assets</span>
        </div>
        <div className="flex gap-2 items-center">
          <Switch  
            onChange={(checked)=>setObjectHost({...objectHost, block_exploits: checked})} 
            checked={objectHost.block_exploits} 
            className='bg-gray-200'
          />
          <span className="font-semibold">Block common exploits</span>
        </div>
        <div className="flex gap-2 items-center">
          <Switch  
            onChange={(checked)=>setObjectHost({...objectHost, allow_websocket_upgrade: checked})}
            checked={objectHost.allow_websocket_upgrade} 
            className='bg-gray-200' 
          />
          <span className="font-semibold">Websockets support</span>
        </div>
      </div>
      <div className="w-full mt-5">
        <h5 className="font-semibold">Access List</h5>
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <Button className="w-full text-left">
            <Space className="flex justify-between w-full">
              {acessKey[access_list_id]}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>
      {isKwaf ?
        <>
          <div className="w-full mt-5">
            <div className="flex gap-2 items-center">
              <Switch  
                onChange={(checked)=>setObjectHost({...objectHost, use_openappsec: checked})}
                checked={objectHost.use_openappsec} 
                className='bg-gray-200' 
              />
              <span className="font-semibold">K-WAF</span>
            </div>

          </div>

          <div className="md:flex w-full mt-5 items-center justify-between">
            <h5 className="font-semibold md:w-1/2 w-full ">
              Enforcement mode
            </h5>
            <div className=" rounded md:w-1/2 w-full">
              <Select
                // size={size}
                value={objectHost?.openappsec_mode}
                onChange={selected => {
                    setObjectHost({...objectHost, openappsec_mode: selected})
                  }}
                style={{
                  width: "100%",
                }}
                disabled={!objectHost.use_openappsec}
                options={[
                  { value: "detect-learn", label: "Detect learn" },
                  { value: "prevent-learn", label: "Prevent learn" }
                ]}
              />
            </div>
          </div>

          <div className="md:flex items-center justify-between mt-5">
            <h5 className="font-semibold md:w-1/2 w-full">
              Minimum confidence for prevent
            </h5>
            <div className=" rounded md:w-1/2 w-full">
              <Select
                onChange={selected => {
                    setObjectHost({...objectHost, minimum_confidence: selected})
                }}
                disabled={!objectHost.use_openappsec}
                value={objectHost?.minimum_confidence}
                style={{
                  width: "100%",
                }}
                options={[
                  { value: "critical", label: "Critical" },
                  { value: "medium", label: "Medium" },
                  { value: "high", label: "High" }
                ]}
              />
            </div>
          </div>

        </>
        :
        null
      }
    </div>
  )
}

const LocationItem = ({id, setLocation, item, onDelete}) => {
    // const item = locations.find(loc => loc.id === id);
    const [displayNginxConfig, setDisplayNginxConfig] = useState(false)
    // const [item, setItemLocation] = useState(location)

    // function setLocation2(data){
    //   setLocation(data)
    // }
    return(
      <div className="pt-5">
        <div className="border border-1 p-6">
          <div className="flex items-center justify-between">
            <div>
              <h5 className="font-semibold">Define location <span className="text-red-500">*</span></h5>
              <Input 
                onChange={ev => {
                  setLocation({...item, path: ev.target.value})
                }} 
                addonBefore="Location"
                value={item.path}
                className='rounded w-full' 
                placeholder="/path" 
              />
            </div>
            <Button  onClick={ () =>{
                  setDisplayNginxConfig(!displayNginxConfig)
                }}
              >
                  <SettingOutlined />
            </Button>
          </div>

          <SchemeForwardPort path='/path'  func={setLocation} data={item}/>

          <div style={{display: !displayNginxConfig && "none"}} className="mt-5">
                <TextArea 
                  value={item.advanced_config}
                  placeholder="#Enter your custom nginx configuration here at your own risk !" 
                  onChange= {(ev) => setLocation({...item, advanced_config: ev.target.value})} rows={3} 
                />
          </div>
          <div className="mt-5">
            <Button
              // type="primary"
              danger
              icon={<DeleteFilled />}
              // loading={loadings[1]}
              onClick={() => onDelete(item.id)}
            />
          </div>
        </div>
      </div>
    )
}

export const Locations = ({locations, setLoactions}) => {
  return(
    <div>
        <Button  onClick={() =>{
                const locals = locations
                const id = Math.random().toString(36).slice(2, 8)
                locals.push({id, forward_scheme: "https"})
                setLoactions([...locals])
            }}
        >
            New Location
      </Button>
      {locations.map(local => {
        return(
            <LocationItem 
              item={locations.find(loc => loc.id === local.id)} 
              key={local.id} 
              setLocation={
                (data) => {
                  const index = locations.map(e => e.id).indexOf(data.id)
                  const locs = locations;
                  locs[index] = data
                  setLoactions([...locs])
                }
              }
              onDelete={(id) => {
                const newLoc = locations.filter(l => l.id !== id)
                setLoactions([...newLoc])
              }}

            />
        )
      })

      }
    </div>
  )
}


export const SSL = ({objectSSL, setObjectSSL}) => {
  const items = [
    {
      key: '1',
      label: (
        <span className="flex gap-2" onClick={()=>setObjectSSL({certificate_id: 0})}>
          {/*<LockOutlined className="text-yellow-700"/>*/}
          <p>
            None <br/>
            <span className="text-xs">
              This host will not use HTTPS
            </span>
          </p>
        </span>
      ),
      icon: <LockOutlined className="text-[#eab308]"/>
    },
    {
      key: '2',
      label: (
        <span className="flex gap-2" onClick={()=>setObjectSSL({...objectSSL, certificate_id: "new"})}>
          {/*<LockOutlined className="text-yellow-700"/>*/}
          <p>
            Request a new SSL Certificate <br/>
            <span className="text-xs">
              With Let's Encrypt
            </span>
          </p>
        </span>
      ),
      icon: <LockOutlined className="text-green-500"/>
    }
  ];
  return(
    <div>
      <div className="w-full">
        <h5 className="font-semibold">SSL Certificate</h5>
        <Dropdown
          menu={{
            items,
          }}
        >
          <Button className="w-full text-left">
            <Space className="flex justify-between w-full">
              {objectSSL.certificate_id ? "Request a new SSL Certificate" : "None"}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>

      <div className="flex flex-wrap gap-8 mt-5">
        <div className="flex flex-col gap-4">
          <div className="flex gap-2 items-center">
            <Switch 
              checked={objectSSL.ssl_forced} 
              className='bg-gray-200'
              disabled={!objectSSL.certificate_id}
              onChange={(checked)=>setObjectSSL({...objectSSL, ssl_forced: checked})} 
            />
            <span className="font-semibold">Force SSL</span>
          </div>
          <div className="flex gap-2 items-center">
            <Switch 
              checked={objectSSL.hsts_enabled} 
              className='bg-gray-200'
              disabled={!objectSSL.ssl_forced}
              onChange={(checked)=>setObjectSSL({...objectSSL, hsts_enabled: checked})} 
            />
            <span className="font-semibold">HSTS Enabled</span>
          </div>
        </div>


        <div className="flex flex-col gap-4">
          <div className="flex gap-2 items-center">
            <Switch  
              onChange={(checked)=>setObjectSSL({...objectSSL, http2_support: checked})} 
              checked={objectSSL.http2_support} 
              disabled={!objectSSL.certificate_id}
              className='bg-gray-200'
            />
            <span className="font-semibold">HTTP2 Support</span>
          </div>

          <div className="flex gap-2 items-center">
            <Switch  
              onChange={(checked)=>setObjectSSL({...objectSSL, hsts_subdomains: checked})} 
              checked={objectSSL.hsts_subdomains}
              disabled={!objectSSL.hsts_enabled} 
              className='bg-gray-200'
            />
            <span className="font-semibold">HSTS Subdomains</span>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-between mt-5">
        
        
      </div>

      <div style={{display: !objectSSL.certificate_id && "none"}} className="flex flex-wrap justify-between mt-5">
        <div className="flex gap-2 items-center">
          <Switch 
            checked={objectSSL.dns_challenge} 
            className='bg-gray-200'
            // disabled={!objectSSL.forceSsl}
            onChange={(checked)=>setObjectSSL({...objectSSL, dns_challenge: checked})} 
          />
          <span className="font-semibold">Use DNS Challenge</span>
        </div>
      </div>
      <div style={{display: !objectSSL.dns_challenge && "none"}} className=" mt-5">
        <div className="bg-gray-200 p-5">
          <p className="text-red-500">
             This section requires some knowledge about Certbot and its DNS plugins. 
             Please consult the respective plugins documentation.
          </p>
          <div className="w-full mt-5">
              <h5 className="font-semibold">
                DNS Provider 
                <span className="text-red-500">*</span>
              </h5>
              <Select
              value={objectSSL.dns_provider}
              style={{
                width: '100%',
              }}
              onChange={(value) =>{
                  const credential = dns_providers[value].credentials
                  const newVlaue = dns_providers[value].display_name
                  setObjectSSL({...objectSSL, dns_provider: newVlaue, dns_provider_credentials: credential})
              }}
              options={getOption()}
            />
          </div>          

          <div className="w-full mt-5" style={{display: !objectSSL.dns_provider && "none"}}>
              <h5 className="font-semibold">
                Credentials File Content 
                <span className="text-red-500">*</span>
              </h5>
              <TextArea 
                value={objectSSL?.dns_provider_credentials}
                // placeholder="#Enter your custom nginx configuration here at your own risk !" 
                onChange= {(ev) => setObjectSSL({...objectSSL, dns_provider_credentials: ev.target.value})} rows={3} 
              />
              <p className="text-gray-500 text-xs">
                This plugin requires a configuration 
                file containing an API token or other credentials to your provider
              </p>              
              <p className="text-red-500 text-xs">
                 This data will be stored as plaintext in the database and in a file!
              </p>
          </div>          

          <div className="w-full mt-5">
              <h5 className="font-semibold">
                Propagation Seconds
              </h5>
              <InputNumber 
                onChange={ev => {
                  setObjectSSL({...objectSSL, propagation_seconds: ev})
                }} 
                value={objectSSL.propagation_seconds}
                className='rounded w-full' 
                // prefix={<SecurityScanFilled />} 
                // status={!domain_name && "error"}
            />
          </div>
        </div>
      </div>

      <div style={{display: !objectSSL.certificate_id && "none"}} className="flex flex-wrap justify-between mt-5">
        <h5 className="font-semibold">Email Address for Let's Encrypt <span className="text-red-500">*</span></h5>
        <Input 
            onChange={ev => {
                setObjectSSL({...objectSSL, letsencrypt_email: ev.target.value})
            }} 
            value={objectSSL.letsencrypt_email}
            // type="email"
            className='rounded w-full '  
            placeholder="Exp: admin@keepsec.info" 
            // prefix={<SecurityScanFilled />} 
            //status={!objectHost.domain && "error"}
        />
      </div>

      <div style={{display: !objectSSL.certificate_id && "none"}} className="flex flex-wrap justify-between mt-5">
         <div className="flex gap-2 items-center">
          <Switch 
            checked={objectSSL.letsencrypt_agree} 
            className='bg-gray-200'
            onChange={(checked)=>setObjectSSL({...objectSSL, letsencrypt_agree: checked})} 
          />
          <span className="font-semibold">
            I agree to the <a href="https://letsencrypt.org/repository/" target="_blank"> Let's Encrypt Term of services </a> <span className="text-red-500">*</span>
          </span>
        </div>
      </div>
    </div>
  )
}
export const Advanced = ({nginxConfig, setNginCOnfig}) => {
  return(
    <div className="">
      <p className="font-semibold">
        These proxy details are available as nginx variables:
      </p>
      <ul className="pt-5">
        <li><Text code >$server</Text> Forward Hostname / IP </li>
        <li><Text code >$port</Text> Forward Port</li>
        <li><Text code >forward_scheme</Text> Scheme</li>
      </ul>

      <div className="pt-5">
        <h5 className="font-semibold">Custom Nginx Configuration</h5>
        <TextArea 
          value={nginxConfig}
          placeholder="#Enter your custom nginx configuration here at your own risk !" 
          onChange= {(ev) => setNginCOnfig(ev.target.value)} rows={3} 
        />
        <p className="mt-3 text-xs">
          <WarningOutlined />
          Please note, that any add_header or set_header directives added here will 
          not be used by nginx. You will have to add a custom 
          location '/' and add the header in the custom config there.
        </p>
      </div>

    </div>
  )
}



