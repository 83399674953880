export default{
    onClickApps: "",
    app: {
        titlePage: "Webs-apps",
        customAppBtn: "Custom App",
        newOneClickAppBtn: "One-Click Apps",
        customAppModal: {
            title: "New custom app",
            hasPersistantData: "Has persistent data",
            cancel: "Cancel",
            create: "Create",
            myAmazingApp: "my-amazing-app",
        },
        goToCofigPageBtn: "Config",
    },
    listOneClicApp: {
        listAppPageTitle: "On-click APPS",
        selectNewAPP: "Select your one click install app",
        searchPlaceHolder: "Search",
        ________: "_______",

    },
    appDetails: {
        deploy: "deploy",
        deployBtn: "Deploy",
        labelMessageAppNameField: "App Name",
        helpMessageAppNameField: "This is your app name. Pick a name such as my-first-1-click-app",
        setupAppText: "Setup your {{displayName}}",
        errorRegexvalidation: "Invalid value. Does not match Regex: {{validRegex}}"
    },
    appDeploying:{
        h1: "Deploying {{ appName }} {{displayName}} ",
        preventMessage: "This process takes a few minutes to  complete. DO NOT refresh this page and DO NOT navigate away!",
        goBackButton: "Go Back & Try Again",
        deploySuccessMessage: "Deployment success!",
        deploySuccessTitle: "Success",
        goListAppButton: "Go to list app page ",
        deplyErrorTitle: "Error",
        parsingTemplate: "Parsing the template",
        parsingTemplateErrorMesssage: "Cannot parse the template. Dependency tree cannot be resolved. Infinite loop!",
        parsingTemplateErrorMesssage2: "Cannot parse the template. No services found!",
        registering: "Registering {{appName}} ",
        configuring: "Configuring {{appName}} (volumes, ports, environmental variables",
        deploying: "Deploying {{appName}} (might take up to a minute)",
        ________: "_______",
        
    },
    configPage: {
        titlePage: "Config",
        appNotFound: "App not found!",
        saveRestartBtn: "Save & Restart",
        deleteAppBtn: "Delete App",
        httpSettingsTab: {
            title: "HTTP Settings",
            httpsEnabledSuccessMessage: "HTTPS is now enabled for your app",
            removeCustomDomainSuccesssMessge: "Your custom domain is successfully removed!",
            httpsActivatedSuccessMssg: "HTTPS is successfully activated for your domain!",
            editnginxConfigBtn: "Edit Default Nginx Configurations",
            newDomainConnectedSUccessMessage: "New domain is now successfully connected!",
            alreadyACtivated: "Already activated",
            clickToActive: "Click to activate HTTPS for this domain",
            htppsEnabled: "HTTPS enabled",
            enabledHtpps: "Enable HTTPS",
            removeDomainBtn: "Remove",
            doNotExposeApp: "Do not expose as web-app",
            dontExposeHelpMessage: "Use this if you don't want your app be externally available.",
            appPublicAvailableUrl: "Your app is publicly available at",
            newDomainHelpMessage: "Make sure the new domain points to this IP, otherwise verification will fail.",
            connectNewDomain: "Connect New Domain",
            containerPortHelpMessage: "HTTP port inside the container. Default is 80. Change only if the app is running in a different port. This is used only for HTTP apps, not databases.",
            containerPortBefore: "Container HTTP Port",
            forceRedirectHtpps: "Force HTTPS by redirecting all HTTP traffic to HTTPS",
            forceRedirectHttpsTooltip: "Forcing HTTPS causes domains without HTTPS to malfunction. Make sure you enable HTTPS for the domain you want to use, before enabling Force HTTPS option.",
            websockedtSupport: "Websocket Support",
            websockedtSupportTooltip: "Adds the upgrade proxy headers to NGINX config",
            editBasicAuth: "Edit HTTP Basic Auth",
            currentState: "Current State",
            inactive: "inactive",
            active: "active",
            sslActivtedMessage: "SSL Certificate is already activated",
            dangerousHtml1: "Templates are built using EJS template pattern. Do not change the areas between <code>&lt;%</code> and&nbsp; <code>%&gt;</code> , unless you really know what you're doing! To revert to default, simply remove all the content.",
            dangerousHtml2: "Your app is internally available as <code class='text-red-500 bg-red-50'>srv-captain--{{appName}}</code> to other Captain apps. In case of web-app, it is accessible via <code class='text-red-500 bg-red-50'>{`http://srv-captain--{{appName}}`}</code> from other apps.",
            basicAuthModal: {
                title: "Edit HTTP Basic Auth",
                p1: "HTTP Basic authentication is the simplest way to enforce access controls to web resources.",
                p2: "You can use it to restrict access to HTTP apps, especially those you create via the One-Click app deployments such as phpMyAdmin, etc.",
                password: "password",
                username: "username",
                confirm: "Confirm",
                cancel: "Cancel"

            },
            ________: "_______",

        },
        appConfigsTab: {
            title: "App Configs",
            portNotUsedTooltip: "Make sure the port is not already used!",
            servPort: "Server Port",
            containerPort: "Container Port",
            noTagAssoc: "Currently no service tag is associated with this service",
            pathInApp: "Path in App",
            label: "Label",
            pathInhostTooltip: "IMPORTANT: Ensure Host Path exists before assigning it here",
            pathInHost: "Path on Host",
            letKeepSecManage: "Let KeepSec manage path",
            setSpecific: "Set specific host path",
            addPersistantDirectory: "Add Persistent Directory",
            leaveEmpty: "Leave empty for automatic placement",
            nodeId: "Node ID",
            changeNodeIdTooltip: "WARNING: Changing Node ID causes the content of your persistent directories to be deleted!",
            editNodeIdButton: "Edit",
            addKeypair: "Add Key/Value Pair",
            envVars: "Environmental Variables",
            notHavingEnvVars: "Currently, this app does not have any custom environmental variables yet.",
            buildEdit: "Bulk Edit",
            portMap: "Port Mapping",
            notHavingportmapping: "Currently, this app does not have any custom port mapping.",
            addPortmappingBtn: "Add Port Mapping",
            persistantDirectory: "Persistent Directories",
            notHavingPersiDirrectory: "Currently, this app does not have any persistent  directories.",
            nberRunningRunn: "Number of running instances of this app",
            instanceCount: "Instance Count",
            multipleInstancePreventTooltip: "Multiple instances of apps with persistent data can be very dangerous and bug prone as they can be accessing the same file on the disk resulting in data corruption. Edit the instance count only if you understand the risk.",
            multipleInstancePreventEditBtn: "Edit",
            prevScript: "Pre-Deploy Script",
            servupOveride: "Service Update Override",
            nothavingPersisDirectory: "Currently, this app does not have any persistent directories.",
            servTags: "Service Tags"
        },
        deploymentTab: {
            title: "Deployment",
            viewAppLogs: "View app logs",
            hideAppLogs: "Hide App Logs",
            viewBuildLogs: "View app build logs",
            hideBuildLogs: "Hide App Build Logs"
        },
    },
    deleteModalApp: {
        appNameDontMatch: "App name did not match. Operation cancelled.",
        appDeleted: "App deleted!",
        title: "Delete this app?",
        warning: "Warning",
        aboutToDelete: "You are about to delete",
        notreversible: "Please note that this is not reversible.",
        p1: "Please select the volumes that you want to delete. If any of the volumes are being used by other KPS apps, they will not be deleted.",
        p2: "Note: Deleting volumes takes more than 10 seconds, please be patient.",
        cinfigAppName: "Confirm app name",
        appNotGoodDeleted: "App deleted but some volumes weren't deleted because they were probably being used by other containers.",
        confirmDelete: "Yes delete",
        cancel: "Cancel",
        undestand: "I understand",

    },
    ________: "_______",
}



