import { Checkbox, Result } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import Paypal from 'components/PaypalModal';
import UserAccountConfirmPrompt from 'components/UserAccountConfirmPrompt';
import AddBotom from 'components/vps/AddBotom';
import { AddCloudServiceForm } from 'components/vps/AddCloudServiceForm';
import { VpsSecMiniPack } from 'components/vps/Packages';
import PromoBottom from 'components/vps/PromoBottom';
import { ServerLocation } from 'components/vps/ServerLocation';
import TrialBottom from 'components/vps/TrialBottom';

import VPSminisec from "../../../assets/img/link/VPSsecmini-logo.png"
import VPSminisecW from "../../../assets/img/link/VPSsecmini.png"
import linux from '../../../assets/img/linux.png'
import almalinux from '../../../assets/img/sec/almalinux.png'
import centos from '../../../assets/img/sec/centos.png'
import debian from '../../../assets/img/sec/debian.png'
import ubuntu from '../../../assets/img/sec/ubuntu.png'
import { AddServiceSectionTitle, DeployAdAdmin, InputPassAdd, InputTextAdd, LabelAdd, Spiner } from '../../../components/Bloc';
import { Toast } from '../../../components/Notify';
import VpsImage from '../../../components/VpsImage'
import UserMgt from '../../../lib/user_managment';
import VPSapi from '../../../lib/vps_service';
import Utils from '../../../utils';

const imgs = {
    ubuntu,
    debian,
    almalinux,
    centos,
    linux
}


export default function AddVpsSecMini(props) {
    const {isTrial, isPromo} = props
    const { flavors } = useSelector(app => app.vps)
    const images = useSelector(app => {
        const imgs = app.vps.images
        const OS = ["Ubuntu", "AlmaLinux", "Debian", "CentOS"];
        const Tab = []
        if (app.vps.images) {
            for (let i = 0; i < OS.length; i++) {
                const os = OS[i];
                const concerts = imgs.filter(img => img.name.toLowerCase().includes(os.toLowerCase()))
                Tab.push({ name: os, list: concerts, id: i })
            }
            return Tab
        }
        return null;
    })
    const user = useSelector(app => app?.user?.user)
    
    const { vps_packages } = useSelector(app => app.vps)

    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [clickedImage, setClickedImage] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [paypalpack, setPackPaypal] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)
    const [link, setlink] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        flavor: null,
        image: null,
        username: "",
        password: "",
        plan_id: null,
        planName: null,
        isMiniSec: true,
        serviceType: "VPS_INSTANCE",
        trialCode: null,
        promoCode: null,
        location: null,
        isWithPromo: isPromo,
        isWithTrial: isTrial,
        vpsTypeId: null,
        isAdminFreeDeployment: null
    })


    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)



    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    async function loadFlavors() {

        if (!flavors) {
            const r = await VPSapi.flavor_list()
            return r
        }

        return flavors
    }
    async function loadImages() {
        !images && VPSapi.image_list()
    }
    async function getVpsTypes() {
        if (!vps_packages) {
            const r = await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        }

        return vps_packages
    }


    useEffect(() => {

        if (Utils.getUrlParam("code")) {
            setVpsParams({ ...vps_params, trialCode: Utils.getUrlParam("code"), promoCode: Utils.getUrlParam("code") })
            onApplyCode(Utils.getUrlParam("code"))
        }
        loadFlavors().then(f => {
            getVpsTypes().then(vps_packages => {

                if (Utils.getUrlParam('prefer')) {
                    const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                    const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                    if (pack) {
                        setClicked(parseInt(selectedIdVpsTypes));
                        onClaculFlavor(pack, f)
                        setPackPaypal(pack.Name);

                    }
                    window.localStorage.removeItem('@user_first_service')

                }
            })

        })
        loadImages()


    }, [])




    async function onApplyCode(code){
        if(!isPromo) return
        try{
            let c = code || vps_params.promoCode
            if(c && c.trim() !== ""){
                const result = await UserMgt.check_promo_code(c);
                if(result && result.code){
                    return setCodeApplied(result?.code)
                }else{
                  return Toast.fire({
                        icon: 'error',
                        title: "Invalid promotion code"
                    })  
                }
            }else{
                return Toast.fire({
                    icon: 'error',
                    title: "Please enter promotion code first !"
                })
            }

        }catch(e){
            console.log('error applying code', e)
        }

        return Toast.fire({
            icon: 'error',
            title: "An error occurred, try again or contact us !"
        })
    }

    async function deployVps(paymentId, dataPayment) {
        console.log('vps_params vps_params', vps_params)
        const result = await UserMgt.create_vps({ ...vps_params, paymentId }, dataPayment)
        setCreating(false)
        if (!result || result.error || result.status !== "success") {
            Toast.fire({
                icon: 'error',
                title: result.message || "An error occurred while running. If this persist, please contact us"
            })
        } else {
            Toast.fire({
                icon: 'success',
                title: result.message || "VPS Instance created"
            })
            const links = result?.result?.links
            console.log('links links links', links);
            const link = links && links.length && links.find(l => l.rel === "approve")
            if(link){
                setlink(link?.href)
                window.open(link?.href, "Confirm", "width=500,height=500, fullscreen=yes")
            }
            if(result&& !result.needToConfirm){
                await UserMgt.get_user_vps() 
                navigate('/compute/vpssecmini')
            }
        }

    }
    async function onPay(ev) {
        console.log('vps_params vps_params', vps_params)
        ev.preventDefault()
        // return;
        if(link){
            return  window.open(link, "Confirm subscription", "width=500,height=500, fullscreen=yes")
         }
        if (!vps_params.image) {
            return Toast.fire({
                icon: 'error',
                title: "Image not selected"
            })
        }
        if (!vps_params.flavor) {
            return Toast.fire({
                icon: 'error',
                title: "Package not selected"
            })
        }
        if (!vps_params.location) {
            return Toast.fire({
                icon: 'error',
                title: "Service location not selected"
            })
        }
        console.log('deploiement params ****', vps_params)

        if (vps_params.password.length < 6) {
            return Toast.fire({
                icon: 'error',
                title: "Password must be at least 6 characters"
            })
        }

        if (vps_params.passwordCon !== vps_params.password) {
            return Toast.fire({
                icon: 'error',
                title: "Passwords did not match"
            })
        }
        if (Utils.testPassworsVps(vps_params.password)) {
            return Toast.fire({
                icon: 'error',
                title: "Password should not contains empty space"
            })
        }
        if (isPromo && !codeApplied) {
            return Toast.fire({
                icon: 'error',
                title: "You should apply promo code !"
            })
        }

        setCreating(true)
        if(!vps_params.isAdminFreeDeployment && !Utils.isDemo()){
            if(!isPromo){
                if(vps_params.trialCode && isTrial){
                    const r = await UserMgt.check_trial_code(vps_params.trialCode)
                    const isValid = r && r.code && (!!r.code.IsMulti ||  !(!!r.code.IsUsed ));
                    if(!isValid ){
                        setCreating(false)
                        setPaypal(false) 
                        return Toast.fire({
                            icon: 'error',
                            title: "Trial code is not valid !"
                        })
                    }
                }

                setPaypal(true)
            }else{
                return deployVps()
            }
        }else{
            return deployVps()
        }
    }

    function onClaculFlavor(pack, f) {
        setPack(pack)
        const f2 = f || flavors
        const flavor = f2 && f2.find(fl => fl.name === pack?.OpenstackFlavorName);
        console.log('Flavor found:', flavor,  pack?.PaypalPackId)
        if (flavor){ 
            setVpsParams({ 
                    ...vps_params, 
                    flavor: flavor.name, 
                    plan_id: (isTrial? pack.PaypalPackIdFree : pack.PaypalPackId),
                    vpsTypeId: pack.Id
                }
            )
        }

    }


    function onDismiss() {
        setCreating(false)
        setPaypal(false)

    }
    function createPaymentAndDeploy(paymentID, dataPayment) {
        setPaypal(false)
        const dataPayment2 = {
            ...dataPayment,
            withFirstTrial: isTrial,
            amountPaid: parseFloat(packSelected?.Price),

        }
        
        return deployVps(paymentID, dataPayment2)



    }
    function calCulFinalPrice(){
        if(packSelected){

            return parseFloat((packSelected.Price) * (1 - (codeApplied ? codeApplied.Percent / 100 : 0))).toFixed(2)
        }else{
            return 0;
        }
    }

    const concertPack = vps_packages &&
        vps_packages.filter(p => p.Package_Type === "VPS" && p.IsMiniSec)
            .sort((a, b) => parseInt(a.Price) - parseInt(b.Price))

    return (
        <div className='relative'>
            
            <div className={user && !user?.EmailConfirmed ?'opacity-[20%]'  : ''}>
                <>

                    <Paypal
                        createPaymentAndDeploy={createPaymentAndDeploy}
                        open={paypal}
                        vpsType={packSelected}
                        onDismiss={onDismiss}
                        withFirstTrial={isTrial}
                        withPromo={isPromo}
                        finalPrice={calCulFinalPrice}
                    />

                    <div className="px-3 md:px-8 relative  dark:text-darkTextColor">
                        <div className="container max-w-full">
                            <div className='mx-auto'>
                                <ServerLocation vps_params={vps_params} setVpsParams={setVpsParams} />
                                <AddServiceSectionTitle
                                    title="Choose your instance plan"
                                />
                                <DeployAdAdmin
                                    title="Choose your instance plan"
                                    creating={creating}
                                    vps_params={vps_params}
                                    setVpsParams={setVpsParams}
                                />
                                {concertPack ?
                                    <form onSubmit={onPay} className="">
                                        <div className=" 2xl:gap-10 md:gap-6 gap-3 mt-5 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap  justify-center ">
                                            <VpsSecMiniPack 
                                                concertPack={concertPack}
                                                setSelected={setSelected}
                                                creating={creating}
                                                clicked={clicked}
                                                setClicked={setClicked}
                                                onClaculFlavor={onClaculFlavor}
                                                setPackPaypal={setPackPaypal}
                                                selected={selected}
                                                noMxauto
                                            />
                                        </div>

                                        <AddServiceSectionTitle
                                            title="Pick your instance image"
                                        />

                                        <div className="mx-auto relative  justify-center pb-5 border-b-2 dark:border-gray-800 flex flex-wrap 2xl:gap-10 md:gap-6 gap-3  mt-5">
                                            {images && images.filter(image => true).map((image, k) =>
                                                <VpsImage
                                                    key={k}
                                                    image={image}
                                                    creating={creating}
                                                    clickedImage={clickedImage}
                                                    setClickedImage={setClickedImage}
                                                    setVpsParams={setVpsParams}
                                                    vps_params={vps_params}
                                                    setSelectedImage={setSelectedImage}
                                                    selectedImage={selectedImage}
                                                    uri={imgs[Object.keys(imgs).find(k => image.name.toLowerCase().includes(k)) || 'linux']}
                                                />
                                            )}

                                        </div>
                                        <AddServiceSectionTitle
                                            title="Server Informations"
                                        />
                                        <div className={'md:w-5/6 mx-auto pt-10 px-20 ' + (creating && paypal ? " pb-72 md:pb-56" : 'pb-56 md:pb-48')}>
                                            <AddCloudServiceForm
                                                creating={creating}
                                                paypal={paypal}
                                                vps_params={vps_params}
                                                handleChange={handleChange}
                                            />
                                            
                                            {
                                                isTrial?
                                                    <TrialBottom 
                                                        creating={creating}
                                                        paypal={paypal}
                                                        packSelected={packSelected}
                                                        vps_params={vps_params}
                                                        handleChange={handleChange}
                                                    />
                                                :
                                                isPromo ?
                                                    <PromoBottom 
                                                        creating={creating}
                                                        paypal={paypal}
                                                        packSelected={packSelected}
                                                        vps_params={vps_params}
                                                        handleChange={handleChange}
                                                        codeApplied={codeApplied}
                                                        onApplyCode={onApplyCode}
                                                    />
                                                :
                                                <AddBotom 
                                                    creating={creating}
                                                    paypal={paypal}
                                                    packSelected={packSelected}
                                                />

                                            }
                                        </div>
                                    </form>
                                    :
                                    <div className='flex justify-center pt-48'>
                                        <Spiner fontSize={50} />
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </>
            </div>
            {user && !user?.EmailConfirmed ?
                <UserAccountConfirmPrompt user={user} />
                :
                null
            }
            
        </div>
    );
}




