import {  SAVE_STORAGE_LIST } from "../action/volumeAction";

const defaultState = {
    list: null
};
  
  
  export default function volume(state = defaultState, action) {
    switch (action.type) {
      case SAVE_STORAGE_LIST:
        return { ...state, list: action.list };
      default:
        return {...state};
    }
  }
  