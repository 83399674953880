import { message } from 'antd'
import { Spiner } from 'components/Bloc'
import UserMgt from 'lib/user_managment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react' 
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { updateHeadLink } from 'reducer/action/coreActions'
import server_img from '../../assets/img/connect/server.png'
import monitoring_img from '../../assets/img/connect/monitoring.png'
import networking_img from '../../assets/img/connect/networking.png'
import touch_img from '../../assets/img/connect/touch.png'
import trust_img from '../../assets/img/connect/trust.png'
import vullify from "../../assets/img/connect/vullify-core.png"

const tools = [
	{
		img: server_img,
		title: "Server",
		task_name: "managment",
		desc: "Server managment",
		isSec: true,
		isMiniSec: true
	},
	{
		img: touch_img,
		title: "KSProxy",
		desc: "Expose your service securely",
		isSec: true,
		isMiniSec: true
	},
	{
		img: vullify,
		title: "Vullifycore",
		desc: "Vullifycore scanner",
		isSec: true,
		isMiniSec: true
	},
	{
		img: networking_img,
		title: "Networking",
		desc: "Infrastructure and server network managment",
		isSec: true
	},
	{
		img: trust_img,
		title: "KPZtrust",
		desc: "True zero trust",
		isSec: true,
	},
	{
		img: monitoring_img,
		title: "Monitoring",
		isSec: true
	}
]
export default function VpsOptions(props){
    const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
    const [server, setvps] = useState(null)
    const [messageApi] = message.useMessage()

    const onNavigate = async (t) => {
		if(t.task_name==="managment"){
			navigate('/compute/vps/manage/'+server?.Id+"?from-conect=true")
		}
	}
    const loadVps = async () => {
		const vps = await UserMgt.getUniqueVps(id) //.user_vps
		if(!vps?.error){
            setvps(vps?.server)
			return vps?.server
		}else{
			messageApi.open({
				type: 'error',
				content: 'An error occured. If this persist, please tell us',
			});
			return navigate(-1)
		}
	}

    useEffect(() => { 

        let headLinks = [];
		loadVps().then((vps) => {
			if(vps && vps.IsMiniSec){
				headLinks = [
					{
						link: "/compute/vpssecmini",
						item: 'vpssecmini',
						alt: "VPSminisec short link"
					},
					{label: vps?.DisplayName, arrow: true},
				]
			}else{

				headLinks = [
					{
						link: "/compute/vps",
						item: 'vps',
						alt: "VPS short link"
					},
					{label: vps?.DisplayName, arrow: true},
				]
			}
			dispatch(updateHeadLink(headLinks))
				
		})
		return () => dispatch(updateHeadLink([]))
	}, [])


    return(
        <>
			<div className=" h-screen overflow-y-auto pb-32 dark:text-darkTextColor  ">
				<div className="container mx-auto max-w-full px-3 md:px-8 h-full">
					{server ?
                        <div className='flex gap-3 flex-wrap'>
							{server?.IsSec ?
							 tools.map((t, k) =><Item key={k} navigate={onNavigate} tool={t} />)
							 :
							 tools.filter(t => t.isMiniSec).map((t, k) =><Item navigate={onNavigate} key={k} tool={t} />)
							}
                        </div>
						:
						<div className='flex my-auto h-full justify-center items-center'>
						    <Spiner fontSize={50} />
						</div>
                    }

                    
                </div>
            </div>
        </>
    )
}

function Item({tool, navigate}){
	return(
		<div  className=" mt-5 relative">
			<div 
				onClick={()=> navigate(tool)}
				className='w-56 xl:w-60 2xl:w-64 cursor-pointer rounded-xl dark:bg-bgPagedark bg-neutral-100 dark:hover:shadow-slate-700'>

				<div className='py-5 text-center text-primary dark:text-darkTextColor'>
					<div className="flex  items-center justify-center mx-auto">
						<span className='font-semibold text-base md:text-xl 2xl:text-xl '> 
							{tool.title}
						</span>
					</div>
					<div className='h-10 flex items-center justify-center'>
						<p   className='font-semibold text-sm my-auto'>
							{tool.desc}
						</p>
					</div>
					<div  className='mx-auto px-28 py-2'>
						{tool.desc ?<div className='border-t-4 rounded-full border-t-primary'/> : null}
					</div>
					<img src={tool.img} alt="" className='2xl:h-12  h-10 mx-auto' />
				</div>
			</div>
		</div>
	)
}
