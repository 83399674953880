

export function IpAddressOption(props){
    return(
        <div  className=" mt-5 relative">
            <div 
                onMouseOver={() => props.setHoveredIpCard(props?.data.id)}
                onClick={() => {
                    if (!props.creatingInfra) {
                        if(props.clickedIpCard == props?.data.id){
                            props.setClickedIpCard(null);
                        }else{
                            props.setClickedIpCard(props?.data.id);
                        }
                        
                    }    
                }}
                className={'w-56 xl:w-60 2xl:w-64 cursor-pointer rounded-xl dark:bg-bgPagedark ' +
                    ((props.hoveredIpCard == props?.data.id) ? "hover:bg-white  hover:shadow-2xl " : '') + "" + ((props.clickedIpCard == props?.data.id) ? "bg-white shadow-2xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')}>

                {(props.clickedIpCard == props?.data.id) &&
                    <svg className="div_check checkmark absolute top-0 right-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                }
                <div className='pt-4 pb-10 text-center text-primary dark:text-darkTextColor'>
                
                    {/* <img src={props?.data.icon} alt="" className='w-10 h-10 mx-auto' /> */}
                    <span className='font-bold text-2xl'> {props?.data.label}</span>
                    <br/>
                    <span className='font-semibold text-xs'> {props?.data.ip}</span>
                    <div className='mx-auto px-28 py-2'>
                        <div className='border-t-4 rounded-full border-t-primary'/>
                    </div>
                    <p className='font-bold text-sm 2xl:text-base'> 
                        {props?.data.ipAdressAllocation} allocable IP address
                    </p>
                </div>
            </div>
        </div>
    )
}