
export default{
    products: "Products" ,
    compute: "Compute" ,
    database: { label: "Database" },
    platform: { label: "Platform" },
    kubernetes: { label: "Kubernetes" },
    infrastructure: { label: "Infrastructure" },
    security: { label:"Security" },
    networking: { label: "Networking" },
    storage:{
        label: "Storage",
        link1: "Volume Block Storage"
    },
    account: {
        label: "Account",
        link1: "Profile",
        link2: "Reward",
        link3: "Billing"
    },
    support: {
        label: "Support"
    },
    order: "Order"
}
