import React, { useEffect, useState } from 'react'
import { SiNginxproxymanager } from "react-icons/si";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdOutlineStream } from "react-icons/md";
import { TbError404 } from "react-icons/tb";


export function PDashboard(props){
	const { proxyHosts, redirectHosts, streams, hosts404 } = props
	const [items, setItems] = useState([])

	useEffect(()=>{
		const items = [
			{
				name: "Proxy Hosts",
				key: "proxy_hosts",
				count: proxyHosts?.length,
				icon: () => <SiNginxproxymanager className="text-primary xl:text-3xl text-2xl" />

			},
			{
				name: "Redirection Hosts",
				key: "redirection_hosts",
				count: redirectHosts?.length,
				icon: () => <FaExternalLinkAlt className="text-primary xl:text-3xl text-2xl" />
			},
			{
				name: "Streams",
				count: streams?.length,
				key: "streams",
				icon: () => <MdOutlineStream className="text-primary xl:text-3xl text-2xl" />
			},
			{
				name: "Hosts",
				count: hosts404?.length,
				key: "404_hosts",
				icon: () => <TbError404 className="text-primary xl:text-3xl text-2xl" />
			},
		]
		setItems([...items])
    }, [proxyHosts, redirectHosts, streams, hosts404])

	return(
		<div className="flex justify-between flex-gap gap-4">
			{items.map(item =>
				<div 
				    key={item.key} 
				    onClick={() => props.onSubItem(item.name)}
				    className={'w-60 relative dark:bg-bgPagedark py-2  cursor-pointer  bg-neutral-100 dark:hover:shadow-slate-700'}>
	                <div className=' relative items-center gap-3 flex pl-4 pr-8 py-[4px]'>
	                    {item.icon()}
	                    <p className="font-semibold text-sm xl:text-base">
	                    	<span className="font-bold">
	                    		{item.count || 0} 
	                    	</span>
	                    	{" "+item.name}
	                    </p>
	                </div>
	            </div>
	        )}
		</div>
	)
}