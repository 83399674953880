import {  LIST_KSLB_SERVERS } from "../action/kslbAction";

const defaultState = {
    list: null
};
  
  
  export default function kslb(state = defaultState, action) {
    switch (action.type) {
      case LIST_KSLB_SERVERS:
        return { ...state, list: action.list };
      default:
        return {...state};
    }
  }
  