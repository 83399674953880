export const SET_HEADER_LINK = "@@APP/SET_HEADER_LINK"
export const SET_NOTIFICATION_HELPER = "@@APP/SET_NOTIFICATION_HELPER"
export const SET_LOCATION = "@@APP/SET_LOCATION"
export const SET_NETWORKS = "@@APP/SET_NETWORKS"
export const FORCE_RELOAD = "@@APP/FORCE_RELOAD"
export const DISPLAY_ALL_SIDEBAR_ITEMS = "@@APP/DISPLAY_ALL_SIDEBAR_ITEMS"
export const SAVE_PAYMENT_METHOD = "@@APP/SAVE_PAYMENT_METHOD"
export const SAVE_DELETABLE_SERVICE = "@@APP/SAVE_DELETABLE_SERVICE"
export const SAVE_DATA_LOCATION = "@@APP/SAVE_DATA_LOCATION"
export const SAVE_REBOOTABLE_SERVICE = "@@APP/SAVE_REBOOTABLE_SERVICE"
export const SAVE_STOPABLE_SERVICE = "@@APP/SAVE_STOPABLE_SERVICE"
export const SAVE_STARTING_SERVICE = "@@APP/SAVE_STARTING_SERVICE"
export const BLUEPRINT_CONFIRM_MODAL = "@@APP/BLUEPRINT_CONFIRM_MODAL"

export function updateHeadLink(headLink, push){
    return {
        type: SET_HEADER_LINK,
        headLink : headLink, 
        push: push
      }
}
export function setNotificationHelper(api){
    return {
        type: SET_NOTIFICATION_HELPER,
        api: api
      }
}
export function setLocation(locations){
    return {
        type: SET_LOCATION,
        locations: locations
      }
}
export function setNetworks(nets){
    return {
        type: SET_NETWORKS,
        networks: nets
      }
}
export function forceReload(){
    return {
        type: FORCE_RELOAD,
      }
}

export function dontDisplayAllItemsItems(checked){
    localStorage.setItem("stillOnDemo", checked);
    return {
        type: DISPLAY_ALL_SIDEBAR_ITEMS,
        shouldDisplay: checked
      }
}
export function save_paymentMethod(methods){
    return {
        type: SAVE_PAYMENT_METHOD,
        paymentMethods: methods
      }
}
export function save_deletable_service(service, returnLink){
    return {
        type: SAVE_DELETABLE_SERVICE,
        service: service,
        returnLink: returnLink
      }
}
export function save_rebootable_service(service, returnLink){
    return {
        type: SAVE_REBOOTABLE_SERVICE,
        service: service,
        returnLink: returnLink
      }
}
export function save_stopable_service(service, returnLink){
    return {
        type: SAVE_STOPABLE_SERVICE,
        service: service,
        returnLink: returnLink
      }
}

export function save_starting_service(service){
    return {
        type: SAVE_STARTING_SERVICE,
        service: service
      }
}
export function show_blueprint_confirm_modal(service, blueprint){
    return {
        type: BLUEPRINT_CONFIRM_MODAL,
        service: service,
        blueprint: blueprint,
      }
}
export function save_geo_location(data){
    return {
        type: SAVE_DATA_LOCATION,
        data: data
      }
}