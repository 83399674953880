import { save_storage_list } from "reducer/action/volumeAction"
import store from "../reducer"
import UserAPI from './keepsec_auth_service'

const {  get, post } = require("./api")


const SERVICE_URL = process.env.REACT_APP_USER_MANGMENT_API //http://72.10.163.146"
const  apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}


const getVolumeSize = async () => {
    try {
        await UserAPI.refrest_token()

        const data = await get(SERVICE_URL + "v1/storage/volume-size", header)
        console.log('user sizes', data)
        return data
    } catch (error) {
        console.error('error wrong when try to logout user >>', error)
        return { error: true, message: error.message } 
    }
};

const list = async () => {
    try {
        await UserAPI.refrest_token()
        
        const data = await get(SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/storage/volume", header)
        if(data?.volumes){
            store.dispatch(save_storage_list(data?.volumes)) 
        }
        return data
    } catch (error) {
        console.error('error wrong when try to logout user >>', error)
        return { error: true, message: error.message } 
    }
};

const createVolume = async (data) => {
    try {
        await UserAPI.refrest_token()

        const result = await post(SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/storage/volume", data, header)
        console.log('user sizes', result)
        return result
    } catch (error) {
        console.error('error wrong when try to logout user >>', error)
        return { error: true, message: error.message } 
    }
};
const mkAction = async (data) => {
    try {
        await UserAPI.refrest_token()
        const result = await post(SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/storage/volume/action", data, header)
        console.log('user sizes', result)
        return result
    } catch (error) {
        console.error('error wrong when try to logout user >>', error)
        return { error: true, message: error.message } 
    }
};

export default {
    getVolumeSize,
    createVolume,
    list,
    mkAction
}