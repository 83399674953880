export const SAVE_USER_INFO = "@@USER/SAVE_USER_INFO"
export const USER_SOUSCRIPTION = "@@USER/USER_SOUSCRIPTION"
export const USER_PAYMENT = "@@USER/USER_PAYMENT"
export const REFRESH_TOKEN = "@@USER/REFRESH_TOKEN"
export const UPDATE_USER_PROFILE = "@@USER/UPDATE_USER_PROFILE"
export const CAN_NAVIGATE_2FA = "@@USER/CAN_NAVIGATE_2FA"

export function save_user_info(data){
    return {
        type: SAVE_USER_INFO,
        data : data, 
      }
}
export function save_user_profile(data){
    return {
        type: UPDATE_USER_PROFILE,
        data : data, 
      }
}

export function list_subscription(data){
    return {
        type: USER_SOUSCRIPTION,
        data : data, 
      }
}
export function list_payment(data){
    return {
        type: USER_PAYMENT,
        data : data, 
      }
}
export function update_refreshToken(refreshToken){
    return {
        type: REFRESH_TOKEN,
        refreshToken : refreshToken, 
      }
}
export function navigateTo2fa(state){
    return {
        type: CAN_NAVIGATE_2FA,
        state, 
      }
}