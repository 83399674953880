import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import VPSapi from '../../../lib/vps_service';
import { Toast } from '../../../components/Notify';
import { Block } from '../../../components/Bloc';
import UserMgt from '../../../lib/user_managment';
import { useNavigate } from "react-router-dom";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { BsFillLightbulbFill } from "react-icons/bs";
import Utils from '../../../utils';

import wordpress from '../../../assets/img/blue/wordpress-logo.png'
import minecraftBedrock from '../../../assets/img/blue/minecraft-bedrock-logo.png'
import VpsImage from '../../../components/VpsImage'
import bronze from '../../../assets/img/mini/bronze.png'
import gold from '../../../assets/img/mini/gold.png'
import silver from '../../../assets/img/mini/silver.png'
import plutonium from '../../../assets/img/mini/plutonium.png'
import KSFusion from '../../../assets/img/mini/KSFusion.png'
import CommingSoon from 'components/CommingSoon';


const plan_imgs = { bronze, gold, silver, plutonium }

const images = [{ name: "Wordpress", src: wordpress, id: "wordpress" }, { name: "Minecraft Java", src: minecraftBedrock, id: "minecraftBedrock" }]
const clientId = "AS_xUgq1mCHM4ZdU6awbcIL7hkxghYzKGRrwkULJoCG2te6xpgnTviD_QHXf6YjRgGnCAXlQOtfFAVP5"



export default function AddKpsMini() {

    const { flavors } = useSelector(app => app.vps)
    const { vps_packages } = useSelector(app => app.vps)

    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [visiblePass, setVissiblePass] = useState(false)

    const [selectedImage, setSelectedImage] = useState(null)
    const [clickedImage, setClickedImage] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [paypalpack, setPackPaypal] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        flavor: null,
        image: "KPS_cloud",
        email: "",
        serviceType: "KPS_CLOUD"
    })


    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)



    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    async function loadFlavors() {

        if (!flavors) {
            const r = await VPSapi.flavor_list()
            return r
        }

        return flavors
    }
    async function loadImages() {
        !images && VPSapi.image_list()
    }
    async function getVpsTypes() {
        if (!vps_packages) {
            const r = await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        }

        return vps_packages
    }


    useEffect(() => {
        loadFlavors().then(f => {
            getVpsTypes().then(vps_packages => {

                console.log('vps_packages vps_packages vps_packages', vps_packages)
                if (Utils.getUrlParam('prefer')) {
                    const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                    const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                    if (pack) {
                        setClicked(parseInt(selectedIdVpsTypes));
                        onClaculFlavor(pack, f)
                        setPackPaypal(pack.Name);

                    }
                    window.localStorage.removeItem('@user_first_service')

                }
            })

        })
        loadImages()

        console.log(' Run useEffet ')
        // if(ix==1) {Object.values(plan_ids).map((plan, i)=>ShowPaypal(plan))}
        // ix++


    }, [])




    async function deployVps(paymentId, dataPayment) {
        console.log('vps_params vps_params', vps_params)

        const result = await UserMgt.create_vps({ ...vps_params, paymentId }, dataPayment)
        setCreating(false)
        if (!result || result.error || result.status !== "success") {
            Toast.fire({
                icon: 'error',
                title: result.message || "An error occurred while running if this persist, please contact us !"
            })
        } else {
            Toast.fire({
                icon: 'success',
                title: "Kps cloud created !"
            })
            console.log('result vps created', result)
            dispatch({ type: "@DEPLOYED", state: true })
            navigate('/app-platform/kps/kpsmini')
        }

    }
    function onPay(ev) {
        console.log('vps_params vps_params', vps_params)
        ev.preventDefault()

        if (!vps_params.email) {
            return Toast.fire({
                icon: 'error',
                title: "Email not provided !"
            })
        }

        if (!vps_params.flavor) {
            const flavor = flavors && flavors.find(fl => fl.name === packSelected?.OpenstackFlavorName);
            console.log('Flavor found:', flavor)
            if (flavor) {
                setVpsParams({ ...vps_params, flavor: flavor.name })
            }

            else {
                return Toast.fire({
                    icon: 'error',
                    title: "Error flavor !"
                })
            }
        }

        setCreating(true)
        setPaypal(true)
        window.$("#" + packSelected?.PaypalPackId).modal('show')
    }

    function onClaculFlavor(pack, f) {
        setPack(pack)
        const f2 = f || flavors
        const flavor = f2 && f2.find(fl => fl.name === pack?.OpenstackFlavorName);
        console.log('Flavor found:', flavor)
        if (flavor) setVpsParams({ ...vps_params, flavor: flavor.name })

    }


    function onDismiss() {
        setCreating(false)
        setPaypal(false)
        window.$(".modalPaypal").modal('hide')

    }
    function createPaymentAndDeploy(paymentID, dataPayment) {
        setPaypal(false)
        console.log('data payment start, createPaymentAndDeploy', paymentID, dataPayment)
        const dataPayment2 = {
            ...dataPayment,
            amountPaid: parseFloat(packSelected?.Price),

        }
        console.log('after declare dataPayment2', dataPayment2)
        console.log('after deploy')
        window.$(".modalPaypal").modal('hide')
        return deployVps(paymentID, dataPayment2)



    }

    const concertPack = vps_packages &&
        vps_packages.filter(p => p.Package_Type === "KPS_CLOUD" && p.IsMini)
            .sort((a, b) => parseInt(a.Price) - parseInt(b.Price))
    
    return <CommingSoon />
    return (
        <>
            <Block />

            <PayPalScriptProvider
                options={{
                    "client-id": clientId,
                    components: "buttons",
                    intent: "subscription",
                    vault: true,
                }}
            >
                {concertPack && concertPack.map((pack, id) => {

                    return <ModalComponent
                        key={id}
                        createPaymentAndDeploy={createPaymentAndDeploy}
                        paypal={paypal}
                        creating={creating}
                        plan_id={pack.PaypalPackId}
                        //plan_ids={()=>concertPack.map(p => { return {[(p.Name).toLowerCase()]: p.PaypalPackId}}} 
                        packName={pack.Name}
                        onDismiss={onDismiss}
                    />
                }
                )}
            </PayPalScriptProvider>

            <div className="px-3 md:px-8 -mt-24 h-screen overflow-y-auto dark:text-darkTextColor pb-32">
                <div className="container max-w-full">
                    <div className='mx-auto'>
                        <p className='text-center text-2xl font-bold text-primary  dark:text-darkTextColor'>Choose your instance plan</p>
                        {concertPack ?
                            <form onSubmit={onPay} className="">
                                <div className="mx-auto justify-center pb-5 border-b-2 dark:border-gray-800  flex flex-wrap gap-2 justify-center  md:w-11/12 mt-10">
                                    {concertPack.map((pack, i) =>
                                        <div key={i} className="mx-auto mt-5 relative">
                                            <div onMouseOver={() => setSelected(pack.Id)}
                                                onClick={() => {
                                                    if (!creating) {
                                                        if (clicked == pack.Id) {
                                                            console.log('cliket on same')
                                                            setClicked(null);
                                                            onClaculFlavor(null)
                                                        } else {
                                                            setClicked(pack.Id);
                                                            setPackPaypal(pack.Name);
                                                            //setPack(pack);
                                                            onClaculFlavor(pack)
                                                        }

                                                    }
                                                }}
                                                className={'w-72 cursor-pointer rounded-xl dark:bg-bgPagedark ' +
                                                    ((selected == pack.Id) ? "hover:bg-white  hover:shadow-2xl " : '') + "" + ((clicked == pack.Id) ? "bg-white shadow-2xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')}>

                                                {(clicked == pack.Id) &&
                                                    <svg className="div_check checkmark absolute  right-0 top-0  " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                    </svg>
                                                }
                                                <div className='pt-3 pb-5 text-center text-primary dark:text-darkTextColor'>
                                                    <div className="px-3">
                                                        <div className="flex gap-1 items-center ">
                                                            <img src={plan_imgs[pack.Name.toLowerCase()]} alt="" className='mx-auto w-14 h-14' />
                                                        </div>
                                                        <span className='font-bold text-2xl' > {pack.Name}</span>
                                                        <br />
                                                        <br />

                                                        <p className='font-bold'>
                                                            <i className='-mt-5'>$</i> <span className='text-center text-3xl'>{pack.Price}</span>/month
                                                        </p>

                                                        <p className={"rounded-full px-0 py-0.5"} >
                                                            <span className='font-semibold'> {pack.Cpus} CPU{pack.Cpus > 1 ? "s" : ""} - {pack.RamGb}GB RAM - {pack.DiskGb}GB SSD</span>
                                                        </p>
                                                    </div>



                                                    {pack.Option.split(',').map((op, id) => {
                                                        return (
                                                            <>

                                                                <p key={id} className='font-bold pt-1' style={{ fontSize: "12px" }}>{op} </p>

                                                                {id == 0 && (
                                                                    <div className='mx-auto px-28 py-2'>
                                                                        <div className='border-t-4 rounded-full border-t-primary' />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )

                                                    })}

                                                    <p className='font-semibold pt-2' style={{ fontSize: "12px" }}>
                                                        Powered by
                                                        <img src={KSFusion} className="mx-auto translate-x-5 -mt-3" />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor pt-10'>Pick your instance image</p>



                                <div className="justify-center mx-auto  pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center  md:w-11/12   mt-10">
                                    {images && images.map((image, k) =>

                                        <VpsImage
                                            key={k}
                                            image={image}
                                            creating={creating}
                                            clickedImage={clickedImage}
                                            setClickedImage={setClickedImage}
                                            setVpsParams={setVpsParams}
                                            vps_params={vps_params}
                                            setSelectedImage={setSelectedImage}
                                            selectedImage={selectedImage}
                                            uri={image.src}
                                            noOption
                                        />

                                    )}
                                </div>



                                <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor pt-10'>Server Informations</p>

                                <div className={'md:w-5/6 mx-auto pt-10 px-20 ' + (creating && paypal ? " pb-72 md:pb-56" : 'pb-56 md:pb-48')}>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="
                                                    block
                                                    uppercase
                                                    tracking-wide
                                                    dark:text-darkTextColor
                                                    text-gray-700 text-xs
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="serv-name"
                                            >
                                                Identify your instance
                                            </label>
                                            <input
                                                className="
                                                    appearance-none
                                                    block
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    leading-tight
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                    focus:outline-none focus:bg-white
                                                "
                                                id="serv-name"
                                                value={vps_params.server_name}
                                                name="server_name"
                                                onChange={handleChange}
                                                pattern="[A-Za-z0-9]{1,}"
                                                required
                                                disabled={creating}
                                                placeholder='Your instance resources must have unique names'
                                            />
                                            <p className=" font-semibold text-red-700 -mt-3" style={{ fontSize: "14px" }} >
                                                <i> Must not contain spaces, special characters or symbols</i>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full xl:w-1/2 px-3">
                                            <label
                                                className="
                                                    block
                                                    uppercase
                                                    tracking-wide
                                                    dark:text-darkTextColor
                                                    text-gray-700 text-xs
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="serv-email"
                                            >
                                                Email for SSL
                                            </label>
                                            <input
                                                className="
                                                     appearance-none
                                                    block
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    leading-tight
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                    focus:outline-none focus:bg-white
                                                "
                                                id='serv-email'
                                                value={vps_params.email}
                                                name="email"
                                                type="email"
                                                onChange={handleChange}
                                                required
                                                disabled={creating}
                                                placeholder='Valid email address to get certificates'
                                            />
                                        </div>
                                    </div>

                                    <div className='mx-auto'>

                                        <div className={'fixed bottom-0 left-0 shawdow w-screen mx-auto md:ml-10 dark:bg-bgPagedark dark:opacity-70 dark:text-darkTextColor ' + (creating && paypal ? "md:h-48 h:64" : 'h-48 md:h-42')}>
                                            <div className='container'>
                                                <div className='grid grid-cols-1' >

                                                    <div className='text-center text-primary dark:text-darkTextColor pt-7'>
                                                        <span className='text-xl'>Summary:</span>
                                                        <br />
                                                        {packSelected ?
                                                            <span className='text-2xl font-bold'>
                                                                <span className='text-base font-bold'>Total: &nbsp;</span>
                                                                $ {(parseFloat(packSelected.Price) * (1 + 14.975 / 100)).toFixed(2)} / month (include Vat)
                                                            </span> :
                                                            <span>(empty)</span>
                                                        }

                                                    </div>
                                                    <div className="mx-auto">
                                                        {/*!paypal*/ true &&
                                                            <div className=''>
                                                                <button className="flex mt-4 mx-auto text-xl  w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                                    type="submit"
                                                                    disabled={creating}
                                                                >
                                                                    {!creating ?
                                                                        <span className='mx-auto flex  items-center'>
                                                                            <BsFillLightbulbFill className='text-2xl' />
                                                                            {" Coming soon"}
                                                                        </span>
                                                                        :
                                                                        <span className='mx-auto flex gap-1 items-center'>
                                                                            <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                            </svg>
                                                                            {!paypal ? " Deploying" : " Waiting for paypal"}
                                                                        </span>

                                                                    }
                                                                </button>
                                                            </div>
                                                        }

                                                        <div className='h-2' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                            :
                            <div className='flex justify-center pt-48'>
                                <svg className="animate-spin  -ml-1mr-3 h-12 w-12 text-primary dark:text-darkTextColor mx-auto" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        }


                    </div>
                </div>
            </div>
        </>
    );
}


function ModalComponent({ onDismiss, packName, plan_id, creating, paypal, createPaymentAndDeploy }) {
    const [{ options }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, []);

    return (
        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modalPaypal"
            id={plan_id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog relative w-auto pointer-events-none">
                <div
                    className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div
                        className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
                            {packName} Plan Subscription
                        </h5>
                        <button type="button"
                            className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            onClick={onDismiss} aria-label="Close"></button>
                    </div>
                    {creating && paypal &&

                        <div className="modal-body relative p-4">
                            <PayPalButtons
                                createSubscription={(data, actions) => {
                                    return actions.subscription
                                        .create({
                                            plan_id: plan_id,
                                        })
                                        .then((orderId) => {
                                            // Your code here after create the order
                                            return orderId;
                                        });
                                }}

                                onCancel={() => {
                                    onDismiss()
                                }}
                                onError={(err) => {
                                    console.log('error paypal ***', err)
                                    onDismiss()
                                }}
                                onApprove={(data, actions) => {
                                    try {
                                        const paymentID = data.subscriptionID //|| data.orderID
                                        console.log("after decalre payment")
                                        if (!data.subscriptionID) {
                                            return onDismiss()
                                        }
                                        const dataPayment = {
                                            status: "success",
                                            details: JSON.stringify(data),
                                            subscriptionID: data.subscriptionID,
                                            paypalTransactionId: data.orderID //|| data.orderID

                                        }

                                        return createPaymentAndDeploy(paymentID, dataPayment)

                                    } catch (e) {
                                        console.log('error onApprove paypal', e)
                                        window.$(".modalPaypal").modal('hide')
                                    }
                                }}
                                style={{
                                    label: "subscribe",
                                    shape: 'rect',
                                    color: 'white',
                                    layout: 'vertical',
                                }}
                            />
                        </div>
                    }
                    <div
                        className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                        <button type="button"
                            className="inline-block px-6 py-2.5 bg-primary-100 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-primary hover:shadow-lg focus:bg-primary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out"
                            onClick={onDismiss}
                        >
                            Annuler
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

