import { FileUploader } from "react-drag-drop-files";
import { useState } from 'react';

const fileTypes = ["JPEG", "PNG", "GIF"];
export default function NewTicket() {
    const [file, setFile] = useState(null);
    const [ticket, setTicket] = useState({});

    const handleChange = (file) => {
        console.log('file file', Object.values(file) )
        setFile({...file});
    };
    const handleCHangeInput = (evt) => {
        setTicket({...ticket,  [evt.target.name]: evt.target.value});
    };
    return (
        <div className='pt-36 px-10'>


            <div className="px-3 md:px-8 h-screen overflow-y-auto dark:text-darkTextColor pb-32 -mt-24">
                <div className="container mx-auto max-w-full">


                    <form className="">
                        <div className="mb-6 w-full md:w-1/2">
                            <div className="md:flex md:items-center mb-6">
                                <div className="w-1/3">
                                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="subject">
                                        Subject <span className='text-red-500 font-bold'>*</span>
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <input className="
                                        bg-gray-200 
                                        appearance-none 
                                        border-2 
                                        border-gray-200 
                                        rounded w-full py-2 px-4 text-gray-700 leading-tight 
                                        focus:outline-none focus:bg-white focus:border-purple-500"
                                        type="text"
                                        id='subject'
                                        name='subject'
                                        onChange={handleCHangeInput}
                                        value={ticket.subject}
                                    />
                                </div>
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <div className="w-1/3">
                                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="message">
                                        Message  <span className='text-red-500 font-bold'>*</span>
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <textarea className="
                                        bg-gray-200 
                                        appearance-none 
                                        border-2 
                                        border-gray-200 
                                        rounded w-full py-2 px-4 text-gray-700 leading-tight 
                                        focus:outline-none focus:bg-white focus:border-purple-500"
                                        type="text"
                                        id='name'
                                        name='message'
                                        onChange={handleCHangeInput}
                                        value={ticket.message}
                                    />
                                </div>
                            </div>
                            <div className="md:flex md:items-center mb-6">
                                <div className="w-1/3">
                                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="name">
                                        Files or screenshot
                                    </label>
                                </div>
                                <div className="w-2/3">
                                    <FileUploader
                                        multiple={true}
                                        handleChange={handleChange}
                                        name="file"
                                        types={fileTypes}
                                        maxSize={2}
                                    />

                                    <div className='pt-5'>
                                        {file ? 
                                            Object.values(file).map((f, i)=> <p key={i} className="text-primary dark:text-darkTextColor font-bold">{f.name}</p>)
                                            : 
                                            <p>no files uploaded yet</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
}
