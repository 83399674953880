import UserAPI from './keepsec_auth_service'
import{ save_paymentMethod } from "../reducer/action/coreActions"
import store from "../reducer"


const {  get, post } = require("./api")

const SERVICE_URL = process.env.REACT_APP_USER_MANGMENT_API
const  apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}

const getDatacenterLocation = async () => {
    try {
        const locations = await get(SERVICE_URL + "v1/datacenter", header)
        console.log('user locations', locations)
        //store.dispatch(vps_packages(locations?.locations))
        return locations
    } catch (error) {
        console.error('error wrong when try to logout user >>', error)
        return { error: true, message: error.message } 
    }
};

const fetchPaymentMethod = async () => {
    try {
        const data = await get(SERVICE_URL + "v1/billing/payment-methods", header)
        console.log('user paymentMethods', data)
        store.dispatch(save_paymentMethod(data?.paymentMethods))
        return data
    } catch (error) {
        console.error('error wrong when try to logout user >>', error)
        return null
    }
};

const checkoutStripe = async () => {
    try {
        await UserAPI.refrest_token()
        const result = await post(
            SERVICE_URL +"v1/billing/create-checkout-session", 
            {isStripe: true}, 
            header
        )
        return result?.clientSecret2 || null
    } catch (error) {
        console.error('Error checkout stripe sessionb >>', error)
        return null 
    }
};
const checkoutPaypal = async () => {
    try {
        await UserAPI.refrest_token()
        const result = await post(
            SERVICE_URL +"v1/billing/create-checkout-session", 
            {}, 
            header
        )
        return result || null
    } catch (error) {
        console.error('Error checkout stripe sessionb >>', error)
        return null 
    }
};

const createSetupIntent = async (data) => {
    try {
        await UserAPI.refrest_token()
        const result = await post(
            SERVICE_URL +"v1/billing/create-payment", 
            {paymentMethod: data}, 
            header
        )
        return result
    } catch (error) {
        console.error('Error checkout stripe sessionb >>', error)
        return null 
    }
};

const deletePaymentMethod = async (data) => {
    try {
        await UserAPI.refrest_token()
        const result = await post(
            SERVICE_URL +"v1/billing/payment-method/action", 
            {...data}, 
            header
        )
        return result || null
    } catch (error) {
        console.error('Error checkout stripe sessionb >>', error)
        return null 
    }
};

const createVaultResp = async (data) => {
    console.log('DATA vault ', data)
    try {
        await UserAPI.refrest_token()
        const result = await post(
            SERVICE_URL +"v1/billing/create-vault-response", 
            {data}, 
            header
        )
        return result || null
    } catch (error) {
        console.error('Error checkout stripe sessionb >>', error)
        return null 
    }
};

const createVaultPayment = async (data) => {
    console.log('DATA vault Payment', data)
    try {
        await UserAPI.refrest_token()
        const result = await post(
            SERVICE_URL +"v1/billing/create-vault-payment", 
            {data}, 
            header
        )
        return result || null
    } catch (error) {
        console.error('Error checkout stripe sessionb >>', error)
        return null 
    }
};

export default {
    getDatacenterLocation,
    checkoutStripe,
    checkoutPaypal,
    createVaultResp,
    fetchPaymentMethod,
    deletePaymentMethod,
    createVaultPayment,
    createSetupIntent
}
