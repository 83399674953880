import { Input } from 'antd';
import { useContext, useEffect } from 'react';
import { TabPanel, useTabs } from "react-headless-tabs";
import { useTranslation } from 'react-i18next';
import { IoMdGitNetwork } from "react-icons/io";
import { RiBillFill, RiComputerFill, RiMicrosoftFill, RiShieldUserFill, RiUserFill } from "react-icons/ri";
import { TiCloudStorage } from "react-icons/ti";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { ExtLink } from 'components/Bloc';
import { InstanceMiniCar } from 'components/ComputeInstace';
import { NetworkServerMiniCard } from "components/networking/NetworkInstance";
import { SecurityServerMiniCard } from "components/security/SecurityInstance";

import { MiniCarK8s } from "../components/k8s/K8sInstance"
import { PlatformServerMiniCard } from '../components/Platform/PlatformInstance';
import { TabSelector } from "../components/TabSelector";
import K8S from '../lib/k8s';
import UserMgt from '../lib/user_managment';
import { ThemeContext } from '../theme/ThemeContext';

const { Search } = Input;
 

export default function Dashboard() {
    const { t } = useTranslation();

    const { theme } = useContext(ThemeContext);
    const today = new Date()
    const [selectedTab, setSelectedTab] = useTabs([
        "ressources",
        "activity"
    ]);

    const list = useSelector(app => {
        return app.vps.list || []
    })
    const { kubernetes } =  useSelector(app => app.k8s) 
    const listApp = useSelector(app => app.webapp?.list)
    const networks = useSelector(app => app.kslb?.list)
    const securities = useSelector(app => {
        const vulliies = app.vullify.list || []
        const kwaf = app.kwaf?.listKwaf || []
        return  (kwaf.concat(vulliies) || []).sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime())
    })


    async function loadVps(){
        await UserMgt.get_user_vps() 
        await K8S.getK8sInstance() 
    }
   

    useEffect(()=>{ 
        loadVps() 
    }, [])

    return(
        <div className="h-full overflow-hidden dark:text-darkTextColor">
            <div className={`${theme !=="dark" && "dash-back"} h-[100%] overflow-y-auto`}>
                <div className={"px-3 md:px-8  "}>
                    <div className="flex lg:flex-row flex-col gap-6 items-center justify-between">
                        <div>
                            <h1 className="lg:text-4xl text-center lg:text-left text-2xl font-semibold">
                                {today.getHours() < 12 ? t("dashboard.goodMorning") : today.getHours() < 18 ? t("dashboard.goodAfternoon") : t("dashboard.goodEvening")} !
                            </h1>
                        </div>
                        <Search 
                            placeholder={t("dashboard.searchPlaceholder")} 
                            onSearch={(value, _e, info) =>console.log('Search')} 
                            enterButton
                            className="w-[350px] lg:w-[400px]" 
                        />
                    </div>

                    <div className="pt-8">

                        <nav className="flex border-b border-gray-300 dark:border-gray-800">
                            <TabSelector
                              isActive={selectedTab === "ressources"}
                              onClick={() => setSelectedTab("ressources")}
                            >
                              {t("dashboard.tab1.title")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "activity"}
                              onClick={() => setSelectedTab("activity")}
                            >
                              {t("dashboard.tab2.title")}
                            </TabSelector>
                           
                        </nav>
                        <div className="py-4">
                            <TabPanel hidden={selectedTab !== "ressources"}>
                                {!kubernetes?.length && !list?.length && !listApp?.length && !securities?.length && !networks?.length ?
                                    <div>
                                        <div className="text-center pt-10">
                                            <h3 className="text-base md:text-xl 2xl:text-2xl font-semibold">{t("dashboard.tab1.noService")}</h3>
                                            <p className="text-sm md:text-base 2xl:text-xl">
                                                {t("dashboard.tab1.createService")}
                                                <br />
                                                <br />
                                                <ExtLink 
                                                    href={"#"}
                                                    text={t("dashboard.tab1.lean")}
                                                    // className="mx-auto"
                                                    // icon={<RiComputerFill/>}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="flex flex-wrap justify-between">
                                            <div className="w-full lg:w-[73%]">
                                                <div className="rounded-xl p-2">
                                                    {list?.length ?
                                                        <div>
                                                            <span className="font-semibold dark:text-darkTextColor">{t("dashboard.tab1.compute")} ({list?.length || 0})</span>
                                                            <div className="mt-3 rounded-xl dark:bg-black border  bg-[#fff]  bg-opacity-[45%] border-gray-300 dark:border-gray-800 p-5">
                                                                {(list && list.length !== 0) &&
                                                                    <div className="flex flex-wrap gap-6  mb-1  items-center">
                                                                        {list.slice(0, 4).map((vps, i)=><InstanceMiniCar key={i} server={vps}/>)}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    {kubernetes?.length? 
                                                        <div className="mt-3">
                                                            <span className="font-semibold dark:text-darkTextColor">{t("dashboard.tab1.kubernetes")} ({kubernetes?.length || 0})</span>
                                                            <div className="mt-3 rounded-xl dark:bg-black bg-[#fff]  bg-opacity-[45%] border border-gray-300 dark:border-gray-800 p-5">
                                                                {(kubernetes && kubernetes.length !== 0) &&
                                                                    <div className="flex flex-wrap gap-6  mb-1  items-center">
                                                                        {kubernetes.slice(0, 4).map((kube, i)=><MiniCarK8s key={i} k8s={kube}/>)}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {listApp?.length? 
                                                        <div className="mt-3">
                                                            <span className="font-semibold dark:text-darkTextColor">KPS ({listApp?.length || 0})</span>
                                                            <div className="mt-3 rounded-xl dark:bg-black bg-[#fff]  bg-opacity-[45%] border border-gray-300 dark:border-gray-800 p-5">
                                                                {(listApp && listApp.length !== 0) &&
                                                                    <div className="flex flex-wrap gap-6  mb-1  items-center">
                                                                        {listApp.slice(0, 4).map((appServer, i)=><PlatformServerMiniCard key={i} server={appServer}/>)}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {securities?.length? 
                                                        <div className="mt-3">
                                                            <span className="font-semibold dark:text-darkTextColor">{t("dashboard.tab1.security")}SECURITY ({securities?.length || 0})</span>
                                                            <div className="mt-3 rounded-xl border dark:bg-black bg-[#fff]  bg-opacity-[45%] border-gray-300 dark:border-gray-800 p-5">
                                                                {(securities && securities.length !== 0) &&
                                                                    <div className="flex flex-wrap gap-6  mb-1  items-center">
                                                                        {securities.slice(0, 4).map((security, i)=><SecurityServerMiniCard key={i} server={security}/>)}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {networks?.length? 
                                                        <div className="mt-3">
                                                            <span className="font-semibold dark:text-darkTextColor">{t("dashboard.tab1.networks")} ({networks?.length || 0})</span>
                                                            <div className="mt-3 rounded-xl border dark:bg-black bg-[#fff]  bg-opacity-[45%] border-gray-300 dark:border-gray-800 p-5">
                                                                {(networks && networks.length !== 0) &&
                                                                    <div className="flex flex-wrap gap-6  mb-1  items-center">
                                                                        {networks.slice(0, 4).map((security, i)=><NetworkServerMiniCard key={i} server={security}/>)}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>

                                            <div className="w-full mt-5 md:mt-0 lg:w-[26%]">
                                                <div className="p-2">
                                                    
                                                    <div>
                                                        <h4 className="font-semibold dark:text-darkTextColor 2xl:text-xl text-base">
                                                        {t("dashboard.tab1.createNow")} 
                                                        </h4>
                                                        <div className="mt-3 px-3 py-4 rounded-xl border dark:bg-black  bg-[#fff]  bg-opacity-[45%] border-gray-300 dark:border-gray-800 ">
                                                            <Link
                                                                to="/compute/vps/deploy"
                                                                className="flex text-primary gap-2 items-center"
                                                            >
                                                                <div className="p-2 border border-primary ">
                                                                    <RiComputerFill className="" />
                                                                </div>
                                                                <p className="2xl:leading-4 leading-3">
                                                                    <span className="text-sm 2xl:text-base">{t("dashboard.tab1.createVps")}</span> 
                                                                    <br/>
                                                                    <span className="2xl:text-xs text-[10px] text-gray-500 ">{t("dashboard.tab1.vpsDesc")}</span> 
                                                                </p>
                                                            </Link>
                                                            <Link
                                                                to="/"
                                                                className="flex mt-3 text-primary gap-2 items-center"
                                                            >
                                                                <div className="p-2 border border-primary ">
                                                                    <IoMdGitNetwork className="" />
                                                                </div>
                                                                <p className="2xl:leading-4 leading-3">
                                                                    <span className="text-sm 2xl:text-base">{t("dashboard.tab1.setLoad")}</span> 
                                                                    <br/>
                                                                    <span className="2xl:text-xs text-[10px] text-gray-500">
                                                                    {t("dashboard.tab1.setLoadText")}
                                                                    </span> 
                                                                </p>
                                                            </Link>

                                                            <Link
                                                                to="/"
                                                                className="flex mt-3 text-primary gap-2 items-center"
                                                            >
                                                                <div className="p-2 border border-primary ">
                                                                    <RiComputerFill className="" />
                                                                </div>
                                                                <p className="2xl:leading-4 leading-3">
                                                                    <span className="text-sm 2xl:text-base">{t("dashboard.tab1.createKc2")}</span> 
                                                                    <br/>
                                                                    <span className="2xl:text-xs text-[10px] text-gray-500">{t("dashboard.tab1.kc2text")}</span> 
                                                                </p>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        <h4 className="font-semibold dark:text-darkTextColor 2xl:text-xl text-base">
                                                        {t("dashboard.tab1.addOnOne")}
                                                        </h4>
                                                        <div className="mt-3 px-3 py-4 rounded-xl border dark:bg-black  bg-[#fff]  bg-opacity-[45%] border-gray-300 dark:border-gray-800 ">
                                                            <Link
                                                                to="/storage/volume/deploy"
                                                                className="flex text-primary gap-2 items-center"
                                                            >
                                                                <div className="p-2 border border-primary ">
                                                                    <TiCloudStorage className="" />
                                                                </div>
                                                                <p className="2xl:leading-4 leading-3">
                                                                    <span className="text-sm 2xl:text-base">{t("dashboard.tab1.createVolum")}</span> 
                                                                    <br/>
                                                                    <span className="2xl:text-xs text-[10px] text-gray-500 ">{t("dashboard.tab1.upgradeStorage")}</span> 
                                                                </p>
                                                            </Link>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </TabPanel>
                            <TabPanel hidden={selectedTab !== "activity"}>
                                <div className="p-20"></div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

// export  function Dashboard2() {
//     const { theme } = useContext(ThemeContext);
//     const today = new Date()
//     const [selectedTab, setSelectedTab] = useTabs([
//         "instance",
//         "cloudapp"
//     ]);

//     const list = useSelector(app => {
//         if(app.vps && app.vps.list){
//             const newEst = app.vps.list.filter(vps => !vps.IsMini && !vps.IsMiniSec)
//             return newEst
//         }
//     })
//     const listApp = useSelector(app => app.webapp?.list)


//     async function loadVps(){
//         console.log('start load vps loadVps')
//         const vps = await UserMgt.get_user_vps() 
//     }
   

//     useEffect(()=>{ 
//         loadVps() 
//     }, [])

//     return (
//         <div className={((selectedTab=="instance" && (!list || list.length === 0) ) || (selectedTab==="cloudapp" && (!listApp || listApp.length === 0)  ))  && theme !== 'dark'  ? ' dash-back w-full h-full  ' : ' h-full  '}>
//             <div className={' h-full   dark:text-darkTextColor '+ (((selectedTab=="instance" && (!list || list.length === 0) ) || (selectedTab==="cloudapp" && (!listApp || listApp.length === 0) )) ? '  ' : '  ')}>
                    
//                     <div className={"px-3 md:px-8  "+ (((selectedTab=="instance" && (!list || list.length === 0) ) || (selectedTab==="cloudapp" && (!listApp || listApp.length === 0) )) ? '  ' : ' ')}>
//                         <div className="grid grid-cols-1 lg:grid-cols-2">
//                             <div>
//                                 <h1 className="lg:text-4xl text-center lg:text-left text-2xl font-semibold">
//                                     {today.getHours() < 12 ? "Good Morning" : today.getHours() < 18 ? "Good Afternoons" : "Good Evening"} !
//                                 </h1>
//                             </div>
//                             <div className="justify-self-center  md:justify-self-end">
//                                 <div className="container flex mx-auto pt-10 md:pt-0">
//                                     <div className="flex border-2 dark:border-gray-800 rounded">
//                                         <button className="flex items-center justify-center px-4 dark:border-gray-800 border-r">
//                                             <svg className="w-6 h-6 text-gray-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
//                                                 viewBox="0 0 24 24">
//                                                 <path
//                                                     d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z">
//                                                 </path>
//                                             </svg>
//                                         </button>
//                                         <input type="text" className="px-4 py-2 w-80 dark:bg-bgPagedark dark:text-darkTextColor" placeholder="FIlter by name, locationn, type or tag" />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

                       



//                         <div className="pt-8">

//                             <nav className="flex border-b border-gray-300 dark:border-gray-800">
//                                 <TabSelector
//                                   isActive={selectedTab === "instance"}
//                                   onClick={() => setSelectedTab("instance")}
//                                 >
//                                   Instances
//                                 </TabSelector>
//                                 <TabSelector
//                                   isActive={selectedTab === "cloudapp"}
//                                   onClick={() => setSelectedTab("cloudapp")}
//                                 >
//                                   KPS Cloud
//                                 </TabSelector>
                               
//                               </nav>
//                               <div className="py-4">
//                                 <TabPanel hidden={selectedTab !== "instance"}>

//                                     <div className="">
                                        
//                                         {(!list || list.length === 0) &&
//                                             <div className="text-center pt-10">
//                                                 <h3 className="text-xl font-semibold">You have no instances rigth now</h3>
//                                                 <p className="text-xl">
//                                                     Create an instance and get started with KeepSec!
//                                                     <br />
//                                                     <Link to="/compute/vps/deploy">
//                                                         <button type="button" className="
//                                                             mt-5
//                                                             inline-block 
//                                                             px-10
//                                                             py-2.5 
//                                                             bg-primary-100
//                                                             text-white 
//                                                             font-medium 
//                                                             text-base 
//                                                             leading-tight 
//                                                             rounded-full 
//                                                             shadow-md 
//                                                             hover:bg-primary
//                                                             hover:shadow-lg 
//                                                             focus:bg-primary
//                                                             focus:shadow-lg 
//                                                             focus:outline-none 
//                                                             focus:ring-0 
//                                                             active:bg-blue-800 
//                                                             active:shadow-lg 
//                                                             transition 
//                                                             duration-150 ease-in-out"
//                                                         >
//                                                            Create VPS instance
//                                                         </button>
//                                                     </Link>
//                                                     <br />
//                                                     <br />
//                                                     <a href="#" target="_blank" className=" mx-auto">
//                                                         Lean more about instances
//                                                     </a>
//                                                 </p>
//                                             </div>
//                                         }
//                                          {(list && list.length !== 0) &&
//                                             <div className="flex justify-between items-center pb-5">
//                                                 <div>
//                                                     <span className="font-bold">Sort by Date</span>
//                                                 </div>
//                                                 <div>
//                                                     <Link to="/compute/vps/deploy">
//                                                             <button type="button" className="
//                                                                 inline-block 
//                                                                 px-10
//                                                                 py-2.5 
//                                                                 bg-primary-100
//                                                                 text-white 
//                                                                 font-medium 
//                                                                 text-base 
//                                                                 leading-tight 
//                                                                 rounded-full 
//                                                                 shadow-md 
//                                                                 hover:bg-primary
//                                                                 hover:shadow-lg 
//                                                                 focus:bg-primary
//                                                                 focus:shadow-lg 
//                                                                 focus:outline-none 
//                                                                 focus:ring-0 
//                                                                 active:bg-blue-800 
//                                                                 active:shadow-lg 
//                                                                 transition 
//                                                                 duration-150 ease-in-out"
//                                                             >
//                                                                 Create instance
//                                                             </button>
//                                                         </Link>
//                                                 </div>
//                                             </div>
//                                         }
//                                         {(list && list.length !== 0) &&
//                                             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3  mb-16 gap-4 items-center">
//                                                 {list.sort((a, b) => b.Id - a.Id).filter(vps => vps.ServiceType ===  "VPS_INSTANCE" || !vps.ServiceType).map((vps, i)=><ServerCard key={i} server={vps}/>)}
//                                             </div>
//                                        }
//                                     </div>

//                                 </TabPanel>
//                                 <TabPanel hidden={selectedTab !== "cloudapp"}>
//                                     {(!listApp || listApp.length === 0) && 
//                                         <div className="text-center pt-10">
//                                             <h3 className="text-xl font-semibold">You have no KPS cloud service rigth now</h3>
//                                             <p className="text-xl">
//                                                 KPS cloud service is a compute ressource to which you can deploy containers.  <br />
//                                                Containers are standard units of software that package code
//                                                 <br />
//                                                 <Link to="/app-platform/kps/deploy">
//                                                     <button type="button" className="
//                                                         mt-5
//                                                         inline-block 
//                                                         px-10
//                                                         py-2.5 
//                                                         bg-primary-100
//                                                         text-white 
//                                                         font-medium 
//                                                         text-base 
//                                                         leading-tight 
//                                                         rounded-full 
//                                                         shadow-md 
//                                                         hover:bg-primary
//                                                         hover:shadow-lg 
//                                                         focus:bg-primary
//                                                         focus:shadow-lg 
//                                                         focus:outline-none 
//                                                         focus:ring-0 
//                                                         active:bg-blue-800 
//                                                         active:shadow-lg 
//                                                         transition 
//                                                         duration-150 ease-in-out"
//                                                     >
//                                                         Create KPS cloud service
//                                                     </button>
//                                                 </Link>
//                                                 <br />
//                                                 <br />
//                                                 <a href="#" target="_blank" className=" mx-auto">
//                                                      Lean more about KPS cloud services
//                                                 </a>
//                                             </p>
//                                         </div>
//                                     }
//                                     {(listApp && listApp.length !== 0) &&
//                                         <div className="flex justify-between items-center pb-5">
//                                             <div>
//                                                 <span className="font-bold">Sort by Date</span>
//                                             </div>
//                                             <div>
//                                                 <Link to="/app-platform/kps/deploy">
//                                                         <button type="button" className="
//                                                             inline-block 
//                                                             px-10
//                                                             py-2.5 
//                                                             bg-primary-100
//                                                             text-white 
//                                                             font-medium 
//                                                             text-base 
//                                                             leading-tight 
//                                                             rounded-full 
//                                                             shadow-md 
//                                                             hover:bg-primary
//                                                             hover:shadow-lg 
//                                                             focus:bg-primary
//                                                             focus:shadow-lg 
//                                                             focus:outline-none 
//                                                             focus:ring-0 
//                                                             active:bg-blue-800 
//                                                             active:shadow-lg 
//                                                             transition 
//                                                             duration-150 ease-in-out"
//                                                         >
//                                                             Create KPS Cloud
//                                                         </button>
//                                                     </Link>
//                                             </div>
//                                         </div>
//                                     }
//                                     {(listApp && listApp.length !== 0) &&
//                                             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3  mb-16 gap-4 items-center">
//                                                 {listApp.sort((a, b) => b.Id - a.Id).map((vps, i)=><ServerCardWebApp key={i} server={vps}/>)}
//                                             </div>
//                                        }
//                                 </TabPanel>
//                               </div>

                         
                           
                            
//                         </div>
//                     </div>
//             </div>
            
//         </div>


//     );
// }
