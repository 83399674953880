import store from "../reducer"
import UserAPI from './keepsec_auth_service'
import {  setVpcNetwork } from "../reducer/action/networkAction"

const {  get, post, onDelete } = require("./api")

const SERVICE_URL = process.env.REACT_APP_USER_MANGMENT_API //http://72.10.163.146"
const  apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}

async function create(data){
	try{
		await UserAPI.refrest_token()
		const result = await post(
			SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/vpc", 
			data,
			header
		)
		console.log('Result VPC', result)
		return result;
	}catch(e){
		console.error('Error createPlan', e)
		return null
	}

}
async function getAll(){
	try{
		await UserAPI.refrest_token()
		const result = await get(
			SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/vpc", 
			header
		)
		const vpc = result?.vpc
        let tab;
        if(vpc){
	        const object = {}
	        for (let i = 0; i < vpc.length; i++) {
	        	const uniquVPC = vpc[i]
	        	const old = object[uniquVPC?.location?.countryCode] || []
	        	old.push(uniquVPC)
	        	object[uniquVPC?.location?.countryCode] = old
	        }
	        tab = Object.keys(object).map((key) => [key, object[key]]);
        }
        let grandO = {}
        for (let i = 0; i < tab.length; i++) {
        	let object2 = {}
        	const lines = tab[i]?.[1]
        	for (let j = 0; j < lines.length; j++) {
        		const line = lines[j]
        		const old = object2[line?.location?.name]  || []
        		old.push(line)
        		object2[line?.location?.name] = old
        	}
        	grandO[tab[i]?.[0]] = Object.keys(object2).map((key) => [key, object2[key]])
        }
        const vpcG = Object.keys(grandO).map((key) => [key, grandO[key]])
        console.log(' VPCG ', vpcG)
        store.dispatch(setVpcNetwork(vpcG))
		return result;
	}catch(e){
		console.error('Error createPlan', e)
		return null
	}

}

async function getUniquenetwork(id){
	try{
		await UserAPI.refrest_token()
		const result = await get(
			SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/vpc/"+id, 
			header
		)
		return result;
	}catch(e){
		console.error('Error createPlan', e)
		return null
	}

}
async function deleteNet(id){
	try{
		await UserAPI.refrest_token()
		const result = await onDelete(
			SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/vpc/"+id,
			{}, 
			header
		)
		console.log('Result DELETION FETCH ', result)
		return result && result.status;
	}catch(e){
		console.error('Error createPlan', e)
		return null
	}

}


export default {
	create,
	getAll,
	getUniquenetwork,
	deleteNet
}

