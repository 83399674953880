import { useState } from 'react';
import { useEffect } from 'react';
import { Checkbox, Button, Tag } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import UserMgt from "lib/user_managment";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { EditLineButton, InputSelect, InputText, DeleteLineButton, AddLineButton, RemoveLineButton } from './Bloc';


const antIcon = (
	<LoadingOutlined
	  style={{ 
		fontSize: 20,
	  }}
	  spin
	/>
);

const portApp = {
	"ssh": {app: "SSH", proto: "TCP", port: "22"},
	"http": {app: "HTTP", proto: "TCP", port: "80"},
	"https": {app: "HTTPS", proto: "TCP", port: "443"},
	"mysql": {app: "MYSQL", proto: "TCP", port: "3306"},
	"dns1": {app: "DNS", proto: "TCP", port: "53"},
	"dns2": {app: "DNS", proto: "UDP", port: "53"},
	"smtp": {app: "SMTP", proto: "TCP", port: "25"},
	"smtps": {app: "SMTP(S)", proto: "TCP", port: "465"},
	"imap": {app: "IMAP", proto: "TCP", port: "143"},
	"imaps": {app: "IMAP(S)", proto: "TCP", port: "993"},
	"pop": {app: "POP", proto: "TCP", port: "110"},
	"pops": {app: "POS(S)", proto: "TCP", port: "995"},	
	"icmp": {app: "PING", proto: "ICMP", port: ""},	
}
const generals = [
	{
		value: 'custom',
		label: 'Custom',
	},
	{
	  label: 'Instance access',
	  options: [
		{ label: 'SSH', value: 'ssh' },
		// { label: 'RDP', value: 'rdp' }
	  ],
	},
	{
	  label: 'Web server',
	  options: [
		{ label: 'HTTP', value: 'http' },
		{ label: 'HTTPS', value: 'https' }
	  ],
	},
	{
	  label: 'Database connections',
	  options: [
		{ label: 'MySQL', value: 'mysql' },
		// { label: 'PostgreSQL', value: 'postgresql' }
	  ],
	},
	{
	  label: 'Networking',
	  options: [
		{ label: 'DNS(TCP)', value: 'dns1' },
		{ label: 'DNS(UDP)', value: 'dns2' },
		{ label: 'PING(ICMP)', value: 'icmp' },
	  ],
	},
	{
	  label: 'Messaging',
	  options: [
		{ label: 'SMTP', value: 'smtp' },
		{ label: 'SMTP(S)', value: 'smtps' },
		{ label: 'POP', value: 'pop' },
		{ label: 'POP(S)', value: 'pops' },
		{ label: 'IMAP', value: 'imap' },
		{ label: 'IMAP(S)', value: 'imaps' }
	  ],
	},
]
const protocols = [
	{
		value: 'tcp',
		label: 'TCP',
	},
	{
		value: 'udp',
		label: 'UDP',
	},
	
]

function getApplication(port, protocol){
	const tabs = Object.values(portApp).filter(o => o.port == port)
	return tabs.length ? tabs[0]?.app : protocol ==="icmp" ? "ICMP" : "Custom"
}

export function Firewall(props){
	const { messageApi } = useSelector(app => app.core)
	const [objectPortCreate, setObjectPortCreate] = useState({})
	const [objectPortDelete, setObjectPortDelete] = useState({})
	const [ruleEditedId, setCurrentEdit] = useState(null)
	const [deletingId, setDeletingId] = useState(null)
	const [rules, setRules] = useState([])

	useEffect(()=>{
		if(props.server?.groups_with_ruls?.security_group_rules){
			setRules([...props.server?.groups_with_ruls?.security_group_rules])
		}
	}, [props.server])

	const onCreatePort = async (vps) => {
		setObjectPortCreate({...objectPortCreate, [vps.Id]: true})
		try {
			const result  = await UserMgt.onPortCreate({server_id: vps?.Id, isDefault: true})
			console.log('Result Port ', result)
			if(result && !result.error){
				messageApi.open({
					type: 'success',
					content: 'Done !'
				});
				await props.reload()
			}else{
				messageApi.open({
					type: 'error',
					content: 'An error occurs, please try again or contact an admin!'
				});
			}
		} catch (error) {
			console.error('Error createPort', error)
		}
		setObjectPortCreate({...objectPortCreate, [vps.Id]: null})
	}

	async function onDeleteRule(rule_id){
		try {
			setDeletingId(rule_id)
			const restul = await UserMgt.delVpsGroupRule(props?.server.Id, rule_id)
			if(!restul.error){
				messageApi.open({
					type: 'success',
					content: 'Rule has been deleted',
				});
				
				return await props.reload()
			}else{
				messageApi.open({
					type: 'error',
					content: restul.message || "Can't deleted it, please try again or contact us.",
				});
			}
		} catch (error) {
			console.error('Error deleting rule ', error)
			messageApi.open({
				type: 'error',
				content: "An errors occurs, please contact us.",
			});
		}
		setDeletingId(null)
	}

	return(
		<div>
			{props.onDisplayrull?
				<div >
					<FormRuleFiwewall 
						messageApi={messageApi} 
						server={props.server} 
						hideForm={props.setOnDisplayRuleComponent} 
						loadVps={props.reload}/>
				</div>
				:
				null
			}


			<div  className="flex flex-col ">
				<div className="overflow-x-auto sm:-mx-6 lg:-mx-8 ">
					<div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
						<div className="overflow-hidden">
							<table className="min-w-full text-left">
								<thead className="border-b bg-neutral-50 dark:bg-bgPagedark">
									<tr>
										<th scope="col" className="text-sm 2xl:text-base font-bold  px-6 py-4">
											Application
										</th>
										<th scope="col" className="text-sm 2xl:text-base font-bold  px-6 py-4">
											Protocol
										</th>
										<th scope="col" className="text-sm 2xl:text-base font-bold  px-6 py-4">
											Port(s)
										</th>
										<th scope="col" className="text-sm 2xl:text-base font-bold  px-6 py-4">
											Restricted to
										</th>
										<th scope="col" className="text-sm 2xl:text-base font-bold  px-6 py-4">

										</th>
									</tr>
								</thead >
								<tbody className="text-left">
									{rules?.filter(g => g.direction === "ingress")
										.sort((a, b)=>{
											if(a.description === "default" || b.description === "default") return 1
											else return -1
										}) 
										.map((rule, i) =>{
										if(ruleEditedId !== rule.id){
											return(
												<tr key={i} className="bg-white border-b dark:bg-bgPagedark text-gray-900 dark:text-darkTextColor">
													<td className="px-6 py-4 whitespace-nowrap text-sm 2xl:text-base font-medium">
														{props.isKube && rule.port_range_max === 6443 ?
															"KubeAPI"
															:
															getApplication(rule.port_range_max, rule.protocol)
														}
														&nbsp;
														{rule.description==="default"?

															<Tag color={"volcano"}>
												              Default
												            </Tag>
												            :
												            null
														}

													</td>
													<td className="text-sm 2xl:text-base  font-medium px-6 py-4 ">
														{rule.protocol}
													</td>
													<td className="text-sm 2xl:text-base  font-medium px-6 py-4 ">
														{
															rule.port_range_min === rule.port_range_max ?
															rule.port_range_min
															:
															rule.port_range_min + " - "+rule.port_range_max
														}
													</td>
													<td className="text-sm 2xl:text-base  font-medium px-6 py-4 ">
														<p>
															{
																rule?.remote_ip_prefix || "Any IP address"
	
															}
															
														</p>
													</td>
													<td className=" font-light px-6 py-4 whitespace-nowrap">
														<p className="flex gap-4">

															{/*<Button 
												                type="primary"
												                disabled={rule.description==="default"}
												                shape="circle" icon={}
												            >
												                
												            </Button>*/}
															<EditLineButton
																onFunction={
																	() => {
																		setCurrentEdit(rule.id); 
																		props.setOnDisplayRuleComponent(false)
																	}
																} 
																placeholder={"Rule"}
															/>
															
															<DeleteLineButton 
																placeholder="Rule"
																record={rule}
																onDelete={()=>onDeleteRule(rule.id)}
															/>
															

												            
												            {/*<Button 
												                className="bg-red-500" 
												                type="danger"
												                shape="circle" icon={<AiFillDelete  className=" cursor-pointer" />}
												                disabled={rule.description==="default"}
												                loading={deletingId === rule.id}
												                style={{color: "white"}} 
												            >
												                
												            </Button>*/}

															
														</p>
													</td>
												</tr>
											)
										}else{
											return(
												<tr key={i} className="bg-white border-b dark:bg-bgPagedark text-gray-900 dark:text-darkTextColor">
													
													<td className=" font-light py-4 whitespace-nowrap" colSpan={5}>
														<FormRuleFiwewall 
															messageApi={messageApi} 
															server={props.server} 
															hideForm={props.setOnDisplayRuleComponent} 
															loadVps={props.reload}
															edition={true}
															rule={rule}
															setCurrentEdit={setCurrentEdit}
														/>
													</td>
												</tr>
											)

										}
									})}

								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


function FormRuleFiwewall({rule, hideForm, server, loadVps, messageApi, edition, setCurrentEdit}){
	const [ruleObject, setRule] = useState(rule || {})
	const [dynamicArr, setDynamicArr] = useState([1])
	const [dynamicObject, setDynamicObject] = useState({})

	const [creatingRule, setCreatingRule] = useState(false)
	const [shouldDisabled, setShouldDisabled] = useState(false)
	
	function rangeInitValue(){
		// if()
		const bool = rule.port_range_min !== rule.port_range_max
		const tabs = Object.entries(portApp).filter(item => item[1]?.port == rule.port_range_min)
		const val = bool ? null : tabs?.[0]?.[0];
		setShouldDisabled(val || rule.protocol==="icmp" ? true : false)
		setDynamicObject({"1": rule?.remote_ip_prefix})
		return  setRule(
			{
				...rule, 
				application: val?  val : bool ? "custom"  : rule.protocol || "custom", 
				restricted: !!rule?.remote_ip_prefix,
				port_range_min: bool ? rule.port_range_min +"-"+ rule.port_range_max : rule.port_range_min
			}
		)
	}

	useEffect(()=>{
		if(edition && rule){
			rangeInitValue()
		}
	}, [])

	function onChange(ev){
		setRule({
			...ruleObject,
			[ev.target.name]: ev.target.value
		})
	}
	function onchaneDybject(ev){
		console.log('Dynamic ', ev.target.name, ev.target.value )
		setDynamicObject({
			...dynamicObject,
			[ev.target.name]: ev.target.value,
		})
	}
	function onRemoveDynamic(dynNumber){
		delete dynamicObject[dynNumber]
		setDynamicObject({
			...dynamicObject
		})
		const newDynArr = dynamicArr.filter(dyn => dyn !== dynNumber)
		setDynamicArr(newDynArr)
	}

	const setApplication = (name, value) => {
		let toset = {...ruleObject, [name]: value };
		const item = portApp[value]
		if(item){
			toset = {...toset, port_range_min: item.port, protocol: item?.proto.toLowerCase()}
			setShouldDisabled(true)
		}else{
			setShouldDisabled(false)
		}
		setRule({...toset })
	}
	async function create(ip, ){
		try {
			const port  = ruleObject.port_range_min
			let port1 = port ? port : ruleObject?.application === "icmp" ? "8" : ""
			let port2 = port ? port : ruleObject?.application === "icmp" ? "0" : ""
			if(String(port).includes('-')){
				port1 = String(port).split('-')[0]
				port2 = String(port).split('-')[1]
			}
			let data = {
				"direction": "ingress",
				"port_range_min": (""+port1).trim(),
				"ethertype": "IPv4",
				"port_range_max": (""+port2).trim(),
				"protocol": ruleObject.protocol ? ruleObject.protocol : ruleObject?.application === "icmp" ? "icmp" : null,
				"security_group_id": server?.groups_with_ruls.id,
				"remote_ip_prefix": ip ? ip : null
			}
			return await UserMgt.addVpsGroupRule(server.Id, data)
		} catch (error) {
			return false
		}
	}
	async function onCreate(){
		try {
			const restrictedIp = [...new Set(Object.values(dynamicObject))] 
			console.log('RULE OBject ', ruleObject, restrictedIp)
			
			const application = ruleObject?.application
			if(!application){
					
				messageApi.error('Application field is required !')
				return;
			}
			if(application !== "icmp"){
				if(!ruleObject?.protocol){
					messageApi.error('Protocol field is required !')
					return;
				}
				if(!ruleObject?.port_range_min){
					messageApi.error('Port field is required !')
					return;
				}
			}
			setCreatingRule(true)
			let result;
			if(rule && edition){
				const restul = await UserMgt.delVpsGroupRule(server.Id, rule.id)
				if(!restul || restul?.error){
					return messageApi.open({
						type: 'error',
						content: "Can't update this rule, please try again or conctact us !",
					});
				}
			}
			
			if(restrictedIp.length){
				for (let i = 0; i < restrictedIp.length; i++) {
					const ip = restrictedIp[i]
					result = await create(ip)
					console.log('result result', result)
				}
			}else{
				result = await create()
			}
			if(result && !result.error){
				messageApi.open({
					type: 'success',
					content: !edition ? 'Rule has been created.' :  "Rule has been updated.",
				});
				await loadVps()
				if(edition) {
					setCurrentEdit(null)
				}else{
					hideForm()
				}
			}else{
				messageApi.open({
					type: 'error',
					content: result.message || "An error occured. If this persist, please tell us.",
				});
			}
		} catch (error) {
			console.log('errpr onCreate', error)
			messageApi.open({
				type: 'error',
				content:  "An error occured. If this persist, please tell us.",
			});
		}
		setCreatingRule(false)
	}
	return(
		<div className="p-6 bg-neutral-100">
			<div className="flex flex-wrap items-center gap-4 w-full">
				<div className="flex flex-col w-[180px]">
					<span className="text-sm 2xl:text-base font-medium">Application</span>
					<InputSelect value={ruleObject.application} options={generals} name="application" onChange={setApplication}/>
				</div>
				<div className="flex flex-col w-[180px]">
					<span className="text-sm 2xl:text-base font-medium">Protocol</span>
					<InputSelect disabled={shouldDisabled} value={ruleObject.protocol} options={protocols} name="protocol" onChange={(name, value)=>setRule({...ruleObject, [name]: value })}/>
				</div>
				<div className="flex flex-col w-[180px]">
					<span className="text-sm 2xl:text-base font-medium">Port or range</span>
					<InputText disabled={shouldDisabled} name="port_range_min" placeholder="" value={ruleObject.port_range_min} onChange={onChange} />
				</div>
				<div className="flex flex-col pt-5">
					<Checkbox defaultChecked={!!rule?.remote_ip_prefix} className="font-medium" onChange={(e) => setRule({...ruleObject, restricted: e.target.checked})}>Restrict to IP address</Checkbox>
				</div>
			</div>
			<div className="pt-2">
				<div style={{display: ruleObject?.restricted ? "block" : "none"}}>
					<p className="text-sm font-medium"> 
						Specify IPs or IPs blocks to allow
					</p>
					<div>
						<p className="py-1 font-normal text-xs">Source IP addresses eg: (192.168.8.1) or (168.168.8.0/24) </p>
						{dynamicArr.map((dyn, i) => {
							return(
								<div key={i} className="flex lg:w-1/3 gap-2 justify-start items-center mt-[5px]">
									<InputText 
										name={dyn} 
										placeholder="127.0.0.1" 
										value={dynamicObject[dyn]} 
										onChange={onchaneDybject} 
										disabled={false}
										className={"w-3/4"}
									/>
									<RemoveLineButton
										placeholder={"Remove line "}
										onFunction={()=> {
											onRemoveDynamic(dyn)
										}}
										outlined
									/>
								</div>
							)
						})
					}
					<div className='pt-2'> 
						<AddLineButton
							placeholder={"Add new line "}
							onFunction={()=> {
								dynamicArr.push(String(dynamicArr.length+1))
								setDynamicArr([...dynamicArr])
							}}
							outlined
						/>
					</div>
					</div>
					<div></div>
				</div>
				<div className="flex justify-end gap-4">
					<Button onClick={() => !edition ? hideForm() : setCurrentEdit(null)}  title='Cancel' icon={<CloseCircleOutlined />} >
						Cancel
					</Button>
					<Button loading={creatingRule} onClick={() => onCreate()} type="primary" title={!edition ? "Create" : "Update"} icon={<CheckCircleOutlined />} >
						{!edition ? "Create" : "Update"}
					</Button>
				</div>
			</div>
		</div>
			
	)
}