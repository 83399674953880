import { Tooltip } from "antd"
import React from 'react'
import { BsFillTerminalFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

import { CONSOLE_LINK, NEW_BLOCK_STORAGE_LINK, VPS_MANAGE, VPSMINI_MANAGE } from 'constants/link';
import { CardServiceLayout, MiniCard, ServiceOptions } from 'layouts/ServiceLayout'

import debian from '../assets/img/debian.png'
import ubuntu from '../assets/img/ubuntu.png'
import almalinux from '../assets/img/almalinux.png'
import centos from '../assets/img/centos.png'
import { OkButton } from './OkButton';
import { useTranslation } from "react-i18next";

const imgs = {
    ubuntu,
    debian,
    almalinux,
    centos
}
const tab = [
    'ubuntu',
    'debian',
    'almalinux',
    'centos'
]

export function InstanceCar({server}) {
    const is = (s) => server?.VpsStatus === s
    const navigate = useNavigate()
    const { t } = useTranslation();
    const isMini = server?.service?.task_name ==="vpsmini"// ? "vps" : "vpsmini"
    function onManage(){
        return navigate ( isMini ? VPSMINI_MANAGE(server.Id) : VPS_MANAGE(server.Id))
    }
    const goToConsole = () => navigate(CONSOLE_LINK(server?.Id))


    return (
        <CardServiceLayout server={server}>
            <div>
                <div className='border-b-2 dark:border-gray-800 pb-4 2xl:pb-8 pt-3'>
                    <div className='flex justify-between items-start'>
                        <div className='flex 2xl:gap-4 gap-2'>
                            <img 
                                src={imgs[tab.find(k => server?.OpenstackInstanceImageName?.toLowerCase().includes(k))]} 
                                alt="" className='w-14 h-14' 
                            />
                            <div>
                                <span className='2xl:text-xl text-base  font-semibold text-primary dark:text-darkTextColor'>{server?.DisplayName || server?.OpenstackInstanceName}</span> <br/>
                                <span className="2xl:text-base text-xs">
                                    {server?.type?.OpenstackFlavorName}&nbsp; {!isMini ? "NVMe" : ""}
                                </span>
                            </div> 
                        </div>
                        <div className='flex justify-end '>
                            {isMini ?
                                <OkButton click fun={onManage} title={t("service.manage")} disabled={server?.VpsStatus !== "up"}  server={server} loanding={false}/>
                                :
                                <Tooltip title={!is('up') ? t("service.startServiceTooltip") : t("service.tootipConsole")}>
                                    <BsFillTerminalFill 
                                        onClick={()=>{
                                            if(is('up')){
                                                goToConsole()
                                            }
                                        }}  
                                        className='text-sm xl:text-xl 2xl:text-2xl dark:text-darkTextColor text-primary cursor-pointer'
                                    />
                                </Tooltip>
                            }
                            &nbsp;&nbsp;&nbsp;
                            <ServiceOptions
                                options={[
                                    {label: t("service.connect"),  case: "onConsole", display: !is('up') && "none"},
                                    {label: t("service.manage"), link: isMini ? VPSMINI_MANAGE(server.Id) : VPS_MANAGE(server.Id), display: !is('up') && "none"},
                                    {label: t("service.addBlockStorage"), link: NEW_BLOCK_STORAGE_LINK+"?selected="+server?.Id, display: !is('Deploying') &&  isMini && "none"},
                                    {label: t("service.stop"), case: "onStop", display: !is('up') && "none"},
                                    {label: t("service.start"), case: "onStart", display: !is('down') && "none"},
                                    {label: t("service.reboot"), case: "onReboot", display: is('Deploying') && "none"},
                                    {label: t("service.delete"), case: "onDelete", display: is('Deploying') && "none"},
                                ]}
                                server={server}
                            />
                        </div>
                    </div>
                    <span className={`text-xs pl-[64px] ${!isMini && "hidden"} `}>
                        {server?.type?.Option?.split(',')[0]}
                    </span>
                </div>

                <div className='flex justify-between pt-2 2xl:pt-4 text-sm 2xl:text-base'>
                    <div className=''>
                        <span className='font-bold text-primary'>
                            {server?.VpsStatus === "up" ? t("service.running") : server?.VpsStatus === "down" ? t("service.stopped") : t(server?.VpsStatus)}
                        </span>
                    </div>
                    <div className=''>
                        <p className='text-right'>
                            <span className="text-xs 2xl:text-sm">{isMini ? server?.VpsMiniUrl || server?.domain : server?.OpenstackInstanceExtIp}</span> <br/>
                            <span>{server?.OpenstackInstanceImageName}</span> <br/>
                            <span>{server?.location?.name || "Montreal, Zone A"}</span> 
                        </p>
                    </div>
                </div>
            </div>
        </CardServiceLayout>
    )

}

export function InstanceMiniCar({server}){
    const is = (s) => server?.VpsStatus === s
    const navigate = useNavigate()
    const goToConsole = () => navigate(CONSOLE_LINK(server?.Id))
    const { t } = useTranslation();
    const isMini = server?.service?.task_name ==="vpsmini"


    // (
    //     <MiniCardServiceLayout server={server}>
    //         <div className='flex justify-between items-start'>
    //             <div className='flex gap-2'>
    //                 <img 
    //                     src={imgs[tab.find(k => server?.OpenstackInstanceImageName?.toLowerCase().includes(k))]} 
    //                     alt="" 
    //                     className='2xl:w-14 2xl:h-14 w-10 h-10' 
    //                 />
    //                 <div>
    //                     <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>{server?.DisplayName || server?.OpenstackInstanceName}</span> 
    //                     <br/>
    //                     <span className="2xl:text-sm font-semibold text-xs">
    //                         {
    //                             server?.VpsStatus === "Deploying" ?
    //                             "Deploying"
    //                             :
    //                             server?.OpenstackInstanceExtIp

    //                         }
    //                     </span>
    //                 </div>
    //             </div> 
    //             <div className='flex flex-row-reverse gap-[1px]  justify-end justify-end'>
    //                 <ServiceOptions
    //                     options={[
    //                         {label: "Connect",  case: "onConsole", display: !is('up') && "none"},
    //                         {label: "Manage", link: isMini ? VPSMINI_MANAGE(server.Id) : VPS_MANAGE(server.Id), display: !is('up') && "none"},
    //                         {label: "Add block storage", link: NEW_BLOCK_STORAGE_LINK+"?selected="+server?.Id, display: !is('Deploying') &&  isMini && "none"},
    //                         {label: "Stop", case: "onStop", display: !is('up') && "none"},
    //                         {label: "Start", case: "onStart", display: !is('down') && "none"},
    //                         {label: "Reboot", case: "onReboot", display: is('Deploying') && "none"},
    //                         {label: "Delete", case: "onDelete", display: is('Deploying') && "none"},
    //                     ]}
    //                     server={server}
    //                 />
    //                 <Tooltip title={!is('up') ? "Start server first before using console":"Go to console"}>
    //                     <BsFillTerminalFill onClick={goToConsole}  className='text-sm xl:text-xl 2xl:text-2xl dark:text-darkTextColor text-primary cursor-pointer'/>
    //                 </Tooltip>
    //             </div>
    //         </div>
    //     </MiniCardServiceLayout>
    // )

    return(
        <MiniCard 
            server={server}
            is={is}
            imgSrc={imgs[tab.find(k => server?.OpenstackInstanceImageName?.toLowerCase().includes(k))]}
            firstText={server?.DisplayName || server?.OpenstackInstanceName}
            IpOrLocationText={is('Deploying') ? t("service.deploying") : server?.OpenstackInstanceExtIp}
            options={[
                {label: t("service.connect"),  case: "onConsole", display: !is('up') && "none"},
                {label: t("service.manage"), link: isMini ? VPSMINI_MANAGE(server.Id) : VPS_MANAGE(server.Id), display: !is('up') && "none"},
                {label: t("service.addBlockStorage"), link: NEW_BLOCK_STORAGE_LINK+"?selected="+server?.Id, display: !is('Deploying') &&  isMini && "none"},
                {label: t("service.stop"), case: "onStop", display: !is('up') && "none"},
                {label: t("service.start"), case: "onStart", display: !is('down') && "none"},
                {label: t("service.reboot"), case: "onReboot", display: is('Deploying') && "none"},
                {label: t("service.delete"), case: "onDelete", display: is('Deploying') && "none"},
            ]}
            withConsoleLink
            functionLink={() => {
                if(is('up')){
                    goToConsole()
                }
            }}
            tooltipText={!is('up') ? t("service.startServiceTooltip") : t("service.tootipConsole")}
        />
    )
}

