import {  useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Toast } from '../../../components/Notify';
import { InputTextAdd, LabelAdd } from '../../../components/Bloc';
import UserMgt from '../../../lib/user_managment';
import VPC from '../../../lib/vpc';
import { useNavigate } from "react-router-dom";
import Utils from '../../../utils';
import { Tooltip, Radio, Badge } from 'antd';
import  DeployServerWrapper from '../../DeployServerWrapper';
import { useTranslation } from 'react-i18next';

function border(val, inspect){
	return val === inspect ? "border-primary bg-[#011a430d] " : "border-gray-200"
}
export default function NewVpc(props){
	const {isTrial, isPromo} = props
	const {t} = useTranslation()
    const { vps_packages } = useSelector(app => app.vps)
    const [hoverServerName, setHoverToolTopServerName] = useState(false)
    const [packSelected, setPack] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)
    const navigate = useNavigate()
    const [heightBottomC, setBottomCalculedHeight] = useState(0)


    const [vps_params, setVpsParams] = useState({
        name: '',
        description: '',
        gateway: false,
        location: null,
        trialCode: null,
        promoCode: null,
        isWithPromo: isPromo,
        isAdminFreeDeployment: null
    })

 
    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)

    

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    
    async function getVpsTypes() {
        if(!vps_packages) {
            const r =  await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        } 
            
        return vps_packages
    }
 


    useEffect(() => {
        getVpsTypes()
        
    }, [])

    async function deployVps(paymentId, dataPayment) {
      
        const result = await VPC.create({ ...vps_params, paymentId }, dataPayment)
        setCreating(false)
        if (!result || result.error) {
            Toast.fire({
                icon: 'error', 
                title: result.message || "An error occurred while running. If this persist, please contact us"
            })
        } else {
            if(result&& !result.needToConfirm){
                Toast.fire({
                    icon: 'success',
                    title: result.message || "New VPC network created"
                })
                await VPC.getAll()
                navigate('/networking/vpc')
            }
        }

    }

    function validate(){
        if (!vps_params.name){
            return {
                isFail: true,
                message: "VPC name required!"
            }
        }
        if (!vps_params.description) {
            return {
                isFail: true,
                message: "VPC description is required field"
            }
        }
        if (!vps_params.location) {
            return {
                isFail: true,
                message: "Service location not selected"
            }
        }

        if (isPromo && !codeApplied) {
            return {
                isFail: true,
                message: "You should apply promo code !"
            }
        }
        return {
            isFail: false
        }
    }


 

    //PaypalPackId
    const concertPack = vps_packages &&  
                        vps_packages.filter(p => p.Package_Type ==="KS-LB" && !p.IsMini  && !p.IsSec)
                        .sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price))
    return (
        <DeployServerWrapper
            paypal={paypal}
            packSelected={packSelected}
            isTrial={isTrial}
            isPromo={isPromo}
            vps_params={vps_params}
            setVpsParams={setVpsParams}
            creating={creating}
            concertPack={concertPack}
            handleChange={handleChange}
            setBottomCalculedHeight={setBottomCalculedHeight}
            isNetwork
            isVPC
            title={t("deploy.chooseAVpcType")}
            label={t("deploy.choseDatacenterLocationRegion")}
            locationDesc={t("deploy.datcenterLocationregDesc")}

            customDeploy={deployVps}
            codeApplied={codeApplied}
            codeApplying={null}
            type={"K-WAF"}
            setPaypal={setPaypal}
            validate={validate}
            setCreating={setCreating}
            setCodeApplied={()=>{}}
            setCodeApplying={()=>{}}
        >  

        	<Radio.Group required  value={vps_params?.gateway} className="w-full pb-5 mt-12">
    			<div className="flex gap-5 flex-wrap justify-center ">
    				<div 
    					className={`
    						border
    						border-2
    						${border(false, vps_params?.gateway)}
    						px-6
    						py-2
    						cursor-pointer
    						items-start
    						flex
    						w-[350px]
    						gap-3
    					`}
    					onClick={() => setVpsParams({...vps_params, gateway: false})}
    				>
    			    	<Radio value={false}></Radio>
    					<div className="text-sm -mt-1 2xl:text-base">
    						<p>
    							<span className="font-semibold text-primary text-base">
    								{t("deploy.noGateway")}
    							</span>
    							<br />
    							<Badge
    						        count={t("deploy.recommended")}
    						        style={{
    						          backgroundColor: '#011a43',
    						        }}
    						    />
    						</p>
    					</div>
    				</div>
    				<div 
    					className={`
    						border
    						border-2
    						${border(true, vps_params?.gateway)}
    						px-6
    						py-2
    						w-[350px]
    						items-start
    						cursor-pointer
    						flex
    						gap-3
    					`}
    					onClick={() => setVpsParams({...vps_params, gateway: true})}
    				>
    			    	<Radio value={true}></Radio>
    			    	<div className="text-sm -mt-1 2xl:text-base">
    						<p>
    							<span className="font-semibold text-primary text-base">
    								{t("deploy.extGateway")}
    							</span>
    							<br/>
    							<span className="">
    								 {t("deploy.subnetSpec")}
    							</span>
    						</p>
    					</div>
    				</div>
    				
    			</div>
    	    </Radio.Group>

            
        
            <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor pt-10'>
                {t("deploy.nameAndDesc")}
                <br />
                <span className="text-xs font-normal xl:text-sm">
                	{t("deploy.nameAndDescLabel")}
                </span>
            </p>

            <div className={'md:w-5/6 mx-auto pt-10 px-20 '} style={{paddingBottom: heightBottomC+25}}>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                        <LabelAdd
                            htmlFor="serv-name"
                            placeholder={t("deploy.vpcName")}
                        />
                        <Tooltip open={hoverServerName}   title={t("deploy.serverNameTooltip")}>
                            <InputTextAdd
                                idx="name"
                                value={vps_params.name}
                                name="name"
                                pattern="[A-Za-z0-9]{1,}"
                                onChange={handleChange}
                                required
                                hover
                                onHover={(state) => setHoverToolTopServerName(state)}
                                disabled={creating}
                                placeholder={t("deploy.vpcNamePlaceholder")}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">


                    <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                        <LabelAdd
                            htmlFor="desc"
                            placeholder={t("deploy.vpcDescription")}
                        />
                        <InputTextAdd
                            idx="description"
                            value={vps_params.description}
                            name="description"
                            onChange={handleChange}
                            required
                            disabled={creating}
                            placeholder={t("deploy.vpcDescriptionPlaceholder")}
                        />
                    </div>
                </div>  
            </div>
        </DeployServerWrapper>
    );
}



