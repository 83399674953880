export const CHANGE_DEPLOY_STATE = "@@WEBAPP/CHANGE_DEPLOY_STATE"
export const SAVE_CAPROVER_TOKEN = "@@CAPROVER/SAVE_CAPROVER_TOKEN"
export const SAVE_CAPROVER_ONE_CLICK_APP = "@@CAPROVER/SAVE_CAPROVER_ONE_CLICK_APP"
export const LIST_WEB_APP_SERVERS = "@@WEBAPP/LIST_WEB_APP_SERVERS"


export function caprover_token_save(data){
    return {
        type: SAVE_CAPROVER_TOKEN,
        data : data, 
      }
}
export function caprover_save_onclick_app(data){
    return {
        type: SAVE_CAPROVER_ONE_CLICK_APP,
        data : data, 
      }
}

export function change_deploy_state(data){
    return {
        type: CHANGE_DEPLOY_STATE,
        data : data, 
      }
}
export function list_web_app_servers(data){
    return {
        type: LIST_WEB_APP_SERVERS,
        list : data, 
      }
}
