import store from "../reducer"
import UserAPI from './keepsec_auth_service'
const {  get, post } = require("./api")

const SERVICE_URL = process.env.REACT_APP_USER_MANGMENT_API //http://72.10.163.146"
const apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}



const createProxyHost = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/proxy-hosts", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};

const createAccesslist = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/access-lists", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create createAccesslist >>', error)
        return null
    }
};

const createUser = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/users", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create createUser >>', error)
        return null
    }
};

const createRedirectHost = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/redirection-hosts", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};

const createStream = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/streams", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const create404Host = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/dead-hosts", body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};

const updateSetting = async (server_id, data, id) => {
    try {
        await UserAPI.refrest_token()
        const body = {...data}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/settings/"+id, body, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};



const getProxyHost = async (server_id) => {
    try

     {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/proxy-hosts", header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getRedirectHost = async (server_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/redirection-hosts", header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getCertificates = async (server_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/certificates", header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};

const getStreams = async (server_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/streams", header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const get404Host = async (server_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/dead-hosts", header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};

const getAccesslist = async (server_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/access-lists", header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getUsers = async (server_id, nginx_user) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(
        		SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/users"+(nginx_user ? "?nginx_user="+nginx_user : ""), 
        		header
        	)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getLogs = async (server_id) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/audit-log", header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};
const getAllSettings = async (server_id, isAdvanced, isOpenapp) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(
            SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/settings?isAdvanced="+isAdvanced+"&isOpenapp="+isOpenapp, 
            header
        )
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
};

//admin@keepsec.info', secret: 'KeepSec@_@2023--@
async function createCert(data, server_id) {

    try {
        await UserAPI.refrest_token()
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id+"/ksproxy/nginx/certificates", data, header)
        return result
    } catch (error) {
        console.error('ERROR Create target >>', error)
        return null
    }
}
 

const sslFormData = async(fd, server_id, idModel) =>{
	await UserAPI.refrest_token()
	const userId = store.getState().user.user?.Id
	let url;
    if(!idModel){
        url = SERVICE_URL+`v1/users/${userId}/vps/${server_id}/ksproxy/nginx/certificates/validate`
    }else{
        url = SERVICE_URL+`v1/users/${userId}/vps/${server_id}/ksproxy/nginx/certificates/upload?idModel=${idModel}`
    } 
    try{
        const result = await post(
            url, 
            fd, 
            header,
            false
        )

        return result
    }catch(e){
        console.log('Error ',)
        return {
            error: true
        }
    }
}



export default {
	getUsers,
	createUser,
	getAccesslist,
	createAccesslist,
    createProxyHost,
    getRedirectHost,
    getProxyHost,
    createRedirectHost,
    getStreams,
    createStream,
    get404Host,
    create404Host,
    getLogs,
    getAllSettings,

    updateSetting,
    sslFormData,
    createCert,
    getCertificates


}

