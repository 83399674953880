import { save_user_info, navigateTo2fa } from "../reducer/action/userAction"
import store from "../reducer"

const { post } = require("./api")

const AUTH_URL = process.env.REACT_APP_USER_AUTH_API
const  apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const BASIC_AUTH = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}

const refrest_token = async () => {
    if(process.env.NODE_ENV === "development"){
        return
    }
    try{
        const data = {
        }
        const user = await post(AUTH_URL+"token", data, {})
        return await dispatcher(user)
    }catch(e){
        console.log('error refresh Token', e)
        return  undefined
    }
}

const reset_pass_when_login = async(data) => {
    console.log('data', data)
    try { 
        const newToken = await refrest_token()
        const accessToken  = newToken?.accessToken
        const resultReset = await post(
            AUTH_URL+"reset-password-on-profile?access_token="+accessToken, 
            {...data, custId: store.getState().user.user?.Id}, 
            BASIC_AUTH
        )
        console.log('resultReset resultReset >>', resultReset)
        //to be delete
        //end
        return resultReset;
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return;
    }
}
async function dispatcher(user){
    await localStorage.setItem('xsrfToken', user.xsrfToken);
    const json = {user: user.userAuth}
    store.dispatch(save_user_info(json))
    return { error: false, data: json }
}

const user_login = async(data) => {
    try { 
        const user = await post(AUTH_URL+"login", data, BASIC_AUTH)
        console.log('user logged >>', user)

        if (user && user.xsrfToken) {
            return await dispatcher(user)
        }else if (user && user?.twoFac) {
            store.dispatch(navigateTo2fa(true))
            return user
        }else if (user && user?.message) {
            return { error: true, message: user?.message }
        } else {
            return { error: true, message: "Undefined error" }
        }
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const create2fa = async(data) => {
    try { 
        await refrest_token()
        const resp = await post(
            AUTH_URL+"create-2fa", 
            {
                ...data,
                custId: store.getState().user.user?.Id
            }, 
            BASIC_AUTH
        )
        return resp
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const disableTwoFa = async() => {
    try { 
        await refrest_token()
        const resp = await post(
            AUTH_URL+"disable-2fa", 
            {
                custId: store.getState().user.user?.Id
            }, 
            BASIC_AUTH
        )
        return resp
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const verifyToken = async(data) => {
    try { 
        await refrest_token()
        const resp = await post(
            AUTH_URL+"2fa-validate", 
            {
                ...data,
                custId: store.getState().user.user?.Id
            }, 
            BASIC_AUTH
        )
        return resp
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const validateToken = async(data) => {
    try { 
        await refrest_token()
        const resp = await post(
            AUTH_URL+"2fa-login-validate", 
            {
                ...data,
                // custId: store.getState().user.user?.Id
            }, 
            BASIC_AUTH
        )
        if(resp && !resp?.error){
            return dispatcher(resp)
        }else{
            return resp
        }
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const logout = async(data) => {
    try { 

        console.log('data', data)
        // const resutl = await onDelete(AUTH_URL+"logout", data, BASIC_AUTH)
        // console.log('resutl logout >>', resutl)
        await localStorage.removeItem('xsrfToken')
        await store.dispatch(save_user_info(null))
        return true  
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}


const b64DecodeUnicode = (str) => {
    return decodeURIComponent(Array.prototype.map.call(atob(str), c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))
}
 
const retriveData = async () => {
    const localStorage = window.localStorage
    try {
        const user =  localStorage.getItem('user')
        if(user && user !== "" && user !== "null"){
            const json = JSON.parse(user)

            store.dispatch(save_user_info({user: json.user, refreshToken: json.refreshToken, accessToken: json.accessToken } ))

            return json
        }
        return false
    } catch (error) {
        console.log('error local storage', error)
        return false
    }
}
const saveData = async (data) => {
    const localStorage = window.localStorage
    try {
        console.log('staring save', data )
        await localStorage.setItem('user', JSON.stringify(data))
        console.log('staring end save +++' )
    } catch (error) {
        console.log('error local storage', error)
    }
}

export default {
    user_login,
    retriveData,
    logout,
    refrest_token,
    reset_pass_when_login,
    create2fa,
    verifyToken,
    disableTwoFa,
    validateToken
}
