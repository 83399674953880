import { Button, Form, Input, Tooltip } from 'antd';
import React from 'react'
import { IoIosCopy } from "react-icons/io";
import { FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";

import { CardLayout } from 'layouts/CardLayout';
import { useTranslation } from 'react-i18next';

import s1 from '../../../assets/img/share/s1.svg'
import s2 from '../../../assets/img/share/s2.svg'
import s3 from '../../../assets/img/share/s3.svg'
import s4 from '../../../assets/img/share/s4.svg'
import s5 from '../../../assets/img/share/s5.svg'
import { OkButton } from '../../../components/OkButton';
import { Texto } from 'layouts/TextoLayout';

export default function GetReward() {
    const { t } = useTranslation()

    const [textTtoooltip, setTool] = React.useState(t("account.reward.getRewardTab.copyToclip"))
    const onFinish = (values) => {
        console.log(values);
    };
    const validateMessages = {
        required: t("account.reward.getRewardTab.labelReq", {label: '${label}'}),
        types: {
          email: t("account.reward.getRewardTab.notValidEMail", {label: '${label}'}),
          number: t("account.reward.getRewardTab.notValidPhone", {label: '${label}'}),
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
    };

    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
    };
    
    const shareUrl = "https://dash.keepsec.ca/register"

    const onCopy = () => {

        navigator.clipboard.writeText(shareUrl);
        setTool(t("account.reward.getRewardTab.copied"))
    }
    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                <div> 
                    <CardLayout className="px-6 py-7 mt-2  flex items-center justify-center relative ">
                        <div className='w-full'>
                            <Texto normal className=" text-slate-800 font-semibold">
                                {t("account.reward.getRewardTab.inviteViaEmail")}
                            </Texto>

                            <div className='pt-5 w-full'>
                                <Form
                                    {...layout}
                                    name="nest-messages"
                                    onFinish={onFinish}
                                    className='w-full'
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item
                                        name={['Email']}
                                        rules={[
                                            {
                                            required: true,
                                            },
                                        ]}
                                        >
                                        <Input className='w-full h-10' type='email' placeholder={t("account.reward.getRewardTab.emailInputPlaceholder")}/>
                                    </Form.Item>
                                    <OkButton title={t("account.reward.getRewardTab.invite")} />

                                    {/* <Button className='h-10 w-28 mt-5 ' Sloading={creating} htmlType="submit">
                                        Invite
                                    </Button> */}
                                </Form> 
                            </div>
                        </div>
                    </CardLayout>

                    <CardLayout className="px-6 py-7 mt-2  flex items-center justify-center relative">
                        <div className='w-full'>
                            <span className="text-xl text-slate-800 font-semibold">
                                {t("account.reward.getRewardTab.share")}
                            </span>
                            <div className='px-6 mt-2 mx-14 py-4 flex gap-6 justify-center items-center bg-gray-100'>
                                <span>
                                    {shareUrl}
                                </span>
                                <Tooltip title={textTtoooltip} color={"#011a43"}>
                                    <IoIosCopy onClick={onCopy} className='text-xl text-primary cursor-pointer' />
                                </Tooltip>
                            </div>
                            
                            <div className='pt-5 w-full flex gap-5 flex-wrap justify-center share'>
                                <FacebookShareButton url={shareUrl} children={<img src={s1} className='w-6' />} />
                                <TwitterShareButton url={shareUrl} children={<img src={s2} className='w-6' />} />
                                <LinkedinShareButton url={shareUrl} children={<img src={s3} className='w-6' />} />
                                <WhatsappShareButton url={shareUrl} children={<img src={s4} className='w-6' />} />
                                <PinterestShareButton url={shareUrl} children={ <img src={s5} className='w-6' />} />
                            </div>
                        </div>
                    </CardLayout>
                </div>  

                <div> 
                    <CardLayout className="px-6 py-7 mt-2  flex items-center justify-center relative">
                        <div className='w-full'>
                            <Texto normal className=" text-slate-800 font-semibold">
                                {t("account.reward.getRewardTab.criteria")}
                            </Texto>

                            <div className='pt-5 w-full'>
                                <p>
                                    <span dangerouslySetInnerHTML={{ __html: t("account.reward.getRewardTab.newUsrOnly") }} /> 
                                    <br/>
                                    <br/>
                                    <span dangerouslySetInnerHTML={{ __html: t("account.reward.getRewardTab.rewardAre") }} /> 
                                    <br/>
                                    <br/>
                                    <span dangerouslySetInnerHTML={{ __html: t("account.reward.getRewardTab.theRef") }} /> 
                                    <br/>
                                    <br/>
                                    <span dangerouslySetInnerHTML={{ __html: t("account.reward.getRewardTab.youWill") }} />                                   
                                    <br/>
                                    <br/>
                                    <span dangerouslySetInnerHTML={{ __html: t("account.reward.getRewardTab.theRef2") }} />  
                                    <br/>
                                    <span dangerouslySetInnerHTML={{ __html: t("account.reward.getRewardTab.theOnce") }} />  
                                    <br/>
                                    <br/>
                                    <span dangerouslySetInnerHTML={{ __html: t("account.reward.getRewardTab.toBeAble") }} /> 
                                </p>
                                <br/>
                                <br/>
                                <a href={(process.env.REACT_WEBSITE_URl || 'wwww.keepsec.ca')+"/legal"} target='_blank' className='w-36'><Button  primary className='w-36 text-primary font-bold'>{t("account.reward.getRewardTab.term")}</Button></a>
                            </div>
                        </div>
                    </CardLayout>
                </div>
            </div>
        </div>
    );
}

