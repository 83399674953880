import CAPTAIN from '../../../lib/libCaprover'
import Utils from '../../../utils'


const createRegisterPromise = (
    appName,
    dockerComposeService
) =>  {
    return Promise.resolve().then(function () {
        return CAPTAIN.registerNewApp(appName, !!dockerComposeService.volumes &&!!dockerComposeService.volumes.length, false)
    })
}

const createConfigurationPromise = (
    appName,
    dockerComposeService
) => {
    return Promise.resolve().then(function () {
        return CAPTAIN.getAllApps()
            .then(function (data) {
                console.log('data data data', data)
                const appDefs = data?.data?.appDefinitions
                console.log('appDefs.length appDefs.length', appDefs)
                for (let index = 0; index < appDefs.length; index++) {
                    const element = appDefs[index]
                    if (element.appName === appName) {
                        return Utils.copyObject(element)
                    }
                }
            })
            .then(function (appDef) {
                if (!appDef) {
                    throw new Error(
                        'App was not found right after registering!!'
                    )
                }

                appDef.volumes = appDef.volumes || []

                const vols = dockerComposeService.volumes || []
                for (let i = 0; i < vols.length; i++) {
                    const elements = vols[i].split(':')
                    if (elements[0].startsWith('/')) {
                        appDef.volumes.push({
                            hostPath: elements[0],
                            containerPath: elements[1],
                        })
                    } else {
                        appDef.volumes.push({
                            volumeName: elements[0],
                            containerPath: elements[1],
                        })
                    }
                }

                appDef.ports = appDef.ports || []
                const ports = dockerComposeService.ports || []
                for (let i = 0; i < ports.length; i++) {
                    const elements = ports[i].split(':')
                    appDef.ports.push({
                        hostPort: Number(elements[0]),
                        containerPort: Number(elements[1]),
                    })
                }

                appDef.envVars = appDef.envVars || []
                const environment = dockerComposeService.environment || {}
                Object.keys(environment).forEach(function (envKey) {
                    appDef.envVars.push({
                        key: envKey,
                        value: environment[envKey],
                    })
                })

                const overrideYaml =
                    Utils.convertComposeToService(
                        dockerComposeService
                    )

                if (!!overrideYaml) {
                    appDef.serviceUpdateOverride = overrideYaml
                }

                if (!!dockerComposeService.caproverExtra) {
                    if (
                        dockerComposeService.caproverExtra.containerHttpPort
                    ) {
                        appDef.containerHttpPort =
                            dockerComposeService.caproverExtra.containerHttpPort
                    }

                    if (
                        !!dockerComposeService.caproverExtra
                            .notExposeAsWebApp
                    ) {
                        appDef.notExposeAsWebApp = true
                    }
                }

                return CAPTAIN.updateConfigAndSave(appName, appDef) 
            })
    })
}

const createDeploymentPromise = (appName, dockerComposeService) => {
 
    return Promise.resolve().then(function () {
        let captainDefinition = {
            schemaVersion: 2,
        }

        if (dockerComposeService.image) {
            captainDefinition.imageName = dockerComposeService.image
        } else {
            captainDefinition.dockerfileLines =
                dockerComposeService.caproverExtra?.dockerfileLines
        }

        return CAPTAIN.uploadCaptainDefinitionContent(
            appName,
            captainDefinition,
            '',
            false
        )
    })
}


export default{
    createDeploymentPromise,
    createConfigurationPromise,
    createRegisterPromise
}
