import { HiOutlinePlusCircle } from "react-icons/hi";

import { PackLayout } from "layouts/PackLayout";

import Amethyst from "../../assets/img/blue/amethyst_img.png"
import Jade from "../../assets/img/blue/jade_img.png"
import Tanzanite from "../../assets/img/blue/tanzanite_img.png"
import Topaz from "../../assets/img/blue/topaz_img.png"
import kwaf from "../../assets/img/kwaf/kwaf.webp"
import bronze from '../../assets/img/mini/bronze.png'
import gold from '../../assets/img/mini/gold.png'
import plutonium from '../../assets/img/mini/plutonium.png'
import silver from '../../assets/img/mini/silver.png'
import kslb from '../../assets/img/networking/icon.png'
import Diamond from '../../assets/img/plan/Diamond.png'
import Emerald from '../../assets/img/plan/Emerald.png'
import osminium from '../../assets/img/plan/Osminium.png'
import Ruby from '../../assets/img/plan/Ruby.png'
import Sapphire from '../../assets/img/plan/Saphire.png'
import titanium from '../../assets/img/plan/Titanium.png'
import vanadium from '../../assets/img/plan/Vanadium.png'
import vibranium from '../../assets/img/plan/Vibranium.png'
import vullify from '../../assets/img/vullify/vullify1.png'
import Utils from '../../utils';
import { useTranslation } from "react-i18next";
import { Texto } from "layouts/TextoLayout";

const plan_imgs = {
    Emerald, 
    Diamond, 
    Ruby, 
    Sapphire,
    Jade,
    Topaz,
    Amethyst,
    Tanzanite,
    bronze, 
    gold,
    silver, 
    plutonium,
    titanium,
    osminium,
    vibranium,
    vanadium
}

const PriceDisplayer = ({price, isHour}) => {
    const {t} = useTranslation()

    if(isHour){
        return(
            <Texto verySmall className='font-bold mt-2'>
                {Utils.renderCurencyCode()} {((Utils.priceConvert(price)) / 730.001).toFixed(2)} /{t("package.hour")}
            </Texto>
        )
    }
    return(
        <div className='font-bold flex gap-2 items-end justify-center'>
            <i className='-mt-5 text-xs'>{Utils.renderCurencyCode()} </i> 
            <Texto big className='text-center'>
                {(Utils.priceConvert(price)).toFixed(2)}
            </Texto>
            /{t("package.month")}
        </div>
    )
}

export function VpsPack(props){
    const {t} = useTranslation()
    const kubeNodeAddComponenent = () => (
        <div className={`pb-3 hidden 2xl:block 2xl:absolute  2xl:left-[105%] 2xl:top-[35%]`} style={{display:  !props.displayAddPlane && "none"}}>
            <div onClick={() => props.onAddNodeItem(props.isMaster)} 
                className="cursor-pointer flex flex-col text-center w-28 justify-center"
            >
                <HiOutlinePlusCircle className={'mx-auto my-auto text-5xl 2xl:text-8xl text-primary dark:text-darkTextColor '} />
                <Texto small className="font-semibold">{props?.last ? t("deploy.addNewNode") : props.isMaster ? t("deploy.addNewControlPlane") : ""}</Texto>
            </div>
        </div>
    )
    return(
        <>
            {props.concertPack && props.concertPack.map((pack, i) =>
                <PackLayout 
                    key={i} 
                    pack={pack}
                    objectProps={
                        {
                            ...props
                        }
                    }
                    kubeNodeAddComponenent = {
                        props.concertPack?.length ===  i+1 ?
                        kubeNodeAddComponenent
                        :
                        function(){}
                    }
                    isVpsInstance
                >
                    <div className='2xl:py-14 py-10 text-center text-primary dark:text-darkTextColor'>
                        <div className="flex">
                            <div className="flex gap-1 items-center mx-auto">
                                <img src={plan_imgs[pack.Name]} alt="" className='2xl:w-10 2xl:h-10 w-6 h-6 ' />
                                <span className='font-bold text-xl 2xl:text-2xl'> {pack.Name}</span>
                            </div>
                        </div>
                        <PriceDisplayer price={pack.Price} />
                        <PriceDisplayer price={pack.Price}  isHour />

                        <div className='mx-auto pt-2'>
                            <div className='border-t-4 mx-auto w-10 rounded-full border-t-primary'/>
                        </div>
                        <p className='font-bold text-sm  2xl:text-base pt-3'>{pack.Cpus} CPU{pack.Cpus > 1 ? "s" : ""}</p>
                        <p className='font-bold text-sm  2xl:text-base pt-3'>{pack.RamGb}GB RAM</p>
                        <p className='font-bold text-sm  2xl:text-base pt-3'>{pack.DiskGb}GB SSD</p>
                    </div>
                </PackLayout>
            )}
        </>
    )
}

export function VpsMiniPack(props){
    return(
        <>
            {props.concertPack && props.concertPack.map((pack, i) =>
                <PackLayout 
                    key={i} 
                    pack={pack}
                    objectProps={
                        {
                            ...props
                        }
                    }
                    kubeNodeAddComponenent = {
                        function(){}
                    }
                >
                    <div className='2xl:py-10 py-7 text-center text-primary dark:text-darkTextColor'>
                        <div className="flex">
                            <div className="flex gap-1 items-center mx-auto">
                                <img src={plan_imgs[pack.Name.toLowerCase()]} alt="" className='2xl:w-10 2xl:h-10 w-6 h-6 ' />
                                <span className='font-bold text-xl 2xl:text-2xl'> {pack.Name}</span>
                            </div>

                        </div>
                        <PriceDisplayer price={pack.Price} />
                        <PriceDisplayer price={pack.Price}  isHour />
                        <div className='mx-auto  pt-2'>
                            <div className='border-t-4 w-10 mx-auto border-t-primary' />
                        </div>
                        <p className='font-bold pt-3 text-sm  2xl:text-base'>{pack.Cpus} CPU{pack.Cpus > 1 ? "s" : ""}</p>
                        <p className='font-bold pt-3 text-sm  2xl:text-base'>{pack.RamGb}GB RAM</p>
                        <p className='font-bold pt-3 text-sm  2xl:text-base'>{pack.DiskGb}GB SSD</p>
                        <p className='font-bold mt-3 text-xs  2xl:text-sm'>{pack.Option.split(',')[0]} </p>

                    </div>
                </PackLayout>
            )}
        </>
    )
}


export function VpsSecMiniPack(props){
    return(
        <>
            {props.concertPack && props.concertPack.map((pack, i) =>
                <PackLayout 
                    key={i} 
                    pack={pack}
                    objectProps={
                        {
                            ...props
                        }
                    }
                    kubeNodeAddComponenent = {
                        function(){}
                    }
                >
                    <div className='2xl:py-10 py-7  text-center text-primary dark:text-darkTextColor'>
                        <div className="flex">
                            <div className="flex gap-1 items-center mx-auto">
                                <img src={plan_imgs[pack.Name.toLowerCase()]} alt="" className='2xl:w-10 2xl:h-10 w-6 h-6' />
                                <span className='font-bold text-xl 2xl:text-2xl'> {pack.Name}</span>
                            </div>
                        </div>
                        <PriceDisplayer price={pack.Price} />
                        <PriceDisplayer price={pack.Price}  isHour />

                        <div className='mx-auto  py-2'>
                            <div className='border-t-4 mx-auto w-10 rounded-full border-t-primary' />
                        </div>
                        <p className='font-bold text-sm  2xl:text-base pt-3'>{pack.Cpus} CPU{pack.Cpus > 1 ? "s" : ""}</p>
                        <p className='font-bold text-sm  2xl:text-base pt-3'>{pack.RamGb}GB RAM</p>
                        <p className='font-bold text-sm  2xl:text-base pt-3'>{pack.DiskGb}GB SSD</p>
                    </div>
                </PackLayout>
            )}
        </>
    )
}
export function VullifyPack(props){
    return(
        <>
            {props.concertPack && props.concertPack.map((pack, i) =>
                <PackLayout 
                    key={i} 
                    pack={pack}
                    objectProps={
                        {
                            ...props
                        }
                    }
                    kubeNodeAddComponenent = {
                        function(){}
                    }
                >
                    <div className='py-10 2xl:py-14  text-center text-primary dark:text-darkTextColor'>
                        <div className="flex">
                            <div className="flex gap-1 items-center mx-auto">
                                <img src={vullify} alt="" className=' h-8 2xl:h-10 ' />
                                <span className='font-bold text-xl 2xl:text-2xl'> {pack.Name}</span>
                            </div>
                        </div>
                        <PriceDisplayer price={pack.Price} />
                        <PriceDisplayer price={pack.Price}  isHour />

                        <div className='mx-auto  py-2'>
                            <div className='border-t-4 w-10 mx-auto rounded-full border-t-primary' />
                        </div>
                    </div>
                </PackLayout>
            )}
        </>
    )
}
export function KwafPack(props){
    return(
        <>
            {props.concertPack && props.concertPack.map((pack, i) =>
                <PackLayout 
                    key={i} 
                    pack={pack}
                    objectProps={
                        {
                            ...props
                        }
                    }
                    kubeNodeAddComponenent = {
                        function(){}
                    }
                >
                    <div className='py-10 2xl:py-14  text-center text-primary dark:text-darkTextColor'>
                        <div className="flex">
                            <div className="flex gap-1 items-center mx-auto">
                                <img src={kwaf} alt="" className=' h-8 2xl:h-10 ' />
                                <span className='font-bold text-2xl'> {pack.Name}</span>
                            </div>
                        </div>
                        <PriceDisplayer price={pack.Price} />
                        <PriceDisplayer price={pack.Price}  isHour />

                        <div className='mx-auto  py-2'>
                            <div className='border-t-4 w-10 mx-auto rounded-full border-t-primary' />
                        </div>
                    </div>
                </PackLayout>
            )}
        </>
    )
}


export function KsLbPack(props){
    return(
        <>
            {props.concertPack && props.concertPack.map((pack, i) =>
                <PackLayout 
                    key={i} 
                    pack={pack}
                    objectProps={
                        {
                            ...props
                        }
                    }
                    kubeNodeAddComponenent = {
                        function(){}
                    }
                >
                    <div className='py-10 2xl:py-14  text-center text-primary dark:text-darkTextColor'>
                        <div className="flex">
                            <div className="flex gap-1 items-center mx-auto">
                                <img src={kslb} alt="" className=' h-8 2xl:h-10 ' />
                                <span className='font-bold text-2xl'> {pack.Name}</span>
                            </div>
                        </div>
                        <PriceDisplayer price={pack.Price} />
                        <PriceDisplayer price={pack.Price}  isHour />

                        <div className='mx-auto  py-2'>
                            <div className='border-t-4 w-10 mx-auto rounded-full border-t-primary' />
                        </div>
                    </div>
                </PackLayout>
            )}
        </>
    )
}

