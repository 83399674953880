

import { message } from 'antd'
import { Spiner } from 'components/Bloc'
import UserMgt from 'lib/user_managment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react' 
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { updateHeadLink } from 'reducer/action/coreActions'
import touch_img from '../../assets/img/connect/touch.png'
import vullify from "../../assets/img/connect/vullify-core.png"

const tools = [
	{
		img: touch_img, 
		title: "KSProxy",
		task_name: "ksproxy",
		desc: "Expose your services securely",
	},
	{
		img: vullify,
		title: "Vullifycore",
		desc: "Vullifycore scanner",
        task_name: "vullifycore"
	}
]
export default function VullifyConnect(props){
    const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
    const [server, setvps] = useState(null)
    const [messageApi] = message.useMessage()

    const onNavigate = async (t) => {
		if(t.task_name==="vullifycore"){
			navigate("/security/vullify/"+id+"/connect/target")
		}
		if(t.task_name==="ksproxy"){
			navigate("/security/vullify/"+id+"/connect/ksproxy")
		}
	}
    const loadVps = async () => {
		const vps = await UserMgt.getUniqueVps(id) //.user_vps
		if(!vps?.error){
            setvps(vps?.server)
            console.log('vps?.server vps?.server', vps?.server)
			return vps?.server
		}else{
			messageApi.open({
				type: 'error',
				content: 'An error occured, if this persist, please tell us',
			});
			return navigate(-1)
		}
	}

    useEffect(() => { 

		loadVps().then((vpsVullify) => {
			const headLinks = [
				{
					link: "/security/vullify",
					item: 'vullify',
					alt: "Vullify short link"
				},
				{label: vpsVullify?.DisplayName, arrow: true},
			]
			dispatch(updateHeadLink(headLinks))
				
		})
		return () => dispatch(updateHeadLink([]))
	}, [])


    return(
        <>
			<div className=" dark:text-darkTextColor  ">
				<div className="container mx-auto max-w-full px-3 md:px-8 h-full">
					{server ?
                        <div className='flex gap-3 flex-wrap'>
							{
                            tools.map((t, k) =><Item key={k} navigate={onNavigate} tool={t} />)
							}
                        </div>
						:
						<div className='flex my-auto h-full justify-center items-center'>
						    <Spiner fontSize={50} />
						</div>
                    }

                    
                </div>
            </div>
        </>
    )
}

function Item({tool, navigate}){
	return(
		<div  className=" mt-5 relative">
			<div 
				onClick={()=> navigate(tool)}
				className='w-56 xl:w-60 2xl:w-64 cursor-pointer rounded-xl dark:bg-bgPagedark bg-neutral-100 dark:hover:shadow-slate-700'>

				<div className='py-5 text-center text-primary dark:text-darkTextColor'>
					<div className="flex  items-center justify-center mx-auto">
						<span className='font-semibold text-base md:text-xl 2xl:text-xl '> 
							{tool.title}
						</span>
					</div>
					<div className='h-10 flex items-center justify-center'>
						<p   className='font-semibold text-sm my-auto'>
							{tool.desc}
						</p>
					</div>
					<div  className='mx-auto px-28 py-2'>
						{tool.desc ?<div className='border-t-4 rounded-full border-t-primary'/> : null}
					</div>
					<img src={tool.img} alt="" className='2xl:h-12  h-10 mx-auto' />
				</div>
			</div>
		</div>
	)
}
