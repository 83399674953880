import React, { useState, useEffect } from 'react'
import { Table, Tag, Space, Button, Tooltip, Modal, Tabs, Input, Switch, Popconfirm    } from 'antd';
import { PlusOutlined, QuestionCircleOutlined, LockOutlined } from '@ant-design/icons';
import { DownOutlined, SmileOutlined, SettingOutlined, DeleteOutlined, EditOutlined, PoweroffOutlined   } from '@ant-design/icons';
import { Dropdown, Select, Alert  } from 'antd'
import { MdMoreVert } from "react-icons/md"
import { Details, Locations, SSL, Advanced } from './section/HostSection'
import KSPROXY from 'lib/proxy'
import Utils from '../../utils'

export function PProxyHosts(props){ 

 const [acessKey, setAccessKey] = useState({"0": "Publicly Accessible"}) 
	const col1 = [
        {
        title: 'Source',
        dataIndex: 'source',
        // key: 'source',
        render: (_, obj) => <div> 
          {obj.domain_names.map(domain => <Tag key={domain} color="#011a43">{domain}</Tag>)}
          <br/> 
          <span>{Utils.formatDate(obj?.created_on, 'Do MMMM YYYY, h:mm a')}</span>
        </div>,
      },
      { 
        title: 'Destination',
        dataIndex: 'destination',
        // key: 'destination',
        // width: 200,
        render: (_, obj) => <div> 
          <span>{obj.forward_scheme + "://"+obj.forward_host+":"+obj.forward_port}</span>
        </div>,
      },
      {
        title: 'SSL',
        dataIndex: 'ssl',
        // key: 'ssl',
        render: (_, obj) => <div> 
          <span>{obj.forward_scheme.toUpperCase()} only</span>
        </div>,
      },
      {
        title: 'Access',
        // key: 'access',
        dataIndex: 'access',
        render: (_, obj) => <div> 
          <span>{obj.access_list_id === 0 ? "Public" : acessKey[obj.access_list_id]}</span>
        </div>,
      },
      {
        title: 'Status',
        // key: 'status',
        dataIndex: 'status',
        render: (_, obj) => <div> 
          <span className="flex gap-1 items-center">
            {obj.enabled ? 
              <div className="w-2 h-2 rounded-full bg-[#15803d]" /> : 
              <div className="w-2 h-2 rounded-full bg-[#b91c1c]" />
            }
            {obj.enabled ? "Online" : "Disabled"}
          </span>        
        </div>,

      },
      {
        title: '',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Dropdown 
              menu={{
                items: [
                  {
                    label: 'Edit',
                    key: '0',
                    onClick: () => setOngoingToEdit(record),
                    icon: <EditOutlined />,
                  },
                  {
                    label: record.enabled ? 'Disable' : "Enable",
                    key: '3',
                    onClick: () => enableDisable(record, record.enabled ? 'disable' : "enable"),
                    icon: <PoweroffOutlined />,
                  },
                  {
                    label: (<Popconfirm
                        title={"Confirm deletion "}
                        description={"Really delete this proxy host ?"}
                        onConfirm={() => confirmDelete(record)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        Delete {record.name}
                      </Popconfirm>
                    ),
                    key: '5',
                    danger: true,
                    icon: <DeleteOutlined />,
                  }
                ]
              }}
            >                
              <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
            </Dropdown>
          </Space>
        ),
      },
    ];

  const [open, setOpen] = useState(false)
  const [loading, setload] = useState(false) 
  const {accessList} = props
  const [onGoingToEdit, setOngoingToEdit] = useState(null) 

  const confirmDelete = async (host) => {
    const result = await KSPROXY.createProxyHost(props.server?.Id, {Odelete: true, id: host?.id})
    console.log('result result result', result)
    if(result && !result.error){

      props.messageApi.success('Proxy Host deleted !');
      getProxyHost()
    }else{
      props.messageApi.error(result?.message ||'An error occurs, please try again later.');

    }
  }
  const enableDisable = async (host, state) => {
    const result = await KSPROXY.createProxyHost(props.server?.Id, {[state]: true, id: host?.id})
    console.log('result result result', result)
    if(result && !result.error){

      props.messageApi.success('Done !');
      getProxyHost()
    }else{
      props.messageApi.error(result?.message ||'An error occurs, please try again later.');

    }
  }

  async function getProxyHost(){
    setload(true)
    try{
      const result = await KSPROXY.getProxyHost(props?.server?.Id)
     result && props.setProxyHost(Object.values(result).filter( arr => typeof arr === "object"))
     !result && props.setProxyHost([])
    }catch(e){
      console.log('Cant get proxy host ', e)
    }
    setload(false)
  }
  useEffect(() => { 
    if(accessList){
      let acc0 = {}
      accessList.map((acc, id) =>{
        acc0 = {...acc0, [acc.id]: acc.name}
      })
      setAccessKey({...acessKey, ...acc0})
    }
    getProxyHost();
  }, [accessList])


	return(
		<div className="w-full "> 
			<NewProxyHost 
        server={props?.server} 
        accessList={props?.accessList} 
        open={open || onGoingToEdit} 
        setOpen={setOpen} 
        isKwaf={props?.isKwaf} 
        acessKey={acessKey} 
        proxyHost={onGoingToEdit} 
        setOngoingToEdit={setOngoingToEdit} 
        getProxyHost={getProxyHost}
      />
      <div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">Proxy Hosts</h5>
				<div className="flex justify-end items-center gap-4">
					<Tooltip 
						overlayStyle={{ lineBreak: 'pre-line' }} 
						title={() =>  <div>
								<p className="font-bold">What is a Proxy Host?</p>
								<p className="mt-2">A Proxy Host is the incoming endpoint for a web service that you want to forward.</p> 
								<p className="mt-2">It provides optional SSL termination for your service that might not have SSL support built in.</p>
								<p className="mt-2">Proxy Hosts are the most common use for the proxy.</p>
						    </div>
						}>
						<QuestionCircleOutlined className="xl:text-2xl text-xl cursor-pointer text-primary"/>
					  </Tooltip>

					 <Button type="primary" onClick={()=> setOpen(true)} icon={<PlusOutlined />}>
			            Proxy Host
			     </Button>
				</div>
			</div>

			<Table  
                columns={col1} 
                dataSource={props.proxyHosts} 
                loading={!props.proxyHosts || loading}
            />
		</div>
	)
}

function NewProxyHost(props){
    const { proxyHost, accessList } = props
    const [objectHost, setObjectHost] = useState({forward_scheme: "https"})
    const [objectSSL, setObjectSSL] = useState({})
    const [locations, setLoactions] = useState([])
    const [nginxConfig, setNginCOnfig] = useState("")
    const [access_list_id, setAccessListId] = useState("0")
    const [creation, setCreation] = useState(false)
    const [eMessage, setErrorMessage] = useState(null)

    useEffect(() => {
      if(proxyHost){
        setObjectHost({
          ...proxyHost,
          domain_names: proxyHost?.domain_names?.map(d =>{
            return{
              value: d,
              label: d
            }
          })
        })
        setObjectSSL({...proxyHost, ...proxyHost?.meta})
        setNginCOnfig(proxyHost?.advanced_config)
        setAccessListId(proxyHost?.access_list_id)
        const { locations } = proxyHost
        const locs = proxyHost?.locations?.map(loc => {
          return {
            id: Utils.generateId(), 
            ...loc
          }
        })
        locs && setLoactions(locs)
      }
  }, [proxyHost])

    async function onCreate(){
      const ssl = objectSSL
      // const dnsChallenge = ssl?.dns_challenge
      const meta = {
        dns_challenge: ssl?.dns_challenge,
        dns_provider: ssl?.dns_provider,
        dns_provider_credentials: ssl?.dns_provider_credentials,
        letsencrypt_email: ssl?.letsencrypt_email,
        letsencrypt_agree: ssl?.letsencrypt_agree,
      }
      // delete ssl.dns_challenge
      // delete ssl.dns_provider
      // delete ssl.dns_provider_credentials
      // delete ssl.letsencrypt_email
      // delete ssl.propagation_seconds
      // delete ssl.letsencrypt_agree

      const data = {
        ...objectHost,
        ...ssl,
        locations: locations.map(loc =>{
          delete loc.id
          return {...loc}
        }),
        advanced_config: nginxConfig,
        meta: meta,
        access_list_id,
        domain_names: objectHost?.domain_names?.map(d => d.value)
      }
      delete data.dns_challenge
      delete data.dns_provider
      delete data.dns_provider_credentials
      delete data.letsencrypt_email
      delete data.propagation_seconds
      delete data.letsencrypt_agree
      
      delete data.access_list
      // delete data.meta
      delete data.modified_on
      delete data.owner
      delete data.owner_user_id
      delete data.enabled
      delete data.created_on
      delete data.certificate
      delete data.nginx_err
      delete data.nginx_online

      try{
        
    
        if(!data?.domain_names?.length){
          return setErrorMessage("Domain names field is required.")
        }
        if(!data?.forward_host){
          return setErrorMessage("Forward Hostname / IP field is required.")
        }
        if(!data?.forward_port){
          return setErrorMessage("Forward Port / IP field is required.")
        }
        if(data?.certificate_id === "new" &&  !data?.meta?.letsencrypt_email){
          return setErrorMessage("Please fill out letsencrypt email field before.")
        }
        if(data?.certificate_id === "new" &&  data?.meta?.dns_challenge && !data?.meta?.dns_provider){
          return setErrorMessage("Please fill out DNS provider field before.")
        }
        if(data?.certificate_id === "new" &&  data?.meta?.dns_challenge && !data?.meta?.dns_provider_credentials){
          return setErrorMessage("Please fill out DNS Credentials field before.")
        }
        if(data?.certificate_id === "new" &&  !data?.meta?.letsencrypt_agree){
          return setErrorMessage("Please accept letsencrypt terms before.")
        }
        setCreation(true)
        setErrorMessage(false)
        const result = await KSPROXY.createProxyHost(props.server?.Id, data)
        setCreation(false)
        console.log('RESULY HOST CREAT ', result)
        if(result?.error){
          return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
        }
        return Utils.sleep(1000).then(() => {
            onInit()
            props.getProxyHost()
        });
      }catch(e){
        console.log('ON CREATE PROXY HOST ERROR ', e)
      }
    }
    function onInit(){
      setObjectHost({access_list_id: "0",  forward_scheme: "https"})
      setObjectSSL({})
      setLoactions([])
      setNginCOnfig("")
      setErrorMessage(null)
      props.setOpen(false)
      props.setOngoingToEdit(null)
    }
  const arrayTab = [
      {
        label: "Details",
        component: <Details 
          acessKey={props.acessKey} 
          accessList={accessList} 
          objectHost={objectHost} 
          isKwaf={props?.isKwaf} 
          setObjectHost={setObjectHost}
          access_list_id={access_list_id} setAccessListId={setAccessListId}
        />
      },
      {
        label: "Custom Location",
        component:  <Locations isKwaf={props?.isKwaf} locations={locations} setLoactions={setLoactions}/>
      },
      {
        label: "SSL",
        component: <SSL isKwaf={props?.isKwaf} objectSSL={objectSSL} setObjectSSL={setObjectSSL}/>
      },
      {
        label: "Advanced",
        component:  <Advanced isKwaf={props?.isKwaf} nginxConfig={nginxConfig} setNginCOnfig={setNginCOnfig}/>
      },
  ]
  return(
    <Modal
      destroyOnClose={true}
      centered
      footer={null}
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={700}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
              New Proxy host 
          </h4>
        </div>

        <div className='px-6 mt-3' style={{display: !eMessage && "none"}}>
          <Alert  message={eMessage } type="error" />         
        </div>
        <div className='px-6 py-3'>
          <Tabs defaultActiveKey="1" 
            // onChange={onChange} 
            items={arrayTab.map((_, i) => {
              const id = String(i);
              return {
                label: _.label,
                key: i,
                children: _.component,
              };
            })} 
          />
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                onInit();
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              loading={creation}
               onClick={() =>onCreate()}
              
              // loading={action}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}