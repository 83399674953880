import store from "../reducer"
import { list_flavors, list_images } from "../reducer/action/vpsActions"
import UserAPI from './keepsec_auth_service'

const { post, get, onDelete } = require("./api")

const SERVICE_URL = process.env.REACT_APP_VPS_SERVICE


const getVpsbyId = async (vps_id) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken
        let params = "?access_token=" + accessToken;
        if(vps_id) params = params+"&vps_id="+vps_id
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const flavor_list = async () => {
    try {
       const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken
        const data = await get(SERVICE_URL  + "flavors?access_token=" + accessToken)
        console.log('flavors loaded >>', data)
        store.dispatch(list_flavors(data?.flavors))
        return data?.flavors
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const image_list = async (image_name) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken
        const data = await get(SERVICE_URL  + "images?access_token=" + accessToken+ ( image_name ? "&image_name="+image_name : ""))
        console.log('images loaded >>', data)
        if(!image_name){  
            store.dispatch(list_images(data?.images.filter(i => !i.name.includes('rescue'))))
        }
        return data?.images.filter(i => !i.name.includes('rescue'))
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const scan = async (ip) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken
        const data = await get(SERVICE_URL  + "vps-security-scan?access_token=" + accessToken+ "&ip="+ip )
        console.log('vps scanned >>', data)
        
        return data
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const create = async (server) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken
        const data = await post(SERVICE_URL + "vps?access_token=" + accessToken, server)
        console.log('vps created >>', data)
        // store.dispatch(list_images(data?.images))
        return data
    } catch (error) {
        console.error('error wrong when try to created new user >>', error)
        return { error: true, message: error.message }
    }
}
const reboot = async (server) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken
        const data = await post(SERVICE_URL + "vps/reboot?access_token=" + accessToken, {...server, access_token: accessToken})
        console.log('vps reboot result >>', data)
        return data
    } catch (error) {
        console.error('error wrong when try to reboot vps>>', error)
        return { error: true, message: error.message }
    }
}

const stop = async (server) => {
    try {
        console.log('server server server', server)
        const newToken = await UserAPI.refrest_token() 
        const accessToken  = newToken?.accessToken
        const json = {...server, access_token: accessToken}
        console.log('Before ran stop function', json)
        const data = await post(SERVICE_URL + "vps/stop?access_token=" + accessToken, json)
        console.log('vps reboot result >>', data)
        return data
    } catch (error) {
        console.error('error wrong when try to reboot vps>>', error)
        return { error: true, message: error.message }
    }
}

const del = async (server) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken
        const response = await onDelete(SERVICE_URL + "vps?access_token=" + accessToken, {...server, access_token: accessToken})
         const data =  await response.json()
        console.log('vps reboot result >>', data)
        return data
    } catch (error) {
        console.error('error wrong when try to reboot vps>>', error)
        return { status: "error", details: "An error occurs, if this percist please contact us !" }
    }
}




export default {
    getVpsbyId,
    flavor_list,
    image_list,
    create,
    reboot,
    stop,
    scan,
    del,
    list: () =>{},


}

