import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { PinInput } from 'react-input-pin-code';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ExtLink } from 'components/Bloc';

import { Logo } from '../../components/Logo';
import { Toast } from '../../components/Notify';
import UserAPI from "../../lib/keepsec_auth_service"
import { navigateTo2fa } from "../../reducer/action/userAction"

import "./Auth.css"

export default function TwoFaVerication(){

	const [data, setData] = useState({token: ['', '', '', '', '', '']})
	const [creating, setCreating] = useState(false)
    const navigate = useNavigate()
	const { t } = useTranslation();
	const { canNavigate2fa } = useSelector(app => app.user)
	const dispatch = useDispatch()
	const [counter, setCounter] = useState(119)
    let  myInterval = null;

	async function onVerfyCode(e){
        e.preventDefault()
      
        setCreating(true)
        try {
        	const token = data?.token?.join('')
            const result = await UserAPI.validateToken({token}, true)
            console.log('result', result)
            setCreating(false)
            if(result && !result.error && result.data){
                Toast.fire({
                    icon: 'success',
                    title: t('Signed in successfully')
                })
                dispatch(navigateTo2fa(false))
                navigate('/')
                
            }else{ 
                Toast.fire({
                    icon: 'error',
                    title: result.message || "An error occurs, please try again or contact us."
                })    
            }
        } catch (error) {
            setCreating(false)
        }
    }

    useEffect(() => {
       
    	if(!canNavigate2fa){
    		 return navigate('/login')
    	}
    	if(!myInterval){
	    	myInterval = setInterval(() => {
                setCounter((prevTime) =>{
                    if(prevTime ==0){
                    	dispatch(navigateTo2fa(false))
                        return navigate('/login')
                    }
                    return prevTime - 1
                });
            }, 1000);
    	}
        return () => clearInterval(myInterval);       
    }, [])

	return(
		<div className='bgCustom'>
            <div className="flex items-center justify-center min-h-screen  content">
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-2xl w-[400px]">
                    <div>
                        <Logo/>
                        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                            {t('Two-Factor Authentification')}
                        </h2>
                        <p className="mt-2 text-sm font-semibold text-center text-gray-600">
                            {t('Open your authentification app and enter a code')}<br /> 
                        </p>
                        
                    </div>
                    <form className="mt-8 space-y-6" method="POST" onSubmit={onVerfyCode}>
                        <div className="flex flex-col text-center justify-center mb-0">
                        	{/* <span>
                            	<b>2FA code (6 characters)</b>
                            </span>
                            <br/> */}
                        	<div className="mt-1 mx-auto">
	                            <PinInput
							      values={data?.token}
							      required
							      length={6}
							      onChange={(value, index, values) => setData({...data, token: values})}
							      // onComplete={(values) => next(current, values)}
							    />
                        	</div>
                        	<br/>
                            <span>
                            	<i className="font-semibold">Session expire in&nbsp;
                            		{
                            			counter >= 60?
                            			1+"min "+(counter - 60)+"s"
                            			:
                            			0+"min "+counter+"s"
                            		}
                            	</i>
                            </span>
                        </div>
                        <div className="">
                        	<p className="mt-2 text-sm font-semibold text-center text-gray-600">
	                            {t("Can't access your authentification app?")}<br /> 
                                <ExtLink 
                                    href='https://www.keepsec.ca/legal'
                                    text={t('Enter a recovery code')}
                                />
                                &nbsp;{t("or")}&nbsp;
                                <ExtLink 
                                    href='https://www.keepsec.ca/legal?tab=privacy' 
                                    text={t('contact support')}
                                />
	                        </p>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="
                                relative
                                flex
                                justify-center
                                w-full
                                px-4
                                py-2
                                text-sm
                                font-medium
                                text-white
                                bg-primary
                                group
                                rounded-md
                                hover:bg-black hover:border-primary
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-blue-500
                                gap-2
                                "
                            >
                                {!creating ?
                                    <svg
                                        className="w-5 h-5 text-white group-hover:text-indigo-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    :
                                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white"  fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                }
                                {!creating ?
                                    <span>{t('Verify code')}</span> :
                                    <span>{t('Ongoing')} ...</span>
                                }
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
	)

}