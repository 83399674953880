import { useEffect, useState } from 'react';
// import ubuntu from '../../../assets/img/ubuntu.png'
// import debian from '../../../assets/img/debian.png'

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { BsFillLightbulbFill } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import CommingSoon from 'components/CommingSoon';
import Paypal from 'components/PaypalModal';
import AddBotom from 'components/vps/AddBotom';
import { ServerLocation } from 'components/vps/ServerLocation';

import almalinux from '../../../assets/img/sec/almalinux.png'
import centos from '../../../assets/img/sec/centos.png'
import debian from '../../../assets/img/sec/debian.png'
import ImageImg from '../../../assets/img/sec/DEBSEC-KS-7.png'
import hyperstene from '../../../assets/img/sec/Hypersthene.png'
import obsidian from '../../../assets/img/sec/KP-Obsidian.png'
import onyx from '../../../assets/img/sec/KP-Onyx.png'
import level0 from '../../../assets/img/sec/KS-level-0.png'
import level1 from '../../../assets/img/sec/KS-level-1.png'
import level2 from '../../../assets/img/sec/KS-level-2.png'
import level3 from '../../../assets/img/sec/KS-level-3.png'
import nuumite from '../../../assets/img/sec/Nuummite.png'
import compliant from '../../../assets/img/sec/test-logo-compliant.png'
import ubuntu from '../../../assets/img/sec/ubuntu.png'
import { Block, Spiner } from '../../../components/Bloc';
import { Toast } from '../../../components/Notify';
import { OkButton } from '../../../components/OkButton';
import VpsImage from '../../../components/VpsImage'
import UserMgt from '../../../lib/user_managment';
import Vps_sec from '../../../lib/vps_sec'
import VPSapi from '../../../lib/vps_service';
import Utils from '../../../utils';

const levels = { level1, level2, level3, level0 }

const plan_imgs = { "onyx": onyx, obsidian, hyperstene, nuumite }

const imgs = {
    ubuntu,
    debian,
    almalinux,
    centos
}

const securitiesLevels = [
        {name: "Default", id: 0, task_name: "default"},
        {name: "Level 1", id: 1, task_name: "level_1"},
        {name: "Level 2", id: 2, task_name: "level_2"},
        {name: "Level 3", id: 3, task_name: "level_3"}
    ]

export default function AddVpsSec() {

    const { flavors } = useSelector(app => app.vps)
    const images = useSelector(app => {
        const imgs = app.vps.images
        const OS = ["Ubuntu", "AlmaLinux", "Debian", "CentOS"];
        const Tab = []
        if (app.vps.images) {
            for (let i = 0; i < OS.length; i++) {
                const os = OS[i];
                const concerts = imgs.filter(img => img.name.toLowerCase().includes(os.toLowerCase()))
                Tab.push({ name: os, list: concerts, id: i })
            }
            return Tab
        }
        return null;
    })
    const { vps_packages } = useSelector(app => app.vps)

    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [disabledCheck, setDisCheck] = useState(false)

    const [selectedImage, setSelectedImage] = useState(null)
    const [clickedImage, setClickedImage] = useState(null)

    const [selectedLevel, setSelectedLevel] = useState(null)
    const [clickedLevel, setClickedLevel] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [paypalpack, setPackPaypal] = useState(null)
    const [state, setState] = useState(0)
    const [generating, setGeneration] = useState(false)
    const [secrets, setSecret] = useState([])

    const navigate = useNavigate()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        flavor: null,
        image: null,
        username: "",
        location: null,
        password: "",
        serviceType: "VPS_INSTANCE"
    })


    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)



    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    async function loadFlavors() {

        if (!flavors) {
            const r = await VPSapi.flavor_list()
            return r
        }

        return flavors
    }
    async function loadImages() {
        !images && VPSapi.image_list()
    }
    async function getVpsTypes() {
        if (!vps_packages) {
            const r = await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        }

        return vps_packages
    }

    
    async function loadVps(){
        console.log('start load vps loadVps')
        const vps = await UserMgt.get_user_vps() 
    } 


    useEffect(() => {
        loadFlavors().then(f => {
            getVpsTypes().then(vps_packages => {

                console.log('vps_packages vps_packages vps_packages', vps_packages)
                if (Utils.getUrlParam('prefer')) {
                    const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                    const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                    if (pack) {
                        setClicked(parseInt(selectedIdVpsTypes));
                        onClaculFlavor(pack, f)
                        setPackPaypal(pack.Name);

                    }
                    window.localStorage.removeItem('@user_first_service')

                }
            })

        })
        loadImages()


    }, [])


    async function onGeneratee() {
        setGeneration(true)
        try {
            const result = await Vps_sec.generatePassword();
            console.log('errrrrrrr', result)
            const output_scr = result?.script_output
            if (!output_scr) {
                return;
            }
            const secret = (output_scr.split("\n"))[0].split(' ')
            setSecret(secret)
            setVpsParams({ ...vps_params, password: (output_scr.split("\n"))[1] })
        } catch (error) {
            console.log('OnGerate error', error)
        }
        setGeneration(false)

    }

    async function deployVps(paymentId, dataPayment) {
        console.log('vps_params vps_params', vps_params)

        const result = await UserMgt.create_vps({ ...vps_params, paymentId }, dataPayment)
        setCreating(false)
        if (!result || result.error || result.status !== "success") {
            Toast.fire({
                icon: 'error',
                title: result.message || "An error occurred while running if this persist, please contact us !"
            })
        } else {
            Toast.fire({
                icon: 'success',
                title: "VPS Instance created !"
            })
            console.log('result vps created', result)
            navigate('/compute/vps')
        }

    }
    function onPay(ev) {
        console.log('vps_params vps_params', vps_params)
        ev.preventDefault()

        if (!vps_params.image) {
            return Toast.fire({
                icon: 'error',
                title: "Image not selected !"
            })
        }
        if (!vps_params.flavor) {
            return Toast.fire({
                icon: 'error',
                title: "Package not selected !"
            })
        }
        if (!vps_params.location) {
            return Toast.fire({
                icon: 'error',
                title: "Service location not selected"
            })
        }
        console.log('deploiement params ****', vps_params)

        if (vps_params.password.length < 6) {
            return Toast.fire({
                icon: 'error',
                title: "The vps password must be at least 6 characters !"
            })
        }

        const pattern = /^(?=.*[A-Za-z0-9–_-])(?=.*\d)[A-Za-z–_-\d]{6,}$/;
        const test = pattern.test(vps_params.password);
        if (!test) {
            return Toast.fire({
                icon: 'error',
                title: "Vps password should contain only dash, undescores or alphanumeric !"
            })
        }

        if (vps_params.passwordCon !== vps_params.password) {
            return Toast.fire({
                icon: 'error',
                title: "Vps password did not match !"
            })
        }
        setCreating(true)
        setPaypal(true)
    }

    function onClaculFlavor(pack, f) {
        if(pack) setPack({...pack})
        else setPack(null)
        const f2 = f || flavors
        const flavor = f2 && f2.find(fl => fl.name === pack?.OpenstackFlavorName);
        console.log('Flavor found:', flavor)
        if (flavor) setVpsParams({ ...vps_params, flavor: flavor.name })

    }


    function onDismiss() {
        setCreating(false)
        setPaypal(false)
    }

    function createPaymentAndDeploy(paymentID, dataPayment) {
        setPaypal(false)
        const dataPayment2 = {
            ...dataPayment,
            amountPaid: parseFloat(packSelected?.Price),

        }
        return deployVps(paymentID, dataPayment2)
    }

    const concertPack = vps_packages &&
        vps_packages.filter(p => p.Package_Type === "VPS" && p.IsSec)
            .sort((a, b) => parseInt(a.Price) - parseInt(b.Price))
 
    return (
        <>
            <Paypal
                createPaymentAndDeploy={createPaymentAndDeploy}
                open={paypal}
                vpsType={packSelected}
                onDismiss={onDismiss}
                withFirstTrial={false}
                withPromo={false}
                finalPrice={()=>{}}
            />
            <div className="px-3 md:px-8  dark:text-darkTextColor ">
                <div className="container max-w-full">
                    <div className='mx-auto'>
                        <ServerLocation vps_params={vps_params} setVpsParams={setVpsParams} />
                        <p className='text-center text-2xl font-bold text-primary  dark:text-darkTextColor'>Choose your instance plan</p>
                        {concertPack ?
                            <form onSubmit={onPay} className="">
                                <div className="mx-auto justify-center pb-5 border-b-2 dark:border-gray-800  flex flex-wrap gap-2 justify-center  md:w-11/12 mt-10">
                                    {concertPack.map((pack, i) =>
                                        <div key={i} className="mx-auto mt-5 ">
                                            <div onMouseOver={() => setSelected(pack.Id)}
                                                onClick={() => {
                                                    if (!creating) {
                                                        if (clicked == pack.Id) {
                                                            console.log('cliket on same')
                                                            setClicked(null);
                                                            onClaculFlavor(null)
                                                        } else {
                                                            setClicked(pack.Id);
                                                            setPackPaypal(pack.Name);
                                                            //setPack(pack);
                                                            onClaculFlavor(pack)
                                                        }

                                                    }
                                                }}
                                                className={'w-64 cursor-pointer relative rounded-xl dark:bg-bgPagedark ' +
                                                    ((selected == pack.Id) ? "hover:bg-white  hover:shadow-2xl " : '') + "" + ((clicked == pack.Id) ? "bg-white shadow-2xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')}>

                                                {(clicked == pack.Id) &&
                                                    <svg className="div_check checkmark float-right absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                    </svg>
                                                }
                                                <div className='pt-6 pb-8 text-center text-primary dark:text-darkTextColor'>

                                                    <div className="flex justify-center items-center gap-2">
                                                        <img src={plan_imgs[pack.Name.toLowerCase()]} alt="" className='w-14 h-14' />
                                                        <div className='font-bold text-2xl' > {pack.Name}</div>
                                                    </div>

                                                    <p className='font-bold'>
                                                        <i className='-mt-5'>$</i> <span className='text-center text-3xl'>{pack.Price}</span>/month
                                                    </p>
                                                    <p className='font-bold text-xs'>$ {(parseFloat(pack.Price) / 730.001).toFixed(2)} /hour</p>

                                                    {/* <p className={"rounded-full px-0 py-0.5"} > 
                                                            <span className='font-semibold'> {pack.Cpus} CPU{pack.Cpus > 1 ? "s" : ""} - {pack.RamGb}GB RAM </span>
                                                            <br/>
                                                            <span className='font-semibold'>- {pack.DiskGb}GB SSD - {pack.DiskHDD} HDD </span>
                                                        </p> */}
                                                    <div className='mx-auto px-28 py-2'>
                                                        <div className='border-t-4 rounded-full border-t-primary' />
                                                    </div>
                                                    <p className='font-bold pt-3'>{pack.Cpus} CPU{pack.Cpus > 1 ? "s" : ""}</p>
                                                    <p className='font-bold pt-3'>{pack.RamGb}GB RAM</p>
                                                    <p className='font-bold pt-3'>{pack.DiskGb}GB SSD</p>
                                                    <p className='font-bold pt-3'>{pack.DiskHDD} HDD</p>

                                                    {/* <p className='font-bold pt-1' style={{fontSize: "12px"}}> Dedicated WAN /29 LAN /24 Subnet </p>
                                                        <p className='font-bold pt-1' style={{fontSize: "12px"}}>AES 256 Data encryption </p>
                                                        <p className='font-bold pt-1' style={{fontSize: "12px"}}>Instant setup </p>
                                                        <p className='font-bold pt-1' style={{fontSize: "12px"}}>Dedicated VPN </p> */}






                                                    <p className='font-semibold' style={{ fontSize: "12px" }}>
                                                        {/* <img src={compliant} className="mx-auto " /> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor pt-10'>Pick your instance image</p>

                                <div className="justify-center mx-auto  pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center   mt-10">
                                    {images && images.filter(image => true).map((image, k) =>
                                        <VpsImage
                                            key={k}
                                            image={image}
                                            creating={creating}
                                            clickedImage={clickedImage}
                                            setClickedImage={setClickedImage}
                                            setVpsParams={setVpsParams}
                                            vps_params={vps_params}
                                            setSelectedImage={setSelectedImage}
                                            selectedImage={selectedImage}
                                            uri={imgs[Object.keys(imgs).find(k => image.name.toLowerCase().includes(k)) || 'linux']}
                                        />
                                    )}

                                </div>

                                <p className='text-center text-2xl font-bold text-primary  dark:text-darkTextColor pt-10'>Pick your instance image security level</p>

                                <div className="justify-center mx-auto  pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center  md:w-11/12   mt-10">
                                    {securitiesLevels && securitiesLevels.map((image, k) =>
                                        <div onClick={() => {
                                            if (!creating) {
                                                if (clickedLevel == image.id) {
                                                    setClickedLevel(null);
                                                    setVpsParams({ ...vps_params, level: null })
                                                } else {
                                                    setClickedLevel(image.id);
                                                    setVpsParams({ ...vps_params, level: image.task_name })
                                                }
                                            }
                                        }} onMouseOver={() => setSelectedLevel(image.id)} key={k} className={'w-68 relative dark:bg-bgPagedark  mt-5  cursor-pointer rounded-xl ' +
                                            ((selectedLevel == image.id) ? "hover:bg-white  hover:shadow-2xl " : '') + "" + ((clickedLevel == image.id) ? "bg-white shadow-xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')}>

                                            {(clickedLevel == image.id) &&
                                                <svg className="div_check checkmark absolute right-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                                    <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                </svg>
                                            }

                                            <div className=' relative pl-3'>
                                                <img src={ImageImg} alt="" className='w-[70px] absolute' />
                                                <div className="ml-[75px] flex py-3 pr-3 items-center justify-between">
                                                    <p className='text-primary dark:text-darkTextColor font-semibold text-xl'>{"Debian KS12"}</p>
                                                    &nbsp;&nbsp;
                                                    <img src={levels['level' + (k)]} alt="" className='w-14' />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>


                                <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor pt-10'>Server Informations</p>

                                <div className={'md:w-5/6 mx-auto pt-10  ' + (creating && paypal ? " pb-72 md:pb-56" : 'pb-56 md:pb-48')}>
                                    <div className="flex flex-wrap  items-end -mx-3 mb-6">
                                        <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0 xl:pr-10" >
                                            <div className="py-3">
                                                <label
                                                    className="
                                                        block
                                                        uppercase
                                                        tracking-wide
                                                        text-gray-700 text-xs
                                                        dark:text-darkTextColor
                                                        font-bold
                                                        mb-2
                                                    "
                                                    htmlFor="serv-name"
                                                >
                                                    Identify your instance
                                                </label>
                                                <input
                                                    className="
                                                        appearance-none
                                                        block
                                                        w-full
                                                        bg-gray-200
                                                        text-gray-700
                                                        border
                                                        rounded
                                                        py-3
                                                        px-4
                                                        mb-3
                                                        leading-tight
                                                        dark:bg-bgPagedark
                                                        dark:text-darkTextColor
                                                        focus:outline-none focus:bg-white
                                                    "
                                                    id="serv-name"
                                                    value={vps_params.server_name}
                                                    name="server_name"
                                                    pattern="[A-Za-z0-9]{1,}"
                                                    onChange={handleChange}
                                                    required
                                                    disabled={creating}
                                                    placeholder='Your instance resources must have unique names'
                                                />
                                                <p className=" font-semibold text-red-700 -mt-3" style={{ fontSize: "14px" }} >
                                                    <i> Must not contain spaces, special characters or symbols</i>
                                                </p>
                                            </div>
                                            <div className="py-3">
                                                <label
                                                    className="
                                                        block
                                                        uppercase
                                                        tracking-wide
                                                        text-gray-700 text-xs
                                                        dark:text-darkTextColor
                                                        font-bold
                                                        mb-2
                                                    "
                                                    htmlFor="serv-username"
                                                >
                                                    Server user
                                                </label>
                                                <input
                                                    className="
                                                        appearance-none
                                                        block
                                                        w-full
                                                        bg-gray-200
                                                        text-gray-700
                                                        border border-gray-200
                                                        rounded
                                                        py-3
                                                        px-4
                                                        leading-tight
                                                        dark:bg-bgPagedark
                                                        dark:text-darkTextColor
                                                        focus:outline-none focus:bg-white focus:border-gray-500
                                                    "
                                                    id='serv-username'
                                                    value={vps_params.username}
                                                    name="username"
                                                    onChange={handleChange}
                                                    required
                                                    disabled={creating}
                                                    pattern="(?!root$)(?!admin$)(?!keepsec$)(?!ubuntu$)(?!almalinux$)([A-Za-z0-9]{3,})$"
                                                    placeholder='Username used to login via SSH'
                                                />
                                            </div>
                                            <div className="py-3">
                                                <label
                                                    className="
                                                        block
                                                        uppercase
                                                        tracking-wide
                                                        text-gray-700 text-xs
                                                        dark:text-darkTextColor
                                                        font-bold
                                                        mb-2
                                                    "
                                                    htmlFor="serv-pass"
                                                >
                                                    Server password
                                                </label>

                                                <div className="flex gap-3 h-12 mb-3">
                                                    <input
                                                        className="
                                                            appearance-none
                                                            block
                                                            w-full
                                                            bg-gray-200
                                                            text-gray-700
                                                            border
                                                            rounded
                                                            py-3
                                                            px-4
                                                            leading-
                                                            dark:bg-bgPagedark
                                                            dark:text-darkTextColor
                                                            focus:outline-none focus:bg-white
                                                        "
                                                        id="serv-pass"
                                                        value={vps_params.password}
                                                        name="password"
                                                        onChange={handleChange}
                                                        required
                                                        type={"text"}
                                                        disabled={true}
                                                        placeholder='Password used to login via SSH'
                                                    />
                                                    {/*<div onClick={()=> setVissiblePass(!visiblePass)} className="flex px-4 cursor-pointer bg-gray-200 dark:bg-bgPagedark items-center justify-center  border">
                                                        {visiblePass ? <AiFillEyeInvisible className="text-2xl text-primary dark:text-darkTextColor"/> : <AiFillEye className="text-2xl text-primary dark:text-darkTextColor"/>}
                                                    </div>*/}
                                                    <div className="">
                                                        <OkButton disabled={disabledCheck} loanding={generating} click fun={onGeneratee} title="Generate" />
                                                    </div>

                                                </div>



                                            </div>
                                        </div>

                                        <div className="w-full xl:w-1/2 px-3">
                                            <span className="text-2xl font-semibold text-primary dark:text-darkTextColor">Password recovery keys </span>
                                            <p className="font-semibold text-red-700" style={{ fontSize: "14px" }}>For password recovery write these words in the correct order on paper.</p>
                                            <div className="flex flex-wrap gap-4">
                                                <div>
                                                    {[1, 2, 3, 4].map((t, i) => (

                                                        <div className="flex items-center gap-2 py-2" key={t}>
                                                            <span className="text-base font-bold  w-7 h-7  text-center flex justify-center items-center bg-primary text-white  rounded-full">
                                                                {t}
                                                            </span>
                                                            <input
                                                                className="
                                                                            appearance-none
                                                                            block
                                                                            bg-gray-200
                                                                            text-gray-700
                                                                            border
                                                                            rounded
                                                                            py-2
                                                                            px-4
                                                                            leading-
                                                                            dark:bg-bgPagedark
                                               
                                                                            dark:text-darkTextColor
                                                                            focus:outline-none focus:bg-white
                                                                        "
                                                                value={secrets?.[i]}
                                                                name={"secret" + t}
                                                                // onChange={handleChange}

                                                                // type={!visiblePass&& "password"}
                                                                disabled={true}

                                                            />
                                                        </div>

                                                    ))}
                                                </div>
                                                <div>
                                                    {[1, 2, 3, 4].map((t, i) => (
                                                        <div className="flex gap-2 py-2 items-center" key={t}>
                                                            <span className="text-base font-bold  w-7 h-7  text-center flex justify-center items-center bg-primary text-white  rounded-full">
                                                                {t + 4}
                                                            </span>
                                                            <input
                                                                className="
                                                                            appearance-none
                                                                            block
                                                                            bg-gray-200
                                                                            text-gray-700
                                                                            border
                                                                            rounded
                                                                            py-2
                                                                            px-4
                                                                            leading-
                                                                            dark:bg-bgPagedark
                                                                            dark:text-darkTextColor
                                                                            focus:outline-none focus:bg-white
                                                                        "
                                                                value={secrets?.[t + 3]}
                                                                name={"secret" + (t + 4)}
                                                                // onChange={handleChange}
                                                                disabled={true}
                                                            // type={!visiblePass&& "password"}     

                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <AddBotom
                                        creating={creating}
                                        paypal={paypal}
                                        soon
                                        packSelected={packSelected}
                                    />
                                    {/* <div className='mx-auto'>
                                       
                                         <div className={'fixed bottom-0 dark:opacity-70 left-0 dark:text-darkTextColor dark:bg-bgPagedark shawdow w-screen mx-auto md:ml-10 py-5'}>
                                            <div className='container'>
                                                <div className='flex flex-wrap  mx-auto gap-8 justify-center px-10 md:pl-72   2xl:ml-0 items-center' >
                                                    <div className="w-72 ">
                                                        <div className='text-center text-primary dark:text-darkTextColor pt-4'>
                                                            <span className='text-xl'>Summary:</span> 
                                                            <br/>
                                                            {packSelected ?
                                                            <span className='text-2xl font-bold'>
                                                                <span className='text-base font-bold'>Total: &nbsp;</span>
                                                                $ {(parseFloat(packSelected.Price) * (1 + 14.975 / 100)).toFixed(2)} / month (include TPS + TVQ)
                                                            </span> :
                                                            <span>(empty)</span>
                                                            }
                                                            
                                                        </div>
                                                        
                                                        <div className="">
                                                            { true&&
                                                                <div className=''>
                                                                    <button className="flex mt-4 mx-auto text-xl   w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                                        type="submit"
                                                                        disabled={creating}
                                                                    >
                                                                        {!creating ?
                                                                            <span className='mx-auto flex  items-center'>
                                                                                <BsFillLightbulbFill className='text-2xl' />
                                                                                {" Deploy Now"}
                                                                            </span>
                                                                            :
                                                                            <span className='mx-auto flex gap-1 items-center'>
                                                                                <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                                </svg>
                                                                                {!paypal ? " Deploying" :  " Waiting for paypal"}
                                                                            </span>

                                                                        }
                                                                    </button>
                                                                </div>
                                                            }
                                                            
                                                            <div className='h-3'/>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="xl:pt-10">
                                                    
                                                        <div className="">
                                                            <label className="form-check-label font-bold inline-block" for="checkAppSecConfirm">
                                                                Please confirm that you noted the password  and wrote the recovery keys 
                                                        </label>
                                                        <br/>
                                                            <input 
                                                                className="form-check-input appearance-none h-7 w-7 border border-gray-300 rounded-sm bg-white dark:bg-black checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                                                type="checkbox" 
                                                                value="" 
                                                                id="checkAppSecConfirm" 
                                                                
                                                            />
                                                        </div> 
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 

                                    </div> */}
                                </div>
                            </form>
                            :
                            <div className='flex justify-center pt-48'>
                                <Spiner fontSize={50} />
                            </div>
                        }


                    </div>
                </div>
            </div>
        </>
    );
}



