import {  LIST_KWAF_SERVERS } from "../action/kwafAction";

const defaultState = {
    listKwaf: null
};
  
  
  export default function kwaf(state = defaultState, action) {
    switch (action.type) {
      case LIST_KWAF_SERVERS:
        return { ...state, listKwaf: action.list };
      default:
        return {...state};
    }
  }
