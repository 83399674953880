import React from "react"
import { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { FaForumbee } from "react-icons/fa";

import { ThemeContext } from '../../theme/ThemeContext';

export default function Forum() {
    const { theme } = useContext(ThemeContext);
    const {t} = useTranslation()


    React.useEffect(()=>{
        //UserMgt.get_user_payment() 
    }, [])


    return (
        <div className='h-full '>


             <div className={"px-3 md:px-8 h-full  dark:text-darkTextColor pt-10  "+ (theme !== "dark" ? "  support-back " : "")}>
                <div className="container mx-auto max-w-full">
                    <div className="block  dark:bg-bgPagedark p-6 rounded-lg shadow-2xl bg-white max-w-sm text-center mx-auto">
                        <div>
                            <FaForumbee  className='text-5xl text-primary dark:text-darkTextColor mx-auto'/>
                        </div> 
                        <h5 className="text-primary dark:text-darkTextColor font-semibold text-xl leading-tight font-medium mb-2">
                             {t('support.forum.cartTitle')} 
                        </h5>
                        <p className="text-gray-700 dark:text-darkTextColor text-base mb-4">
                        {t('support.forum.cartDesc')} 
                        </p>
                        <a target="_blank" href="https://forums.keepsec.ca">
                            <button
                                className="font-bold mx-auto lg:block px-4 py-1 text-sm dark:text-darkTextColor text-gray-700 transition duration-150 ease-in-out  bg-transparent border dark:border-[3px] border-primary rounded focus:outline-none hover:text-white  hover:bg-primary hover:border-primary sm:px-8 sm:py-3"
                            >
                                  {t('support.forum.link')}
                            </button>
                        </a>
                    </div>


                </div>
            </div>
          {/* <div className={'absolute bottom-0 w-screen left-0 support-back bg-no-repeat md:bg-repeat'} style={{ height: "400px", zIndex: -1 }}>
            </div>*/}
        </div> 
    );
}
