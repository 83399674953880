import VULLIFY from 'lib/vullify'
import { message } from 'antd'
import { Spiner } from 'components/Bloc'
import UserMgt from 'lib/user_managment'
import React, { useState } from 'react';
import {  Modal } from 'antd';
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { updateHeadLink } from 'reducer/action/coreActions'
import target from "../../assets/img/vullify/target-keepsec.png"
import { OkButton } from 'components/OkButton'
import { Button, Dropdown, Space, Tooltip, Radio } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { MdMoreVert } from "react-icons/md"
import { AddButton } from 'components/AddButton'
import { SecurityScanFilled, HomeFilled, DoubleRightOutlined, DoubleLeftOutlined, DeleteOutlined, DownOutlined, CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { Typography, Alert } from 'antd';

const { Paragraph } = Typography;
const items = [
    // {
    //   label: 'Connect',
    //   key: '1',
    // //   icon: <BsFillTerminalFill />,
    // },
    // {
    //   label: 'Manage',
    //   key: '2',
    // //   icon: <SettingOutlined />,
    // },
    // {
    //   label: 'Stop',
    //   key: '3',
    // //   icon: <BsStopCircle />,
    // },
    // {
    //   label: 'Reboot',
    //   key: '4',
    // //   icon: <MdOutlineRestartAlt />,
    // },
    // {
    //   label: 'Delete',
    //   key: '5',
    // //   icon: <AiOutlineDelete />,
    // }
]




export default function VullifyTarget(props){
    const { id } = useParams()
    
	const navigate = useNavigate()
	const dispatch = useDispatch()
    const [server, setvps] = useState(null)
    const [targets, setTargets] = useState(null)
    const [target, setTarget] = useState(null)
    const [open, setOpen] = useState(null)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [messageApi, contextHolder] = message.useMessage()
    

    const onNavigate = async (t) => {
		navigate("/security/vullify/"+id+"/connect/target/"+t.id)	
	}
    const loadVps = async () => {
		const vps = await UserMgt.getUniqueVps(id) //.user_vps
		if(!vps?.error){
            setvps(vps?.server)
			return vps?.server
		}else{
			messageApi.open({
				type: 'error',
				content: 'An error occured. If this persist, please tell us',
			});
			return navigate(-1)
		}
	}
    const listTarget = async (server_id) => {
		const result = await VULLIFY.getListTarget(server_id) //.user_vps
        setTargets(result.targets)
        console.log('---------------result--------------', result)
		// if(!vps?.error){
        //     setvps(vps?.server)
		// 	return vps?.server
		// }else{
		// 	messageApi.open({
		// 		type: 'error',
		// 		content: 'An error occured. If this persist, please tell us',
		// 	});
		// 	return navigate(-1)
		// }
	}

    useEffect(() => {
        loadVps().then((vpsVullify) => {
            const headLinks = [
                {
                    link: "/security/vullify",
                    item: 'vullify',
                    alt: "Vullify short link"
                },
                {label: vpsVullify?.DisplayName, link: "security/vullify/"+vpsVullify?.Id+"/connect",  arrow: true},
                {label: "Target", arrow: true}
            ]
            listTarget(vpsVullify?.Id)
            dispatch(updateHeadLink(headLinks))
            
        })
		return () => dispatch(updateHeadLink([]))
	}, [])

    useEffect(() => {
        if(target){
            setOpen(true)
        }
        
	}, [target])

    const onShowModal = () => {
        // setTarget(null)
        const open2 = open
        setOpen(!open2)
        if(open2 ){
            setTarget(null)
        }
        
    }
    const setOpenUpdate2 = (target) => {
        setTarget({...target})
    }

    return(
        <div className=''>
            <div className='andModal'>
                <NewTargetModal 
                    server_id={server?.Id} 
                    server={server} 
                    setOpen={setOpen} 
                    open={open} 
                    tar={target}
                    messageApi={messageApi} 
                    listTarget={listTarget} 
                />
            </div>
			<div className=" px-3 md:px-5  relative  ">
                {contextHolder}
				<div className="container mx-auto max-w-full px-3 md:px-5  pb-8">
					{targets ?
                        <div className='flex gap-1 md:gap-2 xl:gap-3 flex-wrap items-center  pb-14 md:pb-7'>
							{targets && targets.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((target, id) =>{
                            return(
                                    <Target server={server} key={id}  setOpenUpdate={setOpenUpdate2}  navigate={onNavigate} tar={target} /> 
                                   
                            )})}   
							  
                            <div className="">
                                <AddButton click fun={onShowModal} title="New target" duck /> 
                            </div>
                             
                        </div>
						:
						<div className='flex my-auto h-full justify-center items-center'>
						    <Spiner fontSize={50} />
						</div>
                    }

                    
                </div>
            </div>
        </div>
    )
}

function Target({tar, navigate, setOpenUpdate, server}){
    const handleMenuClick = (e) => {
        // message.info('Click on menu item.');
        console.log('click', e.key);
        if(e.key === "1") return
        if(e.key === "2") return 
        if(e.key === "3") return 
        if(e.key === "4") return 
        if(e.key === "5") return 
    
    }; 
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

	return(
		<div  className=" mt-5 relative w-[335px] 2xl:w-[365px]">
			<div 
				// onClick={()=> navigate(tar)}
				className='px-3 py-1  rounded-xl dark:bg-bgPagedark bg-neutral-100 dark:hover:shadow-slate-700'>

				<div className='py-5 text-center text-primary dark:text-darkTextColor flex items-center gap-2 justify-between'>
                    <div className="flex items-center">
                        <img src={target} alt="" className='2xl:h-[80px]  h-[70px] mx-auto' />
                        <div className="text-left">
                            <span className='font-semibold '> 
                                {tar.name.length >10 ? tar.name.slice(0, 9) +"..." : tar.name}
                            </span>
                            <p   className='font-light text-sm my-auto'> 
                                {tar.url}   
                            </p>  

                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div>
                        <OkButton title="Manage" click   fun={() => (tar?.isVerified || server?.noNeedDomainVerification) ? navigate(tar) :  setOpenUpdate(tar)}/>
                        </div>
                        
                        <Dropdown menu={menuProps}>
                            {/*<MoreOutlined /> */}
                            <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
                        </Dropdown>
                    </div>
				</div>
			</div>
		</div>
	)
}

function NewTargetModal(props){
    const {tar} = props
    const [step, setStep] = useState(0)
    const [domain, setDomain] = useState(null)
    const [orgName, setORGname] = useState(null)
    const [domainVlid, setDomainvalid] = useState(true)
    const [orgNameValid, setOrgNameValid] = useState(true);
    const [dataCNAME, setDataCNAME] = useState();
    const [shouldDispayError, setDIsplayError] = useState(false);
    const [shouldDispayError2, setDIsplayError2] = useState(false);
    const [shouldDispayError3, setDIsplayError3] = useState(false);
    const [choice, setChoice] = useState("dns");
    const [action, setAction] = useState(false);
    const [onDeletion, setOndelete] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState("");

    const testDomain = (dns) => {
        const regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
        if (!dns || String(dns).trim() ==="") {
            return false;
        }
        return regex.test(dns)
    }
    const onChangeChoice = (e) => {
        console.log('radio checked', e.target.value);
        setChoice(e.target.value);
    };

    const createTargetOnDatabase = async (e) => {
        setAction(true)
        setDIsplayError(null)
        let result;
        if(choice === "dns"){
            result = await VULLIFY.createTarget(props.server_id, {domain: domain, name: orgName, choice: choice})
            if(props.server?.noNeedDomainVerification){
                return workSuccess()
            }
        }
        setAction(false)
        if(result && !result.error){
            setDataCNAME(result?.verificationData)
            return true
        }
        else{
            setDIsplayError(result)
            return null;
        }
    };
    const onVerify = async () => {
        setAction(true)
        setDIsplayError2(null)
        const result = await VULLIFY.verifyDomain(props.server_id, {
            domain: dataCNAME?.cname, 
            email: selectedEmail, 
            method: choice,
            url: domain
        })
        setAction(false)
        console.log('RESULT ON VERIFY CNAME ', result)
        if(result && !result.error){
            // setDIsplayError2(false)
            return workSuccess()
        }
        else{
            setDIsplayError2(result)
            return null;
        }
    };

    function workSuccess(){
        setStep(0)
        setDomain(null)
        setORGname(null)
        
        props.messageApi.open({
            type: 'success',
            content: 'Done !'
        });
        props.setOpen(false)
        return props.listTarget(props.server_id)
    }
    const onDeleteDomainOnDatabase = async () => {
        setOndelete(true)
        setDIsplayError3(null)
        const result = await VULLIFY.deleteDomain(props.server_id, domain)
        setOndelete(false)
        console.log('RESULT ON VERIFY CNAME ', result)
        if(result && !result.error){
            // setDIsplayError2(false)
            setStep(0)
            setDomain(null)
            setORGname(null)
            
            props.messageApi.open({
                type: 'success',
                content: 'Domain successfully deleted !'
            });
            props.setOpen(false)
            return props.listTarget(props.server_id)
        }
        else{
            setDIsplayError3(result)
            return null;
        }
    };
    const handleMenuClick = (e) => {
        // message.info('Click on menu item.');
        if(e.key === "1") return setSelectedEmail("admin@"+domain)
        if(e.key === "2") return setSelectedEmail("administrator@"+domain)
    
    };
    useEffect(()=>{
        if(props.tar){
            setStep(1)
            setDomain(props?.tar?.url)
            setORGname(props?.tar?.name)
            setDataCNAME({
                cname: props?.tar?.cname,
                entry: props?.tar?.entry,
            })
            setSelectedEmail("admin@"+props?.tar?.url)
        }
    }, [tar])
    return(
        <Modal
            destroyOnClose={true}
            centered
            footer={null}
            closeIcon={false}
            open={props.open}
            // onOk={() => props.setOpen(false)}
            // onCancel={() => props.setOpen(false)}
            className='mPadding'
            width={1000}
         // bodyStyle={{padding: '0px'}}
            // hea
            // maskStyle={{padding: '0px'}}
            // classNames="p-0 "
        >
            <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div style={{display: step==0 ? 'block' : "none"}} className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            Target Setup {props.tar?.id}
                        </h4>
                        <p className='text-sm xl:text-base'>
                            You're on your way to add a target domain. <br/>
                            Before you can add your new target, please complete the step below.
                        </p>
                    </div>
                    <div style={{display: step==1 || step==2 ? 'block' : "none"}} className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            {domain}
                        </h4>
                        <p className='text-sm xl:text-base mt-2'>
                            Congratulations, your target is almost setup! Howerver, you need to verify ownership of your domain. <br/>
                            Please follow steps below.
                        </p>
                    </div>
                    
                </div>
                <div className='px-6 py-3'>
                    {shouldDispayError?.error?
                        <div className="py-3">
                            <Alert  message={shouldDispayError.message || "Can't go to next step new, please try again or contact us!"} type="error" />
                        </div>
                        :
                        null
                    }
                    {shouldDispayError2?.error?
                        <div className="py-3">
                            <Alert  message={shouldDispayError2.message || "Can't verify your domain now, please try another method or contact us!"} type="error" />
                        </div>
                        :
                        null
                    }
                    {shouldDispayError3?.error?
                        <div className="py-3">
                            <Alert  message={shouldDispayError3.message || "Can't delete your domain now, please try again or contact us!"} type="error" />
                        </div>
                        :
                        null
                    }
                    <div style={{display: step==0? 'block' : "none"}}>
                        <div className='border-b border-b-2 pb-1'>
                            <h5 className="font-bold text-sm xl:text-base">
                                Enter Target Domain
                            </h5>
                        </div>
                        <div className='py-3'>
                            <Input 
                                onChange={ev => {
                                    const v = ev.target.value
                                    setDomain(v)
                                }} 
                                value={domain}
                                className='rounded w-full md:w-1/2' size="large" 
                                placeholder="Exp: keepsec.ca" prefix={<SecurityScanFilled />} 
                                status={!domainVlid && "error"}
                                />
                        </div>
                        <h5 className="font-semibold text-sm xl:text-base mt-3">
                            Organization name
                        </h5>
                        <div className='py-3'>
                            <Input 
                                className='rounded w-full md:w-1/2' 
                                size="large" 
                                status={(!orgNameValid) && "error"}
                                onChange={ev => {
                                    const v = ev.target.value
                                    setORGname(v)
                                    
                                }} 
                                value={orgName}
                                placeholder="Organization name here" 
                                prefix={<HomeFilled />} 
                            />
                        </div>
                    </div>
                    <div style={{display: step==1? 'block' : "none"}}>
                        <div className='border-b border-b-2 pb-1'>
                            <h5 className="font-bold flex gap-1 text-sm xl:text-base">
                                <DownOutlined /> Verification method for {domain}
                            </h5>
                            <p className='pt-2'>
                                We need you to verify ownership of your domain.
                                <br/>
                                Please select your prefered verification method and click <b>Next Step</b>
                            </p>

                        </div>
                        <div className='py-4'>
                            <Radio.Group onChange={onChangeChoice} value={choice}>
                                <Radio value={"email"} className='font-semibold'>Email Verification</Radio>
                                <Radio value={"dns"}  className='font-semibold'>DNS (CNAME)</Radio>
                            </Radio.Group>

                            <div style={{display: choice==="dns"? 'block' : "none"}} className='border border-1 shadow mt-1'>
                                <div className='border-b border-b-1 py-2 px-4 bg-gray-100'>
                                    <span className='text-semibold gap-2 flex text-xs md:text-sm'><CheckCircleOutlined /> Folow the steps below</span>
                                </div>
                                <div className='py-2 px-4 overflow-y-auto h-[220px]'>
                                    <p className='text-xs md:text-sm'>
                                        To verify your domain using <b>CNAME record</b>, please fllow steps below:
                                    </p>
                                    <div className='flex gap-1 items-center text-xs md:text-sm pt-2'>
                                        <div className='h-6 font-bold w-6 bg-gray-100 shadow text-center my-auto rounded-full border border-1'> 
                                            1
                                        </div>
                                        <span className=''> 
                                            Sign to your DNS provider, typically the registrar of your domain;
                                        </span>
                                    </div>
                                    <div className='flex gap-1 items-center text-xs md:text-sm pt-1'>
                                        <div className='h-6  font-bold  w-6 bg-gray-100 shadow text-center my-auto rounded-full border border-1'> 
                                            2
                                        </div>
                                        <span className=''> 
                                            Navigate to the section where DNS records are managed;
                                        </span>
                                    </div>
                                    <div className='flex gap-1 items-center text-xs md:text-sm pt-1'>
                                        <div className='h-6  font-bold  w-6 bg-gray-100 shadow text-center my-auto rounded-full border border-1'> 
                                            3
                                        </div>
                                        <span className=''> 
                                            Add the following CNAME record;
                                        </span>
                                    </div>
                                    <div className=''>
                                        <div className='pt-1'>
                                            <span className='font-bold text-sm'> 
                                                Name
                                            </span>
                                            <Paragraph 
                                                copyable
                                                className='border border-1 rounded px-2 py-1'
                                            >
                                                {dataCNAME?.cname || props?.tar?.cname}
                                            </Paragraph>

                                        </div>
                                        <div className=''>
                                            <span className='font-bold text-sm'> 
                                                Point To
                                            </span>
                                            <Paragraph 
                                                copyable
                                                className='border border-1 rounded px-2 py-1'
                                            >
                                               {dataCNAME?.entry || props?.tar?.entry}
                                            </Paragraph>

                                        </div>
                                        <div className=''>
                                            <span className='font-bold text-sm'> 
                                                TTL
                                            </span>
                                            <Paragraph 
                                                copyable={{
                                                    text: '3600',
                                                }}
                                                className='border border-1 rounded px-2 py-1'
                                            >
                                                3600 or lower
                                            </Paragraph>

                                        </div>
                                    </div>
                                    <div className='flex gap-1 items-center text-xs md:text-sm pt-1'>
                                        <div className='h-6  font-bold  w-6 bg-gray-100 shadow text-center my-auto rounded-full border border-1'> 
                                            4
                                        </div>
                                        <span className=''> 
                                           Save your CNAME record and click <b>Next Step</b> to continue.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: choice==="email"? 'block' : "none"}} className='border border-1 shadow mt-1'>
                                <div className='border-b flex flex-wrap justify-between border-b-1 py-2 px-4 bg-gray-100'>
                                    <span className='text-semibold gap-2 flex text-xs md:text-sm'>
                                        <CheckCircleOutlined /> 
                                        Pleas select an email address below
                                    </span>
                                    <Tooltip title="prompt text">
                                        <span className='text-xs md:text-sm flex gap-2'>
                                            How to use email address verification
                                            <QuestionCircleOutlined />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className=' px-4 py-6 flex flex-wrap items-center gap-8'>
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    key: '1',
                                                    label: "admin@"+domain
                                                },
                                                {
                                                    key: '2',
                                                    label:  "administrator@"+domain
                                                }
                                            ],
                                            onClick: handleMenuClick
                                        }}
                                    >
                                        <div className='w-[250px] text-semibold border border-1 py-3 px-4'>
                                            <Space className='flex justify-between'>
                                                {selectedEmail}
                                                <DownOutlined />
                                            </Space>
                                        </div>
                                    </Dropdown>
                                    <span className='text-semibold text-sm xl:text-base'> After selecting an email, click <b>Next Step</b>. </span>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div style={{display: step==2? 'block' : "none"}}>
                        <div className=' pb-4'>
                            <h5 className="font-bold flex gap-1 text-sm xl:text-base">
                                <CheckCircleOutlined /> Verification Method for {domain}
                            </h5>
                        </div>
                        <div className=' pb-2'>
                            <h5 className="font-bold flex gap-1 text-sm xl:text-base">
                                <DownOutlined /> Finalize
                            </h5>
                        </div>
                        <table className="min-w-full text-left">
                            <thead className="border-b bg-neutral-100 dark:bg-bgPagedark text-semibold">
                                <tr>
                                    <th scope="col" className="text-sm xl:text-base font-medium  px-6 py-4">
                                        Domain
                                    </th>
                                    <th scope="col" className="text-sm xl:text-base font-medium  px-6 py-4">
                                        Verification method
                                    </th>
                                    <th scope="col"  className=" text-sm xl:text-base font-medium  px-6 py-4">
                                        Verication status
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-left border-b border-r border-l border-1">  
                                <tr>
                                    <td scope="col" className="text-sm xl:text-base font-medium  px-6 py-4">
                                        {domain}
                                    </td>
                                    <td scope="col" className="text-sm xl:text-base font-medium  px-6 py-4">
                                        {choice==="dns"?
                                            "DNS (CNAME)"
                                            :
                                            "Email Verification"
                                        }
                                    </td>
                                    <td scope="col" className=" text-sm 2xl:text-base font-medium  px-6 py-4">
                                        {!action && !shouldDispayError2 ?
                                            <span className=" flex gap-1 text-sm xl:text-base">
                                                <ClockCircleOutlined /> To start, click <b>Verify Domain</b>
                                            </span>
                                            :
                                            action && !shouldDispayError2 ?
                                            <Spiner fontSize={20} />
                                            :
                                            !action && shouldDispayError2 ?
                                            <span className=" flex gap-1 text-sm xl:text-base text-red-600 font-bold">
                                                Error
                                            </span>
                                            :
                                            
                                            null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="py-5 flex justify-end gap-2 px-6">
                    <Button  onClick={ () => {
                            
                            setDomain(null)
                            setORGname(null)
                            setDIsplayError(null)
                            setDIsplayError2(null)
                            setStep(0)
                            props.setOpen(false);

                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        danger
                        // type="primary"
                        loading={onDeletion}
                        style={{display: step >= 1  ? "block" : "none"}}
                        onClick={async () => {
                            await onDeleteDomainOnDatabase()
                        }} 
                    >
                        <DeleteOutlined />
                        Delete
                    </Button>
                    <Button
                        type="primary"
                        // loading={loadings[1]}
                        style={{display: step == 2  ? "block" : "none"}}
                        onClick={() => {
                            // if(testDomain(domain)){
                                setStep(step-1)
                            // }
                        }} 
                    >
                        <DoubleLeftOutlined />
                        Previous Step
                    </Button>
                    
                    <Button
                        type="primary"
                        loading={action}
                        // disabled={step == 2}
                        onClick={async () => {
                            setOrgNameValid(!!orgName)
                            setDomainvalid(testDomain(domain))
                            if(!!orgName && testDomain(domain)){
                                if(step != 2){
                                    if(step==0) {
                                        const status = await createTargetOnDatabase();
                                        if(status){
                                            setStep(step+1)
                                            setSelectedEmail("admin@"+domain)
                                        }
                                    }else {
                                        setStep(step+1)
                                    }
    
                                }else{
                                    onVerify()
                                }
                            }
                        }}
                    >
                        {step == 2 ? "Verify Domain" : "Next Step"}
                        <DoubleRightOutlined />
                    </Button>
                </div>
            </div>

      </Modal>
    )
}
