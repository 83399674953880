import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";

export default function AppLogsView(props){
    const [expandedLogs, setExpandedLogs] = useState(true)
    const isWrapped = true
	const { t } = useTranslation()
    useEffect(() => { 
    }, [])


    function onExpandLogClicked() {
        setExpandedLogs(!expandedLogs)
    }
    
    return(
    	<div className=" ">
        	<button 	
        		onClick={() => onExpandLogClicked()} 
        		className="flex items-center mt-5 h-10 border-2 dark:border-gray-800 rounded justify-center px-4  dark:border-gray-800 border-r"
        	>
                {expandedLogs ?
                	<IoIosArrowDropdown /> : <IoIosArrowDropup />
                }
                {expandedLogs ?
                	t('platform.configPage.deploymentTab.viewAppLogs') : t('platform.configPage.deploymentTab.hideAppLogs')
                }
            </button>
            <div className="pt-10">
	            {expandedLogs &&
		            <textarea
		                id="applogs-text-id"
		                className="px-4 py-2 w-full dark:bg-bgDark2 rounded-xl dark:text-darkTextColor dark:border-gray-800  border-2 h-48" 
		                style={{
		                    whiteSpace: isWrapped
		                        ? 'pre-line'
		                        : 'pre',
		                }}
		                value={props.appLogsStringified}
		                readOnly
		            />
		        }
		    </div>
        </div>
   	)
}    