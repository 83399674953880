
function Svg({white}){
    return(
        <svg className={"animate-spin  h-5 w-5 dark:text-darkTextColor mx-auto "+ (white ? "text-white" : " text-primary ")}  fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg> 
    )
}

export const Loader = Svg;
export function OkButton(props){

    if(props.danger){
        return(
            <button 
                type="button" 
                className={`
                    border-2
                    border-red-700
                    hover:text-red-700
                    focus:text-red-700 
                    active:text-red-700 
                    inline-block 
                    bg-red-700
                    hover:bg-white
                    px-5
                    py-1.5
                    xl:py-2
                    2xl:py-2.5
                    text-sm
                    2xl:text-base 
                    text-white 
                    font-medium 
                    leading-tight 
                    rounded-full 
                    shadow-md 
                    hover:shadow-lg 
                    focus:bg-white
                    focus:shadow-lg 
                    focus:outline-none 
                    focus:ring-0 
                    active:bg-white 
                    active:shadow-lg 
                    transition 
                    duration-150 ease-in-out
                `}
                disabled={props.loanding || props.disabled}
                onClick={props.click ? props.fun :  ()=>{}}
            >
                {props.loanding?
                <div className='flex justify-center'>
                   <Svg />
                </div>
                :
                props.title

                }
            </button>
        )
    }

    if(props.outlined){
        return (
            <button
              type="button"
              className={`
                inline-block 
                rounded 
                border-2 
                border-primary 
                px-5
                py-1.5
                xl:py-2
                2xl:py-2.5
                text-sm
                2xl:text-base  
                font-medium 
                text-primary 
                transition duration-150 ease-in-out 
                font-medium 
                leading-tight 
                shadow-md 
                hover:shadow-lg 
                hover:border-primary 
                hover:bg-primary 
                hover:text-white 
                text-center
                justify-center
                flex
                `
                  // +" "+(props.loanding ? "bg-white ": "bg-primary  ")
                  +" "+(props.noRadius ? " ": "rounded-full   ")
                  +" "+(props.width ? "  w-"+props.width: "    ")
                  }

                disabled={props.loanding || props.disabled}
               onClick={props.click ? props.fun :  ()=>{}}
                  data-te-ripple-init
            >
              {props.loanding?
                    <div className='flex  mx-auto '>
                        <Svg />
                    </div>
                    :
                    props.title

                }
            </button>
        )
    }
    return(
        <button type="button" className={`
            border-2
            border-primary
            inline-block 
            px-5
            py-1.5
            xl:py-2
            2xl:py-2.5
            text-sm
            2xl:text-base 
            hover:bg-white
            dark:bg-primary
            dark:hover:bg-darkSiderbar
            dark:disabled:hover:bg-darkSiderbar
            hover:text-primary 
            focus:text-primary dark:text-darkTextColor
            active:text-primary
            text-white 
            font-medium 
            leading-tight 
            shadow-md 
            hover:shadow-lg 
            focus:bg-white
            focus:shadow-lg 
            focus:outline-none 
            focus:ring-0 
            active:bg-white 
            active:shadow-lg 
            transition 
            text-center
            flex
            justify-center
            duration-150 ease-in-out `
            +" "+(props.loanding ? "bg-white ": "bg-primary  ")
            +" "+(props.noRadius ? " ": "rounded-full   ")
            +" "+(props.width ? "  w-"+props.width: "    ")
            +" "+(props.class)
            }

            disabled={props.loanding || props.disabled}
           onClick={props.click ? props.fun :  ()=>{}}
        >
            {props.loanding?
                <div className='flex  mx-auto '>
                    <Svg />
                </div>
                :
                props.title

            }
        </button>
    )
}

export function CancelButton(props){

    return(
        <button type="button" 
        className={
            `
                border-2
                inline-block 
                px-5
                py-1.5
                xl:py-2
                2xl:py-2.5
                text-sm
                2xl:text-base 
                font-medium 
                leading-tight 
                rounded-full 
                shadow-md 
                hover:shadow-lg 
                focus:shadow-lg 
                focus:outline-none 
                focus:ring-0 
                active:shadow-lg 
                transition 
                duration-150 ease-in-out

                `+ 
                (
                props.danger ?
                `
                    border-red-700
                    hover:text-red-700
                    focus:text-red-700 
                    active:text-red-700 
                    inline-block 
                    bg-red-700
                    text-white
                    hover:bg-white
                `
                :
                `
                    border-primary
                    text-primary 
                    dark:text-darkTextColor
                    hover:text-white
                    hover:bg-primary
                    active:bg-primary
                    focus:bg-primary
                `
                )
            }
            onClick={() => props.fun()}
            disabled={props.loanding || props.disabled}
        >
            {props.loanding?
                <div className='flex justify-center'>
                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5  mx-auto"  fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                :
                props.title || "Cancel"

            }
        </button>
    )
}