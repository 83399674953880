 import { LIST_K8S } from "../action/kubernetesAction";


const defaultState = {
    kubernetes: null,
    state: false
};
  
  
  export default function k8s(state = defaultState, action) {
    switch (action.type) {
      case 'LOANDING':
        return { ...state, loading: action.stop? false : !state.loading };
      case LIST_K8S:
        return { ...state, kubernetes: [...action.list] };
      default:
        return {...state};
    }
  }