export const TabSelector = ({
  isActive,
  children,
  onClick,
  dropdown,
  isMini
}) => (
  <a 
    role="tab"
    className={` ${!dropdown ? "md:pr-10 pr-4 py-3" : ""}
      hover:border-transparent
      dark:hover:bg-bgPagedark 
      hover:bg-gray-100

      focus:border-transparent
      font-medium
      2xl:text-xl xl:text-base text-sm
      leading-tight
      group inline-flex items-center px-2 py-4 border-b-2 font-medium  leading-5 cursor-pointer whitespace-nowrap ${
      isActive
        ? "border-primary text-primary dark:text-blue-700 dark:border-blue-700 focus:outline-none dark:focus:border-blue-700 focus:text-primary focus:border-primary"
        : "border-transparent text-gray-500 hover:text-gray-600  focus:text-gray-600 focus:border-gray-300"
    }`}
    onClick={onClick}
  >
    {children}
  </a>
);