import React, { useState, useEffect } from "react";
import { OkButton } from "../../components/OkButton";
import { NetworkRender } from "../../components/Bloc";
import UserMgt from '../../lib/user_managment';
import { message } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../../components/TabSelector";
import { updateHeadLink } from "reducer/action/coreActions";
import { useDispatch, useSelector } from "react-redux";
import { Spiner } from '../../components/Bloc'
import KSProxy from '../../pages/ksproxy/KSProxy'
import Utils from '../../utils'
import kwaf from "../../assets/img/kwaf/kwaf.webp"
 
export default function KwafKSProxy(){
	const { id } = useParams()
	const [server, setvps] = useState(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [messageApi, contextHolder] = message.useMessage()

	const loadVps = async () => {
        const vps = await UserMgt.getUniqueVps(id) //.user_vps
        console.log('Server server ', vps)
        if(!vps?.error){
            setvps(vps?.server)
            return vps?.server
        }else{
            messageApi.open({
                type: 'error',
                content: 'An error occured, if this persist, please tell us',
            });
            return navigate(-1)
        }
    }


    useEffect(()=>{
        loadVps().then((server) => {
            const headLinks = [
                {
                    link: "/security/kwaf",
                    item: 'kwaf',
                    alt: "kwaf short link"
                },
                {label: server?.DisplayName, arrow: true, link: "security/kwaf/"+server?.Id+"/connect"},
                {
                    // link: "/security/vullify/",
                    label: 'K-WAF',
                    // alt: "Vullify short link",
                    arrow: true
                }
            ]
            dispatch(updateHeadLink(headLinks))
                
        })
        return () => dispatch(updateHeadLink([]))
    }, [])

	return(
		<div>
			<div style={{display: !server && "none"}} className=" dark:text-darkTextColor container mx-auto max-w-full px-3 md:px-8 pb-10">
				<div className="md:flex gap-6 items-center">
	                <div className="md:w-1/12 md:flex hidden">
	                   <img
	                      src={kwaf}
	                      alt=""
	                      className="w-20 h-20  2xl:w-28 2xl:h-28"
	                   />
	                </div>
	                <div className="md:w-11/12 w-full ">
	                   <div>
	                      
	                      <div className="flex justify-between items-end border-b-2 border-slate-300 dark:border-gray-800 pb-3 pt-2">
	                         <div>
	                         <img
	                            src={kwaf}
	                            alt=""
	                            className="w-14 h-14 md:hidden"
	                         />
	                            <span className="2xl:text-3xl text-xl xl:text-2xl">
	                               {server?.DisplayName}
	                            </span>{" "}
	                            <br />
	                            <span className="text-sm 2xl:text-base">
	                            {server?.type?.OpenstackFlavorName}</span>{" "}
	                            <br />
	                            <span className="text-sm 2xl:text-base">
	                               {server?.location?.name || "Montreal, Zone A"}
	                            </span>{" "}
	                            <br />
	                         </div>

	                         <div className="">
	                            <div className="flex flex-col md:flex-row gap-2 md:gap-4">
	                               
	                               <OkButton loanding={false} click server={server} fun={() => {}} title={"User Dashboard"} />
	                            </div>
	                            <p className="text-right pt-1 text-sm 2xl:text-base">State: <span className="font-bold">{server?.VpsStatus === "up" ? "Running" : server?.VpsStatus === "down" ? "Stopped" : server?.VpsStatus} </span></p>
	                         </div>

	                      </div>
	                   </div>

	                   <div className="grid grid-cols-1 md:grid-cols-2 text-sm 2xl:text-base">
	                      <div className="text-left">
	                         <p className="text-left pt-2">
	                            Public hostname:{" "}
	                            <span className="font-bold text-primary">
	                            	{server?.OpenstackInstanceName + ".k-waf.keepsec.cloud"}
	                            </span>{" "}
	                            <br />
	                            Private hostname:{" "}
	                            <span className="font-bold  text-primary">
	                               {server?.OpenstackInstanceName }
	                            </span>{" "}
	                            <br />
	                         </p>
	                      </div>
	                      <div className="text-left md:text-right">
	                         <p className="text-left md:text-right pt-2">
	                            Public IP:{" "}
	                            <span className="font-bold text-primary">
	                               {server?.OpenstackInstanceExtIp}
	                            </span>{" "}
	                            <br />
	                            InterconnectKS IP:{" "}
	                            <span className="font-bold  text-primary">
	                               {server?.netmaker?.map((net, i) => {
	                               	return (i != 0 ? ", ": "")+net?.node?.address?.IP
	                               })}
	                            </span>{" "}
	                            <br />
	                            VPC IP:{" "}
	                            <span className="font-bold  text-primary">
	                            	<NetworkRender
										vpc={server?.vpc}
									/>
	                            </span>{" "}
	                            
	                         </p>
	                      </div>
	                   </div>
	                </div>
	            </div>

	            <div className="mt-2">
					<KSProxy isKwaf={true} server={server} />
				</div>
			</div>

			<div style={{display: server && "none"}} className='flex h-[400px] items-center justify-center'>
            	<Spiner fontSize={50} />
            </div>
		</div>
	)
}