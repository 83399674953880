import {  LIST_VPC } from "../action/networkAction";

const defaultState = {
    vpc: {
      list: null
    }
};
  
  
  export default function network(state = defaultState, action) {
    switch (action.type) {
      case LIST_VPC:
        return { ...state, vpc: {...state.vpc, list: [...action.list]}};
      default:
        return {...state};
    }
  }
