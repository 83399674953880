import React, { useState, useEffect } from 'react'
import { Table, Tag, Space, Button, Tooltip, Modal, Tabs, Input, Switch    } from 'antd';
import { PlusOutlined, QuestionCircleOutlined, LockOutlined, WarningOutlined } from '@ant-design/icons';
import { DownOutlined, SmileOutlined, SettingOutlined  } from '@ant-design/icons';
import { Dropdown, Select, Typography } from 'antd'
import Utils from '../../../utils'

const { Text  } = Typography;

//Basic Authorization via Nginx HTTP Basic Authentication
const AddRule = ({accesLocal, shouldMt, setData}) => {
  return(
    <div className={`flex justify-between ${shouldMt && " mt-2 "}`} >
      <div className="w-[32%]">
         <Select
          value={accesLocal.directive}
          style={{
            width: '100%',
          }}
          onChange={(value) =>{
            accesLocal.directive = value
            setData(accesLocal)
          }}
          options={[
            {
              value: 'allow',
              label: 'Allow',
            },
            {
              value: 'deny',
              label: 'Deny',
            }
          ]}
        />
      </div>        

      <div className="w-[65%]">
        <Input 
            onChange={ev => {
              accesLocal.address = ev.target.value
              setData(accesLocal)
            }} 
            value={accesLocal.address}
            className='rounded w-full' 
            placeholder="IP/Subnet"
          />
      </div>
    </div>
  )
}
export const Access = ({access, setAccess}) => {
  function setData(temp){
    const index = access.map(e => e.id).indexOf(temp.id)
    access[index] = temp
    setAccess([...access])
  }
  return(
    <div>
      <div>
        <p className="font-semibold text-sm md:text-base">IP Address Whitelist/Blacklist via {" "}
          <a href="https://nginx.org/en/docs/http/ngx_http_access_module.html" target="_blank">
            Nginx HTTP Access
          </a>
        </p>
      </div>
      <div>
        {access && access.map((accesLocal, id) =>{
          return(
            <AddRule shouldMt={id !== 0} key={id} accesLocal={accesLocal} setData={setData} />
          )
        })}

      </div>
      <div className="mt-2 flex justify-between">
        <div className="w-[32%]">
          <Input  
              value={"deny"}
              className='rounded w-full' 
              disabled
              placeholder="IP/Subnet"
            />
        </div>

        <div className="w-[65%]">
          <Input 
              value={"all"}
              disabled
              className='rounded w-full' 
              placeholder="IP/Subnet"
            />
        </div>
      </div>
      <div className="mt-5">
        <p className="text-sm md:text-base">
          Note that the <Text code >allow</Text> and <Text code >deny</Text> directives will be applied in the order they are defined.
        </p>
      </div>
      <div className="flex justify-end mt-5">
        <Button
              type="primary"
              // loading={action}
              onClick={ () =>{
                  const newA = {id: Utils.generateId()}
                  const temp = access
                  temp.push(newA)
                  setAccess([...temp])
              }}
          >
              Add
          </Button>
      </div>
    </div>
  )
}



const UserNamePassword = ({auth, shouldMt, setData}) => {
    return(
      <div className={`flex justify-between ${shouldMt && " mt-2 "}`} >
        <div className="w-[49%]">
           <Input 
              onChange={ev => {
                auth.username = ev.target.value
                setData(auth)
              }} 
              value={auth.username}
              className='rounded w-full' 
            />
        </div>        

        <div className="w-[49%]">
          <Input 
              onChange={ev => {
                auth.password = ev.target.value
                setData(auth)
              }} 
              value={auth.password}
              className='rounded w-full' 
              placeholder={auth?.hint || ""}
            />
        </div>
      </div>
    )
  }

export const Authorization = ({authorizations, setAuth}) => {
  
  function setData(temp){
    const index = authorizations.map(e => e.id).indexOf(temp.id)
    authorizations[index] = temp
    setAuth([...authorizations])
  }
  
  return(
    <div>
      <div>
        <p className="font-semibold text-sm md:text-base">Basic Authorization via {" "}
          <a href="https://nginx.org/en/docs/http/ngx_http_auth_basic_module.html" target="_blank">
            Nginx HTTP Basic Authentication
          </a>
        </p>
      </div>
      <div className="mt-5 flex justify-between">
          <div className="w-[49%]">
            <h5 className="font-semibold">Username</h5>
          </div>

          <div className="w-[49%]">
            <h5 className="font-semibold">Password</h5>
          </div>
      </div>
      <div>
        {authorizations && authorizations.map((auth, id) =>{
          return(
            <UserNamePassword shouldMt={id !== 0} key={id} auth={auth} setData={setData} />
          )
        })}

      </div>
      <div className="flex justify-end mt-5">
        <Button
              type="primary"
              // loading={action}
              onClick={ () =>{
                  const auth = {id: Utils.generateId()}
                  const temp = authorizations
                  temp.push(auth)
                  setAuth([...temp])
              }}
          >
              Add
          </Button>
      </div>
    </div>
  )
}
export const Details = ({setObjectDetail, objectDetail}) => {

  return(
    <div>
      <div>
        <h5 className="font-semibold">Name <span className="text-red-500">*</span></h5>
        <Input 
            onChange={ev => {
              setObjectDetail({...objectDetail, name: ev.target.value})
            }} 
            value={objectDetail.name}
            className='rounded w-full' 
            placeholder={``} 
        />
      </div>

      <div className="flex flex-wrap gap-8 mt-5">
        <div className="flex gap-2 items-center">
          <Switch 
            checked={objectDetail.satisfy_any} 
            className='bg-gray-200'
            onChange={(checked)=>setObjectDetail({...objectDetail, satisfy_any: checked})} 
          />
          <span className="font-semibold">Satisfy Any</span>
        </div>
        <div className="flex gap-2 items-center">
          <Switch  
            onChange={(checked)=>setObjectDetail({...objectDetail, pass_auth: checked})} 
            checked={objectDetail.pass_auth} 
            className='bg-gray-200'
          />
          <span className="font-semibold">Pass Auth to Host</span>
        </div>
      </div>

    </div>
  )
}