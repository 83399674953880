import { useEffect, useState } from 'react';
import guard from '../assets/img/guard_keepsec1.png'
import { Button, Modal } from 'antd';
import UserMgt from '../lib/user_managment';


export default function UserAccountConfirmPrompt({user}){
    const [emailIsSending, setEmailIsSending] = useState(false)
    const [counter, setCounter] = useState(59)
    let  myInterval = null;
    useEffect(() => {
        // user && !user?.EmailConfirmed &&  onConfirmEmail()
        
        return () => clearInterval(myInterval);
        
    }, [])

    async function onConfirmEmail(){ 
        setEmailIsSending(true)
        try {
            await UserMgt.reSendEmailVerification({EmailAddr: user?.EmailAddr, FirstName: user?.FirstName})

            myInterval = setInterval(() => {
                console.log('count ', counter)
                setCounter((prevTime) =>{
                    if(prevTime ==0){
                        clearInterval(myInterval)
                        return 59
                    }
                    return prevTime - 1
                });
            }, 1000);
            
        } catch (error) {
            console.log('onConfirm error', error)
        }
        setEmailIsSending(false)
    }

    return(
        <div className=' inset-0  absolute flex justify-center items-center -translate-y-12'>
            <div className='bg-white px-5 py-3 md:py-5 md:px-10 shadow-2xl h-[410px] w-[500px] rounded-xl text-center'>
                <span className='text-base md:text-xl font-bold'>Confirm your email address</span>
                <br/>
                <img src={guard} className='h-48 mx-auto mt-2' />
                
                <p className='font-bold mt-2'>We sent an email to {user?.EmailAddr}</p>
               
                <p className='pt-2 font-semibold'>Please confirm your email address by clicking the link we just sent to your inbox</p>
                
                
                {counter != 59?
                    <p className='font-semibold mt-5 '>
                       Can resend after  <span className='font-bold'> 0:{String(counter).padStart(2, '0')}</span>
                    </p>
                    :
                    <Button  onClick={onConfirmEmail} className='px-5 mt-5 ' loading={emailIsSending} type="primary">
                        Resend verification email
                    </Button>
                }
            </div>
        </div>
    )
}
