import React, { useState, useEffect } from 'react'
import { Table, Tag, Space, Button, Tooltip, Modal  } from 'antd';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import KSPROXY from 'lib/proxy'
import Utils from '../../utils'

export function PAuditLog(props){
  function work(objec){
    const {object_type, meta} = objec
    let items = []
    switch (object_type) {
      case 'proxy-host':
          items = meta.domain_names;
          break;
      case 'redirection-host':
          items = meta.domain_names;
          break;
      case 'stream':
          items.push(meta.incoming_port);
          break;
      case 'dead-host':
          items = meta.domain_names;
          break;
      case 'access-list':
          items.push(meta.name);
          break;
      case 'user':
          items.push(meta.name);
          break;
      case 'certificate':
          if (meta.provider === 'letsencrypt') {
              items = meta.domain_names;
          } else {
              items.push(meta.nice_name);
          }
          break;
    }
    return items
  }

	const col1 = [
        {
        title: 'User',
        dataIndex: 'user',
        render: (_, obj) => <div> 
          <span className="font-semibold">{obj?.user?.name}</span>
        </div>,
      },
      {
        title: 'Event',
        dataIndex: 'event',
        render: (_, obj) => <div> 
          <span>{Utils.capitalizeFirstLetter(obj.action)} {Utils.capitalizeFirstLetter(obj.object_type.replaceAll('-', ' '))} - {work(obj).map(rol => <Tag color="#011a43">{rol}</Tag>)} </span>
          <br/>
          <span>{Utils.formatDate(obj?.created_on, 'Do MMMM YYYY, h:mm a')}</span> 
        </div>,
        // width: 200,
        // render: (text) => <div className="w-[200px]"> <a target="_blank" href={text}>{text}</a></div>,
      },
      {
        title: '',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            {/*<a>Invite {record.name}</a>*/}
            <a onClick={()=>setLogline(record)}>View Details</a>
          </Space>
        ),
      },
    ];

  const [logLine, setLogline] = useState(null)
  const [loading, setload] = useState(false) 
  const [logs, setLogs] = useState(null) 

  async function getLogs(){
    setload(true)
    try{
      const result = await KSPROXY.getLogs(props?.server?.Id)
      console.log('result result getLogs ', result)
      setLogs(Object.values(result).filter( arr => typeof arr === "object"))
     // !result && props.setUsers([])
    }catch(e){
      console.log('Cant get proxy host ', e) 
    }
    setload(false)
  }
  useEffect(() => { 
    getLogs()
  }, [])


	return(
		<div className="w-full">
      <Modal
        destroyOnClose={true}
        centered
        footer={null}
        closeIcon={false}
        open={logLine}
        className='mPadding'
        width={700}
      >
        <div className=''>
          <div className='bg-primary py-3 px-6 text-white'>
            <h4 className="font-semibold text-base xl:text-xl">
                Details for event
            </h4>
          </div>

          <div className='mt-3 py-3 px-6 flex flex-wrap gap-4'>
            <div className="flex rounded text-white text-[0.75rem] font-semibold">
              <div className="px-[0.5rem] pb-1 bg-black leading-[2em]">
                  {logLine?.action? Utils.capitalizeFirstLetter(logLine.action): ''} 
                  {" "}
                  {logLine?.object_type ?Utils.capitalizeFirstLetter(logLine?.object_type.replaceAll('-', ' ')) : ""}
               </div>
              <div className="px-[0.5rem] pb-1 bg-[#fd9644] leading-[2em]">
                #{logLine?.object_id}
              </div>
            </div>
            <div className="flex rounded text-white text-[0.75rem] font-semibold">
              <div className="px-[0.5rem] pb-1 bg-black leading-[2em]">
                  User
               </div>
              <div className="px-[0.5rem] pb-1 bg-[#2bcbba] leading-[2em]">
                {logLine?.user?.name}
              </div>
            </div>
            <div className="flex rounded text-white text-[0.75rem] font-semibold">
              <div className="px-[0.5rem] pb-1 bg-black leading-[2em]">
                  Date
               </div>
              <div className="px-[0.5rem] pb-1 bg-[#3730a3] leading-[2em]">
                {logLine ? Utils.formatDate(logLine?.created_on, 'Do MMMM YYYY, h:mm a'): null}
              </div>
            </div>
          </div>
          <div className='px-6 py-3'>
            <pre>{JSON.stringify(logLine?.meta, null, 2)}</pre>
          </div>

          <div className="py-5 flex justify-end gap-2 px-6">
            <Button  onClick={ () =>{
                    setLogline(null);
                }}
            >
                Cancel
            </Button>
          </div>
        </div>
      </Modal>
			<div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">Audit Log</h5>
			</div>

			<Table  
                columns={col1} 
                loading={loading}
                dataSource={logs || []} 
            />
		</div>
	)
}