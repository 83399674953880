import { CheckCircleFilled, DownloadOutlined, DownOutlined, StarFilled } from '@ant-design/icons';
import { Badge, Button, Divider, Dropdown, Input, Modal, Table, Tooltip } from 'antd';
import { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdAddCircle, MdRestartAlt, MdUpgrade } from "react-icons/md";
import { SiKubernetes } from "react-icons/si";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { VpsPack } from 'components/vps/Packages';

import controlPlane from '../../../assets/img/k8/controlplane.png';
import k8sImage from '../../../assets/img/k8/kubernetes-kp.webp';
import nodeSelector from '../../../assets/img/k8/node-kp.png';
import volume from "../../../assets/img/storage/volume.png";
import { LabelAdd, NetworkRender, PageTitle, Spiner } from '../../../components/Bloc';
import { Firewall } from '../../../components/Firewall';
import CreateVolume from '../../../components/volume/CreateVolume';
import { AttachVpsToVolumeModal, ModalIncreaseSize, TableFunction } from '../../../components/volume/VolumeSection';
import { STANDARD_ERROR_MESSAGE } from '../../../constants/message.js';
import K8S from '../../../lib/k8s';
import UserMgt from '../../../lib/user_managment';
import Utils from '../../../utils';

const neural = require('../../../assets/img/k8/neural.png')
const node = require('../../../assets/img/k8/node.png')

const getId = () => Math.random().toString(36).slice(2, 8)

function getAllInstance(k8s) {
	let totalNode = [];
	for (let i = 0; i < k8s?.clusters?.length; i++) {
		const instances = k8s?.clusters[i]?.instances
		totalNode = totalNode.concat(instances)
	}
	return totalNode
}
function PackModal({ open, setOpen, k8s, messageApi, masterCluster, isControlPlane }) {
	const instances = !isControlPlane ? masterCluster?.instances?.filter(inst => !inst.isKubeMaster) : masterCluster?.instances?.filter(inst => !!inst.isKubeMaster)
	const nodebase = { id: getId() }
	const [nodes, setListNode] = useState([
		nodebase
	])
	const [creating, setCreation] = useState(false)
	const { vps_packages } = useSelector(app => app.vps)

	async function getVpsTypes() {
		if (!vps_packages) {
			const r = await UserMgt.get_vps_types()
		}
	}
	const getName = () => isControlPlane ? "control plane " : "node "
	async function onAddNodeItem() {
		const newNodes = nodes
		newNodes.push({ id: getId() })
		setListNode([...newNodes])
		const element = document.getElementById("endPack");
		console.log('Nodes ', nodes)
		await Utils.sleep(300)
		element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
	}
	function deleteNode(nodeId) {
		const newNodes = nodes.filter(node => node.id !== nodeId)
		setListNode([...newNodes])
	}
	function setSelected(Id, nodeId) {
		const node = nodes.find(node => node.id === nodeId)
		const index = nodes.map(e => e.id).indexOf(nodeId)
		const allNode = nodes
		allNode[index] = { ...node, selected: Id }
		setListNode([...allNode])
	}
	function onCalculFlav(pack, nodeId) {
		const node = nodes.find(node => node.id === nodeId) 
		const index = nodes.map(e => e.id).indexOf(nodeId)
		const allNode = nodes
		allNode[index] = { ...node, pack, clicked: pack?.Id }
		setListNode([...allNode])
	}
	function setName(name, nodeId) {
		const node = nodes.find(node => node.id === nodeId)
		const index = nodes.map(e => e.id).indexOf(nodeId)
		const allNode = nodes
		allNode[index] = { ...node, nameName: name }
		setListNode([...allNode])
	}

	useEffect(() => {
		getVpsTypes()
	}, [])

	const getLength = () => instances?.length + nodes?.length

	async function onAddNode() {
		try {
			const instance = instances?.[0]

			const nodes2 = nodes.map(node => {
				return { ...node.pack, nameName: node?.nameName, isMaster: isControlPlane }
			})
			const suffixArr = instance?.OpenstackInstanceName?.split('-')
			const suffix = suffixArr[suffixArr?.length - 1]
			const instancesNameArr = getAllInstance(k8s).map(inst => inst.OpenstackInstanceName)

			if (nodes2.map(n => n.nameName).filter((e, i, a) => a.indexOf(e) !== i).length) {
				return messageApi.open({
					type: 'error',
					content: "Some "+getName()+" name are not defined or duplicated."
				})
			}
			for (let i = 0; i < nodes2.length; i++) {
				const node = nodes2[i]

				if (!node?.Id) {
					return messageApi.open({
						type: 'error',
						content: "You didn't pick "+getName()+ getNumber(i) + " package"
					})
				}
				if (!node?.nameName) {
					return messageApi.open({
						type: 'error',
						content: "You didn't enter "+getName()+ getNumber(i) + " name"
					})
				}
				if (instancesNameArr?.includes((node?.nameName + '-' + suffix).toLowerCase())) {
					return messageApi.open({
						type: 'error',
						content: "Another instance already named <<" + node?.nameName + ">>."
					})
				}
			}
			if(isControlPlane){
				const totalPlane = instances?.length + nodes2?.length
				if(!(totalPlane % 2)){
					return messageApi.open({
						type: 'error',
						content: "The total of  control planes should be an  odd number."
					})
				}
				if(totalPlane > 7){
					return messageApi.open({
						type: 'error',
						content: "You can't have more than 7 controls planes."
					})
				}
			}
			
			setCreation(true)
			const result = await K8S.addNode(k8s?.id, nodes2)
			await Utils.sleep(1500)
			setOpen(false);
			if (!result?.error) {
				messageApi.open({
					type: 'success',
					content: result?.message
				});
			} else {
				messageApi.open({
					type: 'error',
					content: 'An error occurs, please try again or contact an admin!'
				});
			}
		} catch (e) {
			console.error('Add node error ', e)
			messageApi.open({
				type: 'error',
				content: 'An error occurs, please try again or contact an admin!'
			});
		}
		const t = [nodebase]
		setListNode([...t]);
		setCreation(false)
	}

	const concertPack = vps_packages &&
		vps_packages.filter(p => p.Package_Type === "VPS" && !p.IsMini && !p.IsSec && !p.IsMiniSec)
			.sort((a, b) => parseInt(a.Price) - parseInt(b.Price))

	const getNumber = (k) => Utils.zeroPad((instances?.length+k+1), "0")
	return (
		<Modal
			destroyOnClose={true}
			footer={null}
			centered
			closeIcon={false}
			className='mPadding'
			width={1300}
			open={open}
		>
			<div className=''>
				<div className='bg-primary py-5 px-6 text-white'>
					<div className=''>
						<h4 className="font-bold text-base xl:text-xl">
							Add {isControlPlane ? "control plane" : "node"} to {masterCluster?.name}
						</h4>
						<p className='text-sm xl:text-base'>
							Pick new instance.
						</p>
					</div>

				</div>
				<div className=" h-[70vh] overflow-y-auto ">
					{nodes.map((node, k) => { 
						return (
							<div className="border-b-2 py-3 dark:border-gray-800">
								<p className="justify-center items-center gap-2 flex 2xl:text-xl font-bold text-primary">
									{!isControlPlane? "Node": "Control plane"} {getNumber(k)}
									{nodes.length !== 1 &&

										<Tooltip title={"Delete "+getName() + (getNumber(k))}>
											<Button onClick={() => deleteNode(node?.id)} type="primary" className="flex items-center justify-center" shape="circle">
												<ImCross />
											</Button>
										</Tooltip>
									}
								</p>
								<div className="2xl:gap-10 md:gap-6 gap-3 mt-5 pb-5  flex flex-wrap  justify-center ">
									<VpsPack
										concertPack={concertPack}
										setSelected={(Id) => setSelected(Id, node.id)}
										creating={creating}
										clicked={node.clicked}
										setClicked={() => { }}
										onClaculFlavor={(pack) => onCalculFlav(pack, node.id)}
										setPackPaypal={() => { }}
										selected={node.selected}
										key={node.id}
										noMxauto
									/>
								</div>
								<div className={'w-full px-3 mb-6 '}>
									<div className="mx-auto w-[500px]">
										<LabelAdd
											htmlFor="serv-name"
											placeholder={"Identify your "+ (!isControlPlane? "node ": "control plane ") + (getNumber(k))}
										/>
										<Tooltip
											trigger={['focus']}
											title={"Must not contain spaces, special characters or symbols"}
											placement="topLeft"
											overlayClassName="numeric-input"
										>
											<Input
												placeholder="Your instance resources must have unique names"
												onChange={(e) => setName(e.target.value, node.id)}
												value={node?.nameName}
												variant="filled"
												className="bg-gray-200 dark:bg-bgPagedark py-2 2xl:py-3 px-4 mb-3"
											/>
										</Tooltip>
									</div>
								</div>
							</div>
						)
					})}
					<div id="endPack" />
				</div>
				<div className="py-5 flex justify-between gap-2 px-6">
					<Tooltip className={isControlPlane && getLength() >= 7 && ' invisible '} title={isControlPlane ? " Add control planes " :  "Add nodes"}>
						<Button className="flex gap-2 items-center" size="large" type="primary" onClick={onAddNodeItem}
						>
							<FaPlus /> Add {isControlPlane ? " control plane " :  "node"}
						</Button>
					</Tooltip>

					<div className="flex gap-2 justify-end">
						<Button size="large" onClick={() => {
							setOpen(false);
							const t = [nodebase]
							setListNode([...t]);
						}}
						>
							Cancel
						</Button>
						<Button
							loading={creating}
							className="flex items-center gap-2"
							size="large" type="primary"
							onClick={() => {
								onAddNode()
							}}
						>
							<SiKubernetes />
							Deploy
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}
export function Overview({ k8s, instances, createFile, networks }) {

	const openstackNetwork = networks && networks?.find(net => net.isOpenstack)
	const netMakerNet = networks && networks?.find(net => net.isNetMaker)

	const totalCPUArr = instances && instances?.map(inst => inst?.type?.Cpus).reduce((a, b) => a + b, 0)
	const totalRAMArr = instances && instances?.map(inst => inst?.type?.RamGb).reduce((a, b) => a + b, 0)
	const totalSSDArr = instances && instances?.map(inst => inst?.type?.DiskGb).reduce((a, b) => a + b, 0)

	async function loadNetWork() {
		try {
			const data = await K8S.loadNetWork(k8s?.id)
			// if(data?.network){
			// 	setAllNewtwork({
			// 		ksNet: data?.network,
			// 		...allNetwork
			// 	})
			// }
		} catch (e) {
			console.error('Loading network error ', e)
		}
	}
	function getRunnigInstance(text) {
		let running = 0
		for (let i = 0; i < instances?.length; i++) {
			const ins = instances[i]
			if (ins.VpsStatus === "up") running++
		}
		return running + "/" + instances?.length + " " + text
	}

	useEffect(() => {
		// loadNetWork()
	}, [k8s])
	return (
		<div>
			<div>
				<PageTitle text={"Overview"} />
			</div>
			<div className="flex flex-wrap justify-between">
				<div className="p-4 mt-5 border-2 rounded-md border-gray-300 w-full md:w-[48%]  shadow-lg">
					<div className="text-sm 2xl:text-base">
						<div className="">
							<span className="font-bold text-base text-primary">Configuration </span>
						</div>
						<div className="flex justify-between pt-7">
							<div className="text-base 2xl:text-xl w-1/3">
								<span className="font-bold text-gray-400">CPU</span>
								<br />
								<span className="font-bold ">{totalCPUArr} vCPUs</span>
							</div>
							<div className="text-base 2xl:text-xl w-1/3">
								<span className="font-bold text-gray-400">Memory</span>
								<br />
								<span className="font-bold ">{totalRAMArr} GB</span>
							</div>
							<div className="text-base 2xl:text-xl w-1/3">
								<span className="font-bold text-gray-400">Disk</span>
								<br />
								<span className="font-bold ">{totalSSDArr} GB</span>
							</div>
						</div>
						<div className="pt-10">
							<span className="font-bold text-gray-400">
								VPC Network
							</span>
							<div className="pt-5 flex items-center gap-4">
								<img src={neural} width={35} />

								<span style={{ display: !openstackNetwork && 'none' }} className="font-bold text-primary">
									{openstackNetwork?.subnet}
								</span>
								<span style={{ display: openstackNetwork && "none" }}>
									<Spiner fontSize={22} />
								</span>

							</div>


						</div>

						<div className="pt-10">
							<span className="font-bold text-gray-400">
								INTERCONNECT KS  Network
							</span>
							<br />
							<span className="text-gray-400" style={{ display: !!k8s?.isHA && netMakerNet && "none" }}>
								<i>(Not defined!)</i>
							</span>

							<div style={{ display: !k8s?.isHA && "none" }} className="pt-1 flex items-center gap-4">
								<div className="pt-5 flex items-center gap-4">
									<img src={neural} width={35} />
									<span className="font-bold text-primary">
										{netMakerNet?.name}
									</span>
									<span className="text-primary font-bold">
										&nbsp;&nbsp;{netMakerNet?.subnet}
									</span>
								</div>

							</div>
						</div>
						<div className="pt-5 pb-2">
							<Button onClick={() => createFile()} type="primary" icon={<DownloadOutlined />} size={"large"}>
								Download config file
							</Button>
						</div>
					</div>
				</div>

				<div className="w-full md:w-[48%]">
					<div className="py-4 mt-5 border-2 rounded-md border-gray-300 w-full   shadow-lg">
						<div className="text-sm px-4 2xl:text-base">
							<div className="p">
								<span className="font-bold text-base text-primary">Node Pool Status </span>
							</div>
							<div className="pt-5 flex gap-2 items-center">
								<CheckCircleFilled className="text-green-500 text-3xl" />
								<span className="font-extrabold">
									{getRunnigInstance("Running")}
								</span>
							</div>
							<div className="mt-5 flex items-center justify-between">
								<div className="flex gap-2 items-center ">
									<Badge size="large" status="success" />
									<img src={k8sImage} width={25} />
									<span className="text-primary font-bold">{k8s?.name}</span>
								</div>

								<span className="text-gray-400 font-semibold text-base">
									{getRunnigInstance("Nodes Running")}
								</span>
							</div>
						</div>
						<Divider plain></Divider>
						<div className="px-4">
							<a className="text-sm text-primary 2xl:text-base " href="javascript:;">
								View Ressources details
							</a>
						</div>

					</div>
					<div className="py-4 mt-5 border-2 rounded-md border-gray-300 w-full   shadow-lg">
						<div className="text-sm px-4 2xl:text-base">
							<div className="p">
								<span className="font-bold text-base text-primary">Version </span>
							</div>

							<div className="mt-5">
								<span className="text-gray-400 text-sm font-semibold">Current version</span>
								<br />
								<span className="text-primary font-extrabold text-base 2xl:text-xl">
									Kubernetes {k8s?.version}
								</span>
								<br />
								<a className="text-sm text-primary " target="_blank" href="https://github.com/kubernetes/kubernetes/blob/master/CHANGELOG/README.md">
									View changelog
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


function getTitle(text) {
	return <span className="text-primary font-bold">{text}</span>
}

function tableFormat(action, masterCluster) {
	const columns = [
		{
			title: getTitle('Name'),
			dataIndex: 'OpenstackInstanceName',
			// key: 'OpenstackInstanceName',
			render: (_, serv) => {
				return (
					<div className="flex gap-2 items-center">
						<Badge status="success" />
						<img src={node} width={25} />
						<span className="text-primary font-semibold">
							{serv?.OpenstackInstanceName}
							{serv?.isPrimarykubeMaster ?
								<StarFilled />
								:
								null
							}
						</span>
					</div>
				)
			}
		},
		{
			title: getTitle('Ressources'),
			dataIndex: 'OpenstackInstanceFlavor',
			render: (_, serv) => {
				return (
					<span className="text-primary font-semibold">{serv?.type?.OpenstackFlavorName}</span>
				)
			}
		},
		{
			title: getTitle("Volume"),
			dataIndex: 'volume',
			render: (_, serv) => {
				return (
					<span className="text-primary font-semibold">
						{
							serv?.volumes?.map((v, i) => {
								return (i !== 0 ? ', ' : '') + v.name
							})
						}
					</span>
				)
			}
		},
		{
			title: getTitle("IP"),
			dataIndex: 'ip',
			render: (_, serv) => {
				return (
					<span className="text-primary font-semibold">
						{serv?.OpenstackInstanceExtIp ?

							<Tooltip title={"Default public IP"}>
								{serv?.OpenstackInstanceExtIp}
							</Tooltip>
							:
							null
						}
						<NetworkRender
							vpc={serv?.vpc}
							OpenstackInstanceExtIp={serv?.OpenstackInstanceExtIp}
						/>
					</span>
				)
			}
		},
		{
			title: getTitle("Type"),
			dataIndex: 'type',
			render: (_, serv) => {
				return (
					<span className="text-primary font-semibold">
						{
							serv?.isKubeMaster ?
								(serv?.kubeInstanceId === masterCluster?.id ? "Master" : "HA Master")
								:
								(serv?.kubeInstanceId === masterCluster?.id ? "Worker" : "HA Worker")
						}
					</span>
				)
			}
		}
	];

	columns.push(action)
	return columns
}


export function Networking({ k8s, master, instances, masterCluster, reloadK8s }) {
	const [displayingRuleNode, setDisplayRuleNode] = useState(null)
	const action = {
		title: '',
		dataIndex: '',
		render: (_, serv) => (
			<>
				<span
					className="text-base font-bold w-28 flex cursor-pointer"
					onClick={() => {
						setDisplayRuleNode(serv.Id);
					}}
				>
					<MdAddCircle className="text-primary text-2xl" />
					View Rules
				</span>
			</>
		),
	}
	const columns = tableFormat(action, masterCluster)

	return (
		<div>
			<div className="">
				<div className="flex gap-2 items-center justify-between">
					<PageTitle text={"IPv4 Networking"} />
				</div>
				<p className="xl:text-sm 2xl:text-base pt-2">
					Create rules to open ports to the internet or to specify IPv4 addresses and ranges.
					<br />
					<a className="text-sm 2xl:text-base " href="#" target="_blanc">
						Lean more about firewall rules
					</a>
				</p>

			</div>
			<div className="pt-5">
				<Table columns={columns} dataSource={instances} />
			</div>
			<FirewallModal
				reloadK8s={reloadK8s}
				setOpen={setDisplayRuleNode}
				node={displayingRuleNode}
				instances={instances}

			/>
		</div>
	)

}

function FirewallModal({ setOpen, node, reloadK8s, instances }) {
	const [onDisplayrull, setOnDisplayRuleComponent] = useState(false)
	return (
		<Modal
			destroyOnClose={true}
			footer={null}
			centered
			closeIcon={false}
			className='mPadding'
			width={1300}
			open={node}
		>
			<div className=''>
				<div className='bg-primary py-5 px-6 text-white'>
					<div className=''>
						<h4 className="font-bold text-base xl:text-xl">
							Modify {(instances?.find(i => i.Id === node))?.DisplayName} firewall rules
						</h4>
					</div>

				</div>
				<div className="px-6 py-3">
					{!onDisplayrull ?
						<div className="flex justify-end">
							<Button
								className="flex items-center gap-2"
								size="large"
								type="primary"
								onClick={() => {
									setOnDisplayRuleComponent(!onDisplayrull);
								}}
							>
								<SiKubernetes />
								Add Rule
							</Button>
						</div>
						:
						null
					}

					<Firewall
						onDisplayrull={onDisplayrull}
						server={instances?.find(i => i.Id === node)}
						setOnDisplayRuleComponent={setOnDisplayRuleComponent}
						reload={reloadK8s}
						isKube={true}
					/>
				</div>
				<div className="py-5 flex justify-end gap-2 px-6">
					<Button size="large" onClick={() => {
						setOpen(null);
					}}
					>
						Cancel
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export function Ressource({ k8s, master, instances, messageApi, masterCluster, reloadK8s, volumes }) {
	const [open, setOpen] = useState(false);
	const [instance, setAttachModalVisible] = useState(false);
	const [isControlPlane, setIscontrolPlane] = useState(false);

	const items = [
		{
			label: 'Attach volume',
			key: '1',
		},
	];

	const handleMenuClick = (e, serv) => {
		if (e.key === "1") return setAttachModalVisible(serv)
	};
	const action = {
		title: '',
		dataIndex: '',
		render: (_, serv) => (
			<>
				<Dropdown
					menu={{
						items,
						onClick: (e) => handleMenuClick(e, serv),
					}}
				// onOpenChange={handleOpenChange}
				// open={open}
				>
					<BsThreeDots />
				</Dropdown>
			</>
		),
	}

	const options = [
        {
          label: <span className='font-semibold'>Control plane</span>,
          key: '1',
          icon: <img src={controlPlane}  alt="" className=' w-[20px] ' />,
		  disabled: (masterCluster?.instances?.filter(ins => !!ins.isKubeMaster)?.length >= 7)
        },
        {
			label: <span className='font-semibold'>Slave</span>,
			key: '2',
			icon: <img src={nodeSelector}  alt="" className=' w-[20px] ' />
        }
    ]


	const handleMenuClick2 = (e) => {
        if(e.key === "1"){
			setOpen(!open)
			setIscontrolPlane(true)
		}
        if(e.key === "2"){
			setIscontrolPlane(false)
			setOpen(!open)
		}
    
    };
    const menuProps = {
        items: options,
        onClick: handleMenuClick2,
    };


	const columns = tableFormat(action, masterCluster)
	return (
		<div>
			<AttachVpsToVolumeModal
				open={instance}
				setOpen={setAttachModalVisible}
				reloadVolumeList={reloadK8s}
				volumes={volumes}
				instance={instance}
			/>
			<div>
				<PackModal
					messageApi={messageApi}
					k8s={k8s} 
					open={open}
					isControlPlane={isControlPlane}
					setOpen={setOpen}
					masterCluster={masterCluster}
				/>
				<PageTitle text="Ressources" />
				
			</div>
			<div className="mt-5 flex justify-between">
				<div className="flex gap-2 items-center">
					<p className="font-semibold text-primary text-sm  2xl:text-base">NODE POOL</p>
				</div>
				<div>
					<Dropdown menu={menuProps}>
						<Button
							size="large"
							type="primary"
							className="flex items-center gap-2"
						>	
								<SiKubernetes />
								Add Nodes 
								<DownOutlined />
						</Button>
					</Dropdown>
					
				</div>

			</div>
			<div className="pt-5">
				<Table columns={columns} dataSource={instances} />
			</div>
		</div>
	)

}

export function Rebuild({ k8s, master, instances, masterCluster }) {
	const [loading, setLoanding] = useState({})

	async function onRebuildNode(id) {
		setLoanding({ [id]: true })
		const result = await K8S.rebuild(k8s?.id, id)
		console.log('result result REBUILD ', result)
		setLoanding({})
	}
	const action = {
		title: '',
		dataIndex: '',
		render: (_, obj) => (
			<>
				<Button loading={loading && loading[obj?.Id]} onClick={() => onRebuildNode(obj?.Id)} className="flex items-center gap-1" type="primary">
					<MdRestartAlt className="text-xl" />
					Rebuild
				</Button>
			</>
		),
	}
	const columns = tableFormat(action, masterCluster)
	return (
		<div>
			<div>
				<PageTitle text="Rebuild" />
			</div>
			<p className="mt-2 text-sm">
				Pick your node to rebuild
			</p>

			<div className="mt-3 flex justify-between">
				<div className="flex gap-2 items-center">
					<p className="font-semibold text-primary text-sm  2xl:text-base">NODE POOLS</p>
				</div>
			</div>
			<div className="pt-5">
				<Table columns={columns} dataSource={instances} />
			</div>
		</div>
	)

}

function UpgradeModal({ open, setOpen, k8s, messageApi, instance, masterCluster, reloadK8s }) {

	const [creating, setCreating] = useState(false)
	const [pack, setPack] = useState(null)
	const { vps_packages } = useSelector(app => app.vps)
	async function getVpsTypes() {
		if (!vps_packages) {
			const r = await UserMgt.get_vps_types()
		}
	}

	function setSelected(Id) {
		setPack({ ...pack, selected: Id })
	}
	function onCalculFlav(pack) {
		setPack({ ...pack, clicked: pack?.Id })
	}

	useEffect(() => {
		getVpsTypes()
	}, [instance])

	const onUpgrade = async () => {
		console.log('pack pack ', pack)
		if (!pack) {
			messageApi.error('Please pick package first!')
		}
		setCreating(true)
		let result;
		try {
			const data = {
				vpsId: instance.Id,
				plan_id: pack.PaypalPackId,
				newPlanId: pack?.Id,
				openstackInstanceId: instance.OpenstackInstanceId,
			}
			result = await UserMgt.onUpdate(data)
			console.log('result result ', result)
		} catch (e) {
			console.log('error on upgrade', e)
		}
		setCreating(false)
		setPack(null)
		if (result && !result?.error) {
			setOpen(false)
			if (result?.paypal) {
				const link = (result?.data?.links.find(l => l.rel == "approve"))?.href
				messageApi.success("Confirm paypal subscription before see your server upgrade.")
				onSleep(link)

			} else {
				reloadK8s()
				messageApi.success(result.message || "Server upgraded.")

			}
		} else {
			messageApi.error(result?.message || STANDARD_ERROR_MESSAGE)
		}
	}

	async function onSleep(link) {
		await Utils.sleep(4000)
		window.open(link, '_blank')
	}
	// async function onUpgrade(){
	// 	const data = {
	// 		...pack,
	// 		plan_id: pack?.PaypalPackId,
	// 		serviceType: "VPS_INSTANCE",
	// 		vpsId: instance?.Id,
	// 		flavor: pack?.OpenstackFlavorName,
	// 		image: instance?.OpenstackInstanceImageName,
	// 		openstackInstanceId: instance?.OpenstackInstanceId,
	// 	}
	// 	console.log('DATA UP ', data)
	// 	setCreation(true)
	// 	try{
	// 		const result = await UserMgt.onUpdate(data)
	// 		await Utils.sleep(1500)
	// 		setOpen(false);
	// 		if(!result?.error){
	//     		messageApi.open({
	// 				type: 'success',
	// 				content: result?.message || "Service upgraded."
	// 			});
	// 		}else{
	// 			messageApi.open({
	// 				type: 'error',
	// 				content: 'An error occurs, please try again or contact an admin!'
	// 			});
	// 		}
	// 	}catch(e){
	// 		console.error('Add node error ', e)
	// 		messageApi.open({
	// 			type: 'error',
	// 			content: 'An error occurs, please try again or contact an admin!'
	// 		});
	// 	}
	// 	setCreation(false)
	// 	setPack(null)
	// }

	const concertPack = vps_packages && vps_packages
		.filter(p => p.service?.task_name === "vps" && parseInt(p.Price) > parseInt(instance?.type?.Price))
		.sort((a, b) => parseInt(a.Price) - parseInt(b.Price))

	return (
		<Modal
			destroyOnClose={true}
			footer={null}
			centered
			closeIcon={false}
			className='mPadding'
			width={1300}
			open={open}
		>
			<div className=''>
				<div className='bg-primary py-5 px-6 text-white'>
					<div className=''>
						<h4 className="font-bold text-base xl:text-xl">
							Upgrade {instance?.OpenstackInstanceName}
						</h4>
						<p className='text-sm xl:text-base'>
							Pick new package.
						</p>
					</div>

				</div>
				<div className=" overflow-y-auto ">
					<div className="border-b-2 py-3 dark:border-gray-800">
						<div className="mx-auto gap-2 justify-center mt-2  flex flex-wrap gap-2 justify-center ">
							<VpsPack
								concertPack={concertPack}
								setSelected={setSelected}
								creating={creating}
								clicked={pack?.clicked}
								setClicked={() => { }}
								onClaculFlavor={onCalculFlav}
								setPackPaypal={() => { }}
								selected={pack?.selected}
								noMxauto
							/>
						</div>
					</div>
				</div>
				<div className="py-5 flex justify-end gap-2 px-6">
					<Button size="large" onClick={() => {
						setOpen(false);
						setPack(null)
					}}
					>
						Cancel
					</Button>
					<Button
						loading={creating}
						className="flex items-center gap-2"
						size="large" type="primary"
						onClick={() => {
							onUpgrade()
						}}
					>
						<SiKubernetes />
						Upgrade
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export function Upgrade({ k8s, master, instances, messageApi, masterCluster, reloadK8s }) {
	const [open, setOpen] = useState(null)
	const [instance, setInst] = useState(null)

	const action = {
		title: '',
		dataIndex: '',
		render: (_, rec) => (
			<>
				<Button
					onClick={() => {
						setInst(rec)
						setOpen(!open)
					}}
					className="flex items-center gap-1"
					type="primary"
				>
					<MdUpgrade className="text-xl" />
					Upgrade
				</Button>
			</>
		),
	}
	const columns = tableFormat(action, masterCluster)
	return (
		<div>
			<UpgradeModal
				messageApi={messageApi}
				open={open}
				setOpen={setOpen}
				k8s={k8s}
				reloadK8s={reloadK8s}
				instance={instance}
			/>
			<div>
				<PageTitle text="Upgrade" />
			</div>
			<p className="mt-2 text-sm">
				Pick your node to upgrade
			</p>

			<div className="mt-3 flex justify-between">
				<div className="flex gap-2 items-center">
					<p className="font-semibold text-primary text-sm  2xl:text-base">NODE POOLS</p>
				</div>
			</div>
			<div className="pt-5">
				<Table columns={columns} dataSource={instances} />
			</div>
		</div>
	)

}


export function StorageK8s({ k8s, volumes, reloadK8s, instances }) {

	const navigate = useNavigate()
	const [loadingData, setLoading] = useState(false)
	const [editedVolume, setEditVolume] = useState(null)

	// async function listStorageVolume(){
	//     setLoading(true)
	//     try{
	//         const data = await STORAGE.list()
	//         setVols(data?.volumes.filter(v => v.serverId === server?.Id))
	//     }catch(e){
	//         console.eror('Error load volume storage ', e)
	//     }
	//     setLoading(false)
	// }

	// useEffect(() => {
	//     listStorageVolume()  
	// }, [])

	return (
		<div className='w-full '>
			<div className='flex justify-between items-start w-full'>
				<div className='pb-4'>
					<div className="">
						<PageTitle text="Add new Volume Block Storage" />
					</div>
					<p className="xl:text-sm 2xl:text-base pt-2">
						Volumes are highly available units of block storage that you can attach to a
						node to increase its available space.
					</p>
				</div>
				{volumes && volumes.length ?
					<CreateVolume
						instances={instances}
						params={{
							kubeId: k8s?.id
						}}
						reload={reloadK8s}
					/>
					:
					null
				}
			</div>

			{!loadingData ?
				<div>
					<ModalIncreaseSize
						open={editedVolume}
						setOpen={setEditVolume}
						vlume={editedVolume}
						reloadVolumeList={reloadK8s}
					/>
					{volumes && volumes.length ?
						<TableFunction
							setEditVolume={setEditVolume}
							data={volumes}
							// instances={instances}
							reloadVolumeList={reloadK8s}
						/>
						:
						<div>
							<div className="border border-primary border-1 py-8 flex flex-col justify-center">
								<img src={volume} className="w-28 mx-auto" />
								<p className="text-center py-5">
									<span className="font-semibold text-base 2xl:text-xl">
										Looks like you don't have any block storage volumes
									</span>
									<br />
									<span className="text-sm 2xl:text-base">
										Volumes are highly available units of block storage that you can attach to a
										node to increase its available space.
									</span>
								</p>
								<div className="mx-auto flex justify-center">
									<CreateVolume
										instances={instances}
										params={{
											kubeId: k8s?.id
										}}
										second={true}
										reload={reloadK8s}
									/>
								</div>
							</div>
						</div>
					}
				</div>
				:
				<div className='flex justify-center'>
					<Spiner fontSize={40} />
				</div>
			}
		</div>
	);
}