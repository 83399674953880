import { Typography } from 'antd';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { save_deletable_service, save_rebootable_service, save_stopable_service, save_starting_service, show_blueprint_confirm_modal } from 'reducer/action/coreActions';

import { Toast } from '../components/Notify';
import K8S from '../lib/k8s';
import STORAGE from '../lib/storage';
import UserMgt from '../lib/user_managment';
import VPC from '../lib/vpc';
import Utils from '../utils';
import { CancelButton, OkButton } from './OkButton';
import { reload_vps_instace } from 'reducer/action/vpsActions';

const { Text  } = Typography; 

export function Delete({ service, returnLink }) {
    const [loanding, setloading] = useState(false)
    const { messageApi } = useSelector(app => app.core)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function onCancel() {
        dispatch(save_deletable_service(null, null))
    }

    const onDeleteMethod = async() => {
        setloading(true)
        const serviceType = service?.service?.task_name
        const isStorage = serviceType === "volume_blocks_torage"
        let response;
        if(isStorage || (Utils.isAdmin() && service?.IsDeployedByAdmin) || (service?.VpsStatus === "Pending" && service?.isWithPromotion) || service?.isDemo){
            if(serviceType === "kskubhybr"){
                response = await K8S.deleteInstance(service?.id)
            }else if(["vpc"].includes(serviceType)){
                response = await VPC.deleteNet(service?.id)
            }else if(isStorage){
                const data = {
                    action: "forceDelete",
                    vlumeId: service?.id
                }
                response = await STORAGE.mkAction(data)
            }
            else{
                response = await UserMgt.deleteServer(service?.Id)
            }
            if(response === 204 || isStorage && response && !response.error){
                Utils.onNotifyCrisp(service)
                messageApi.success('Service deleted.')
                if(["vpc"].includes(serviceType)){
                    await VPC.getAll()
                }else if(serviceType === "kskubhybr"){
                    await K8S.getK8sInstance()
                }else if(isStorage){
                    await STORAGE.list()
                }
                else{
                    await UserMgt.get_user_vps()
                }
            }else if(response === 403){
                messageApi.error(t('core.constants.ERROR_MESSAGE_403'))
            }else{
                messageApi.error(isStorage && response?.message || t('core.constants.STANDARD_ERROR_MESSAGE'))
            }
        }else{
            const result = await UserMgt.update_subcription(service.Id, "suspend")
            if (result && result.status === "error") {
                messageApi.error(result?.message || t('core.constants.STANDARD_ERROR_MESSAGE'))
            }else{
                Utils.onNotifyCrisp(service)
                messageApi.success(t('core.constants.SUBSCRIPTION_CANCELLED_MESSAGE'))
            }
        }
        setloading(false)
        onCancel()
        if(returnLink) navigate(returnLink)
    }

    return (
        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={700}
	        open={service}
	    >
	        <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                             {t('deleteModal.title')}
                        </h4>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
                        <p className="pt-5">
                            <span className="text-orange-600 font-semibold">{t('deleteModal.warning')}: </span> {t('deleteModal.warContent1')}
                            <br />
                            <br />
                            {t('deleteModal.warContent2')}
                            <br />
                        </p>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => onCancel()}>
	                        {t('deleteModal.cancelBtn')}
	                    </Button>
                        
                        <Button 
                            loading={loanding} 
                            size="large" 
                            onClick={onDeleteMethod}
                            className="bg-red-500" 
                            danger
                            style={{color: "white"}}
	                    >
	                        {t('deleteModal.yesBtn')}
	                    </Button>
            	</div>
            </div>
	    </Modal>

    )
}



export function Reboot({ returnLink, service }) {
    const [loanding, setloading] = useState(false)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const { messageApi } = useSelector(app => app.core)
    function onCancel() {
        dispatch(save_rebootable_service(null, null))
    }

    async function onRebootMethod() {
        setloading(true)
        const result =  await UserMgt.onVpsAction("reboot", service?.Id)
        console.log('result onReboot', result)
        setloading(false)
        onCancel()
        if (result && (result.status === "success" || result.statusCode === "200" || !result.error)) {
            UserMgt.get_user_vps()
            return  messageApi.success(t('rebootModal.doneMessage', {DisplayName: service.DisplayName}))
        } else {
            messageApi.error(result?.message || t('core.constants.SUBSCRIPTION_CANCELLED_MESSAGE'))
        }

    }
    return (

        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={700}
	        open={service}
	    >
            <div>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                             {t('rebootModal.title')}
                        </h4>
                    </div>
                    
                </div>

                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
                        <p className="pt-5">
                            <span className="text-orange-600 font-semibold">{t('rebootModal.warning')}: </span> {t('rebootModal.warContent1')}
                            <br />
                            <br />
                            {t('rebootModal.warContent2')}
                            <br />
                        </p>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => onCancel()}>
	                        {t('rebootModal.cancelBtn')}
	                    </Button>
                        
                        <Button 
                            loading={loanding} 
                            size="large" 
                            onClick={onRebootMethod}
                            className="bg-red-500" 
                            danger
                            style={{color: "white"}}
	                    >
	                        {t('rebootModal.yesBtn')}
	                    </Button>
            	</div>
            </div>     
        </Modal>
    )
}

export function Stop({ returnLink, service }) {
    const [loanding, setloading] = useState(false)
    const {t} = useTranslation()
    const { messageApi } = useSelector(app => app.core)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    function onCancel() {
        return dispatch(save_stopable_service(null, null))
    }
    async function onStopMethod() {
        setloading(true)
        const result = await UserMgt.onVpsAction("stop", service?.Id)
        setloading(false)
        onCancel()
        if (result && (result.status === "success" || result.statusCode === "200" || !result.error)) {
            UserMgt.get_user_vps()
            messageApi.success(t('stopModal.doneMessage', {DisplayName: service.DisplayName}))
            if(returnLink) navigate(returnLink)
        } else {

            messageApi.error(result?.message || t('core.constants.SUBSCRIPTION_CANCELLED_MESSAGE'))
        }

    }
    return (
        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={700}
	        open={service}
	    >
            <div>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                             {t('stopModal.title')}
                        </h4>
                    </div>
                    
                </div>

                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
                        <p className="pt-5">
                            <span className="text-orange-600 font-semibold">{t('stopModal.warning')}: </span> {t('stopModal.warContent1')}
                            <br />
                            <br />
                            {t('stopModal.warContent2')}
                            <br />
                            {t('stopModal.warContent3')}
                            <br />
                            <br />
                            {t('stopModal.warContent4')}
                            <br />
                        </p>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => onCancel()}>
	                        {t('stopModal.cancelBtn')}
	                    </Button>
                        
                        <Button 
                            loading={loanding} 
                            size="large" 
                            onClick={onStopMethod}
                            className="bg-red-500" 
                            danger
                            style={{color: "white"}}
	                    >
	                        {t('stopModal.yesBtn')}
	                    </Button>
            	</div>
            </div>
        </Modal>
    )
}
export function RBuildVpsConfirm({open,  onContinue, setShowRebuildModal }) {
    const {t} = useTranslation()

    function onCancel() {
        return setShowRebuildModal(null)
    }
    async function okRebuild() {
        onCancel()
        onContinue()
    }
    return (
        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={700}
	        open={open}
	    >
            <div>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                             {t('rebuildModal.title')}
                        </h4>
                    </div>
                    
                </div>

                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
                        <p className="pt-5">
                            <span className="text-orange-600 font-semibold">{t('rebuildModal.warning')}: </span> {t('rebuildModal.warContent1')}
                            <br />
                            <br />
                            {t('rebuildModal.warContent2')}
                            <br />
                            {t('rebuildModal.warContent3')}
                            <br />
                        </p>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => onCancel()}>
	                        {t('rebuildModal.cancelBtn')}
	                    </Button>
                        
                        <Button 
                            size="large" 
                            onClick={okRebuild}
                            className="bg-red-500" 
                            danger
                            style={{color: "white"}}
	                    >
	                        {t('rebuildModal.yesBtn')}
	                    </Button>
            	</div>
            </div>
        </Modal>
    )
}
export function Start({ service }) {
    const [loanding, setloading] = useState(false)
    const {t} = useTranslation()
    const { messageApi } = useSelector(app => app.core)
    const dispatch = useDispatch()
    function onCancel() {
        return dispatch(save_starting_service(null, null))
    }
    async function onStartMethod() {
        setloading(true)
        const result =  await UserMgt.onVpsAction("start", service?.Id)
        console.log('result onStop', result)
        setloading(false)
        onCancel()
        if (result && (result.status === "success" || result.statusCode === "200" || !result.error)) {
            UserMgt.get_user_vps()
            
            return  messageApi.success(t('startModal.doneMessage', {DisplayName: service.DisplayName}))
        } else {
            messageApi.error(result?.message || t('core.constants.SUBSCRIPTION_CANCELLED_MESSAGE'))
        }

    }
    return (

        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={700}
	        open={service}
	    >
            <div>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                             {t('startModal.title')}
                        </h4>
                    </div>
                    
                </div>

                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
                        <p className="pt-5">
                             {t('startModal.warContent1')}
                            
                            <br />
                        </p>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => onCancel()}>
	                        {t('startModal.cancelBtn')}
	                    </Button>
                        
                        <Button 
                            loading={loanding} 
                            size="large" 
                            onClick={onStartMethod}
                            type='primary'
	                    >
	                        {t('startModal.yesBtn')}
	                    </Button>
            	</div>
            </div>
        </Modal>
    )
}


export function BlueprintInstallConfirmModal({ blueprint, server }) {
    const navigate = useNavigate()
    const [loanding, setloading] = useState(false)
    const dispatch = useDispatch()
    const isVpsMini = blueprint?.isVpsMini
    const withCredentials = blueprint?.withCredentials
    const {t} = useTranslation()
    const { messageApi } = useSelector(app => app.core)
    const [data, setDataResult] = useState(null)
    function onCancel(reloadInstance) {
        if(reloadInstance)dispatch(reload_vps_instace(true))
        return dispatch(show_blueprint_confirm_modal(null, null))
    }

    const okInstall = async () => {
        if (isVpsMini) {
            setloading(true)
            const d = {
                 payload: blueprint?.payload
              }
            const res = await UserMgt.onVpsAction("micro_blueprint", server?.Id, d);
            if (res && !res?.error) {
                messageApi.success((!withCredentials ? res?.message :  null) || t('blueprintModal.blueDeployed'))
                if(withCredentials){
                    setDataResult(JSON.stringify(JSON.parse(res?.message), null, 2))
                }
                // loadVps(true)
            } else {
                messageApi.error(res?.message || t('core.constants.STANDARD_ERROR_MESSAGE'))
            }
            setloading(false)
            if(!withCredentials){
                onCancel(true);
            }
        } else {
            onCancel();
            navigate("/compute/vps/" + server.Id + "/console?blueprint=" + blueprint?.payload)
        }
    }


    return (

        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={700}
	        open={ blueprint && server}
	    >
            <div>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            {!data ?
                                t('blueprintModal.pleaseConfirmInstallItem', {item: blueprint?.name})
                                :
                                t('blueprintModal.pleaeKeepTheAppCredentialSecure')
                            }
                        </h4>
                    </div>
                    
                </div>

                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
                        <p className="pt-5">
                            {!data ?                           
                                <>
                                    <span className="text-orange-600 font-semibold">{t('blueprintModal.warning')}: </span>
                                    {!isVpsMini && <>
                                        {t('blueprintModal.warContent1')} 
                                        <br />
                                        <br />
                                        {t('blueprintModal.warContent2')} 
                                        <br />
                                        <br />
                                    </>}
                                    {t('blueprintModal.warContent3')} 
                                    <br />
                                    <br />
                                </>
                                :
                                <>
                                    <span >{t('blueprintModal.dbRootPass')} </span>&nbsp;&nbsp;
                                    <Text code copyable className='font-bold text-primary' >
                                        {JSON.parse(data)?.db_root_password}
                                    </Text>
                                    <br />
                                    <br />
                                    <span >{t('blueprintModal.dbUsrPass')}</span>&nbsp;&nbsp;
                                    <Text code copyable  className='font-bold text-primary'>
                                        {JSON.parse(data)?.db_wpuser_password}
                                    </Text>
                                    <br />
                                </>
                            }
                        </p>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => onCancel(!!data)}>
                            {!data ? t('blueprintModal.cancelBtn') : t('blueprintModal.okeyBtn')}
	                    </Button>
                        {!data &&
                            <Button 
                                loading={loanding} 
                                size="large" 
                                onClick={okInstall}
                                type='primary'
                            >
                                {t('blueprintModal.yesBtn')}
                            </Button>
                        }
            	</div>
            </div>

            
        </Modal>
    )
}


