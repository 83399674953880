import store from "../reducer"
import UserAPI from './keepsec_auth_service'
const {  get, post } = require("./api")


const SERVICE_URL = process.env.REACT_APP_USER_MANGMENT_API //http://72.10.163.146"
const  apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}

async function createBalance(data){
	try{
		await UserAPI.refrest_token()
		const result = await post(
					SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/loadBalancer/add", 
					data,
					header
				)
		return result;
	}catch(e){
		console.error('Error create LB ', e)
		return null
	}

}

async function deleteBalance(data){
	try{
		await UserAPI.refrest_token()
		const result = await post(
					SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/loadBalancer/delete", 
					data,
					header
				)
		return result;
	}catch(e){
		console.error('Error create LB ', e)
		return null
	}

}
async function listLoadBalancer(loadBalancerServerId){
	try{
		await UserAPI.refrest_token()
		const result = await get(
					SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/loadBalancer/"+loadBalancerServerId, 
					header
				)
		return result;
	}catch(e){
		console.error('Error create LB ', e)
		return null
	}

}

export default {
	createBalance,
	listLoadBalancer,
	deleteBalance
}