import { useEffect, useState } from 'react';

import CAPTAIN from '../../../../lib/libCaprover';
import Utils from '../../../../utils';
import AppBuildLog from './AppBuildLog'
import AppLogsView from './AppLogsView'

export default function Deployment(props){
    const [isAppBuilding, setIsBuilding] = useState(false)
    const [buildLogs, setBuildLogs] = useState('')
    const [lastLineNumberPrinted, setLastLineNumberPrinted] = useState(-10000)
    const [appLogsStringified, setAppLogsStringified] = useState("")

	const app = props?.apiData?.appDefinition
    const rootDomain = props?.apiData?.rootDomain

    let willUnmountSoon = false

	
	function fetchLogs(app2) {
		const app3 = app2 || app

		// See https://docs.docker.com/engine/api/v1.30/#operation/ContainerAttach for logs headers
		const separators = [
			'00000000',
			'01000000',
			'02000000',
			'03000000', // This is not in the Docker docs, but can actually happen when the log stream is broken https://github.com/caprover/caprover/issues/366
		]
		const ansiRegex = Utils.getAnsiColorRegex()
		CAPTAIN
			.fetchAppLogsInHex(app3.appName)
			.then(function (logInfo) {
				const logsProcessed = logInfo?.data.logs
					.split(new RegExp(separators.join('|'), 'g'))
					.map((rawRow) => {
						let time = 0

						let textUtf8 = Utils.convertHexStringToUtf8(rawRow)

						try {
							time = new Date(textUtf8.substring(0, 30)).getTime()
						} catch (err) {
							// ignore... it's just a failure in fetching logs. Ignore to avoid additional noise in console
						}

						return {
							text: textUtf8,
							time: time,
						}
					})
					.sort((a, b) =>
						a.time > b.time ? 1 : b.time > a.time ? -1 : 0
					)
					.map((a) => {
						return a.text
					})
					.join('')
					.replace(ansiRegex, '')

				if (logsProcessed === appLogsStringified) {
					return
				}

				const firstLogs = !appLogsStringified

				let textareaNow = document.getElementById('applogs-text-id')
				// Almost at the bottom. So keep the scroll at the bottom. Otherwise, user, may have manually scrolled up. Respect the user!
				const shouldScrollToBottom =
					firstLogs ||
					(!!textareaNow &&
						Math.abs(
							textareaNow.scrollTop -
								(textareaNow.scrollHeight -
									textareaNow.offsetHeight)
						) < 100)
				setAppLogsStringified(logsProcessed)

				if (shouldScrollToBottom)
					setTimeout(function () {
						let textarea =
							document.getElementById('applogs-text-id')
						if (textarea) textarea.scrollTop = textarea.scrollHeight
					}, 100)
			})
			.catch(function (error) {
				console.log("-----------------------",error)
				setAppLogsStringified('fetching app log failed...')
			})
			.then(function () {
				setTimeout(() => {
					if (!willUnmountSoon) {
						fetchLogs()
					}
				}, 5000) // Just a random number to avoid hitting at the same time as build log fetch!
			})
	}


	function fetchBuildLogs(app2) {
		const app3 = app2 || app
		CAPTAIN
			.fetchBuildLogs(app3.appName)
			.then(function (logInfo) {
				logInfo = logInfo.data
				if (isAppBuilding && !logInfo.isAppBuilding) {
					// App was building but not anymore
					// onAppBuildFinished()
				}

				setIsBuilding(logInfo.isAppBuilding)
				
				let lines = logInfo.logs.lines
				let firstLineNumberOfLogs = logInfo.logs.firstLineNumber
				let firstLinesToPrint = 0
				if (firstLineNumberOfLogs > lastLineNumberPrinted) {
					if (firstLineNumberOfLogs < 0) {
						// This is the very first fetch, probably firstLineNumberOfLogs is around -50
						firstLinesToPrint = -firstLineNumberOfLogs
					} else {
						setBuildLogs( `${buildLogs}[[ TRUNCATED ]]\n`)
						
					}
				} else {
					firstLinesToPrint =
						lastLineNumberPrinted - firstLineNumberOfLogs
				}
				setLastLineNumberPrinted(firstLineNumberOfLogs + lines.length)

				let lineAdded = false

				let buildLogs2 = buildLogs
				const ansiRegex = Utils.getAnsiColorRegex()
				for (let i = firstLinesToPrint; i < lines.length; i++) {
					const newLine = (lines[i] || '')
						.trim()
						.replace(ansiRegex, '')
					buildLogs2 += newLine + '\n'

					lineAdded = true
				}
				setBuildLogs(buildLogs2)

				if (lineAdded) {
					setTimeout(function () {
						let textarea =
							document.getElementById('buildlog-text-id')
						if (textarea) textarea.scrollTop = textarea.scrollHeight
					}, 100)
				}
			})
			.catch(
				(er)=>console.log('error fetching build logs', er)
			)
	}

	// function onAppBuildFinished(app) {
    //     CAPTAIN
    //         .getAllApps()
    //         .then(function (data) {
    //             data = data?.data
    //             const appDefs = data.appDefinitions
    //             for (let index = 0; index < appDefs.length; index++) {
    //                 const element = appDefs[index]
    //                 if (
    //                     element.appName == appName
    //                 ) {
    //                     return Utils.copyObject(element)
    //                 }
    //             }
    //             throw new Error('App not found!')
    //         })
    //         .then(function (app) {
    //             setUpdateVersion({
    //                 deployedVersion: app?.deployedVersion,
    //                 versions: app?.versions,
    //             })
    //         })
    //         .catch((err)=>console.log('Could not fetch app build infos', err))
    // }

	useEffect(() => {
        let fetchBuildLogsInterval  = null
		if(props?.apiData?.appDefinition){
			fetchBuildLogsInterval = setInterval(function () {
				fetchBuildLogs(props?.apiData?.appDefinition)
				fetchLogs(props?.apiData?.appDefinition)
			}, 2000)
		}
        return () => {
			willUnmountSoon = true
            if (fetchBuildLogsInterval) {
                clearInterval(fetchBuildLogsInterval)
            }
        } 
    }, [props?.apiData?.appDefinition])

    return(
    	<div className=" ">
        	<AppLogsView app={app} rootDomain={rootDomain} appLogsStringified={appLogsStringified} />
        	<AppBuildLog 
        		app={app} 
        		isAppBuilding={isAppBuilding} 
        		lastLineNumberPrinted={props.lastLineNumberPrinted} 
        		buildLogs={buildLogs} 
        	 />
        	<p>
        	</p>
        </div>
   	)
}    