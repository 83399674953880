import listAppDescriptionInfranch from '../../constants/kpsListAppInFrench'
export default{
    "onClickApps": "",
    "app": {
        "titlePage": "Apps-web",
        "customAppBtn": "App personnalisée",
        "newOneClickAppBtn": "Apps en un clic",
        "customAppModal": {
        "title": "Nouvelle application personnalisée",
        "hasPersistantData": "A des données persistantes",
        "cancel": "Annuler",
        "create": "Créer",
        "myAmazingApp": "my-amazing-app"
        },
        "goToCofigPageBtn": "Configuration"
    },
    "listOneClicApp": {
        "listAppPageTitle": "Apps en un clic",
        "selectNewAPP": "Sélectionnez votre application d'installation en un clic",
        "searchPlaceHolder": "Rechercher",
        ...listAppDescriptionInfranch
    },
    "appDetails": {
        "deploy": "déployer",
        "deployBtn": "Déployer",
        "labelMessageAppNameField": "Nom de l'application",
        "helpMessageAppNameField": "Ceci est le nom de votre application. Choisissez un nom tel que my-first-1-click-app",
        "setupAppText": "Configurer votre {{displayName}}",
        "errorRegexvalidation": "Valeur invalide. Ne correspond pas à l'expression régulière : {{validRegex}}"
    },
    "appDeploying": {
        "h1": "Déploiement de {{ appName }} {{displayName}} ",
        "preventMessage": "Ce processus prend quelques minutes à compléter. NE PAS rafraîchir cette page et NE PAS naviguer ailleurs !",
        "goBackButton": "Retour et réessayer",
        "deploySuccessMessage": "Déploiement réussi !",
        "deploySuccessTitle": "Succès",
        "goListAppButton": "Aller à la page de liste des applications",
        "deplyErrorTitle": "Erreur",
        "parsingTemplate": "Analyse du modèle",
        "parsingTemplateErrorMesssage": "Impossible d'analyser le modèle. L'arbre de dépendance ne peut pas être résolu. Boucle infinie !",
        "parsingTemplateErrorMesssage2": "Impossible d'analyser le modèle. Aucun service trouvé !",
        "registering": "Enregistrement de {{appName}} ",
        "configuring": "Configuration de {{appName}} (volumes, ports, variables d'environnement)",
        "deploying": "Déploiement de {{appName}} (peut prendre jusqu'à une minute)",
        "_______": "_______"
    },
    "configPage": {
        "titlePage": "Configuration",
        "appNotFound": "Application non trouvée !",
        "saveRestartBtn": "Enregistrer et redémarrer",
        "deleteAppBtn": "Supprimer l'application",
        "httpSettingsTab": {
        "title": "Paramètres HTTP",
        "httpsEnabledSuccessMessage": "HTTPS est maintenant activé pour votre application",
        "removeCustomDomainSuccesssMessge": "Votre domaine personnalisé est supprimé avec succès !",
        "httpsActivatedSuccessMssg": "HTTPS est activé avec succès pour votre domaine !",
        "editnginxConfigBtn": "Modifier les configurations Nginx par défaut",
        "newDomainConnectedSUccessMessage": "Le nouveau domaine est maintenant connecté avec succès !",
        "alreadyACtivated": "Déjà activé",
        "clickToActive": "Cliquez pour activer HTTPS pour ce domaine",
        "htppsEnabled": "HTTPS activé",
        "enabledHtpps": "Activer HTTPS",
        "removeDomainBtn": "Supprimer",
        "doNotExposeApp": "Ne pas exposer en tant qu'application web",
        "dontExposeHelpMessage": "Utilisez ceci si vous ne souhaitez pas que votre application soit disponible en externe.",
        "appPublicAvailableUrl": "Votre application est publiquement disponible à",
        "newDomainHelpMessage": "Assurez-vous que le nouveau domaine pointe vers cette IP, sinon la vérification échouera.",
        "connectNewDomain": "Connecter un nouveau domaine",
        "containerPortHelpMessage": "Port HTTP à l'intérieur du conteneur. La valeur par défaut est 80. Ne modifiez que si l'application s'exécute sur un port différent. Ceci est utilisé uniquement pour les applications HTTP, pas les bases de données.",
        "containerPortBefore": "Port HTTP du conteneur",
        "forceRedirectHtpps": "Forcer HTTPS en redirigeant tout le trafic HTTP vers HTTPS",
        "forceRedirectHttpsTooltip": "Forcer HTTPS peut entraîner le dysfonctionnement des domaines sans HTTPS. Assurez-vous d'activer HTTPS pour le domaine que vous souhaitez utiliser avant d'activer l'option Forcer HTTPS.",
        "websockedtSupport": "Prise en charge de Websocket",
        "websockedtSupportTooltip": "Ajoute les en-têtes de proxy de mise à niveau à la configuration NGINX",
        "editBasicAuth": "Modifier l'authentification HTTP de base",
        "currentState": "État actuel",
        "inactive": "inactif",
        "active": "actif",
        "sslActivtedMessage": "Le certificat SSL est déjà activé",
        "dangerousHtml1": "Les modèles sont construits en utilisant le modèle EJS. Ne modifiez pas les zones entre <code>&lt;%</code> et&nbsp; <code>%&gt;</code> , sauf si vous savez vraiment ce que vous faites ! Pour revenir à la valeur par défaut, supprimez simplement tout le contenu.",
        "dangerousHtml2": "Votre application est disponible en interne sous la forme <code class='text-red-500 bg-red-50'>srv-captain--{{appName}}</code> pour les autres applications Captain. Dans le cas d'une application web, elle est accessible via <code class='text-red-500 bg-red-50'>{`http://srv-captain--{{appName}}`}</code> depuis d'autres applications.",
        "basicAuthModal": {
            "title": "Modifier l'authentification HTTP de base",
            "p1": "L'authentification HTTP de base est le moyen le plus simple d'appliquer des contrôles d'accès aux ressources web.",
            "p2": "Vous pouvez l'utiliser pour restreindre l'accès aux applications HTTP, en particulier celles que vous créez via les déploiements d'applications en un clic tels que phpMyAdmin, etc.",
            "password": "mot de passe",
            "username": "nom d'utilisateur",
            "confirm": "Confirmer",
            "cancel": "Annuler"
        },
        "_______": "_______"
        },
        appConfigsTab: {
            title: "Configurations de l'application",
            portNotUsedTooltip: "Assurez-vous que le port n'est pas déjà utilisé !",
            servPort: "Port du serveur",
            containerPort: "Port du conteneur",
            noTagAssoc: "Actuellement, aucun tag de service n'est associé à ce service",
            pathInApp: "Chemin dans l'application",
            label: "Étiquette",
            pathInhostTooltip: "IMPORTANT : Assurez-vous que le chemin hôte existe avant de l'attribuer ici",
            pathInHost: "Chemin sur l'hôte",
            letKeepSecManage: "Laisser KeepSec gérer le chemin",
            setSpecific: "Définir un chemin hôte spécifique",
            addPersistantDirectory: "Ajouter un répertoire persistant",
            leaveEmpty: "Laisser vide pour un placement automatique",
            nodeId: "ID du nœud",
            changeNodeIdTooltip: "AVERTISSEMENT : Modifier l'ID du nœud entraîne la suppression du contenu de vos répertoires persistants !",
            editNodeIdButton: "Modifier",
            addKeypair: "Ajouter une paire clé/valeur",
            envVars: "Variables d'environnement",
            notHavingEnvVars: "Actuellement, cette application n'a pas encore de variables d'environnement personnalisées.",
            buildEdit: "Modification en bloc",
            portMap: "Mappage de port",
            notHavingportmapping: "Actuellement, cette application n'a pas de mappage de port personnalisé.",
            addPortmappingBtn: "Ajouter un mappage de port",
            persistantDirectory: "Répertoires persistants",
            notHavingPersiDirrectory: "Actuellement, cette application n'a pas de répertoires persistants.",
            nberRunningRunn: "Nombre d'instances en cours d'exécution de cette application",
            instanceCount: "Nombre d'instances",
            multipleInstancePreventTooltip: "Plusieurs instances d'applications avec des données persistantes peuvent être très dangereuses et sujettes à des erreurs, car elles peuvent accéder au même fichier sur le disque, entraînant la corruption des données. Modifiez le nombre d'instances uniquement si vous comprenez le risque.",
            multipleInstancePreventEditBtn: "Modifier",
            prevScript: "Script de pré-déploiement",
            servupOveride: "Remplacement de la mise à jour du service",
            nothavingPersisDirectory: "Actuellement, cette application n'a pas de répertoires persistants.",
            servTags: "Tags de service"
        },
        deploymentTab: {
        title: "Déploiement",
        viewAppLogs: "Afficher les journaux de l'application",
        hideAppLogs: "Masquer les journaux de l'application",
        viewBuildLogs: "Afficher les journaux de compilation de l'application",
        hideBuildLogs: "Masquer les journaux de compilation de l'application"
        }
    },
    deleteModalApp: {
        appNameDontMatch: "Le nom de l'application ne correspond pas. Opération annulée.",
        appDeleted: "Application supprimée !",
        title: "Supprimer cette application ?",
        warning: "Avertissement",
        aboutToDelete: "Vous êtes sur le point de supprimer",
        notreversible: "Veuillez noter que ceci n'est pas réversible.",
        p1: "Veuillez sélectionner les volumes que vous souhaitez supprimer. Si l'un des volumes est utilisé par d'autres applications KPS, il ne sera pas supprimé.",
        p2: "Remarque : La suppression de volumes prend plus de 10 secondes, veuillez patienter.",
        cinfigAppName: "Confirmer le nom de l'application",
        appNotGoodDeleted: "Application supprimée, mais certains volumes n'ont pas été supprimés car ils étaient probablement utilisés par d'autres conteneurs.",
        confirmDelete: "Oui, supprimer",
        cancel: "Annuler",
        undestand: "Je comprends"
    },
    ________: "_______",
}



