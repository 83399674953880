import React from 'react'
import { useNavigate } from 'react-router-dom';

import { KSLB_MANAGE_LINK } from 'constants/link';
import { CardServiceLayout, MiniCard, ServiceOptions } from 'layouts/ServiceLayout'

import kslb from '../../assets/img/networking/icon.png'
import { OkButton } from '../OkButton';
import { useTranslation } from 'react-i18next';

// import { Spiner } from "components/Bloc";

export function NetworkServerCard({server}) {
    const is = (s) => server?.VpsStatus === s
    const { t } = useTranslation();
    const navigate = useNavigate()

    function onManage(){
        return navigate(KSLB_MANAGE_LINK(server?.Id))
    }

    return(
        <CardServiceLayout server={server}>
            <div>
                <div className='border-b-2 dark:border-gray-800 pb-4 2xl:pb-8 pt-3'>
                    <div className='flex justify-between items-start'>
                        <div className='flex 2xl:gap-4 gap-2'>
                            <img src={kslb} alt="" className='w-14 h-14' />
                            <div>
                                <span className='2xl:text-xl text-base font-semibold text-primary dark:text-darkTextColor'>{server?.DisplayName || server?.OpenstackInstanceName}</span> <br/>
                                <span className="2xl:text-base text-xs">{'KS lb'}</span>
                            </div> 
                        </div>
                        <div className='flex justify-end '>
                            <OkButton click fun={onManage} title={t("service.manage")}   server={server} loanding={false}/>
                            &nbsp;&nbsp;&nbsp;
                            <ServiceOptions
                                options={[
                                    {label: t("service.manage"),  cunstomFun: ()=> onManage(), display: !is('up') && "none"},
                                    {label: t("service.delete"), case: "onDelete", display: is('Deploying') && "none"},
                                ]}
                                server={server}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex justify-between pt-2 2xl:pt-4 '>
                    <div className='text-sm 2xl:text-base'>
                        <span className="font-bold text-primary">
                            {
                                is("up")? 
                                t("service.running") : 
                                server?.VpsStatus
                            }
                        </span>
                    </div> 
                    <div className=''>
                        <p className='text-right'>
                            <span className="text-sm 2xl:text-base">{server?.OpenstackInstanceExtIp}</span> <br/>
                            <span
                                className="text-sm 2xl:text-base">{server?.location?.name || "Montreal, Zone A"}</span>
                        </p>
                    </div>
                </div>
            </div>
        </CardServiceLayout>

    )
}


export function NetworkServerMiniCard({server}){
    const is = (s) => server?.VpsStatus === s

    const navigate = useNavigate()
    const { t } = useTranslation();
    function onManage(){
        return navigate(KSLB_MANAGE_LINK(server?.Id))
    }
    return(
        <MiniCard 
            server={server}
            is={is}
            imgSrc={kslb}
            firstText={server?.DisplayName || server?.OpenstackInstanceName}
            IpOrLocationText={server?.location?.name || "Montreal, Zone A"}
            options={[
                {label: t("service.manage"),  cunstomFun: ()=> onManage(), display: !is('up') && "none"},
                {label: t("service.delete"), case: "onDelete", display: is('Deploying') && "none"},
            ]}
            withInternalLink
            functionLink={() => onManage()}
            tooltipText={t("service.tootipMAnage")}
        />
    )
}
