import { PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Input, Space } from 'antd';
import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AddMethodModal, PaymentMethodList } from '../../components/billing/Payment'
import { Spiner } from '../../components/Bloc'
import UTILITY from '../../lib/utility_service'

export default function PaymentMethods(){
	const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false)
	const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
	
	const { paymentMethods } = useSelector(app => app.core)
	useEffect(() => {
		if(!paymentMethods){
			setLoading(true)
			UTILITY.fetchPaymentMethod().then(() => setLoading(false));
		}
	}, []);

	function reload(){
		// setLoading(true)
		UTILITY.fetchPaymentMethod()//.then(() => setLoading(false));
	}


	function AddButton({mxAuto}){
		return(
			<Button 
				// className={` px-3 font-semibold h-10 w-[170px] 2xl:h-12 ${mxAuto && 'mx-auto'} `} 
				// style={{borderRadius: "0px"}} 
				type="default"
				icon={<PlusOutlined />}
				onClick={
					()=>setOpenPaymentMethodModal(true)
				}
			>
				{t("account.billing.overview.paymentMethod.addBtn")}
			</Button>
		)
	}
	return(
		<div className='w-full h-full '>
			<AddMethodModal
				open={openPaymentMethodModal}
				reload={reload}
				setOpen={setOpenPaymentMethodModal}
			/>
			{loading ?
				<div className='flex justify-center'>
	                <Spiner fontSize={40} />
	            </div>
				:
				<div>
					<div className="flex w-full justify-between items-end text-primary" >
						
						<span className="text-md 2xl:text-xl font-semibold">
							{t("account.billing.overview.paymentMethod.pymentsM")}
						</span>
						<AddButton />
					</div>
					{paymentMethods && paymentMethods?.length ?
						<div className="">
							<div className="pt-4 text-sm 2xl:text-base">
								<span className="text-primary font-semibold text-sm 2xl:text-base">{t("account.billing.overview.paymentMethod.primary")}</span>
								<p className="mt-1  text-md 2xl:text-md">
									{t("account.billing.overview.paymentMethod.longText")}
								</p>
							</div>
							<div className="pt-4">
								<PaymentMethodList 
									paymentMethods={paymentMethods.filter(method => !!method.isPrimary && !!method.isValidated)}
								/>
							</div>

							<div className="pt-4 text-sm 2xl:text-base">
								<span className="text-primary font-semibold text-sm 2xl:text-base">{t("account.billing.overview.paymentMethod.backup")}</span>
								<p className="mt-1  text-md 2xl:text-md">
									{t("account.billing.overview.paymentMethod.whenAuto")}
								</p>
							</div>

							<div className="pt-4">
								<PaymentMethodList 
									paymentMethods={paymentMethods.filter(method => !method.isPrimary && !!method.isValidated)}
								/>
								{!paymentMethods.filter(method => !method.isPrimary && !!method.isValidated).length ?
									<div className="font-semibold text-primary  text-md 2xl:text-md">
										<i>
											{t("account.billing.overview.paymentMethod.noBackup")}
										</i>										
									</div>
									:
									null
								}
							</div>
						</div>
						:
						<div className="pt-4">
							<div className="">
								<Alert
							      message={t("account.billing.overview.paymentMethod.noteInfo")}
							      description={t("account.billing.overview.paymentMethod.youCurr")}
							      type="info"
							      showIcon
							    />
								{/*<div className="border w-full border-primary border-1 py-14 flex flex-col justify-center">
									<img 
										src={require('../../assets/img/billing/payment-metod.webp')} 
										className="w-[430px] mx-auto my-2" 
									/>
									<p className="text-center py-5">
										<span className="font-semibold text-base 2xl:text-xl">
											Look like you don't have any payments method
										</span>	
										<br/>
										<span className="text-sm 2xl:text-base">
											Volume are heighly available units of bloc storage that you can attach to
											Droplet to increase its space.
										</span>	
									</p>
									<AddButton mxAuto />
								</div>*/}
							</div>
						</div>
					}

					<div  className="pt-6">
						<span className="text-sm 2xl:text-xl font-semibold text-primary">
							{t("account.billing.overview.paymentMethod.promos")}
						</span>
						<div className="pt-2">
							<span className="text-primary font-semibold text-sm 2xl:text-base">{t("account.billing.overview.paymentMethod.cpromo")}</span>
							<p className=" text-md 2xl:text-md">
								{t("account.billing.overview.paymentMethod.havePromo")}
							</p>
							<div className='pt-3'>
								<Space.Compact style={{ width: '500px' }}>
									<Input size='large' defaultValue={t("account.billing.overview.paymentMethod.addPromoCode")} />
									<Button size='large' className='ml-0' type="primary">{t("account.billing.overview.paymentMethod.apllyCode")}</Button>
								</Space.Compact>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	)
}


