import React, { useState, useEffect } from 'react'
import { Table, Tag, Space, Button, Tooltip, Modal, Input, Switch, Popconfirm, Radio } from 'antd';
import { 
    PlusOutlined, QuestionCircleOutlined, DeleteOutlined,
    SettingOutlined, LoginOutlined, EditOutlined
 } from '@ant-design/icons';
import { Dropdown, Select, Typography, Alert } from 'antd'
import { MdMoreVert } from "react-icons/md"
import KSPROXY from 'lib/proxy'
import Utils from '../../utils'

const { Text  } = Typography;
export function PUsers(props){


	const col1 = [
        {
        title: 'Name',
        dataIndex: 'name', 
        render: (_, obj) => <div> 
          <span className="font-semibold">
          {obj.name} {obj.is_disabled ? <Tag color="error">Disabled</Tag> : null }
          </span>
          <br/> 
          <span>{Utils.formatDate(obj.created_on)}</span>
        </div>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
        render: (_, obj) => <div> 
          {obj?.roles?.map(rol => <Tag color="#011a43">{rol}</Tag>)} 
        </div>,
      },
      {
        title: 'Actions',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Dropdown 
              menu={{
                items: [
                  {
                    label: 'Edit Details',
                    key: '2',
                    disabled: record.email === "admin@keepsec.info",
                    onClick: () => setEditDetails(record),
                    icon: <SettingOutlined />,
                  },
                  {
                    label: 'Edit Permisions',
                    key: '3',
                    onClick: () => setChanginPermision(record),
                    disabled: (record.email === "admin@keepsec.info" || record?.roles?.includes('admin')),
                    icon: <EditOutlined />,
                  },
                  {
                    label: 'Change Password',
                    key: '4',
                    disabled: record.email === "admin@keepsec.info",
                    onClick: () => setChanginPassword(record),
                    icon: <LoginOutlined />,
                  },
                  {
                    label: (<Popconfirm
                        title={"Confirm deletion "}
                        description={"Really delete user named "+record.name}
                        onConfirm={() => confirmDelete(record)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        Delete {record.name}
                      </Popconfirm>
                    ),
                    key: '5',
                    disabled: record.email === "admin@keepsec.info",
                    danger: true,
                    icon: <DeleteOutlined />,
                  }
                ]
              }}
            >                
              <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
            </Dropdown>
          </Space>
        ),
      },
    ];

	const [open, setOpen] = useState(false)
  const [loading, setload] = useState(false) 
  const [detailEdit, setEditDetails] = useState(null) 
  const [userPassword, setChanginPassword] = useState(null) 
  const [userPermission, setChanginPermision] = useState(null) 

  const confirmDelete = async (user) => {
    const result = await KSPROXY.createUser(props.server?.Id, {Odelete: true, id: user?.id})
    console.log('result result result', result)
    if(result && !result.error){

      props.messageApi.success('User deleted !');
      props?.getUsers()
    }else{
      props.messageApi.error('An error occurs, please try again later.');

    }
  }

  // async function getUsers(){
  //   setload(true)
  //   try{
  //     const result = await KSPROXY.getUsers(props?.server?.Id)
  //     console.log('result result getUsers ', result)
  //    result && props.setUsers(Object.values(result).filter( arr => typeof arr === "object" && arr.email !== "admin@keepsec.info"))
  //    !result && props.setUsers([])
  //   }catch(e){
  //     console.log('Cant get proxy host ', e)
  //   }
  //   setload(false)
  // }
  useEffect(() => { 
    //getUsers()
  }, [])  
	return(
		<div className="w-full">
			<NewUser 
        open={open || detailEdit} 
        setOpen={setOpen}
        setEditDetails={setEditDetails}
        server={props?.server}
        getUsers={props.getUsers}
        user={{...detailEdit, is_admin: detailEdit?.roles?.includes("admin")}}
        messageApi={props?.messageApi}
      />
      <ChangePassword 
        open={userPassword} 
        setOpen={setChanginPassword}
        server={props?.server}
        getUsers={props.getUsers}
        user={userPassword}
        messageApi={props?.messageApi}
      />
      <ChangePermisions
        open={userPermission} 
        setOpen={setChanginPermision}
        server={props?.server}
        getUsers={props.getUsers}
        user={userPermission}
        messageApi={props?.messageApi}
      />
			<div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">Users</h5>
				<div className="flex justify-end items-center gap-4">
					{/*<Tooltip 
						overlayStyle={{ lineBreak: 'pre-line' }} 
						title={() =>  <div>
								<p className="font-bold">What is an Access List?</p>
								<p className="mt-2">Access Lists provide a blacklist or whitelist of specific client IP addresses along with authentication for the Proxy Hosts via Basic HTTP Authentication.</p> 
								<p className="mt-2"> You can configure multiple client rules, usernames and passwords for a single Access List and then apply that to a Proxy Host.</p>
								<p className="mt-2">This is most useful for forwarded web services that do not have authentication mechanisms built in or that you want to protect from access by unknown clients. </p>
						    </div>
						}>
						<QuestionCircleOutlined className="xl:text-2xl text-xl cursor-pointer text-primary"/>
					  </Tooltip>
*/}
					<Button type="primary" onClick={()=> setOpen(true)} icon={<PlusOutlined />}>
			            User
			        </Button>
				</div>
			</div>

			<Table  
          columns={col1} 
          dataSource={props.users} 
          loading={!props.users || props.loading} 
      />
		</div>
	)
}

function NewUser(props){
  const editUser = props.user
  const [user, setUser] = useState({})
  const [creation, setCreation] = useState(false)
  const [eMessage, setErrorMessage] = useState(null)

  useEffect(() => {
      editUser && setUser(editUser)
  }, [editUser])


  async function onCreate(){
    const roles = [];
    if(user.is_admin) roles.push('admin')

    const data = {
      ...user,
      roles

    }
    delete data.is_admin
    delete data.created_on
    delete data.modified_on
    delete data.avatar

    try{
      setCreation(true)
      setErrorMessage(false)
      const result = await KSPROXY.createUser(props.server?.Id, data)
      setCreation(false)
      console.log('RESULY HOST CREAT ', result)
      if(result?.error){
        return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
      }
      return Utils.sleep(1000).then(() => {
                  setUser({})
                  props.setOpen(false)
                  props.setEditDetails(null)
                  props.getUsers()
                   props.messageApi.open({
                      type: 'success',
                      content: 'Done !'
                  });
              });
    }catch(e){
      console.log('ON CREATE PROXY HOST ERROR ', e)
    }
  }

  return(
    <Modal
      destroyOnClose={true}
      // centered
      footer={null}
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={700}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
             { "User Form" }
          </h4>
        </div>

        <div className='px-6 mt-3' style={{display: !eMessage && "none"}}>
          <Alert  message={eMessage } type="error" />         
        </div>

        <div className='px-6 py-3'>
          <div>
            <div className="flex flex-wrap justify-between">
	            <div className="w-full md:w-[48%]">
	              <h5 className="font-semibold">Full Name <span className="text-red-500">*</span></h5>
	              <Input 
	                  onChange={ev => {
	                      setUser({...user, name: ev.target.value})
	                  }} 

	                  value={user.name}
	                  className='rounded w-full'
	                  placeholder="" 
	                  // prefix={<SecurityScanFilled />} 
	                  //status={!objectStream.domain && "error"}
	              />
	            </div>
							<div className="w-full md:w-[48%]">
	              <h5 className="font-semibold">Nickname <span className="text-red-500">*</span></h5>
	              <Input 
	                  onChange={ev => {
	                      setUser({...user, nickname: ev.target.value})
	                  }} 

	                  value={user.nickname}
	                  className='rounded w-full' 
	                  placeholder="Eg: 8080" 
	                  // prefix={<SecurityScanFilled />} 
	                  //status={!objectStream.domain && "error"}
	              />
	            </div>
            </div>

            <div className="w-full mt-5">
                <h5 className="font-semibold">Email <span className="text-red-500">*</span></h5>
                <Input 
                    onChange={ev => {
                      setUser({...user, email: ev.target.value})
                    }} 
                    value={user.email}
                    className='rounded w-full' 
                    placeholder={``} 
                />

              </div>
            

            <h5 className="font-semibold mt-5">Roles</h5>
            <div className="flex flex-wrap gap-8 mt-4">
              <div className="flex gap-2 items-center">
                <Switch 
                  checked={user.is_admin} 
                  // defaultChecked={userAdmin()}
                  className='bg-gray-200'
                  onChange={(checked)=>setUser({...user, is_admin: checked})} 
                />
                <span className="font-semibold">Administrateur</span>
              </div>
              <div className="flex gap-2 items-center">
                <Switch  
                  defaultChecked={user.is_disabled}
                  onChange={(checked)=>setUser({...user, is_disabled: checked})} 
                  checked={user.is_disabled} 
                  className='bg-gray-200'
                />
                <span className="font-semibold">Disabled</span>
              </div>
            </div>

          </div>
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                  props.setOpen(false);
                  props.setEditDetails(null);
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              // loading={action}
              onClick={() =>onCreate()}
              loading={creation}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

function ChangePassword(props){
  const editUser = props.user
  const [user, setUser] = useState({})
  const [creation, setCreation] = useState(false)
  const [eMessage, setErrorMessage] = useState(null)

  useEffect(() => {
      editUser && setUser(editUser)
  }, [editUser])

  async function onCreate(){
    const data = {
      type:    'password',
      current: user?.password1,
      secret:  user?.password2,
      id: user?.id
    }
 
    try{
      setErrorMessage(false)
      if(user?.password2 && user?.password1 && user?.password1 !== user?.password2){
        return setErrorMessage('Password is not valid.')
      }
      setCreation(true)
      const result = await KSPROXY.createUser(props.server?.Id, data)
      setCreation(false)
      console.log('RESULY HOST CREAT ', result)
      if(result?.error){
        return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
      }
      return Utils.sleep(1000).then(() => {
                  setUser({})
                  props.setOpen(null)
                  props.getUsers()
                  props.messageApi.open({
                      type: 'success',
                      content: 'Done !'
                  });
              });
    }catch(e){
      console.log('ON CREATE PROXY HOST ERROR ', e)
    }
  }

  return(
    <Modal
      destroyOnClose={true}
      // centered
      footer={null}
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={700}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
              Changing Password for {editUser?.name}
          </h4>
        </div>

        <div className='px-6 mt-3' style={{display: !eMessage && "none"}}>
          <Alert  message={eMessage } type="error" />         
        </div>

        <div className='px-6 py-3'>
          <div>
            <div className="">
              <div className="w-full">
                <h5 className="font-semibold">New Password <span className="text-red-500">*</span></h5>
                <Input 
                    onChange={ev => {
                        setUser({...user, password1: ev.target.value})
                    }} 

                    value={user.password1}
                    type="password"
                    className='rounded w-full'
                    placeholder="" 
                    // prefix={<SecurityScanFilled />} 
                    //status={!objectStream.domain && "error"}
                />
              </div>
              <div className="w-full mt-5">
                <h5 className="font-semibold">Confirm Password <span className="text-red-500">*</span></h5>
                <Input 
                    onChange={ev => {
                        setUser({...user, password2: ev.target.value})
                    }} 

                    value={user.password2}
                    className='rounded w-full'
                    type="password"
                    // prefix={<SecurityScanFilled />} 
                    //status={!objectStream.domain && "error"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                  props.setOpen(false);
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              // loading={action}
              onClick={() =>onCreate()}
              loading={creation}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

function ChangePermisions(props){
  const editUser = props.user
  const [user, setUser] = useState({})
  const [creation, setCreation] = useState(false)
  const [eMessage, setErrorMessage] = useState(null)
  const [permObject, setPermObject] = useState({});

  async function initData(nginx_user_id){
    const result = await KSPROXY.getUsers(props.server?.Id, nginx_user_id)
    if(result && !result.error){
      const {permissions} = result
      delete permissions.created_on
      delete permissions.id
      delete permissions.modified_on
      delete permissions.user_id
      setPermObject({...permissions})
    }
    console.log('result initData', result?.permissions)
  }
  useEffect(() => {
      if(editUser) {
        setUser(editUser)
        initData(editUser.id)
      }
  }, [editUser])

  async function onCreate(){
    const data = {
      ...permObject,
      permission: true,
      id: editUser?.id
    }
 
    try{
      setErrorMessage(false)
      setCreation(true)
      const result = await KSPROXY.createUser(props.server?.Id, data)
      setCreation(false)
      console.log('RESULY HOST CREAT ', result)
      if(result?.error){
        return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
      }
      return Utils.sleep(1000).then(() => {
                  setUser({})
                  props.setOpen(null)
                  props.getUsers()
                  props.messageApi.open({
                      type: 'success',
                      content: 'Done !'
                  });
              });
    }catch(e){
      console.log('ON CREATE PROXY HOST ERROR ', e)
    }
  }

  const perms = [
      'proxy-hosts', 
      'redirection-hosts', 
      'dead-hosts', 
      'streams', 
      'access-lists', 
      'certificates'
    ];
  return(
    <Modal
      destroyOnClose={true}
      centered
      footer={null}
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={500}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
              Changing permission for {editUser?.name}
          </h4>
        </div>

        <div className='px-6 mt-3' style={{display: !eMessage && "none"}}>
          <Alert  message={eMessage } type="error" />         
        </div>

        <div className='px-6 py-3'>
          <div>
            <div className="">
              <div className="w-full mt-1">
                <h5 className="font-semibold">
                  {"Items visibility"}
                </h5>
                <Radio.Group 
                  className="w-full mt-1 font-semibold" 
                  buttonStyle="solid"
                  onChange={({ target: { value } })=>{
                    setPermObject({...permObject, visibility: value})
                  }}
                  value={permObject?.visibility}
                >
                    <Radio.Button className="w-1/2 text-center" value="user">Created items Only</Radio.Button>
                    <Radio.Button className="w-1/2 text-center" value="all">All Items</Radio.Button>
                </Radio.Group>
              </div>

              {perms.map((item, k) => {
                const perm = item.replace('-', '_');
                return(
                  <div className="w-full mt-2" key={k}>
                    <h5 className="font-semibold">
                      {perm.split('_').map(val => Utils.capitalizeFirstLetter(val)+" ")}
                    </h5>
                    <Radio.Group 
                        className="w-full mt-1 font-semibold" 
                        buttonStyle="solid"
                        onChange={({ target: { value } })=>{
                          setPermObject({...permObject, [perm]: value})
                        }}
                        value={permObject[perm]}
                    >
                      <Radio.Button className="w-1/3 text-center" value="manage">Manage</Radio.Button>
                      <Radio.Button className="w-1/3 text-center" value="view">View Only</Radio.Button>
                      <Radio.Button className="w-1/3 text-center" value="hidden">Hidden</Radio.Button>
                    </Radio.Group>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                  props.setOpen(false);
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              // loading={action}
              onClick={() =>onCreate()}
              loading={creation}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}