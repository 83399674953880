import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Button as AntButton, Input, Space } from "antd";
import { Spin } from "antd";
import { Tooltip } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { useTranslation } from 'react-i18next';
import { AiFillDelete } from "react-icons/ai";
import { BsPlusCircleFill } from "react-icons/bs";
import { FaMinusCircle } from "react-icons/fa";
import { IoIosCopy } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ExtLink } from 'components/Bloc';
import { Texto } from "layouts/TextoLayout";
import { updateHeadLink } from "reducer/action/coreActions";
import { save_rebootable_service, save_stopable_service } from "reducer/action/coreActions";

import apache from "../../../assets/img/blue/apache.png";
import blueprint from "../../../assets/img/blue/blueprint-KS.png";
import nginx from "../../../assets/img/blue/nginx.png";
import wordpress from '../../../assets/img/blue/wordpress-logo.png'
import debian from "../../../assets/img/debian.png";
import ubuntu from "../../../assets/img/ubuntu.png";
import { DeleteServiceTap, HelpIcon, InputPassAdd, InputTextAdd, LabelAdd, PageTitle, Spiner } from '../../../components/Bloc';
import BlueprintCart from '../../../components/BlueprintCart'
import { OkButton } from "../../../components/OkButton";
import { TabSelector } from "../../../components/TabSelector";
import UserMgt from "../../../lib/user_managment";
import Utils from "../../../utils";
import UpgradeVps from '../manage/UpgradeVps'
import RebuildMini from './RebuildMini'
import { reload_vps_instace } from "reducer/action/vpsActions";


const imgs = {
   ubuntu,
   debian,
};
const tab = ["ubuntu", "debian"];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function VpsMiniManage() {
   const { id } = useParams();
   const navigate = useNavigate();
   const dispatch = useDispatch()
   const { t } = useTranslation();
   const [visibleCustomDOmain, showCustomDomain] = useState(false);
   const [server, setvps] = useState(null);
   const [onRun, setonRun] = useState({});
   const [customDomainFind, setRunCustomDomainList] = useState(false);
   const [deletedRun, setDeletedDomain] = useState({});
   const [blueprints, setblue] = useState([]);

   const [updatingPassword, setUpdatingPassword] = useState(false)

   const [update, setUpdate] = useState({
      password: null,
      confirmation: null
   })

   const {messageApi} = useSelector(app => app.core )
   const {reload} = useSelector(app => app.vps)
   const [domains, setDomainsArray] = useState([]);
   const [mkActOb, setRunMkACtion] = useState({});

   const shareUrl = "ssh -p " + server?.SshPort + " " + server?.SshUsername + "@" + server?.OpenstackInstanceExtIp;
   const shareUrl2 = "sftp -oPort=" + server?.SshPort + " " + server?.SshUsername + "@" + server?.OpenstackInstanceExtIp;

   const [selectedTab, setSelectedTab] = useTabs([
      "Overview",
      "Console",
      // "Preview",
      "Delete",
      "Rebuild",
      "Files",
      "Upgrade",
      "Reset Password",
   ]);

   const handleChange = (ev) => {
        const { name, value } = ev.target
        return setUpdate({...update, [name]: value})
    }

   async function loadVps(initial = false) {
      const vps = await UserMgt.getUniqueVps(id, true)
      console.log('server server  ', vps)
      if(!vps?.error){
         setvps(vps?.server);
         setvps(vps?.server);
         listCustomDomain(vps?.server);
         return vps?.server
      }else{
         navigate(-1)
      }
   }

   const onUpdatePassword = async () => {
        try {
         if(
            update.password && 
            (String(update.password).trim()).length >= 6 
         ){
            if (Utils.testPassworsVps(update.password)) {
                  return messageApi.open({
                      type: 'error',
                      content: t("core.constants.PASSWORD_CHARACTER_ERROR")
                  })
              }

            if(update.password === update.confirmation){
               setUpdatingPassword(true)
               const data = {
                  // action: "reset_password",
                  password: update.password
               } 
               const result = await UserMgt.onVpsAction("reset_password", server?.Id,  data)
               setUpdatingPassword(false)
               if(!result.error){
                  messageApi.open({
                     type: 'success',
                     content: t("core.constants.ACTION_DONE_MESSAGE"),
                  });
                  await loadVps(true)
               }else{
                  messageApi.open({
                     type: 'error',
                     content: result?.message || t("core.constants.STANDARD_ERROR_MESSAGE"),
                  });
               }
            }else{
               messageApi.open({
                  type: 'error',
                  content: t("core.constants.PASSWORD_DONT_MATCH"),
               });
               return
            }
         }else{
            messageApi.open({
               type: 'error',
               content: t("core.constants.PASSWORD_LENGTH_ERROR"),
            });
            return
         }
      } catch (error) {
         console.log('Update password error ', error)
      }
    }

   async function listCustomDomain(server) {
      setRunCustomDomainList(true);
      const result = await UserMgt.get_custom_domain(
         server?.OpenstackInstanceName
      );
      setDomainsArray(result?.domains);
      console.log("result result result Custom doamain", result);
      setRunCustomDomainList(false);
      showCustomDomain(false);
   }
   async function onRemoveCustomDOmain(domain) {
      setDeletedDomain({ [domain]: true });
      const d = {
         domain: domain
      }
      const result = await UserMgt.onVpsAction("delete_custom_domain", id, d);
      setDeletedDomain({});
      if (!result.error) {
         messageApi.success(result?.message,);
         return listCustomDomain(server);
      }
      messageApi.error(t("core.constants.STANDARD_ERROR_MESSAGE"));
   }


   async function mkAct(payload, data) {
      console.log('START STOP', payload)
      setRunMkACtion({ [payload]: true })
      const result = await UserMgt.onVpsAction(payload, server?.Id)
      setRunMkACtion({})
      console.log('START STOP', result)
      if (result && !result.error) {
         loadVps(true)
         // else await UserMgt.get_user_vps()
         messageApi.success(result.details || result.message)
         if(payload === "stop") return navigate("/compute/vpsmini")
      } else {

         messageApi.error(result?.message || t('core.constants.STANDARD_ERROR_MESSAGE'))

      }
      

   }
   async function onRbuild(payload, data) {
      setRunMkACtion({ [payload]: true })
      const result = await UserMgt.rebuildVps(payload, server?.OpenstackInstanceName, data)
      setRunMkACtion({})
      if (result && (result.status === "success" || result.statusCode === "200")) {
         loadVps(true)
         messageApi.success(result.details || result.message)
      } else {

         messageApi.error(result?.message || t('core.constants.STANDARD_ERROR_MESSAGE'))

      }
      return result && (result.status === "success" || result.statusCode === "200")

   }


   useEffect(() => {
      loadVps().then(vps =>{

         const headLinks = [
               {
                  link: "/compute/vpsmini",
                  item: 'vpsmini',
                  alt: "VPS short link"
               },
               {label: vps?.DisplayName, arrow: true},
            ]
         dispatch(updateHeadLink(headLinks))
         return () => dispatch(updateHeadLink([]))
      }); 
   }, []);
   
   useEffect(() => {
      if(reload){
         loadVps(true) 
         dispatch(reload_vps_instace(false))
      }
   }, [reload]);
   
   useEffect(() => {
    const blueprints = [
        {
           name: "Apache", 
           payload: "blueprint-apache", 
           version: "", 
           img: apache,
           disabled: 
              server?.webserver === "Nginx" 
              || 
              server?.blueprints?.includes("nginx") 
              || 
              server?.blueprints?.includes("wordpress") 
           
        },
        {
           name: "Wordpress", 
           payload: "blueprint-wordpress", 
           version: "", 
           img: wordpress,
           withCredentials: true,
           disabled:
              server?.webserver === "Nginx"
              ||
              server?.blueprints?.includes("wordpress") 
           
        },
        {
           name: "Nginx", 
           payload: "blueprint-nginx", 
           version: "", 
           img: nginx,
           disabled:
              server?.webserver === "Apache"
              ||
              server?.blueprints?.includes("apache") 
              || 
              server?.blueprints?.includes("wordpress") 
           
        }
     ] 
      setblue(blueprints)
   }, [server]);

   async function onDelete() {
		Utils.dispatchToDelete(server, '/compute/vpsmini')
	}



   return (
      <>
         <div className=" dark:text-darkTextColor pb-10">
            <div className="container mx-auto max-w-full px-3  md:px-8">
               {server && (
                  <div>
                     <div className="md:flex gap-6 items-center">
                        <div className="md:w-1/12 md:flex hidden">
                           <img
                              src={
                                 imgs[
                                 tab.find((k) =>
                                    server?.OpenstackInstanceImageName?.toLowerCase().includes(
                                       k
                                    )
                                 )
                                 ]
                              }
                              alt=""
                              className="w-14 h-14 xl:w-16 xl:h-16 2xl:w-20 2xl:h-20"
                           />
                        </div>
                        <div className="md:w-11/12 w-full ">
                           <div>
                              
                              <div className="flex justify-between items-end border-b-2 border-slate-300 dark:border-gray-800 pb-3 pt-2">
                                 <div>
                                 <img
                                    src={
                                       imgs[
                                       tab.find((k) =>
                                          server?.OpenstackInstanceImageName?.toLowerCase().includes(
                                             k
                                          )
                                       )
                                       ]
                                    }
                                    alt=""
                                    className="w-14 h-14 md:hidden"
                                 />
                                    <span className="2xl:text-3xl text-xl xl:text-2xl">
                                       {server?.DisplayName || server?.OpenstackInstanceName}
                                    </span>{" "}
                                    <br />
                                    <span className="text-sm 2xl:text-base">
                                       {server?.type?.OpenstackFlavorName}
                                    </span>{" "}
                                    <br />
                                    <span className="text-sm 2xl:text-base">
                                       {server?.type?.Option?.split(",")[0]}
                                    </span>{" "}
                                    <br />
                                 </div>

                                 <div className="">
                                    <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                                       {/*<OkButton click server={server} loanding={false} 
                                          fun={() => {
                                             // const op = 
                                             window.open("https://keepsec.ca/vpsmini/preview?serve="+server?.VpsMiniUrl, "Preview", "width=2000,height=2000, fullscreen=yes")
                                             // op.location.href='https://keepsec.ca';
                                          }}
                                        title={"Preview"}
                                        {t("compute.vps.networkTab.desc")} 
                                        />*/}
                                       {server?.VpsStatus !== "down" && <OkButton 
                                             loanding={mkActOb['stop']} 
                                             click server={server} 
                                             fun={() => dispatch(save_stopable_service(server, "/"))} 
                                             title={t("compute.stop")} 
                                          />
                                       }
                                       {server?.VpsStatus !== "up" && <OkButton loanding={mkActOb['start']} click server={server} fun={() => mkAct('start')} title={t("compute.start")} />}
                                       <OkButton 
                                          click 
                                          server={server} 
                                          loanding={mkActOb['reboot']} 
                                          fun={() => dispatch(save_rebootable_service(server))} 
                                          title={t("compute.reboot")} 
                                       />
                                    </div>
                                    <Texto small className="text-right pt-1 ">
                                       {t("compute.state")}:{" "}  <span className="font-bold">{server?.VpsStatus === "up" ? t("compute.running") : server?.VpsStatus === "down" ?  t("compute.down") : t(server?.VpsStatus)} </span>
                                    </Texto>
                                 </div>

                              </div>
                           </div>

                           <div className="grid grid-cols-1 md:grid-cols-2 text-sm 2xl:text-base">
                              <div className="text-left">
                                 <p className="text-left pt-2">
                                 {t("compute.publicHostname")}:{" "}
                                    <span className="font-bold text-primary">
                                       {server?.domain}
                                    </span>{" "}
                                    <br />
                                    {t("compute.location")}:{" "}
                                    <span className="font-bold  text-primary">
                                       {server?.locationObject?.name || "Montreal, Zone A"}
                                    </span>{" "}
                                    <br />
                                 </p>
                              </div>
                              <div className="text-left md:text-right">
                                 <p className="text-left md:text-right pt-2">
                                    <span className="inline">
                                       <HelpIcon className={"hidden md:inline"} message={t("compute.openPortTooltips")} /> {" "}
                                       {t("compute.openPorts")}:
                                       <i className="font-bold text-primary">
                                          {" "}80, 443, 8080, 8443, 3000{" "}
                                       </i>{" "}
                                       <HelpIcon className={" md:hidden"} message={t("compute.openPortTooltips")} /> {" "}
                                    </span>
                                    <br />
                                    {t("compute.publicIp")}:{" "}
                                    <span className="font-bold text-primary">
                                       {server?.OpenstackInstanceExtIp}
                                    </span>{" "}
                                    <br />
                                    OS:{" "}
                                    <span className="font-bold text-primary">
                                       {server?.OpenstackInstanceImageName}
                                    </span>{" "}
                                    <br />
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={server?.VpsStatus === "down" && "opacity-[5%]"}>
                        <nav className="flex  border-b border-gray-300 dark:border-gray-800 overflow-x-auto">
                           <TabSelector 
                              isActive={selectedTab === "Overview"}
                              onClick={() => setSelectedTab("Overview")}
                           >
                              {t("compute.vpsmini.overview.title")}
                           </TabSelector>

                           <TabSelector
                              isActive={selectedTab === "Console"}
                              onClick={() => setSelectedTab("Console")}
                           >
                              {t("compute.console")}
                           </TabSelector>
                           {/* <TabSelector
                              isActive={selectedTab === "Preview"}
                              onClick={() => setSelectedTab("Preview")}
                           >
                              Preview
                           </TabSelector> */}
                           <TabSelector
                              isActive={selectedTab === "Files"}
                              onClick={() => setSelectedTab("Files")}
                           >
                              {t("compute.files")}
                           </TabSelector>
                           <TabSelector
                              isActive={selectedTab === "Rebuild"}
                              onClick={() => setSelectedTab("Rebuild")}
                           >
                              {t("compute.vps.rebuildTab.title")}
                           </TabSelector>
                           <TabSelector
                              isActive={selectedTab === "Upgrade"}
                              onClick={() => setSelectedTab("Upgrade")}
                           >
                              {t("compute.vps.upgradeTab.title")}
                           </TabSelector>
                           <TabSelector
                              isActive={selectedTab === "Reset Password"}
                              onClick={() => setSelectedTab("Reset Password")}
                           >
                              {t("compute.vpsmini.changePassworw.title")}
                           </TabSelector>
                           <TabSelector
                              isActive={selectedTab === "Delete"}
                              onClick={() => setSelectedTab("Delete")}
                           >
                              {t("deleteCompoent.title")}
                           </TabSelector>
                        </nav>

                        <div className="pt-5">
                           <TabPanel hidden={selectedTab !== "Overview"}>
                              <div className="flex flex-wrap w-full  justify-between items-stretch ">
                                 <div className="w-[600px] 2xl:w-[24%]">
                                    <br />
                                    <Texto className="pb-2">
                                       {t("compute.vpsmini.overview.useOwnFTP")}
                                    </Texto>
                                    <ExtLink 
                                       href={"#"}
                                       text={t("compute.vpsmini.overview.howtoConnectUsingFTP")}
                                    />
                                    <ConnectCard 
                                      isFTP 
                                      server={server}
                                      shareUrl={shareUrl2}
                                    />

                                    <br />
                                    <Texto className=" pt-4 pb-2">
                                       {t("compute.vps.tab1.useOwn")}
                                    </Texto>
                                    <ExtLink 
                                       href={"#"}
                                       text={t("compute.vps.tab1.connectToSSH")}
                                    />
                                    <ConnectCard 
                                       server={server}
                                       shareUrl={shareUrl}
                                    />
                                 </div>
                                 <div className="relative w-[600px] 2xl:w-[43%]">

                                    <iframe
                                       className="w-full h-full  fileframe1"
                                       
                                       
                                       src={
                                          encodeURI(
                                             "https://filemanager-a1.keepsec.tech/login" +
                                             "?hostname="+ server?.OpenstackInstanceExtIp +
                                             "&username="+ server?.SshUsername+
                                             "&password="+ server?.SshPassword+
                                             "&port="+ server?.SshPort+
                                             "&type=sftp"
                                          )
                                       }
                                       allow="fullscreen;speaker"
                                    >
                                       
                                    </iframe>
                                    {/* <img src={mac} alt="mac keepsec" />
                                    <iframe
                                       id="iframe"
                                       className="absolute shadow-2xl bg-[black] shadow-zinc-900 dark:shadow-xl dark:shadow-slate-700 rounded-t-2xl left-0 top-0 w-[100%] h-[75%]"
                                       src={
                                          Utils.getUrlConsole()[
                                          Math.floor(Math.random() * 3)
                                          ] +
                                          "?access_token=" +
                                          server?.Token
                                       }
                                       height="700"
                                       border="frameno"
                                       style={{
                                          borderRadius: "20px",
                                          border: "20px solid black",
                                       }}
                                    ></iframe> */}
                                 </div>
                                 <div className="w-[600px] 2xl:w-[30%]">
                                    <Texto className="">
                                       {t("compute.vpsmini.overview.useOwnDomain")}
                                    </Texto>
                                    <div className="py-2 px-4 mt-5 font-bold  border-2 rounded-md border-gray-300 w-full  shadow-lg">
                                       {/* <Texto small className="font-bold">
                                          {t("compute.vpsmini.overview.makeSureTypeAEnabled")}{" "}
                                       </Texto> */}
                                       <Alert
                                          type="warning"
                                          showIcon
                                          message={t("compute.vpsmini.overview.makeSureTypeAEnabled")+":"}
                                          />
                                       <Texto className=" font-bold mt-4">
                                          {server?.OpenstackInstanceExtIp}{" "}
                                       </Texto>
                                       <div className="h-3" />
                                       <Texto className="font-bold">
                                       {t("compute.vpsmini.overview.domainList")}
                                       </Texto>
                                       <div className="h-1" />
                                       {!customDomainFind ? (
                                          <div>
                                             {domains && domains.length > 0 ? (
                                                <div>
                                                   {domains.map(
                                                      (domain, id) => {
                                                         return (
                                                            <div
                                                               key={id}
                                                               className="text-base flex justify-between dark:bg-darkSiderbar mt-3 bg-neutral-300 px-5 py-2 rounded-md"
                                                            >
                                                               <span>
                                                                  {
                                                                     domain.DomainName
                                                                  }{" "}
                                                               </span>
                                                               <div className="flex gap-2">
                                                                  {id ===
                                                                     domains.length -
                                                                     1 && (
                                                                        <Tooltip
                                                                           title={t("compute.vpsmini.overview.addNewDomain")}
                                                                           color={
                                                                              "#011a43"
                                                                           }
                                                                        >
                                                                           {!visibleCustomDOmain ? (
                                                                              <BsPlusCircleFill
                                                                                 onClick={() =>
                                                                                    showCustomDomain(
                                                                                       !visibleCustomDOmain
                                                                                    )
                                                                                 }
                                                                                 className="2xl:text-2xl text-xl dark:text-blue-700 text-primary cursor-pointer"
                                                                              />
                                                                           ) : (
                                                                              <FaMinusCircle
                                                                                 onClick={() =>
                                                                                    showCustomDomain(
                                                                                       !visibleCustomDOmain
                                                                                    )
                                                                                 }
                                                                                 className="2xl:text-2xl text-xl dark:text-blue-700 text-primary cursor-pointer"
                                                                              />
                                                                           )}
                                                                        </Tooltip>
                                                                     )}
                                                                  {!deletedRun[
                                                                     domain
                                                                        .DomainName
                                                                  ] ? (
                                                                     <Tooltip
                                                                        title={t("compute.vpsmini.overview.removeDomain")}
                                                                        color={
                                                                           "#011a43"
                                                                        }
                                                                     >
                                                                        <AiFillDelete
                                                                           onClick={() =>
                                                                              onRemoveCustomDOmain(
                                                                                 domain.DomainName
                                                                              )
                                                                           }
                                                                           className="2xl:text-2xl text-xl text-red-700  cursor-pointer"
                                                                        />
                                                                     </Tooltip>
                                                                  ) : (
                                                                     <Spin
                                                                        indicator={
                                                                           antIcon
                                                                        }
                                                                     />
                                                                  )}
                                                               </div>
                                                            </div>
                                                         );
                                                      }
                                                   )}
                                                </div>
                                             ) : (
                                                <div>
                                                   <div>
                                                      <Tooltip
                                                         title={
                                                            t("compute.vpsmini.overview.addFirstDomain")
                                                         }
                                                         color={"#011a43"}
                                                      >
                                                         {!visibleCustomDOmain ? (
                                                            <BsPlusCircleFill
                                                               onClick={() =>
                                                                  showCustomDomain(
                                                                     !visibleCustomDOmain
                                                                  )
                                                               }
                                                               className="text-xl 2xl:text-2xl dark:text-blue-700 text-primary cursor-pointer"
                                                            />
                                                         ) : (
                                                            <FaMinusCircle
                                                               onClick={() =>
                                                                  showCustomDomain(
                                                                     !visibleCustomDOmain
                                                                  )
                                                               }
                                                               className="text-xl 2xl:text-2xl dark:text-blue-700 text-primary cursor-pointer"
                                                            />
                                                         )}
                                                      </Tooltip>
                                                   </div>
                                                </div>
                                             )}
                                             {visibleCustomDOmain && (
                                                <div className="w-full py-4">
                                                   <CustomDomainAdd
                                                      server={server}
                                                      listCustomDomain={
                                                         listCustomDomain
                                                      }
                                                   />
                                                </div>
                                             )}
                                          </div>
                                       ) : (
                                          <Spin indicator={antIcon} />
                                       )}
                                    </div>
                                    <div className="pt-8">
                                       <div className="flex gap-2 items-center ">
                                          <img
                                             src={blueprint}
                                             alt=""
                                             className="2xl:w-12 2xl:h-12 w-10 h-10"
                                          />
                                          <Texto className="">
                                             {t("compute.vpsmini.overview.microBlueprint")}
                                          </Texto>

                                       </div>
                                       <div className="py-2 px-4 mt-5 font-bold  border-2 rounded-md border-gray-300 w-full  shadow-lg">

                                          <div className="gap-4 grid grid-cols-2 md:grid-cols-3">
                                             {
                                                blueprints?.map((blueprint, k) => {
                                                   return <BlueprintCart 
                                                      blueprint={{...blueprint, isVpsMini: true}}
                                                      key={k}
                                                      server={server}
                                                   />
                                                })
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </TabPanel>

                           <TabPanel hidden={selectedTab !== "Console"}>
                              <iframe
                                 className="shadow-2xl bg-[black] shadow-zinc-900 dark:shadow-xl  dark:shadow-slate-700 w-full "
                                 src={
                                    Utils.getUrlConsole()[
                                    Math.floor(Math.random() * 3)
                                    ] +
                                    "?access_token=" + 
                                    (server?.fernetToken || server?.Token) 
                                 }
                                 height="700"
                                 border="frameno"
                                 style={{
                                    borderRadius: "20px",
                                    border: "20px solid black",
                                 }}
                              />
                           </TabPanel>

                           <TabPanel hidden={selectedTab !== "Upgrade"}> 
                              <div className="px-5">
                                 <UpgradeVps isMini vpsId={id} loadVps={loadVps} server={server} />
                              </div>
                           </TabPanel> 
                           <TabPanel hidden={selectedTab !== "Files"}>
                              <div className="w-full">
                                    <iframe 
                                       style={
                                          {
                                             height: "500px", width: '100%', border: "9px solid #0000001a", borderRadius: "5px", boxSizing: "border-box"
                                          }
                                       }
                                       id="appframe" 
                                       frameborder="0" 
                                       src={
                                          encodeURI(
                                             "https://filemanager-a1.keepsec.tech/login" +
                                             "?hostname="+ server?.OpenstackInstanceExtIp +
                                             "&username="+ server?.SshUsername+
                                             "&password="+ server?.SshPassword +
                                             "&port="+ server?.SshPort +
                                             "&type=sftp"
                                          )
                                       }
                                       allow="fullscreen;speaker"
                                    >

                                    </iframe>
                              </div>
                           </TabPanel>
                           <TabPanel hidden={selectedTab !== "Rebuild"}>
                              <RebuildMini onRbuild={onRbuild} server={server} loadVps={loadVps} />
                           </TabPanel>
                           <TabPanel hidden={selectedTab !== "Delete"}>
                              <div className="">
                                 <DeleteServiceTap 
												server={server} 
												onDelete={onDelete}
												onRun={onRun}
											/>
                              </div>
                           </TabPanel>

                           <TabPanel hidden={selectedTab !== "Reset Password"}>
                              <div className="">
                                 <div className="flex gap-2 items-center">
                                    <PageTitle text={t("compute.vpsmini.changePassworw.h1")} />
                                    
                                 </div>
                                 
                                 <div className="w-full pb-10 mt-10 lg:w-[400px] 2xl:w-[500px] pt-2 md:pt-0">
                                    <div className="">
                                       <LabelAdd
                                          htmlFor="password"
                                          toLowerCase
                                          placeholder={t("compute.vpsmini.changePassworw.newPassLabel")}
                                       />
                                       <InputPassAdd
                                          idx="password"
                                          value={update.password}
                                          name="password"
                                          onChange={handleChange}
                                          required
                                          disabled={updatingPassword}
                                          placeholder={t("compute.vpsmini.changePassworw.newPassPlaceHolder")}
                                       />
                                    </div>
                                    <div>
                                       <LabelAdd
                                          toLowerCase
                                          htmlFor="confirmation"
                                          placeholder={t("compute.vpsmini.changePassworw.newPassConfirmLabel")}
                                       />
                                       <InputTextAdd
                                          idx="confirmation"
                                          value={update.confirmation}
                                          name="confirmation"
                                          type="password"
                                          onChange={handleChange}
                                          required
                                          disabled={updatingPassword}
                                          placeholder={t("compute.vpsmini.changePassworw.newPassConfirmLabelPlaceholder")}
                                       />
                                    </div>
                                    <div className="pt-5">
                                       
                                          <AntButton 
                                             onClick={onUpdatePassword} 
                                             size="large" 
                                             type="primary" 

                                             className=" w-56 flex items-center font-bold py-4 px-4 text-center justify-center" 
                                             loading={updatingPassword}>
                                              {t("compute.vpsmini.changePassworw.changePassword")}
                                          </AntButton>
                                       {/*</Popconfirm>*/}
                                    </div>
                                 </div>

                              </div>
                           </TabPanel>
                        </div>
                     </div>
                  </div>
               )}

               {!server && (
                  <div className="flex justify-center mt-28">
                     <Spiner fontSize={50} />
                  </div>
               )}
            </div>
         </div>
      </>
   );
}

function ConnectCard({isFTP, shareUrl, server}) {
   const {t} = useTranslation()
   const [textTtoooltip2, setTool2] = React.useState(t("copyToClip"));

   const onCopy = () => {
      navigator.clipboard.writeText(shareUrl);
      setTool2(t("copied"));
   };
   return(
      <div className="py-2 px-4 mt-5 border-2 rounded-md border-gray-300 w-full  shadow-lg">
         <div className="text-sm 2xl:text-base font-semibold flex gap-2 justify-between items-center">
            <div className="text-left">
               <Texto  verySmall>{t("compute.vps.tab1.connectTo")}</Texto>
               <span>
                  {isFTP ? "sftp://" : ""}
                  {server?.OpenstackInstanceExtIp}
               </span>
            </div>
            <div className="text-left">
               <Texto  verySmall>{t("compute.vpsmini.overview.username")}</Texto>
               <span>{server?.SshUsername}</span>
            </div>
            <div className="text-left">
               <Texto  verySmall>{t("compute.port")}</Texto>
               <span>{server?.SshPort}</span>
            </div>
         </div>

         <div className=" flex items-center justify-between dark:bg-darkSiderbar mt-5 bg-neutral-300 px-2 py-2 rounded-md">
            <Texto className="font-semibold"  small> <i>{shareUrl}</i> </Texto>
            <Tooltip
               title={textTtoooltip2}
               color={"#011a43"}
            >
               <IoIosCopy
                  onClick={onCopy}
                  className="2xl:text-xl text-base text-primary cursor-pointer"
               />
            </Tooltip>
         </div>
      </div>
   )
}

function CustomDomainAdd({ server, listCustomDomain }) {
   const [domain, setDomain] = useState("");
   const { t } = useTranslation();
   const { messageApi } = useSelector(app => app.core)
   const [loanding, onRun] = useState(false);
   const onSave = async () => {
      if (!domain || domain.trim() == "") return;
      onRun(true);
      const d = {
         domain: domain
      }
      const result = await UserMgt.onVpsAction("add_custom_domain", server?.Id, d);
      if (!result.error) {
         messageApi.success(result.message);
      } else {
         messageApi.error(result?.message || t("core.constants.STANDARD_ERROR_MESSAGE"));
      }

      onRun(false);
      return listCustomDomain(server);
   };
   return (
      <Space.Compact
         style={{
            width: "100%",
         }}
      >
         <Input
            placeholder={t("compute.vpsmini.overview.cutomDomainAddInput")}
            onChange={(e) => setDomain(e.target.value)}
            value={domain}
         />
         <AntButton loading={loanding} type="primary" onClick={onSave}>
             {t("compute.vpsmini.overview.cutomDomainSave")}
         </AntButton>
      </Space.Compact>
   );
}
