import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


const CustomFlowStripe = ({reload}) => {
  const stripe = useStripe();
  const elements = useElements();
  const {t} = useTranslation()

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }
    setLoading(true)
    const setup = await stripe.confirmSetup({
      elements, 
      redirect: 'if_required',
      confirmParams: { 
      },
    });
    console.log('setup finished ', setup)
    if (setup?.error) {
      setErrorMessage(setup?.error?.message);
    } else {
      console.log('>>> Process finished ===========', setup)
      reload()

    }
    setLoading(false)
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && 
        <Alert
          message="Error"
          description={errorMessage}
          type="error"
        />
      }

      <Button loading={loading}  disabled={!stripe || loading} className='w-full h-10 gap-2 mt-2 flex items-center justify-center' htmlType="submit" type="primary">
        <img src={require('../../assets/img/stripe.png')} />
        <span className='font-bold'>{t("account.billing.overview.paymentMethod.submit")}</span>
      </Button>

    </form>
  );
};

export default CustomFlowStripe;