import { Alert, Button, Modal } from 'antd'
import useSelection from 'antd/es/table/hooks/useSelection'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { AddButton } from 'components/AddButton'
import { Spiner } from 'components/Bloc'
import { OkButton } from 'components/OkButton'
import VULLIFY from 'lib/vullify'
import { updateHeadLink } from 'reducer/action/coreActions'

import Utils from '../../utils'

export default function VullifyListScan(props){
    const { id } = useParams()
    const { targetId } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
    const [target, setTarget] = useState(null)
    const {messageApi}= useSelection(app => app.core)
    const [modalDisplayed, setShowModal] = useState(false)

    const onNavigate = async (scan) => {
		navigate("/security/vullify/"+id+"/connect/target/"+targetId+"/"+scan.id)
	}
    const loadTarget = async () => {
        setTarget(null)
		const result = await VULLIFY.getTarget(id, targetId) //.user_vps
		if(!result?.error){
            setTarget(result?.target)
			return result?.target
		}else{
			messageApi.open({
				type: 'error',
				content: 'An error occured. If this persist, please tell us',
			});
			return navigate(-1)
		}
	}

    useEffect(() => { 

		loadTarget().then((target) => {
			const headLinks = [
				{
					link: "/security/vullify",
					item: 'vullify',
					alt: "Vullify short link"
				},
				{label: target?.server?.DisplayName, link: "security/vullify/"+target?.server?.Id+"/connect",  arrow: true},
                {label: "Target", link: "security/vullify/"+target?.server?.Id+"/connect/target", arrow: true},
                {label: target.name, arrow: true},
			]
			dispatch(updateHeadLink(headLinks))
				
		})
		return () => dispatch(updateHeadLink([]))
	}, [])
    const onShowModal = () => setShowModal(true)

    return( 
        <>
            <NewScanModal 
                loadTarget={loadTarget} 
                target_id={targetId} 
                server_id={target?.server?.Id} 
                open={modalDisplayed} 
                setShowModal={setShowModal}
            />
			<div className=" dark:text-darkTextColor  ">
				<div className="container mx-auto max-w-full px-3 md:px-8 h-full pb-14 md:pb-7">
					{target ?
                        <div className='flex gap-3 flex-wrap'>
                            {target?.scans && target?.scans.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((scan, id) => <Scan  navigate={onNavigate}  scan={scan} key={id}/>)}
							
                            <div className="pt-3">
                                <AddButton click fun={onShowModal} title="New scan" duck /> 
                            </div>
                        </div>
						:
						<div className='flex my-auto h-full justify-center items-center'>
						    <Spiner fontSize={50} />
						</div>
                    }

                    
                </div>
            </div>
        </>
    )
}
 
export function NewScanModal(props){
    const [loanding, setloading] = useState(false)
    const [scanName, setScanName] = useState("")
    const [scanMessage, setScanMessage] = useState(null)
    const [scanErrorMessage, setScanErrorMessage] = useState(null)

    async function onCancel(){
        if(scanMessage){
            props.loadTarget()
        }
        setScanMessage(null)
        setScanErrorMessage(null)
        setloading(false)
        setScanName("")
        return props.setShowModal(false)
    }

    const onScan = async () => { 
        setloading(true)
        try{
            const result = await VULLIFY.startScan(
                {
                    displayName: scanName, //.normalize('NFD').replace(/[^a-zA-Z0-9]/g, "_"),
                    server_id: props.server_id,
                    target_id: props.target_id,

                }
            )
            if(!result?.error && result?.scanREsult){
                setScanMessage(result?.scanREsult)
                Utils.sleep(4000).then(() => {
                    //props.loadTarget()
                    props.loadTarget()
                    setScanErrorMessage(null)
                    setScanMessage(null)
                    setloading(false)
                    setScanName("")
                    return props.setShowModal(false)
                });
            }else{
                setScanMessage(null)
                setScanErrorMessage(result?.scanREsult || result?.message)
            }
            console.log('result Scan', result)
        }catch(e){
             
        }
        setloading(false)
    }

    return(
        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={450}
	        open={props?.open}
	    >
            <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            New scan
                        </h4>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6 py-10">						
                        {scanMessage?
                            <div className="py-3 px-5">
                                <Alert  message={scanMessage} type="success" />
                            </div>
                            :
                            null
                        }
                        {scanErrorMessage?
                            <div className="py-3 px-5">
                                <Alert  message={scanErrorMessage} type="error" />
                            </div>
                            :
                            null
                        }
                        <div className="px-20 pt-5">
                                <input className=" dark:bg-black dark:border-black dark:text-darkTextColor
                                    text-gray-700 w-full shadow border rounded border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3" 
                                    type="text" 
                                    value={scanName}
                                    placeholder="Identify my scan"
                                    onChange={(e) =>setScanName(e.target.value)}
                                />
                        </div>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                    <Button size="large" onClick={() => onCancel()}>
                        Cancel
                    </Button>
                    
                    <Button
                        type="primary"
                        loading={loanding}
                        // disabled={!scanName}
                        onClick={() => {
                            scanName && onScan()
                        }}
                        
                    >
                        Start scan
                    </Button>
            	</div>
            </div>

        </Modal>
    )
}


const categories = [
    {name: "Low", count: 250, color: "#08673f"},
    {name: "Medium", count: 67, color: "#b4b709"},
    {name: "High", count: 132, color: "#cf7500"},
    {name: "Critical", count: 5, color: "#9f0032"}
]

function Scan({scan, navigate}){
    const handleMenuClick = (e) => {
        // message.info('Click on menu item.');
        console.log('click', e.key);
        if(e.key === "1") return
        if(e.key === "2") return 
        if(e.key === "3") return 
        if(e.key === "4") return 
        if(e.key === "5") return 
    
    }; 
    // const menuProps = {
    //     items,
    //     onClick: handleMenuClick,
    // };

	return(
		<div  className=" mt-5 relative">
			<div 
				// onClick={()=> navigate(scan)}
				className='px-3 py-1 cursor-pointer rounded-xl dark:bg-bgPagedark bg-neutral-100 dark:hover:shadow-slate-700'>

				<div className='py-5 text-center text-primary dark:text-darkTextColor flex items-center gap-2 justify-between'>
					<div className="text-left">
						<span className='font-semibold '> 
							{scan?.displayName || scan?.name}
						</span>
                        <p   className='font-light text-sm my-auto'>
							{new Date(scan.createdAt).toUTCString()}
						</p>

					</div>
                    <OkButton title="See Report" click   fun={() => navigate(scan) }/>
				</div>
				{/* <div className='flex justify-between mt-3 pb-2'>
                    {categories.map((cat, i) =>{
                        return(
                            <div key={i} style={{borderColor: cat.color}} className={"bg-primary border-4  flex items-center justify-center text-white h-[65px] w-[65px] text-center p-1"}>
                                <div className=''>
                                    <p className='font-semibold text-sm'>{cat.name}</p> 
                                    <p className='font-semibold text-xs'>{cat.count}</p> 
                                </div>
                            </div>
                    )})}

				</div> */}
			</div>
		</div>
	)
}
