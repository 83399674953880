import React, { useState, useEffect }  from 'react'
import { Table, Tag, Space, Button, Tooltip,  Modal, Tabs, Alert, Dropdown, Popconfirm  } from 'antd';
import { PlusOutlined, QuestionCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Utils from '../../utils'
import KSPROXY from 'lib/proxy'
import { Details, Authorization, Access } from './section/AccessListSection'
import { MdMoreVert } from "react-icons/md"

export function PAccessLists(props){
	const col1 = [
        {
        title: 'Name',
        dataIndex: 'name',
        render: (_, obj) => <div> 
          <span>{obj.name}</span>
          <br/> 
          <span>{Utils.formatDate(obj?.created_on, 'Do MMMM YYYY, h:mm a')}</span>
        </div>,
      },
      {
        title: 'Authorization',
        dataIndex: 'authorization',
        key: 'authorization',
        render: (_, obj) => <div> 
          <span>{obj?.items?.length > 1  ? obj.items.length + " Users" : obj?.items?.length+" User"}</span>
        </div>
        // width: 200,
        // render: (text) => <div className="w-[200px]"> <a target="_blank" href={text}>{text}</a></div>,
      },
      {
        title: 'Access',
        dataIndex: 'access',
        render: (_, obj) => <div> 
          <span>{obj?.clients?.length > 1 ? obj.clients.length + " Rules" : obj.clients.length+" Rule"}</span>
        </div>      
      },
      {
        title: 'Satisfy',
        render: (_, obj) => <div> 
           <span>{obj?.satisfy_any ? "Any" : "All"}</span>
        </div> ,
        dataIndex: 'satisfy',
      },
      {
        title: 'Proxy Hosts',
        render: (_, obj) => <div> 
           <span>{obj?.proxy_host_count} {obj?.proxy_host_count !== 0 ? "Proxy Hosts" : "Proxy Host"}</span>
        </div> ,
        dataIndex: 'proxy_hosts',
      },
      {
        title: 'Actions',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Dropdown 
              menu={{
                items: [
                  {
                    label: 'Edit',
                    key: '3',
                    onClick: () => setOngoingToEdit(record),
                    icon: <EditOutlined />,
                  },
                  {
                    label: (<Popconfirm
                        title={"Confirm deletion "}
                        description={"Really delete access list named "+record.name}
                        onConfirm={() => confirmDelete(record)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        Delete {record.name}
                      </Popconfirm>
                    ),
                    key: '5',
                    danger: true,
                    icon: <DeleteOutlined />,
                  }
                ]
              }}
            >                
              <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
            </Dropdown>
          </Space>
        ),
      },
    ];

  const [open, setOpen] = useState(false) 
  // const [loading, setload] = useState(false) 
  const [onGointToEdit, setOngoingToEdit] = useState(null) 

  const confirmDelete = async (list) => {
    const result = await KSPROXY.createAccesslist(props.server?.Id, {Odelete: true, id: list?.id})
    if(result && !result.error){

      props.messageApi.success('Access list deleted !');
      props?.getAccesslist()
    }else{
      props.messageApi.error('An error occurs, please try again later.');

    }
  }
  // async function getAccesslist(){
  //   setload(true)
  //   try{
  //     const result = await KSPROXY.getAccesslist(props?.server?.Id)
  //    result && props.setAccessList(Object.values(result).filter( arr => typeof arr === "object"))
  //    !result && props.setAccessList([])
  //   }catch(e){
  //     console.log('Cant get proxy host ', e)
  //   }
  //   setload(false)
  // }
  // useEffect(() => { 
  //   getAccesslist()
  // }, [])
	return(
		<div className="w-full">
      <NewAccessList 
        open={open || onGointToEdit} 
        setOpen={setOpen}
        accessList={onGointToEdit}
        setOngoingToEdit={setOngoingToEdit}
        getAccesslist={props?.getAccesslist}
        server={props?.server}
        setAccessList={props.setAccessList}
        messageApi={props?.messageApi}
      />
			<div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">Access List</h5>
				<div className="flex justify-end items-center gap-4">
					<Tooltip 
						overlayStyle={{ lineBreak: 'pre-line' }} 
						title={() =>  <div>
								<p className="font-bold">What is an Access List?</p>
								<p className="mt-2">Access Lists provide a blacklist or whitelist of specific client IP addresses along with authentication for the Proxy Hosts via Basic HTTP Authentication.</p> 
								<p className="mt-2"> You can configure multiple client rules, usernames and passwords for a single Access List and then apply that to a Proxy Host.</p>
								<p className="mt-2">This is most useful for forwarded web services that do not have authentication mechanisms built in or that you want to protect from access by unknown clients. </p>
						    </div>
						}>
						<QuestionCircleOutlined className="xl:text-2xl text-xl cursor-pointer text-primary"/>
					  </Tooltip>

					<Button type="primary" onClick={()=> setOpen(true)} icon={<PlusOutlined />}>
			            Access List
			        </Button>
				</div>
			</div>

			<Table  
                columns={col1} 
                // dataSource={props.proxyHosts} 
                loading={!props.accessList || props?.loading}
                dataSource={props.accessList} 
            />
		</div>
	)
}

function NewAccessList(props){
    const { accessList } = props
    const init = [{id: Utils.generateId()}]
    const [objectDetail, setObjectDetail] = useState({})
    const [authorizations, setAuth] = useState(init)
    const [access, setAccess] = useState([{id: Utils.generateId()}])
    const [creation, setCreation] = useState(false)
    const [eMessage, setErrorMessage] = useState(null)

  useEffect(() => {
      if(accessList){
        const copy = Object.assign(accessList)
        const clients = copy?.clients?.map(client => {
          return {
            id: Utils.generateId(), 
            address: client.address,
            directive: client.directive
          }
        })
        const items = copy?.items?.map(item => {
          return {
            id: Utils.generateId(), 
            password: item.password,
            username: item.username,
            hint: item.hint
          }
        })
        items && items.length && setAuth(items)
        clients && clients.length && setAccess(clients)
        // delete copy.clients
        // delete copy.items
        delete copy.created_on
        delete copy.meta
        delete copy.owner
        delete copy.owner_user_id
        delete copy.proxy_host_count
        delete copy.modified_on
        setObjectDetail(copy)
      }
  }, [accessList])
 
  const arrayTab = [
      {
        label: "Details",
        component: <Details  objectDetail={objectDetail} setObjectDetail={setObjectDetail}/>
      },
      {
        label: "Authorization",
        component: <Authorization authorizations={authorizations} setAuth={setAuth}/>
      },
      {
        label: "Access",
        component:  <Access access={access} setAccess={setAccess}/>
      },
  ]

  async function onCreate(){
    setErrorMessage(null)
    const clients = access.filter(acc  =>  acc.directive && acc.address).map(acc => {
      return {directive: acc.directive, address: acc.address}
    })
    const items = authorizations.filter(auth  => auth.hint || auth.username && auth.password).map(auth => {
      return {username: auth.username, password: auth.password}
    })
    if(!items.length && !clients.length){
      setErrorMessage('At least one   row of access or authorizations should be definied')
      return
    }

    const data = {
      ...objectDetail,
      items: items,
      clients: clients,
      // domain_names: objectDetail?.domain_names?.map(d => d.value)

    }
    console.log('DATA Create ', data)

    try{
        setCreation(true)
        setErrorMessage(false)
        const result = await KSPROXY.createAccesslist(props.server?.Id, data)
        setCreation(false)
        console.log('RESULY HOST CREAT ', result)
        if(result?.error){
          return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
        }
        return Utils.sleep(1000).then(() => {
                  onInitParams()
                  props.messageApi.open({
                      type: 'success',
                      content: 'Done !'
                  });
                  props.getAccesslist()
                });
      }catch(e){
        console.log('ON CREATE PROXY HOST ERROR ', e)
      }

  }
  function onInitParams(){
    setObjectDetail({})
    setAccess([{id: Utils.generateId()}])
    setAuth([{id: Utils.generateId()}])
    props.setOpen(false)
    props.setOngoingToEdit(null)

  }
  return(
    <Modal
      destroyOnClose={true}
      // centered
      footer={null}
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={700}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
              Acess List Form
          </h4>
        </div>

        <div className='px-6 mt-3' style={{display: !eMessage && "none"}}>
          <Alert  message={eMessage } type="error" />         
        </div>

        <div className='px-6 py-3'>
          <Tabs defaultActiveKey="1" 
            // onChange={onChange} 
            items={arrayTab.map((_, i) => {
              const id = String(i);
              return {
                label: _.label,
                key: i,
                children: _.component,
              };
            })} 
          />
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                  onInitParams()
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              loading={creation}
               onClick={() =>onCreate()}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}