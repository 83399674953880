export default{
    khks: {
        deploy: {
            addNewNode: "Ajout Node",
            addNewControlPlane: "Ajout Control Plane",
        },
    },
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
}