import React from 'react'

import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../../components/TabSelector";
import GetReward from './section/GetReward'
import { ThemeContext } from '../../theme/ThemeContext';
import coins from "../../assets/img/reward/KS-coins.png"
import click from "../../assets/img/reward/click.png"
import shop from "../../assets/img/reward/shop.png"
import edit from "../../assets/img/reward/edit.png"
import ShopKs from "../../assets/img/reward/store.svg"
import { useTranslation } from 'react-i18next';


export default function Reward() {
    const { t } = useTranslation()
     const { theme, setTheme } = React.useContext(ThemeContext);

     const [selectedTab, setSelectedTab] = useTabs([
        "get",
        "Store", 
        "Win",
        "arcades",
        "withdraw",
        "History"
      ]);



    React.useEffect(()=>{
 
        //UserMgt.get_user_payment() 
    }, [])

    
    return (
        <div>
            <div className='reward  dark:text-darkTextColor '>
                <div className="px-3 md:px-8 h-auto ">
                    <div className="pt-5">
                    <div className="flex flex-wrap gap-5 items-start top2 ">
                          <div className="flex items-center ">
                            <img src={coins} className="w-10" />
                            <svg className="svg0">
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">{t("account.reward.token")}:2</text>
                            </svg>
                          </div>

                          <div className="flex items-center">
                            <img src={click} className="w-10" />
                            <svg>
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">{t("account.reward.click")}:2</text>
                            </svg>
                          </div>

                          <div className="flex items-center">
                             <img src={edit} className="w-10" />
                            <svg className="svg1">
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">{t("account.reward.reg")}:2</text>
                            </svg>
                          </div>

                          <div className="flex items-center">
                            <img src={shop} className="w-10" />
                            <svg className="svg2">
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">{t("account.reward.refPur")}:0</text>
                            </svg>
                          </div>

                          <div>
                            <div className="progress-bar">
                              <div className="progress-fill"></div>
                            </div>
                            <svg className="svg4">
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">{t("account.reward.xp")}</text>
                            </svg>
                          </div>
                          <div className="-translate-y-[25px] ml-14">
                            <svg className=" z-50 relative svg5">
                              <text className={theme !== "dark"? "svgText  mx-auto text-center " : " svgTextDark mx-auto text-center "} x="10" y="45">{t("account.reward.level")}</text>
                            </svg>
                            <div className="w-14 z-0 relative -translate-y-3.5 h-14 mx-auto bg-primary rotate-45 flex items-center justify-center ">
                              <span className="text-white font-bold text-3xl -rotate-45">2</span>
                            </div>
                          </div>
                        </div>
                        {/* <div className="flex  gap-5 items-start top2">
                          <div className="flex items-center ">
                            <img src={coins} className="w-10" />
                            <svg className="svg0">
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">Token:2</text>
                            </svg>
                          </div>

                          <div className="flex items-center">
                            <img src={click} className="w-10" />
                            <svg>
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">Click:2</text>
                            </svg>
                          </div>

                          <div className="flex items-center">
                             <img src={edit} className="w-10" />
                            <svg className="svg1">
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">Register:2</text>
                            </svg>
                          </div>

                          <div className="flex items-center">
                            <img src={shop} className="w-10" />
                            <svg className="svg2">
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">Referal Purchase:0</text>
                            </svg>
                          </div>

                          <div>
                            <div className="progress-bar">
                              <div className="progress-fill"></div>
                            </div>
                            <svg className="svg4">
                              <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">63XP to level 3</text>
                            </svg>
                          </div>
                          <div className="-translate-y-[25px] ml-14">
                            <svg className=" z-50 relative svg5">
                              <text className={theme !== "dark"? "svgText  mx-auto text-center " : " svgTextDark mx-auto text-center "} x="10" y="45">Level</text>
                            </svg>
                            <div className="w-14 z-0 relative -translate-y-3.5 h-14 mx-auto bg-primary rotate-45 flex items-center justify-center ">
                              <span className="text-white font-bold text-3xl -rotate-45">2</span>
                            </div>
                          </div>
                        </div> */}
                        <nav className="flex border-b border-gray-300 dark:border-gray-800">
                            <TabSelector
                              isActive={selectedTab === "get"}
                              onClick={() => setSelectedTab("get")}
                            >
                             {t("account.reward.getRewardTab.name")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Store"}
                              onClick={() => setSelectedTab("Store")}
                            >
                              {t("account.reward.storeTab.name")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Win"}
                              onClick={() => setSelectedTab("Win")}
                            >
                              {t("account.reward.winpriceTab.name")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "arcades"}
                              onClick={() => setSelectedTab("arcades")}
                            >
                              {t("account.reward.arcadesTab.name")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "withdraw"}
                              onClick={() => setSelectedTab("withdraw")}
                            >
                              {t("account.reward.wthdrawTab.name")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "History"}
                              onClick={() => setSelectedTab("History")}
                            >
                              {t("account.reward.historyTab.name")}
                            </TabSelector>
                           
                          </nav>
                          <div className="pl-1 pt-6">
                          <TabPanel hidden={selectedTab !== "get"}>
                            <GetReward />
                          </TabPanel>  

                          <TabPanel hidden={selectedTab !== "Store"}>
                              <div className="py-5">

                                <img src={ShopKs} className="w-full" />
                              </div>
                          </TabPanel>

                           <TabPanel hidden={selectedTab !== "Win"}>
                               
                          </TabPanel>
                          <TabPanel hidden={selectedTab !== "History"}>
                               
                          </TabPanel>
                         </div> 

                    </div>

            
                </div>
            </div>
        </div>
    );
}

