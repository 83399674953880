import { save_geo_location } from "reducer/action/coreActions"

import store from "../reducer"
import { list_kslb_servers } from "../reducer/action/kslbAction"
import { list_kwaf_servers } from "../reducer/action/kwafAction"
import { list_payment, list_subscription } from "../reducer/action/userAction"
import { save_user_profile } from "../reducer/action/userAction"
import { list_vps, vps_packages } from "../reducer/action/vpsActions"
import { list_images } from "../reducer/action/vpsActions"
import { list_vullify_servers } from "../reducer/action/vullifyAction"
import { list_web_app_servers } from "../reducer/action/webappAction"
import Utils from '../utils';
import UserAPI from './keepsec_auth_service'

const {  get, post, put, onDelete } = require("./api")

// console.log('{process.env.REACT_APP_SECRET_NAME} 2', process.env.REACT_APP_PASSWORD_VPSMINI_API, process.env.REACT_APP_PSSEC_ALGO_PASSWORD)


const SERVICE_URL = process.env.REACT_APP_USER_MANGMENT_API
const  apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}

const user_create = async (data) => {
    try {
      console.log('data', data)
      const user = await post(SERVICE_URL+"v1/users", data, header)
      console.log('user created >>', user)
      return user
    } catch (error) {
      console.error('error wrong when try to created new user >>', error)
      return undefined
    }
};
const reset_password_request = async (data) => {
    try {

       let OSName = "Unknown";
      if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) OSName="Windows 10";
      if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1) OSName="Windows 8.1";
      if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName="Windows 8";
      if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName="Windows 7";
      if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName="Windows Vista";
      if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName="Windows XP";
      if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName="Windows 2000";
      if (window.navigator.userAgent.indexOf("Mac")            != -1) OSName="Mac/iOS";
      if (window.navigator.userAgent.indexOf("X11")            != -1) OSName="UNIX";
      if (window.navigator.userAgent.indexOf("Linux")          != -1) OSName="Linux";

      const browser = (function() {
          const test = function(regexp) {return regexp.test(window.navigator.userAgent)}
          switch (true) {
              case test(/edg/i): return "Microsoft Edge";
              case test(/trident/i): return "Microsoft Internet Explorer";
              case test(/firefox|fxios/i): return "Mozilla Firefox";
              case test(/opr\//i): return "Opera";
              case test(/ucbrowser/i): return "UC Browser";
              case test(/samsungbrowser/i): return "Samsung Browser";
              case test(/chrome|chromium|crios/i): return "Google Chrome";
              case test(/safari/i): return "Apple Safari";
              default: return "Other";
          }
      })();

      const obj = {
            ...data,
           "operatingSystem": OSName,
           "webBrowser": browser
        }
      const reqResp = await post(SERVICE_URL+"v1/users/request/password/reset", obj, header)
      return reqResp
    } catch (error) {
      console.error('error reset_password_request >>', error)
      return error
    }
};
const active_account = async (data) => {
    try {
      const reqResp = await post(SERVICE_URL+"v1/users/activate", data, header)
      console.log('result active_account >>', reqResp)
      return reqResp
    } catch (error) {
      console.error('error active_account >>', error)
      return error
    }
};
const password_reset = async (data) => {
    try {
      console.log('data', data)
      
      const reqResp = await post(SERVICE_URL+"v1/users/password/reset", data, header)
      console.log('result reset_password_request >>', reqResp)
      return reqResp
    } catch (error) {
      console.error('error reset_password_request >>', error)
      return error
    }
};

const getCountryCode = async () => {
    return await fetch('/')
    .then(function (response) {
      return {
        error: false,
        countryCode:  response.headers.get('x-country-code'),
        countryName: response.headers.get('x-country-name')
      }
    })
  }

const get_vps_types = async () => {
    try {
        await UserAPI.refrest_token()
        const countryData = await getCountryCode()
        const vpsTypes = await get(SERVICE_URL + "v1/vps-types?countryCode="+countryData?.countryCode, header)
        store.dispatch(vps_packages(vpsTypes?.vpsTypes))
        const dataLocation = {
            countryCode: countryData?.countryCode,
            currencyCode: countryData?.countryCode === "CA" ? "$CAD " : "$USD ",
            excRate: parseFloat(vpsTypes?.excRate || 1).toFixed(2)
        }
        store.dispatch(save_geo_location(dataLocation)) 
        return vpsTypes
    } catch (error) {
        console.error('error wrong when try to logout user >>', error)
        return { error: true, message: error.message } 
    }
};

const create_payment = async (dataPayment) => { ///v1/users/:user/payment
    const newToken = await UserAPI.refrest_token()
    const accessToken  = newToken?.accessToken
    const payment = {...dataPayment, custId: store.getState().user.user?.Id, access_token: accessToken}
    try {
        console.log('beofr request')
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/payment", payment, {"skip-auth": true, ...header})
        console.log('create vps result', result)
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const rebuildVps = async (payload, serverName, params) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const body = {
            "clientservername": serverName,
            "clientid": ""+store.getState().user.user?.Id,
            ...params

        }
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/rebuild", {...body, payload: "rebuild", access_token: accessToken}, header)

        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return null
    }
};
const rebuildNormlVps = async (params) => {
    try {
        await UserAPI.refrest_token()
        const body = {
            "custId": ""+store.getState().user.user?.Id,
            ...params
        }
        console.log('body body', body)
       
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/rebuild?isNotMini=true", {...body}, header)
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return null
    }
};
const create_vps = async (data, dataPayment) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        // const payment = {...dataPayment, custId: store.getState().user.user?.Id, access_token: accessToken}
        let paymentState;
        const isDemo = await Utils.isDemo()
        if(!isDemo && !data.isFreeDeploy && !data.isAdminFreeDeployment && !data.isWithPromo) paymentState = await create_payment(dataPayment)
        const params = {
            ...data,   
            withFirstTrial: dataPayment?.withFirstTrial, 
            access_token: accessToken, 
            paymentId: paymentState?.newPaymentId, 
            subscriptionId: paymentState?.subscriptionId,
            country: "canada", 
            isDemo: isDemo
        }
        const result = await post(SERVICE_URL  +"v1/users/"+store.getState().user.user?.Id+"/vps", params, {"skip-auth": true, ...header})
        console.log('create vps result', result)
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const create_k8s = async (data, dataPayment) => {
    try {
        console.log('data data data', data)
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken
        const isDemo = await Utils.isDemo()
        // const payment = {...dataPayment, custId: store.getState().user.user?.Id, access_token: accessToken}
        let paymentState;
        if(!isDemo && !data.isFreeDeploy && !data.isAdminFreeDeployment && !data.isWithPromo) paymentState = await create_payment(dataPayment)
        console.log('paymentState paymentState', paymentState)
        const params = {
            ...data, 
            withFirstTrial: dataPayment?.withFirstTrial, 
            access_token: accessToken, 
            paymentId: paymentState?.newPaymentId, 
            subscriptionId: paymentState?.subscriptionId,
            user: store.getState().user.user?.Id,
            isDemo: isDemo
        }
        const result = await post(SERVICE_URL  +"v1/users/"+store.getState().user.user?.Id+"/k8s", params, {"skip-auth": true, ...header})
        console.log('create vps result', result)
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const addVpsGroupRule = async (server_id, data) => {
    try {
        await UserAPI.refrest_token()
        const user = store.getState().user
        const result = await post(SERVICE_URL  +"v1/users/"+store.getState().user.user?.Id+"/vps/security-group/rule/add", {...data, server_id}, {...header})
        console.log('Group rule result ', result)
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const onVpsAction = async (action, server_id, object={}) => {
    try {
        const data = {
			action: action,
            ...object
		}
        await UserAPI.refrest_token()
        const result = await post(SERVICE_URL  +"v1/users/"+store.getState().user.user?.Id+"/vps/mk-action", {...data, server_id}, {...header})
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const delVpsGroupRule = async (server_id, rule_id) => {
    try {
        await UserAPI.refrest_token()
        const user = store.getState().user
        const result = await onDelete(SERVICE_URL  +"v1/users/"+store.getState().user.user?.Id+"/vps/security-group/rule?rule_id="+rule_id+"&server_id="+server_id, {}, {...header})
        const data = result && await result.json()
        console.log('Group rule deletion  ', data)
        return data 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const deleteServer = async (server_id) => {
    try {
        await UserAPI.refrest_token()
        const user = store.getState().user
        const result = await onDelete(SERVICE_URL  +"v1/users/"+store.getState().user.user?.Id+"/vps/"+server_id, {}, {...header})
        // console.log('result result ', result)
        // const data = result && await result.json()
        // console.log('VPS deletion  ', data)
        return result && result.status
    } catch (error) {
        console.error('error wrong when try to delete server >>', error)
        return { error: true, message: error.message }
    }
};
const get_user_vps = async (dontDispatch) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps?access_token="+accessToken, header)
        console.log('getting vps vps result', result)
        if(result && result.user_vps && !dontDispatch){
            const sorted = result?.user_vps.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime())
            store.dispatch(list_vps(sorted.filter(vps => vps.service?.category?.task_name === "compute")))
            store.dispatch(list_web_app_servers(sorted.filter(vps => vps.service?.category?.task_name ===  "platform")))
            store.dispatch(list_vullify_servers(sorted.filter(vps => vps.service?.task_name ===  "vullify")))
            store.dispatch(list_kwaf_servers(sorted.filter(vps => vps.service?.task_name ===  "kwaf")))
            store.dispatch(list_kslb_servers(sorted.filter(vps => vps.service?.task_name ===  "kslb")))
        }
        return result 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const getUniqueVps = async (id) => {
    try {
        await UserAPI.refrest_token()

        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/"+id, header)
        return result 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const getVpsImages = async () => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/images/", header)
        console.log('result getVpsImages getVpsImages ', result)
        store.dispatch(list_images(result?.images.filter(i => !i.name.includes('rescue'))))
        return result?.images.filter(i => !i.name.includes('rescue'))
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};

const get_user_souscription = async () => {
    try {
        console.log('START get_user_souscription')
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken 
        console.log('END TPKEN', newToken)

        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/subscriptions?access_token="+accessToken, header)
        console.log('getting user_subscriptions result', result)
        const result2 = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/payments?access_token="+accessToken, header)
        console.log('getting user_payments result', result2)
        store.dispatch(list_payment(result2?.payments))
        store.dispatch(list_subscription(result?.user_subscriptions))
        return result 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
}; 

const get_user_payment = async () => {
    try {
        console.log('start user user_payments')
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/payments?access_token="+accessToken, header)
        console.log('getting user_payments result', result)
        store.dispatch(list_payment(result?.user_payments))
        return result 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};

const check_promo_code = async (code) => {
    try {
        console.log('start  check_promo_code')
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const result = await get(SERVICE_URL +"v1/get-promo-code?code="+code+"&access_token="+accessToken, header)
        console.log('getting user_payments result', result)
        return result 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const check_trial_code = async (code) => {
    try {
        console.log('start  check_trial_code')
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const result = await get(SERVICE_URL +"v1/get-trial-code?code="+code+"&access_token="+accessToken, header)
        console.log('getting user_payments result', result)
        return result 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const get_custom_domain = async (serverName) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const result = await get(SERVICE_URL +"v1/"+store.getState().user.user?.Id+"/"+serverName+"/domains?access_token="+accessToken, header)
        console.log(' get_custom_domain result', result)
        return result 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};
const get_user_profile = async (id) => {
    try {
        const newToken = await UserAPI.refrest_token()

        const result = await get(SERVICE_URL +"v1/users/"+id+"/profile", header)
        if(result?.statusCode === 200 && result?.user_profile){
            store.dispatch(save_user_profile(result?.user_profile))
            return result?.user_profile
        }
        return {} 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return {}
    }
};
const update_subcription = async (serverId, payload, force) => {
    try {
        console.log('start user update_subcription')
        await UserAPI.refrest_token()
        const data = {serverId, payload: payload, force: force}
        const result = await put(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/subscription/edit", data, header)
        console.log('getting update_subcription result', result)
        return result 
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return { error: true, message: error.message }
    }
};

const vps_ssh = async (params) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        console.log('>> SSH Params ', params)
        const result = await post(SERVICE_URL +"v1/users/ssh-token", {...params, access_token: accessToken}, header)
        console.log('vps ssh token result', result?.SSHtoken)
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return null
    }
};


const create_pre_order = async (params) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const data = {...params, access_token: accessToken, custId: store.getState().user.user?.Id}
        
        console.log('>> create_pre_order Params ', data)

        const result = await post(SERVICE_URL +"v1/users/order", data, header)
        console.log('>> create_pre_order result', result)
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return null
    }
};
const reSendEmailVerification = async (params) => {
    try {
        await UserAPI.refrest_token()

        const data = {...params, custId: store.getState().user.user?.Id}
        
        console.log('>> reSendEmailVerification Params ', data)

        const result = await post(SERVICE_URL +"v1/users/send-verification-email", data, header)
        console.log('>> reSendEmailVerification result', result)
        return result
    } catch (error) {
        console.error('error wrong when try to create vps >>', error)
        return null
    }
};

const login_on_captain = async (params) => {
    try {
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const data = {...params, custId: store.getState().user.user?.Id}
        
        console.log('>> loginOnCaptain Params ', data)
        const result = await post(SERVICE_URL +"v1/users/login-on-captain", data, header)
        console.log('>> loginOnCaptain result', result)
        return result?.data
    } catch (error) {
        console.error('ERROR LOGIN CAPTAIN >>', error)
        return null
    }
};
const onEnableNetwork = async (vpsId) => {
    try {
        await UserAPI.refrest_token()
        const data = {server_id: vpsId, custId: store.getState().user.user?.Id}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/network", data, header)
        return result
    } catch (error) {
        console.error('ERROR LOGIN CAPTAIN >>', error)
        return null
    }
};
const onPortCreate = async (body, actionDelete) => {
    try {
        await UserAPI.refrest_token()
        const data = {...body, actionDelete: actionDelete}
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/ports", data, header)
        return result
    } catch (error) {
        console.error('ERROR LOGIN CAPTAIN >>', error)
        return null
    }
};

const createPaymentMethod = async (data) => {
    try {
        await UserAPI.refrest_token()
        const result = await post(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/payment-method", data, header)
        return result
    } catch (error) {
        console.error('ERROR Create Payment Method >>', error)
        return null
    }
};
const getNetwork = async (vpsId) => {
    try {
        await UserAPI.refrest_token()
        const result = await get(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps/network?server_id="+vpsId, header)
        return result
    } catch (error) {
        console.error('ERROR LOGIN CAPTAIN >>', error)
        return null
    }
};

const  onUpdate = async (params) => { 
  try { 
        await UserAPI.refrest_token()
        const data = {...params}
        const result = await put(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id+"/vps", data, header)
        console.log('>> onUpdate result', result)
        return result
  } catch (error) {
      console.error('ERROR onUpdate >>', error)
      return null
  }
}
const  on_update_profile = async (params) => { 
  try { 
        const newToken = await UserAPI.refrest_token()
        const accessToken  = newToken?.accessToken

        const data = {...params, access_token: accessToken}
        
        console.log('>> onUpdate Params ', data)

        const result = await put(SERVICE_URL +"v1/users/"+store.getState().user.user?.Id, data, header)
        console.log('>> onUpdate result', result)
        return result?.resultPaypal
  } catch (error) {
      console.error('ERROR onUpdate >>', error)
      return null
  }
}





export default {
    get_vps_types,
    create_vps,
    create_k8s,
    get_user_vps,
    user_create,
    reset_password_request,
    password_reset,
    on_update_profile,
    get_user_profile,
    active_account,
    get_user_souscription,
    update_subcription,
    vps_ssh,
    get_user_payment,
    create_pre_order,
    login_on_captain,
    check_promo_code,
    check_trial_code,
    onUpdate,
    get_custom_domain,
    create_payment,
    rebuildVps,
    rebuildNormlVps,
    reSendEmailVerification,
    getUniqueVps,
    delVpsGroupRule,
    addVpsGroupRule,
    onVpsAction,
    onEnableNetwork,
    getNetwork,
    onPortCreate,
    deleteServer,
    getVpsImages,
    createPaymentMethod
}