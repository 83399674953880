import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Utils from '../../utils'
import { Block } from '../../components/Bloc';
import UserMgt from '../../lib/user_managment';

import blueprint from '../../assets/img/blue/blueprint-KS.png'
import Cyberpanel from '../../assets/img/blue/cyberpanel-logo.png'
// import cyberpanel from '../../assets/img/blue/cyberpanel-logo.png'
import Cpanel from '../../assets/img/blue/cpanel-logo.png'
import Joomla from '../../assets/img/blue/joomla-logo.png'
import LibreNMS from '../../assets/img/blue/LibreNMS-2-logo.png'
import MinecraftJava from '../../assets/img/blue/minecraft-logo.png'
import Nextcloud from '../../assets/img/blue/nextcloud-logo.png'
import Plesk from '../../assets/img/blue/plesk-logo.png'
import Webmin from '../../assets/img/blue/Webmin-logo.png'
import Wordpress from '../../assets/img/blue/wordpress-logo.png'
import Docker from '../../assets/img/blue/docker-logo.png'
import MinecraftBedrock from '../../assets/img/blue/minecraft-bedrock-logo.png'
import Pufferpanel from '../../assets/img/blue/pufferpanel.png'
import Portainer from '../../assets/img/blue/portainer.png'
import Traefik from '../../assets/img/blue/traefik.png'
import OpenVPN from '../../assets/img/blue/openvpn.png'
import MariaDB from '../../assets/img/blue/mariadb.png'
import NginxProxyManager from '../../assets/img/blue/nginxproxymanager.png'
import MongoDB from '../../assets/img/blue/mongodb.png'
import Netmaker from '../../assets/img/blue/Netmaker-logo.png'
import Odoo from '../../assets/img/blue/odoo.png'
import CloudPanel from '../../assets/img/blue/cloudpanel.png'
import Jitsi from '../../assets/img/blue/jitsi.png'
import Gitlab from '../../assets/img/blue/gitlab.png'
import Pritunl from '../../assets/img/blue/pritunl-logo.png'

import { updateHeadLink } from 'reducer/action/coreActions';

const preScripts = {
    Cpanel: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/cpanel-kpbp.sh" -O; sudo bash cpanel-kpbp.sh',
    Webmin: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/webmin-kpbp.sh" -O; sudo bash webmin-kpbp.sh',
    Nextcloud: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/nextcloud-kpbp.sh" -O; sudo bash nextcloud-kpbp.sh',
    MinecraftJava: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/minecraft-java-kpbp.sh" -O; sudo bash minecraft-java-kpbp.sh',
    MinecraftBedrock: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/minecraft-bedrock-kpbp.sh" -O; sudo bash minecraft-bedrock-kpbp.sh',
    Joomla: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/joomla-kpbp.sh" -O; sudo bash joomla-kpbp.sh',
    Wordpress: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/wordpress-kpbp.sh" -O; sudo bash wordpress-kpbp.sh',
    Docker: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/docker-kpbp.sh" -O; sudo bash docker-kpbp.sh',
    Plesk: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/plesk-kpbp.sh" -O; sudo bash plesk-kpbp.sh',
    LibreNMS: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/librenms-kpbp.sh" -O; sudo bash librenms-kpbp.sh',
    Cyberpanel: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/cyberpanel-kpbp.sh" -O; sudo bash cyberpanel-kpbp.sh',
    Pufferpanel: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/pufferpanel-kpbp.sh" -O; sudo bash pufferpanel-kpbp.sh',
    Portainer: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/portainer-kpbp.sh" -O; sudo bash portainer-kpbp.sh',
    NginxProxyManager: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/nginx-proxy-mng-kpbp.sh" -O; sudo bash nginx-proxy-mng-kpbp.sh',
    Traefik: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/traefik-kpbp.sh" -O; sudo bash traefik-kpbp.sh',
    MariaDB: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/mariadb-kpbp.sh" -O; sudo bash mariadb-kpbp.sh',
    MongoDB: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/mongodb-kpbp.sh" -O; sudo bash mongodb-kpbp.sh',
    OpenVPN: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/openvpn-kpbp.sh" -O; sudo bash openvpn-kpbp.sh',
    Netmaker: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/netmaker-kpbp.sh" -O; sudo bash netmaker-kpbp.sh -a',
    Odoo: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/odoo-kpbp.sh" -O; sudo bash odoo-kpbp.sh',
    CloudPanel: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/cloudpanel-kpbp.sh" -O; sudo bash cloudpanel-kpbp.sh',
    Jitsi: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/jitsi-kpbp.sh" -O; sudo bash jitsi-kpbp.sh',
    Gitlab: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/gitlab-kpbp.sh" -O; sudo bash gitlab-kpbp.sh',
    Pritunl: 'clear; curl -ks "https://repo-a1.keepsec.tech/scripts/pritunl-vpn-kpbp.sh" -O; sudo bash pritunl-vpn-kpbp.sh',
}

const imgs = {
    Cyberpanel,
    Cpanel,
    Joomla,
    LibreNMS,
    MinecraftJava,
    Nextcloud,
    Plesk,
    Webmin,
    Wordpress,
    Pufferpanel,
    Docker,
    MinecraftBedrock,
    Portainer,
    NginxProxyManager,
    Traefik,
    MariaDB,
    MongoDB,
    OpenVPN,
    Netmaker,
    Odoo,
    CloudPanel,
    Jitsi,
    Gitlab,
    Pritunl
}


export default function VpsConsole({ props }) {

    const { list } = useSelector(app => app.vps)
    const { id } = useParams()
    const navigate = useNavigate()
    const preScriptToBeExecuted = Utils.getUrlParam('blueprint')

    const [ssh, setSshO] = useState(null)
    const [onRun, setonRun] = useState({})
    const [vps, setvps] = useState()
    const dispatch = useDispatch()
    

    async function loadVps() {
        const vps = await UserMgt.getUniqueVps(id, true)
        if(!vps?.error){
            setvps(vps?.server);
        }else{
            navigate(-1)
        }
    }

    useEffect(() => {
        dispatch(updateHeadLink([{label: "Console"}]))
        loadVps()
        return () => dispatch(updateHeadLink([]))
    }, [])

    const urls = [
        "https://console-a.canada-a1.keepsec.tech/",
        "https://console-b.canada-a1.keepsec.tech/",
        "https://console-c.canada-a1.keepsec.tech/",
        "https://console-d.canada-a1.keepsec.tech/"
    ]

    return (
        <>

            <div className="px-3 md:px-8 ">
                <div className="container mx-auto max-w-full">
                    <div className="flex">
                        {preScriptToBeExecuted &&
                            <div>
                                <img src={blueprint} alt="" className='w-20 ' />
                                <div className="h-5" />
                                <img src={imgs[preScriptToBeExecuted]} alt="" className='w-20' />
                            </div>

                        }
                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-1 px-4 mb-16 gap-4 items-center w-full">
                            {vps &&
                                <iframe className="shadow-2xl bg-[black] shadow-zinc-900 dark:shadow-xl  dark:shadow-slate-700 w-full " 
                                    src={urls[Math.floor(Math.random() * 3)] + "?access_token=" + (vps?.fernetToken || vps?.token?.Token) + (preScriptToBeExecuted ? "&command=" + preScripts[preScriptToBeExecuted] : "")} 
                                    height="700" 
                                    border="frameno" 
                                    style={{ borderRadius: "20px", border: "20px solid black" }}
                                >
                                </iframe>

                            }
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}






