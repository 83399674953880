import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Button, Modal } from 'antd';
import { useEffect } from 'react';

import Utils from '../utils'

const clientId = Utils.PAYPAL_CLIENT_ID;

export default function Paypal({open, label, onDismiss, createPaymentAndDeploy, vpsType, finalPrice, withPromo, withFirstTrial, shouldDispatch}){

    return(
        <PayPalScriptProvider
            options={{
                "client-id": clientId,
                components: "buttons",
                intent: "subscription",
                vault: true,
            }}
        >
                <PaypalModal 
                    createPaymentAndDeploy={createPaymentAndDeploy} 
                    open={open}
                    plan_id={withFirstTrial? vpsType?.PayPalPackIdFree : vpsType?.PaypalPackId} 
                    finalPrice={finalPrice}
                    promo={withPromo} 
                    vpsType={vpsType} 
                    label={label}
                    onDismiss={onDismiss} 
                /> 
        </PayPalScriptProvider>
    )
}

function PaypalModal({onDismiss, label, plan_id, createPaymentAndDeploy, finalPrice, promo, vpsType, open}){
    const [{ options }, dispatch] = usePayPalScriptReducer();

    let create = {plan_id: plan_id}
    if(promo){
        create = {
            ...create,
            "plan": {
                "billing_cycles": [
                    {
                        "sequence": 1,
                        "total_cycles": 0,
                        "pricing_scheme": {
                            "fixed_price": {
                                "value": "" +finalPrice(),
                                "currency_code": "CAD"
                            }
                        }
                    }
                ]
            },
        }
    }
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [vpsType]);

    return(

        <Modal
            destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            open={open}
        >
            <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            {vpsType?.Name ? vpsType?.Name+ "  Plan Subscription" : label}
                        </h4>
                        <p className='text-sm xl:text-base'>
                            {/*Pick new nodes. */}
                        </p>
                    </div>
                    
                </div>
                <div className="p-6">
                    <PayPalButtons
                        createSubscription={(data, actions) => {
                            return actions.subscription
                                .create({...create}) 
                                .then((orderId) => {
                                    // Your code here after create the order
                                    return orderId;
                                });
                        }}

                        onCancel={() => {
                            onDismiss()
                        }}
                        onError={(err) => {
                            console.log('error paypal ***', err)
                            onDismiss()
                        }}
                        onApprove={(data, actions) => {
                             try{
                                const paymentID = data.subscriptionID //|| data.orderID
                                console.log("after decalre payment")
                                if(!data.subscriptionID){
                                    return onDismiss()
                                }
                                const dataPayment = {
                                    status: "success",
                                    details: JSON.stringify(data),
                                    subscriptionID: data.subscriptionID,  
                                    paypalTransactionId: data.orderID //|| data.orderID

                                }
                                
                                return createPaymentAndDeploy(paymentID, dataPayment)

                            }catch(e){
                                console.log('error onApprove paypal', e)
                                return onDismiss()
                            }
                        }}
                        style={{
                            label: "subscribe",
                            shape: 'rect',
                            color: 'white',
                             layout: 'vertical',
                        }}
                    />
                </div>

                <div className="py-5 flex justify-end  px-6">
                    <Button size="large" onClick={onDismiss}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>   

    )
}
