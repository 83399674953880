export default{
    vpc: {
      h1: "Réseaux VPC",
      createBtn: "Créer un réseau VPC",
      description: "Les ressources attribuées au même réseau VPC peuvent communiquer de manière sécurisée entre elles via une IP privée. Pour communiquer avec les ressources en dehors du VPC, vous devez utiliser une IP réseau.",
      howToCreateVpc: "Comment utiliser un réseau VPC",
      notHavingVpc: "Il semble que vous n'ayez aucun réseau",
      createFirstBtn: "Créer un premier réseau VPC",
      ressourceInOption: "Ressources",
      deleteInOption: "Supprimer",
      tagDefault: "Par défaut",
      noRessource: "Aucune ressource",
      ressources: "Ressources",
      manage: "Gérer",
      vpcLinkAlt: "Lien court VPC",
      overview: {
        name: "Vue d'ensemble",
        title: "Vue d'ensemble",
        description: "DESCRIPTION",
        defaultDescription: "Par défaut",
        descriptionText: "Ceci est défini comme VPC par défaut pour la région {{locationName}}",
        netDetails: "DÉTAILS DU RÉSEAU",
        ipRange: "Plage d'IP",
        availAddr: "Adresse disponible",
        ress: "Ressources",
        vpcNetRess: "RESSOURCES DU RÉSEAU VPC",
        docs: "Docs",
        apiDoc: "API Docs",
        tuto: "Tutoriel",
        _____: "______",
        _____: "______"
      },
      ressources: {
        name: "Ressources",
        title: "Ressources",
        addServiceBtn: "Ajouter un service",
        noRessource: "Vous n'avez aucune ressource dans ce réseau",
        allCommunication: "Toute communication entre ces ressources via une IP privée est sécurisée.",
        traffic: "Le trafic vers les IP publiques doit être sécurisé avec un",
        firewall: "Pare-feu",
        services: "SERVICES",
        addServiceModal: {
          title: "Ajouter un nouveau service dans la zone",
          subtitle: "Sélectionnez une nouvelle instance à ajouter.",
          selectlabel: "Sélectionnez une nouvelle instance ici",
          placeholder: "Sélectionnez un service",
          addServicBtn: "Ajouter un service",
          cancel: "Annuler",
          _____: "______"
        },
        collumName: "Nom",
        publicIp: "IP publique",
        notDefined: "(Non défini)",
        privateIp: "IP privée",
        deleteServiceInZoneTooltip: "Supprimer {{displayName}} dans cette zone",
        _____: "______",
        _____: "______"
      },
      settings: {
        name: "Paramètres",
      },
      delete: {
        name: "Supprimer",
      },
      _____: "______",
      _____: "______",
      _____: "______",
      _____: "______",
      _____: "______"
    }
  }