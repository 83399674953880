import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { KHKS_MANAGE_LINK } from 'constants/link';
import { CardServiceLayout, MiniCard, ServiceOptions } from 'layouts/ServiceLayout'

import k8sImage from '../../assets/img/k8/kubernetes-kp.webp'
import Utils from '../../utils'
import { OkButton } from '../OkButton';

export function K8sCard({k8s}){
	const navigate = useNavigate()
    const { t } = useTranslation();
    const masterCluster = k8s?.clusters?.find(cluster => cluster?.isPrimaryInstance)
    let totalNode = [];
    for (var i = 0; i < k8s?.clusters?.length; i++) {
        const instances = k8s?.clusters[i]?.instances
        totalNode = totalNode.concat(instances)
    }
	const nodeMaster = masterCluster && masterCluster.instances?.find(inst => !!inst.isKubeMaster && !!inst.isPrimarykubeMaster)

	const totalCPUArr = totalNode && totalNode?.map(inst => inst?.type?.Cpus).reduce((a, b) => a + b, 0)
	const totalRAMArr = totalNode && totalNode?.map(inst => inst?.type?.RamGb).reduce((a, b) => a + b, 0)
	const totalSSDArr = totalNode && totalNode?.map(inst => inst?.type?.DiskGb).reduce((a, b) => a + b, 0)
	
    const is = (state) => nodeMaster?.VpsStatus === state
    

	const onManage = async() => {
    	return navigate(KHKS_MANAGE_LINK(k8s.id)) 
    }



    return (
        <CardServiceLayout grayScale={!k8s?.is_deployed} server={k8s}>
            <div>
                <div className='border-b-2 dark:border-gray-800 pb-4 2xl:pb-8 pt-3'>
                    <div className='flex justify-between items-start'>
                        <div className='flex 2xl:gap-4 gap-2'>
                            <img src={k8sImage} alt="" className='w-14 h-14' />
                            <div>
                                <span className='2xl:text-xl text-base font-semibold text-primary dark:text-darkTextColor'>
                                	{masterCluster?.name}
                                </span> <br/>
                                <span className="2xl:text-base text-xs">{totalCPUArr+"CPU-"+totalRAMArr+"GB-"+totalSSDArr+"GB"}</span><br/>
                            </div> 
                        </div>
                        <div className='flex justify-end '>
                            <OkButton click fun={onManage} title={t("service.manage")} disabled={!k8s?.is_deployed}  server={nodeMaster} loanding={false}/>
                            &nbsp;&nbsp;&nbsp;
                            <ServiceOptions
                                options={[
                                    {label: t("service.downConfig"), cunstomFun: () => !!k8s?.is_deployed && Utils.createK8SFile(k8s), display: !true && "none"},
                                    {label: t("service.manage"), link: KHKS_MANAGE_LINK(k8s.id), display: !k8s?.is_deployed  && "none"},
                                    {label: t("service.viewNodes"), link: KHKS_MANAGE_LINK(k8s.id)+'?act=nodes', display: !k8s?.is_deployed  && "none"},
                                    {label: t("service.delete"), case: "onDelete", display: is('Deploying') && "none"},
                                ]}
                                server={k8s}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex justify-between pt-2 2xl:pt-4 text-sm 2xl:text-base'>
                    <div className=''>
                        <span className='font-bold text-primary'>
                        {k8s?.is_deployed && nodeMaster?.VpsStatus === "up" ? t("service.running") : nodeMaster?.VpsStatus === "down" ? t("service.stopped") : t(nodeMaster?.VpsStatus)}
                        </span>
                    </div>
                    <div className=''>
                        <p className='text-right'>
                            <span className="font-bold text-primary">{t("service.ha")}</span> <br/>
                            <span>{k8s?.isHA ? t("service.enabled") : t("service.notEnabled")}</span> <br/>
                            <span>{nodeMaster?.location?.name || "Montreal, Zone A"}</span>  
                        </p>
                    </div>
                </div>
            </div>
        </CardServiceLayout>
    )

}



export function MiniCarK8s({k8s}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const masterCluster = k8s?.clusters?.find(cluster => cluster?.isPrimaryInstance)
    let totalNode = [];
    for (var i = 0; i < k8s?.clusters?.length; i++) {
        const instances = k8s?.clusters[i]?.instances
        totalNode = totalNode.concat(instances)
    }
	const nodeMaster = masterCluster && masterCluster.instances?.find(inst => !!inst.isKubeMaster && !!inst.isPrimarykubeMaster)

	// const totalCPUArr = totalNode && totalNode?.map(inst => inst?.type?.Cpus).reduce((a, b) => a + b, 0)
	// const totalRAMArr = totalNode && totalNode?.map(inst => inst?.type?.RamGb).reduce((a, b) => a + b, 0)
	// const totalSSDArr = totalNode && totalNode?.map(inst => inst?.type?.DiskGb).reduce((a, b) => a + b, 0)
	
    const is = (state) => nodeMaster?.VpsStatus === state
    

    return(
        <MiniCard 
            server={k8s} 
            grayScale={!k8s?.is_deployed} 
            is={is}
            imgSrc={k8sImage}
            firstText={masterCluster?.name}
            IpOrLocationText={
                <span>
                    {t("service.ha2")}
                    &nbsp; 
                    {k8s?.isHA ?
                        <CheckCircleFilled className="text-primary" />
                        :
                        <CloseCircleFilled className="text-gray" />
                    }
                </span>
            }
            options={[
                {label: t("service.downConfig"), cunstomFun: () => !!k8s?.is_deployed && Utils.createK8SFile(k8s), display: !true && "none"},
                {label: t("service.manage"), link: KHKS_MANAGE_LINK(k8s.id), display: !k8s?.is_deployed  && "none"},
                {label: t("service.viewNodes"), link: KHKS_MANAGE_LINK(k8s.id)+'?act=nodes', display: !k8s?.is_deployed  && "none"},
                {label: t("service.delete"), case: "onDelete", display: is('Deploying') && "none"},
            ]}
            withInternalLink
            functionLink={() => navigate(KHKS_MANAGE_LINK(k8s.id))}
            tooltipText={!!k8s?.is_deployed ? t("service.goToKhksManagePage") :" "}
        />
    )
}



