import { CheckSVG } from "components/Bloc";

export function PackLayout({className, children, objectProps, pack, kubeNodeAddComponenent, isVpsInstance}){
    return(
        <div className={`
                ${!objectProps.noMxauto && 'mx-auto'} 
                mt-5 
                relative
                ${className} 
            `}
        >
            <div 
                onMouseOver={
                    () => objectProps.setSelected(pack.Id)
                }
                onClick={() => {
                    if (!objectProps.creating) {
                        if(objectProps.clicked == pack.Id){
                            objectProps.setClicked(null);
                            objectProps.onClaculFlavor(null)
                        }else{
                            objectProps.setClicked(pack.Id);
                            objectProps.setPackPaypal(pack.Name);
                            objectProps.onClaculFlavor(pack)
                        }
                        
                    }    
                }}
                className={`
                    w-48 
                    xl:w-52 
                    2xl:w-64 
                    cursor-pointer 
                    rounded-xl 
                    dark:bg-bgPagedark  
                    ${(objectProps.selected == pack.Id) && "hover:bg-white  hover:shadow-2xl "} 
                    ${(objectProps.clicked == pack.Id) ? "bg-white shadow-2xl" : 'bg-neutral-100 dark:hover:shadow-slate-700'}
                `}
            >

                {(objectProps.clicked == pack.Id) &&
                    <CheckSVG />
                }
                {children}
            </div>
            {isVpsInstance && kubeNodeAddComponenent()}
        </div>
    )
}