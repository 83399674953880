import React, { useState, useEffect }  from 'react'
import { Table, Tag, Space, Button, Tooltip,  Modal, Tabs, Alert, Popconfirm, Dropdown  } from 'antd';
import { PlusOutlined, QuestionCircleOutlined, LockOutlined } from '@ant-design/icons';
import { DownOutlined, SmileOutlined, SettingOutlined, DeleteOutlined, EditOutlined, PoweroffOutlined   } from '@ant-design/icons';
import { Details } from './section/RedirectHostSection'
import { SSL, Advanced } from './section/HostSection'
import KSPROXY from 'lib/proxy'
import Utils from '../../utils'
import { MdMoreVert } from "react-icons/md"

// SSL, Advanced
export function RedirectionHosts(props){
	const col1 = [
        {
        title: 'Source',
        dataIndex: 'source',
        render: (_, obj) => <div> 
          {obj.domain_names.map(domain => <Tag color="#011a43">{domain}</Tag>)}
          <br/> 
          <span>{Utils.formatDate(obj?.created_on, 'Do MMMM YYYY, h:mm a')}</span>
        </div>,
      },
      {
        title: 'Http Code',
        dataIndex: 'code',
        key: 'code',
        render: (_, obj) => <div> 
           <span className="font-semibold">{obj.forward_http_code}</span>
        </div>,
        // width: 200,
        // render: (text) => <div className="w-[200px]"> <a target="_blank" href={text}>{text}</a></div>,
      },
      {
        title: 'Scheme',
        dataIndex: 'scheme', 
        render: (_, obj) => <div> 
           <span className="font-semibold">
            {
              obj.forward_scheme === "$scheme" ?
              "auto"
              :
              obj.forward_scheme
            }
          </span>
        </div>,
      },
      {
        title: 'Destination',
        render: (_, obj) => <div> 
           <span className="font-semibold">
            {
              obj.forward_domain_name
            }
          </span>
        </div>,
        dataIndex: 'destination',
      },
      {
        title: 'SSL',
        dataIndex: 'ssl',
        // key: 'ssl',
        render: (_, obj) => <div> 
            <span>
              {
                obj.forward_scheme === "$scheme" ?
                "HTTP "
                :
                obj.forward_scheme.toUpperCase()
              } 
            only</span>
          </div>,
      },
      {
        title: 'Status',
        render: (_, obj) => <div> 
            <span className="flex gap-1 items-center">
              {obj.enabled ? 
                <div className="w-2 h-2 rounded-full bg-[#15803d]" /> : 
                <div className="w-2 h-2 rounded-full bg-[#b91c1c]" />
              }
              {obj.enabled ? "Online" : "Disabled"}
            </span>
          </div>,
        dataIndex: 'status',
      },
      {
        title: '',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Dropdown 
              menu={{
                items: [
                  {
                    label: 'Edit',
                    key: '0',
                    onClick: () => setOngoingToEdit(record),
                    icon: <EditOutlined />,
                  },
                  {
                    label: record.enabled ? 'Disable' : "Enable",
                    key: '3',
                    onClick: () => enableDisable(record, record.enabled ? 'disable' : "enable"),
                    icon: <PoweroffOutlined />,
                  },
                  {
                    label: (<Popconfirm
                        title={"Confirm deletion "}
                        description={"Really delete access list named "+record.name}
                        onConfirm={() => confirmDelete(record)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        Delete {record.name}
                      </Popconfirm>
                    ),
                    key: '5',
                    danger: true,
                    icon: <DeleteOutlined />,
                  }
                ]
              }}
            >                
              <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
            </Dropdown>
          </Space>
        ),
      },
    ];
 
  const [open, setOpen] = useState(false) 
  const [loading, setload] = useState(false) 
  const [onGoingEdit, setOngoingToEdit] = useState(false) 

  const confirmDelete = async (host) => {
    const result = await KSPROXY.createRedirectHost(props.server?.Id, {Odelete: true, id: host?.id})
    console.log('result result result', result)
    if(result && !result.error){

      props.messageApi.success(result?.message ||'Redirection Host deleted !');
      getRedirectHost()
    }else{
      props.messageApi.error('An error occurs, please try again later.');

    }
  }
  const enableDisable = async (host, state) => {
    const result = await KSPROXY.createRedirectHost(props.server?.Id, {[state]: true, id: host?.id})
    console.log('result result result', result)
    if(result && !result.error){

      props.messageApi.success('Done !');
      getRedirectHost()
    }else{
      props.messageApi.error(result?.message ||'An error occurs, please try again later.');

    }
  }
  async function getRedirectHost(){
    setload(true)
    try{
      const result = await KSPROXY.getRedirectHost(props?.server?.Id)
      console.log('result result result', result)
     result && props.setRedirectHost(Object.values(result).filter( arr => typeof arr === "object"))
      !result && props.setRedirectHost([])
    }catch(e){
      console.log('Cant get proxy host ', e)
    }
    setload(false)
  }
  useEffect(() => { 
    getRedirectHost()
  }, [])

  //createRedirectHost
  //getRedirectHost
	return(
		<div className="w-full">
      <NewRedirectionHost 
        open={open || onGoingEdit} 
        setOpen={setOpen}
        server={props?.server}
        getRedirectHost={getRedirectHost}
        redirectHost={onGoingEdit} 
        setOngoingToEdit={setOngoingToEdit} 
      />
			<div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">Redirection Hosts</h5>
				<div className="flex justify-end items-center gap-4">
					<Tooltip 
						overlayStyle={{ lineBreak: 'pre-line' }} 
						title={() =>  <div>
								<p className="font-bold">What is a Redirection Host?</p>
								<p className="mt-2">A Redirection Host will redirect requests from the incoming domain and push the viewer to another domain.</p> 
								<p className="mt-2">The most common reason to use this type of host is when your website changes domains but you still have search engine or referrer links pointing to the old domain.</p>
						    </div>
						}>
						<QuestionCircleOutlined className="xl:text-2xl text-xl cursor-pointer text-primary"/>
					  </Tooltip>

					<Button type="primary" onClick={()=> setOpen(true)} icon={<PlusOutlined />}>
			            Redirection Host
			        </Button>
				</div>
			</div>

			<Table  
            columns={col1} 
            dataSource={props.redirectHosts} 
            loading={!props.redirectHosts || loading}
        />
		</div>
	)
}


function NewRedirectionHost(props){
  const { redirectHost } = props
  const [objectHost, setObjectHost] = useState({ 
      forward_scheme: "$scheme",
      block_exploits: false,
      preserve_path: false
  })
  const [objectSSL, setObjectSSL] = useState({
    hsts_enabled: false,
    hsts_subdomains: false,
    certificate_id: 0,
    http2_support: false,
    ssl_forced: false,
    meta: {
      "letsencrypt_agree": false,
      "dns_challenge": false
    }
  })
  const [locations, setLoactions] = useState([])
  const [nginxConfig, setNginCOnfig] = useState("")
  const [creation, setCreation] = useState(false)
  const [eMessage, setErrorMessage] = useState(null)

  useEffect(() => {
      if(redirectHost){
        setObjectHost({
          ...redirectHost,
          domain_names: redirectHost?.domain_names?.map(d =>{
            return{
              value: d,
              label: d
            }
          })
        })
        setNginCOnfig(redirectHost?.advanced_config)
        setObjectSSL({...redirectHost, ...redirectHost.meta})
        //forward_http_code
        // const { locations } = redirectHost
        // const locs = redirectHost?.locations?.map(loc => {
        //   return {
        //     id: Utils.generateId(), 
        //     ...loc
        //   }
        // })
        // locs && setLoactions(locs)
      }
  }, [redirectHost])

  async function onCreate(){
    const ssl = objectSSL
      // const dnsChallenge = ssl?.dns_challenge
    const meta = {
      dns_challenge: ssl?.dns_challenge,
      dns_provider: ssl?.dns_provider,
      dns_provider_credentials: ssl?.dns_provider_credentials,
      letsencrypt_email: ssl?.letsencrypt_email,
      letsencrypt_agree: ssl?.letsencrypt_agree,
    }

    const data = {
      ...objectHost,
      ...ssl,
      meta: meta,
      advanced_config: nginxConfig,
      domain_names: objectHost?.domain_names?.map(d => d.value)
    }
      // delete data.access_list
      // delete data.meta
     delete data.dns_challenge
    delete data.dns_provider
    delete data.dns_provider_credentials
    delete data.letsencrypt_email
    delete data.propagation_seconds
    delete data.letsencrypt_agree

    delete data.owner
    delete data.owner_user_id
    delete data.enabled
    delete data.modified_on
    delete data.created_on
    delete data.certificate
    delete data.nginx_err
    delete data.nginx_online

    try{
      if(!data?.domain_names?.length){
          return setErrorMessage("Domain names field is required.")
        }
        // if(!data?.forward_host){
        //   return setErrorMessage("Scheme  field is required.")
        // }
        if(!data?.forward_domain_name){
          return setErrorMessage("Forward Domain field is required.")
        }
        if(!data?.forward_http_code){
          return setErrorMessage("HTTP Code field is required.")
        }
        if(data?.certificate_id === "new" &&  !data?.meta?.letsencrypt_email){
          return setErrorMessage("Please fill out letsencrypt email field before.")
        }
        if(data?.certificate_id === "new" &&  data?.meta?.dns_challenge && !data?.meta?.dns_provider){
          return setErrorMessage("Please fill out DNS provider field before.")
        }
        if(data?.certificate_id === "new" &&  data?.meta?.dns_challenge && !data?.meta?.dns_provider_credentials){
          return setErrorMessage("Please fill out DNS Credentials field before.")
        }
        if(data?.certificate_id === "new" &&  !data?.meta?.letsencrypt_agree){
          return setErrorMessage("Please accept letsencrypt terms before.")
        }

      setCreation(true)
      setErrorMessage(false)
      const result = await KSPROXY.createRedirectHost(props.server?.Id, data)
      setCreation(false)
      console.log('RESULY HOST CREAT ', result)
      if(result?.error){
        return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
      }
      return Utils.sleep(4000).then(() => {
                  onReset()
                  props.getRedirectHost()
              });
    }catch(e){
      console.log('ON CREATE PROXY HOST ERROR ', e)
    }
  }
  function onReset(){
    setObjectHost({forward_scheme: "$scheme"})
    setObjectSSL({})
    setLoactions([])
    setNginCOnfig("")
    props.setOpen(false)
    props.setOngoingToEdit(null)
  }
  const arrayTab = [
      { 
        label: "Details",
        component: <Details objectHost={objectHost} setObjectHost={setObjectHost}/>
      },
      {
        label: "SSL",
        component: <SSL objectSSL={objectSSL} setObjectSSL={setObjectSSL}/>
      },
      {
        label: "Advanced",
        component:  <Advanced nginxConfig={nginxConfig} setNginCOnfig={setNginCOnfig}/>
      },
  ]
  return(
    <Modal
      destroyOnClose={true}
      // centered
      footer={null}
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={700}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
              New Redirection host 
          </h4>
        </div>
        <div className='px-6 mt-3' style={{display: !eMessage && "none"}}>
          <Alert  message={eMessage } type="error" />         
        </div>
        <div className='px-6 py-3'>
          <Tabs defaultActiveKey="1" 
            // onChange={onChange} 
            items={arrayTab.map((_, i) => {
              const id = String(i);
              return {
                label: _.label,
                key: i,
                children: _.component,
              };
            })} 
          />
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                 onReset()
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              loading={creation}
               onClick={() =>onCreate()}
              // loading={action}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}