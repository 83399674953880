export const LIST_VULLIFY_SERVERS = "@@VULLIFY/LIST_VULLIFY_SERVERS"


// export function caprover_token_save(data){
//     return {
//         type: SAVE_CAPROVER_TOKEN,
//         data : data, 
//       }
// }
// export function caprover_save_onclick_app(data){
//     return {
//         type: SAVE_CAPROVER_ONE_CLICK_APP,
//         data : data, 
//       }
// }

// export function change_deploy_state(data){
//     return {
//         type: CHANGE_DEPLOY_STATE,
//         data : data, 
//       }
// }
export function list_vullify_servers(data){
    return {
        type: LIST_VULLIFY_SERVERS,
        list : data, 
      }
}
