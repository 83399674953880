import { 
  SAVE_USER_INFO, 
  USER_SOUSCRIPTION, 
  USER_PAYMENT, 
  REFRESH_TOKEN, 
  UPDATE_USER_PROFILE,
  CAN_NAVIGATE_2FA
} from "../action/userAction";

const defaultState = {
  subscriptions: [],
  payments: []
};
  
  
  export default function user(state = defaultState, action) {
    switch (action.type) {
      case 'LOANDING':
        return { ...state, loading: action.stop? false : !state.loading };
      case SAVE_USER_INFO:
        return { ...state, ...action.data };
      case UPDATE_USER_PROFILE:
        const userOld = {user: action.data}
        console.log('userOld userOld', userOld, state)
        return { ...state, ...userOld };

      case USER_SOUSCRIPTION:
        return { ...state, subscriptions: action.data };
      case REFRESH_TOKEN:
        return { ...state };
      case USER_PAYMENT:
        return { ...state, payments: action.data };
      case CAN_NAVIGATE_2FA:
        return { ...state, canNavigate2fa: action.state };
   
      default:
        return {...state};
    }
  }
  