import React from "react"
import { FaTicketAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import TicketAPI from "../../../lib/hesk_desk"

export default function Ticket() {
    const [isVisible, setIsVisible] = useState(false)

    async function onGetTicket(){
        try {
            const resulls = await TicketAPI.get_ticket()
            console.log('resulls resulls resullsticket', resulls)
        } catch (error) {
          console.log('error geting tickets', error)  
        }
    }


    useEffect(()=>{
        //UserMgt.get_user_payment() 
    }, [])
    useEffect(() => {
        onGetTicket()
    }, [])

    return (
        <div className='pt-36 px-10'>

            <div className="px-3 md:px-8 h-screen overflow-y-auto dark:text-darkTextColor pb-32 -mt-24">
                <div className="container mx-auto max-w-full pt-5">
                    <Link to="/new-ticket">
                        <button
                            //onClick={toogleChat}
                            className="font-bold gap-2 items-center flex  px-4 py- dark:text-darkTextColor  text-sm text-gray-700 transition duration-150 ease-in-out  bg-transparent  border dark:border-[3px] border-primary rounded focus:outline-none hover:text-white  hover:bg-primary hover:border-primary sm:px-8 sm:py-3"
                        >
                            <FaTicketAlt />
                            {" New Ticket"}
                        </button>

                       
                    </Link>
                    
                        
                    <div>
                    </div>
                </div>
            </div>
           {/* <div className={'absolute bottom-0 w-screen left-0 support-back bg-no-repeat md:bg-repeat'} style={{ height: "400px", zIndex: -1 }}>
            </div>*/}
        </div>
    );
}
