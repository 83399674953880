import { LinkOutlined } from "@ant-design/icons"
import { Button, Modal } from 'antd'
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AiFillSetting } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { updateHeadLink } from "reducer/action/coreActions";

import { AddButton } from "../../components/AddButton"
import CAPTAIN from '../../lib/libCaprover';

export default function WebApp() {
    const { server_id } = useParams()
    const [appDefs, setAppDef] = useState([])
    const [rootDomain, setRootDomain] = useState("")
    const [modalDisplayed, setShowModal] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t }  = useTranslation()

    async function getAllApps2(){
        const result = await CAPTAIN.getAllApps2()
        if(!result ||  !result.data) {
            return navigate('/')
        }

        const appDefs = result?.data?.appDefinitions.map(app =>{
            let versionFound = app.versions.filter(
                (v) => v.version === app.deployedVersion
            )

            let lastDeployTime = ''

            if (versionFound.length === 0) {
                console.log(
                    `App ${app.appName} has invalid deployVersion=${
                        app.deployedVersion
                    }, versions:${JSON.stringify(app.versions)}`
                )
            } else {
                lastDeployTime = versionFound[0].timeStamp || ''
            }

            return { ...app, lastDeployTime }
        })
        console.log('appDefs appDefs appDefs', appDefs)
        setAppDef(appDefs)
        setRootDomain(result?.data?.rootDomain)
    }
    const headLinks = [
        {
            item: 'kps',
            link: '/app-platform/kps',
            alt: "KPS short link"
        },
        {
            label: t("platform.app.titlePage"),
            second: true
        }
    ]
    useEffect(()=>{
        dispatch(updateHeadLink(headLinks))
        getAllApps2() 
        return () =>  dispatch(updateHeadLink([]))
    }, [])

    const onShowModal = () => {
        setShowModal(true)
    }
       
    return (
        <div className="dark:bg-black   pb-5">
            <CreatAppModal open={modalDisplayed} setShowModal={setShowModal} getAllApps2={()=>getAllApps2()} />
 
            <div className="px-3 md:px-5  pb-10">
                <div className="container mx-auto max-w-full"> 
                    <div className="flex flex-wrap gap-3 px-4 mb-16  items-start">

                        {appDefs && appDefs.sort((a, b)=> new Date(b.lastDeployTime).getTime() - new Date(a.lastDeployTime).getTime()).map((app, i) =>  <AppCard navigate={navigate} server_id={server_id} rootDomain={rootDomain} key={i} app={app} />)}
                       
                       
                        <div>
                            <AddButton click fun={onShowModal} title={t("platform.app.customAppBtn")} duck /> 
                            <div className="h-1"/>
                            <AddButton link={"/app-platform/kps/"+server_id+"/add"} title={t("platform.app.newOneClickAppBtn")} duck /> 
                        </div>

                        


                    </div>
                </div>
            </div>
        </div>
    );
}


export function CreatAppModal(props){
    const [loanding, setloading] = useState(false)
    const [appName, setAppName] = useState("")
    const [hasPersistency, setHasPersistency] = useState(false) 
    const {t} = useTranslation();
    const {messageApi} = useSelector(app => app.core)
    function onCancel(){
        return props.setShowModal(false)
    }

    const registerNewApp = async () => { 
        setloading(true)
        try{
            const result = await CAPTAIN.registerNewApp(
                appName,
                hasPersistency,
                true
            )
            if(result && result.description){
                messageApi.success(result.description)
                setAppName("")
                setHasPersistency(false)
                props.getAllApps2()
                onCancel()
            }else{
                messageApi.error("core.constants.STANDARD_ERROR_MESSAGE")
            }
            console.log('result register new app', result)
        }catch(e){
            messageApi.error("core.constants.STANDARD_ERROR_MESSAGE")
        }
        setloading(false) 
    }
 
    return(

        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={450}
	        open={props?.open}
	    >
            <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                        {t("platform.app.customAppModal.title")}
                        </h4>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6 py-10">						
                            <input className=" dark:bg-black dark:border-black dark:text-darkTextColor
                                text-gray-700 w-full shadow border rounded border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3" 
                                type="text" 
                                value={appName}
                                required
                                placeholder={t("platform.app.customAppModal.myAmazingApp")}
                                onChange={(e) =>setAppName(e.target.value)}
                            />

                            <div className="form-check flex">
                                <input 
                                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white dark:bg-black checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                    type="checkbox" 
                                    value="" 
                                    id="appCheck" 
                                    checked={hasPersistency}
                                    onChange={(e)=>{
                                        console.log('erre',e)
                                        setHasPersistency(e.target.checked)
                                    }}
                                 />
                                <label className="form-check-label " htmlFor="appCheck">
                                    {t("platform.app.customAppModal.hasPersistantData")}
                               </label> 
                            </div>
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => onCancel()}>
                        {t("platform.app.customAppModal.cancel")}
	                    </Button>
                        
                        <Button 
                            loading={loanding} 
                            size="large" 
                            onClick={registerNewApp} 
                            type="primary"
                            style={{color: "white"}}
	                    >
	                        {t("platform.app.customAppModal.create")}
	                    </Button>
            	</div>
            </div>

        </Modal>
    )
}

function AppCard({app, rootDomain, server_id, navigate}) {
    const link = app.envVars.find(v => v.key === "APP_BASE_URL")
    const {t} = useTranslation();
    function onConfig(){
        //navigate('/kps/deploy/'+server_id+'/'+app.appName+'/config')
    }


    return (
        <div className="py-6 px-3 min-w-[230px]  bg-grey block  rounded-lg shadow-lg   dark:bg-bgPagedark dark:text-darkTextColor">
            <div className='flex justify-center'>
                <a 
                    href={!app.notExposeAsWebApp ?
                        `http${app?.hasDefaultSubDomainSsl ? 's' : ''}://${app?.appName}.${rootDomain}`
                        :
                        "javascript:;"
                    }   
                    {...{target: !app.notExposeAsWebApp ? "_blank" : ""}} 
                    className="font-bold text-center text-sm xl:text-base 2xl:text-xl" 
                > 
                    <span className="text-primary text-center inline dark:text-darkTextColor ">
                        {app.appName}&nbsp;
                        {!app.notExposeAsWebApp ?
                            <LinkOutlined />
                            :
                            null
                        }
                    </span>
                </a>
            </div>
            <div className="text-center">
                <img src={require('../../assets/img/blue/KS-logo-round.png')} className="w-10 2xl:w-14 mx-auto" alt=""/>
                
                <Link  to={'/app-platform/kps/'+server_id+'/'+app.appName+'/config'}>
                    <Button 
                        className="mx-auto mt-3 px-4 flex justify-center items-center  rounded-xl" 
                        type="primary"
                    >
                        <AiFillSetting className='text-xl' /> &nbsp;
                         {t("platform.app.goToCofigPageBtn")}
                    </Button>

                </Link>

            </div>
        </div>
    )

}

