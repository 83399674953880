import React, { useState, useEffect } from 'react'
import { Table, Tag, Space, Button, Tooltip, Popconfirm, Dropdown   } from 'antd';
import { PlusOutlined, QuestionCircleOutlined, LockOutlined } from '@ant-design/icons';
import { InputNumber, Switch, Input, Modal, Alert } from 'antd';
import { DownOutlined, SmileOutlined, SettingOutlined, DeleteOutlined, EditOutlined, PoweroffOutlined   } from '@ant-design/icons';
import { MdMoreVert } from "react-icons/md"
import KSPROXY from 'lib/proxy'
import Utils from '../../utils'

export function Streams(props){
	const col1 = [
        {
        title: 'Incoming port',
        dataIndex: 'port',
        render: (_, obj) => <div> 
          {obj.incoming_port}
          <br/> 
          <span>{Utils.formatDate(obj?.created_on, 'Do MMMM YYYY, h:mm a')}</span>
        </div>,
      },
      {
        title: 'Protocols',
        dataIndex: 'destination',
        render: (_, obj) => <div> 
          <span>{obj.forwarding_host+":"+obj.forwarding_port}</span>
        </div>,
        // width: 200,
        // render: (text) => <div className="w-[200px]"> <a target="_blank" href={text}>{text}</a></div>,
      },
      {
        title: 'Protocol',
        dataIndex: 'protocol',
        render: (_, obj) => <div> 
          {obj.tcp_forwarding ? <Tag color="#011a43">{"TCP"}</Tag> : null}
          {obj.udp_forwarding ? <Tag color="#011a43">{"UDP"}</Tag> : null}
        </div>,
      },
      {
        title: 'Status',
        render: (_, obj) => <div> 
          <span className="flex gap-1 items-center">
            {obj.enabled ? 
              <div className="w-2 h-2 rounded-full bg-[#15803d]" /> : 
              <div className="w-2 h-2 rounded-full bg-[#b91c1c]" />
            }
            {obj.enabled ? "Online" : "Disabled"}
          </span>
        </div>,
        dataIndex: 'status',
      },
      {
        title: 'Actions',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Dropdown 
              menu={{
                items: [
                  {
                    label: 'Edit',
                    key: '0',
                    onClick: () => setOngoingToEdit(record),
                    icon: <EditOutlined />,
                  },
                  {
                    label: record.enabled ? 'Disable' : "Enable",
                    key: '3',
                    onClick: () => enableDisable(record, record.enabled ? 'disable' : "enable"),
                    icon: <PoweroffOutlined />,
                  },
                  {
                    label: (<Popconfirm
                        title={"Confirm deletion "}
                        description={"Really delete access list named "+record.name}
                        onConfirm={() => confirmDelete(record)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        Delete {record.name}
                      </Popconfirm>
                    ),
                    key: '5',
                    danger: true,
                    icon: <DeleteOutlined />,
                  }
                ]
              }}
            >                
              <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
            </Dropdown>          
          </Space>
        ),
      },
    ];

  const [open, setOpen] = useState(false)
  const [loading, setload] = useState(false) 

  const [onGoingEdit, setOngoingToEdit] = useState(false) 

  const confirmDelete = async (host) => {
    const result = await KSPROXY.createStream(props.server?.Id, {Odelete: true, id: host?.id})
    console.log('result result result', result)
    if(result && !result.error){

      props.messageApi.success('Stream deleted !');
      getStreams()
    }else{
      props.messageApi.error(result?.message ||'An error occurs, please try again later.');

    }
  }
  const enableDisable = async (host, state) => {
    const result = await KSPROXY.createStream(props.server?.Id, {[state]: true, id: host?.id})
    console.log('result result result', result)
    if(result && !result.error){

      props.messageApi.success('Done !');
      getStreams()
    }else{
      props.messageApi.error(result?.message ||'An error occurs, please try again later.');

    }
  }
  async function getStreams(){
    setload(true)
    try{
      const result = await KSPROXY.getStreams(props?.server?.Id)
      console.log('result result getStreams ', result)
     result && props.setStreams(Object.values(result).filter( arr => typeof arr === "object"))
     !result && props.setStreams([])
    }catch(e){
      console.log('Cant get proxy host ', e)
    }
    setload(false)
  }
  useEffect(() => { 
    getStreams()
  }, []) 

	return(
		<div className="w-full">
      <NewStream 
        open={open || onGoingEdit} 
        setOpen={setOpen}
        server={props?.server}
        getStreams={getStreams}

        stream={onGoingEdit} 
        setOngoingToEdit={setOngoingToEdit} 
      />
			<div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">Streams</h5>
				<div className="flex justify-end items-center gap-4">
					<Tooltip 
						overlayStyle={{ lineBreak: 'pre-line' }} 
						title={() =>  <div>
								<p className="font-bold">What is a Stream?</p>
								<p className="mt-2">A Stream will serve to forward TCP/UDP traffic directly to another computer on the network.</p> 
								<p className="mt-2">If you're running game servers, FTP or SSH servers this can come in handy.</p>
						    </div>
						}>
						<QuestionCircleOutlined className="xl:text-2xl text-xl cursor-pointer text-primary"/>
					  </Tooltip>

					<Button  onClick={()=> setOpen(true)} type="primary" icon={<PlusOutlined />}>
	            Stream
	        </Button>
				</div>
			</div>

			<Table  
                columns={col1}
                loading={!props.streams || loading} 
                dataSource={props.streams} 
            />
		</div>
	)
}

function NewStream(props){
  const { stream } = props
  const [objectStream, setObjectStream] = useState({})
  const [creation, setCreation] = useState(false)
  const [eMessage, setErrorMessage] = useState(null)

  useEffect(() => {
      if(stream){
        setObjectStream(stream)
      }
  }, [stream])

  async function onCreate(){
    const data = {
      ...objectStream,
    }
    delete data.owner
    delete data.owner_user_id
    delete data.enabled
    delete data.modified_on
    delete data.created_on
    delete data.certificate
    delete data.nginx_err
    delete data.nginx_online

    try{
      setCreation(true)
      setErrorMessage(false)
      const result = await KSPROXY.createStream(props.server?.Id, data)
      setCreation(false)
      if(result?.error){
        return setErrorMessage(result?.message || "An error occurs, please try again or contact us.")
      }
      return Utils.sleep(1000).then(() => {
          setObjectStream({})
          props.setOpen(false)
          props.setOngoingToEdit(null)
          props.getStreams()
      });
    }catch(e){
      console.log('ON CREATE PROXY HOST ERROR ', e)
    }
  }

  return(
    <Modal
      destroyOnClose={true}
      // centered
      footer={null}
      closeIcon={false}
      open={props.open}
      className='mPadding'
      width={700}
    >
      <div className=''>
        <div className='bg-primary py-3 px-6 text-white'>
          <h4 className="font-semibold text-base xl:text-xl">
              Stream form 
          </h4>
        </div>

        <div className='px-6 mt-3' style={{display: !eMessage && "none"}}>
          <Alert  message={eMessage } type="error" />         
        </div>
        
        <div className='px-6 py-3'>
          <div>
            <div>
              <h5 className="font-semibold">Incoming Port <span className="text-red-500">*</span></h5>
              <InputNumber 
                  onChange={val => {
                      setObjectStream({...objectStream, incoming_port: val})
                  }} 

                  value={objectStream.incoming_port}
                  className='rounded w-full' size="large" 
                  placeholder="Eg: 8080" 
                  // prefix={<SecurityScanFilled />} 
                  //status={!objectStream.domain && "error"}
              />
            </div>
            
            <div className="flex mt-5 items-center justify-between w-full">
              <div className="w-full md:w-[64%]">
                <h5 className="font-semibold">Forward Host <span className="text-red-500">*</span></h5>
                <Input 
                    onChange={ev => {
                      setObjectStream({...objectStream, forwarding_host: ev.target.value})
                    }} 
                    value={objectStream.forwarding_host}
                    className='rounded w-full' 
                    placeholder={`Exp: keepsec.ca or 192.168.1.1`} 
                />

              </div>
              <div className="w-full md:w-[32%]">
                <h5 className="font-semibold">Forward Port <span className="text-red-500">*</span></h5>
                <InputNumber 
                  onChange={val => {
                      setObjectStream({...objectStream, forwarding_port: val})
                  }} 

                  value={objectStream.forwarding_port}
                  className='rounded w-full'
                  placeholder="Eg: 80" 
                  // prefix={<SecurityScanFilled />} 
                  //status={!objectStream.domain && "error"}
                />
              </div>

            </div>

            <div className="flex flex-wrap gap-8 mt-5">
              <div className="flex gap-2 items-center">
                <Switch 
                  checked={objectStream.tcp_forwarding} 
                  className='bg-gray-200'
                  onChange={(checked)=>setObjectStream({...objectStream, tcp_forwarding: checked})} 
                />
                <span className="font-semibold">TCP Forwarding</span>
              </div>
              <div className="flex gap-2 items-center">
                <Switch  
                  onChange={(checked)=>setObjectStream({...objectStream, udp_forwarding: checked})} 
                  checked={objectStream.udp_forwarding} 
                  className='bg-gray-200'
                />
                <span className="font-semibold">UDP Forwarding</span>
              </div>
            </div>

          </div>
        </div>

        <div className="py-5 flex justify-end gap-2 px-6">
          <Button  onClick={ () =>{
                  props.setOpen(false);
                  props.setOngoingToEdit(null)
                  setObjectStream({})
              }}
          >
              Cancel
          </Button>
          <Button
              type="primary"
              onClick={() =>onCreate()}
              loading={creation}
              // loading={action}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}