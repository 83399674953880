import { useEffect, useState } from 'react';
import { BsFillLightbulbFill } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import amethyst from "../../assets/img/blue/amethyst_img.png"
import jade from "../../assets/img/blue/jade_img.png"
import tanzanite from "../../assets/img/blue/tanzanite_img.png"
import topaz from "../../assets/img/blue/topaz_img.png"
import { Block, Spiner } from '../../components/Bloc';
import { Toast } from '../../components/Notify';
import UserMgt from '../../lib/user_managment';
import VPSapi from '../../lib/vps_service';
import Utils from '../../utils';

const plan_imgs = {amethyst, topaz, jade, tanzanite}






export default function FreeTryKps() {

    const { flavors } = useSelector(app => app.vps)
    const { images } = useSelector(app => app.vps)
    const { vps_packages } = useSelector(app => app.vps)

    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [packSelected, setPack] = useState(null)

    const navigate = useNavigate()
    const [vps_params, setVpsParams] = useState({
        server_name: '',
        flavor: null,
        image: "KPS_cloud",
        email: "",
        isFreeDeploy: true,
        serviceType: "KPS_CLOUD"
    })


 
    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)

    

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    async function loadFlavors() {
       
        if(!flavors) {
            const r =  await VPSapi.flavor_list()
            return r
        } 
            
        return flavors
    }
    async function loadImages() {
        !images &&   VPSapi.image_list()
    }
    async function getVpsTypes() {
        if(!vps_packages) {
            const r =  await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        } 
            
        return vps_packages
    }

    let ix = 1;   

    useEffect(() => {
        loadFlavors().then(f=>{
            getVpsTypes().then(vps_packages => {
           
                console.log('vps_packages vps_packages vps_packages', vps_packages)
                if(Utils.getUrlParam('prefer')){
                    const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                    const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                    if(pack){
                        setClicked(parseInt(selectedIdVpsTypes));
                        onClaculFlavor(pack, f)

                    }
                     window.localStorage.removeItem('@user_first_service')

                }
            })
     
        })
        loadImages()

         console.log(' Run useEffet ')
          // if(ix==1) {Object.values(plan_ids).map((plan, i)=>ShowPaypal(plan))}
          // ix++
        
        
    }, [])


    

    async function deployVps() {
        console.log('vps_params vps_params', vps_params)
      
        const result = await UserMgt.create_vps({ ...vps_params }, null)
        setCreating(false)
        if (!result || result.error || result.status !== "success") {
            Toast.fire({
                icon: 'error',
                title: result.message || "An error occurred while running if this persist, please contact us !"
            })
        } else {
            Toast.fire({
                icon: 'success', 
                title: "VPS Instance created !"
            })
            console.log('result vps created', result)
            navigate('/app-platform/kps/deploy')
        } 

    }
    function onPay(ev) {
        console.log('vps_params vps_params', vps_params)
        ev.preventDefault()
        
        if (!vps_params.email) {
            return Toast.fire({
                icon: 'error',
                title: "Email not provided !"
            })
        }
        if (!vps_params.flavor) {
            const flavor = flavors && flavors.find(fl => fl.name === packSelected?.OpenstackFlavorName);
            console.log('Flavor found:', flavor)
            if (flavor){
                setVpsParams({ ...vps_params, flavor: flavor.name })
            }

            else {
                return Toast.fire({
                    icon: 'error',
                    title: "Error flavor !"
                })
            }
        }
        setCreating(true)
        return deployVps()
    }

    function onClaculFlavor(pack, f) {
        setPack(pack)
        const f2 = f || flavors
        const flavor = f2 && f2.find(fl => fl.name === pack?.OpenstackFlavorName);
        console.log('Flavor found:', flavor)
        if (flavor) setVpsParams({ ...vps_params, flavor: flavor.name })

    }

  
    function onDismiss(){
        setCreating(false)
        setPaypal(false)
        window.$(".modalPaypal").modal('hide')

    }
  
    return (
        <>
            <Block />
           
            

            <div className="px-3 md:px-8 h-screen overflow-y-auto dark:text-darkTextColor pb-32 -mt-24">
                <div className="container max-w-full">
                    <div className='mx-auto'>
                        <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor'>Choose your instance plan</p>
                        {vps_packages ?
                            <form onSubmit={onPay} className="">
                                <div className="mx-auto gap-2 justify-center pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center  md:w-11/12 mt-10">
                                    {vps_packages.filter(p => p.Package_Type ==="KPS_CLOUD" && !p.IsMini).sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price)).map((pack, i) =>
                                        <div key={i} className="mx-auto mt-5">
                                            <div onMouseOver={() => setSelected(pack.Id)}
                                                onClick={() => {
                                                    if (!creating) {
                                                        if(clicked == pack.Id){
                                                            console.log('cliket on same')
                                                            setClicked(null);
                                                            onClaculFlavor(null)
                                                        }else{
                                                            setClicked(pack.Id);
                                                            //setPack(pack);
                                                            onClaculFlavor(pack)
                                                        }
                                                        
                                                    }    
                                                }}
                                                className={'w-72 cursor-pointer rounded-xl dark:bg-bgPagedark ' +
                                                    ((selected == pack.Id) ? "hover:bg-white  hover:shadow-2xl " : '') + "" + ((clicked == pack.Id) ? "bg-white shadow-2xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')}>

                                                {(clicked == pack.Id) &&
                                                    <svg className="div_check checkmark float-right " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                    </svg>
                                                }
                                                <div className='pt-10 pb-14 text-center text-primary dark:text-darkTextColor'>
                                                    <div className="px-3">
                                                        <div className="flex gap-1 items-center ">
                                                            <img src={plan_imgs[pack.Name.toLowerCase()]} alt="" className='mx-auto w-14 h-14' />
                                                        </div>
                                                        <span className='font-bold text-2xl' > {pack.Name}</span>
                                                        <br/>
                                                        <br/>

                                                        <p className='font-bold'>
                                                            <i className='-mt-5'>$</i> <span className='text-center line-through text-3xl'>{pack.Price}</span>/month
                                                        </p>

	                                                    <p className={"rounded-full px-0 py-0.5"} > 
	                                                    	<span className='font-semibold'> {pack.Cpus} CPU{pack.Cpus > 1 ? "s" : ""} - {pack.RamGb}GB RAM - {pack.DiskGb}GB SSD</span>
	                                                    </p>
                                                    </div>
                                                    

                                                    <div className='mx-auto px-28 py-2'>
                                                        <div className='border-t-4 rounded-full border-t-primary'/>
                                                    </div>
                                                    <p className='font-semibold pt-1' style={{fontSize: "12px"}}>Over 250 applications ready to be deployed </p>
                                                    <p className='font-semibold pt-1' style={{fontSize: "12px"}}>Simple & customisable 
                                                        
                                                    </p>
                                                    <p className='font-semibold pt-1' style={{fontSize: "12px"}}>Instant setup</p>
                                                    <p className='font-semibold pt-1' style={{fontSize: "12px"}}>LetsEncrypt SSL Certificates</p>
                                                    <p className='font-semibold pt-2' style={{fontSize: "12px"}}>Full plan details</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                

                                <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor pt-10'>Server Informations</p>

                                <div className={'md:w-5/6 mx-auto pt-10 px-20 '+ (creating && paypal ? " pb-72 md:pb-56" : 'pb-56 md:pb-48')}>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="
                                                    block
                                                    uppercase
                                                    tracking-wide
                                                    dark:text-darkTextColor
                                                    text-gray-700 text-xs
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="serv-name"
                                            >
                                                Identify your instance
                                            </label>
                                            <input
                                                className="
                                                    appearance-none
                                                    block
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    leading-tight
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                    focus:outline-none focus:bg-white
                                                "
                                                id="serv-name"
                                                value={vps_params.server_name}
                                                name="server_name"
                                                onChange={handleChange}
                                                pattern="[A-Za-z0-9]{1,}"
                                                required
                                                disabled={creating}
                                                placeholder='Your instance resources must have unique names'
                                            />
                                            <p className=" font-semibold text-red-700 -mt-3" style={{fontSize: "14px"}} >
                                            <i> Must not contain spaces, special characters or symbols</i>
                                        </p>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full xl:w-1/2 px-3">
                                            <label
                                                className="
                                                    block
                                                    uppercase
                                                    tracking-wide
                                                    dark:text-darkTextColor
                                                    text-gray-700 text-xs
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="serv-email"
                                            >
                                               Email for SSL
                                            </label>
                                            <input
                                                className="
                                                     appearance-none
                                                    block
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    leading-tight
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                    focus:outline-none focus:bg-white
                                                "
                                                id='serv-email'
                                                value={vps_params.email}
                                                name="email"
                                                type="email"
                                                onChange={handleChange}
                                                required
                                                disabled={creating}
                                                placeholder='Valid email address to get certificates'
                                            />
                                        </div>
                                    </div>

                                    <div className='mx-auto'>
      
                                        <div className={'fixed bottom-0 dark:opacity-70 left-0 dark:text-darkTextColor dark:bg-bgPagedark shawdow w-screen mx-auto md:ml-10 '+ (creating && paypal ? "md:h-48 h:64" : 'h-48 md:h-42')}>
                                            <div className='container'>
                                                <div className='grid grid-cols-1' >
                                                    
                                                    <div className='text-center text-primary dark:text-darkTextColor pt-7'>
                                                        <span className='text-xl'>Summary:</span> 
                                                        <br/>
                                                        {packSelected ?
                                                        <span className='text-xl font-bold'>
                                                            <i>{packSelected.Name}<span className="text-red-500"> (free)</span></i>
                                                        </span> :
                                                        <span>(empty)</span>
                                                        }
                                                        
                                                    </div>
                                                    <div className="mx-auto">
                                                        {/*!paypal*/ true&&
                                                            <div className=''>
                                                                <button className="flex mt-4 mx-auto text-xl   w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                                    type="submit"
                                                                    disabled={creating}
                                                                >
                                                                    {!creating ?
                                                                        <span className='mx-auto flex  items-center'>
                                                                            <BsFillLightbulbFill className='text-2xl' />
                                                                            {" Try Now"}
                                                                        </span>
                                                                        :
                                                                        <span className='mx-auto flex gap-1 items-center'>
                                                                            <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                            </svg>
                                                                           Deploying
                                                                        </span>

                                                                    }
                                                                </button>
                                                            </div>
                                                        }
                                                        <div className='h-2'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                            :
                            <div className='flex justify-center pt-48'>
                                <Spiner fontSize={50} />
                            </div>
                        }


                    </div>
                </div>
            </div>
        </>
    );
}



