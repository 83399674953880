import React, { useState, useEffect } from 'react'
import KSPROXY from 'lib/proxy'
import Utils from '../../utils'
import { EyeOutlined } from '@ant-design/icons';

import { Table, Tag, Button, Tooltip, Tabs, Modal } from 'antd';


export function PSecurityLog(props){
  const [allEventData, setAllEventData] = useState(null)
  const [importantEventData, setImportantEventData] = useState(null)
  const [notifData, setNotifData] = useState(null)
  const [loading, setload] = useState(false) 
  const [logLine, setLogline] = useState(null)
 	
	async function getAllSettings2(){
    setload(true)  
    try{ 
      const result = await KSPROXY.getAllSettings(props?.server?.Id, null, true)
      if(result && !result?.error){
      	delete result.code
      	const arrays = Object.values(result);
      	setNotifData(arrays.filter(evt => evt.eventTopic))
      	setAllEventData(arrays.filter(evt => !evt.eventTopic))
      	setImportantEventData([])
      	console.log('RESULT EVENT ', arrays)
      }
    }catch(e){
      console.log('Cant get proxy host ', e) 
    }
    setload(false)
  }

  useEffect(() => { 
    getAllSettings2()
  }, [])

	const eventCollumn = [
		{
			title: 'Time', 
			dataIndex: 'time', 
			render: (_, obj) => <div className="w-[170px] flex">
				<span className="cursor-pointer">
					<Tag 
						icon={<EyeOutlined />} 
						onClick={()=>setLogline(obj)}
						color="#011a43"
					>
		        Open
		      </Tag>
	      </span>
				<p>
					{Utils.formatDate(obj?.eventTime, 'Do MMMM YYYY, h:mm a')}
				</p>
			</div>
		}, 
		{title: 'Event severity', render: (_, obj) => obj?.eventSeverity}, 
		{title: 'Asset name', render: (_, obj) => "-"}, 
		{title: 'Security action', render: (_, obj) => "-"}, 
		{title: 'K-WAF incident type', render: (_, obj) => "-"}, 
		{title: 'Source identifier', render: (_, obj) => "-"}, 
		{title: 'Source IP', render: (_, obj) => "-"}, 
		{title: 'Proxy IP', render: (_, obj) => "-"}, 
		// {title: 'Http host', render: (_, obj) => "-"}, 
		// {title: 'Http method', render: (_, obj) => "-"}, 
		// {title: 'Http response code', render: (_, obj) => "-"}, 
		// {title: 'Http uri path', render: (_, obj) => "-"}, 
		// {title: 'Matched location', render: (_, obj) => "-"}, 
		// {title: 'Matched parameter', render: (_, obj) => "-"}, 
		// {title: 'Matched sample', render: (_, obj) => "-"}, 
	]

	const notifTable = [
		{
			title: 'Time', render: (_, obj) => <div className="w-[170px] flex">
				<span className="cursor-pointer">
					<Tag 
						icon={<EyeOutlined />} 
						onClick={()=>setLogline(obj)}
						color="#011a43"
					>
		        Open
		      </Tag>
	      </span>
				<p>
					{Utils.formatDate(obj?.eventTime, 'Do MMMM YYYY, h:mm a')}
				</p>
			</div>
		},
		{title: 'Event severity', render: (_, obj) => obj?.eventSeverity},
		{title: 'Event priority', render: (_, obj) => obj?.eventPriority},
		{title: 'Event topic', render: (_, obj) => obj?.eventTopic},
		{title: 'Event name', render: (_, obj) => obj?.eventName},
		{title: 'Suggested remediation if applicable', render: (_, obj) => "-"},
		{title: 'Asset name', render: (_, obj) => "-"},
	]
	function ImportantEvent(){
		return(
			<div className="w-full overflow-x-auto">
				<Table  
	                columns={eventCollumn} 
	                loading={!importantEventData || loading}
	                dataSource={importantEventData} 
	            />
			</div>
		)
	}

	function AllEvent(){
		return(
			<div className="w-full overflow-x-auto">
				<Table  
	                columns={eventCollumn} 
	                loading={!allEventData || loading}
	                dataSource={allEventData} 
	            />
			</div>
		)
	}

	function Notifications(){
		return(
			<div className="w-full overflow-x-auto">
				<Table  
	                columns={notifTable} 
	                loading={!notifData || loading}
	                dataSource={notifData} 
	            />
			</div>
		)
	}

	const arrayTab = [
      {
        label: "Important events",
        component: <ImportantEvent />
      },
      {
        label: "All events",
        component:  <AllEvent />
      },
      {
        label: "Notifications",
        component: <Notifications />
      }
  ]
	return(
		<div className='w-full'>
			<Modal
        destroyOnClose={true}
        centered
        footer={null}
        closeIcon={false}
        open={logLine}
        className='mPadding'
        width={1000}
      >
        <div className=''>
          <div className='bg-primary py-3 px-6 text-white'>
            <h4 className="font-semibold text-base xl:text-xl">
                {logLine?.eventName}
            </h4>
          </div>

          <div className='mt-3 py-3 px-6 flex flex-wrap gap-4'>
            <div className="flex rounded text-white text-[0.75rem] font-semibold">
              <div className="px-[0.5rem] pb-1 bg-black leading-[2em]">
              	Handler
               </div>
              <div className="px-[0.5rem] pb-1 bg-[#fd9644] leading-[2em]">
                 {logLine?.serviceName}
              </div>
            </div>
            <div className="flex rounded text-white text-[0.75rem] font-semibold">
              <div className="px-[0.5rem] pb-1 bg-black leading-[2em]">
                  Severity
               </div>
              <div className="px-[0.5rem] pb-1 bg-[#2bcbba] leading-[2em]">
                {logLine?.eventSeverity}
              </div>
            </div>
            <div className="flex rounded text-white text-[0.75rem] font-semibold">
              <div className="px-[0.5rem] pb-1 bg-black leading-[2em]">
                  Date
               </div>
              <div className="px-[0.5rem] pb-1 bg-[#3730a3] leading-[2em]">
                {logLine ? Utils.formatDate(logLine?.eventTime, 'Do MMMM YYYY, h:mm a'): null}
              </div>
            </div>
          </div>
          <div className='px-6 py-3'>
            <pre>{logLine ? JSON.stringify(logLine, null, 2) : null}</pre>
          </div>

          <div className="py-5 flex justify-end gap-2 px-6">
            <Button  onClick={ () =>{
                    setLogline(null);
                }}
            >
                Cancel
            </Button>
          </div>
        </div>
      </Modal>
			<div className="flex justify-between items-center gap-4 pb-6">
				<h5 className="font-bold text-base  xl:text-xl">Security logs</h5>
        	</div>
           <Tabs defaultActiveKey="1" 
            items={arrayTab.map((_, i) => {
              const id = String(i);
              return {
                label: _.label,
                key: i,
                children: _.component,
              };
            })} 
           />
        </div>
	)
}