import { useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { updateHeadLink } from "reducer/action/coreActions";

import k8sImage from '../../../assets/img/k8/kubernetes-kp.webp';
import { Spiner } from '../../../components/Bloc';
import { OkButton } from "../../../components/OkButton";
import { TabSelector } from "../../../components/TabSelector";
import K8S from '../../../lib/k8s';
import Utils from '../../../utils';
import { Networking, Overview, Ressource, StorageK8s, Upgrade } from "./KsKubHyBrSection";

const tabs = [
	"Overview",
	"Networking",
	"Ressources",
	"Upgrade",
	"Storage",
	// "Rebuild",
	"Delete",
]
export default function KsKubHyBrManage() {
	const [k8s, setK8s] = useState(null)
	const [server, setServerMaster] = useState(null)
	const [masterCluster, setMasterCluster] = useState(null)
	const [listNode, setListNode] = useState(null)
	const [networks, setNetworks] = useState(null)
	const [volumes, setVolumemes] = useState(null)
	const [gFlavor, setGlobalFlavor] = useState("")
	const { id } = useParams();
	const navigate = useNavigate()
	const [selectedTab, setSelectedTab] = useTabs(tabs);
	const dispatch = useDispatch()
	const { forceReload, messageApi } = useSelector(app => app.core)

	async function loadK8() {
		const data = await K8S.getUniqueK8s(id);
		const k8s = data?.k8s
		if (!k8s) return navigate("/")
		setK8s(k8s);
		setNetworks(data?.networks);
		setVolumemes(data?.volumes);
		const masterCluster = k8s?.clusters?.find(cluster => cluster?.isPrimaryInstance)
		setServerMaster(masterCluster.instances?.find(inst => !!inst.isKubeMaster && !!inst.isPrimarykubeMaster))
		setMasterCluster(masterCluster)

		let totalNode = [];
		for (var i = 0; i < k8s?.clusters?.length; i++) {
			const instances = k8s?.clusters[i]?.instances
			totalNode = totalNode.concat(instances)
		}
		setListNode([...totalNode])
		const totalCPUArr = totalNode && totalNode?.map(inst => inst?.type?.Cpus).reduce((a, b) => a + b, 0)
		const totalRAMArr = totalNode && totalNode?.map(inst => inst?.type?.RamGb).reduce((a, b) => a + b, 0)
		const totalSSDArr = totalNode && totalNode?.map(inst => inst?.type?.DiskGb).reduce((a, b) => a + b, 0)

		setGlobalFlavor(totalCPUArr + "CPU-" + totalRAMArr + "GB-" + totalSSDArr + "GB NVMe")

		const headLinks = [
			{
				link: "/kubernetes/kskubhybr",
				item: 'kskubhybr',
				alt: "kskubhybr short link"
			},
			{ label: masterCluster?.name, arrow: true },
		]
		dispatch(updateHeadLink(headLinks))

		return k8s
	}

	async function onDelete() {
		await Utils.dispatchToDelete(k8s, '/kubernetes/kskubhybr')
	}

	useEffect(() => {
		loadK8().then(() => {
			if (Utils.getUrlParam('act') == "nodes") {
				setSelectedTab('Ressources')
			}
		})
	}, [forceReload])

	return (
		<>
			<div className=" dark:text-darkTextColor pb-10">
				<div className="container mx-auto max-w-full px-3  md:px-8">
					<div style={{ display: !k8s && "none" }}>
						<div className="md:flex gap-6 items-center">
							<div className="md:w-1/12 md:flex hidden">
								<img
									src={k8sImage}
									alt=""
									className="w-14 h-14 xl:w-16 xl:h-16 2xl:w-20 2xl:h-20"
								/>
							</div>
							<div className="md:w-11/12 w-full ">
								<div>

									<div className="flex justify-between items-end border-b-2 border-slate-300 dark:border-gray-800 pb-3 pt-2">
										<div>
											<img
												src={k8sImage}
												alt=""
												className="w-14 h-14 md:hidden"
											/>
											<span className="2xl:text-3xl text-xl xl:text-2xl">
												{masterCluster?.name}
											</span>{" "}
											<br />
											<span className="text-sm 2xl:text-base">
												{gFlavor}</span>{" "}
											<br />
											<span className="text-sm 2xl:text-base">
												{server?.location?.name || "Montreal, Zone A"}
											</span>{" "}
											<br />
										</div>

										<div className="">
											<div className="flex flex-col md:flex-row gap-2 md:gap-4">

												<OkButton loanding={false} click server={server} fun={() => setSelectedTab("Ressources")} title={"View nodes"} />
												<OkButton loanding={false} click server={server} fun={() => Utils.createK8SFile(k8s)} title={"Download config"} />
											</div>
											<p className="text-right pt-1 text-sm 2xl:text-base">State: <span className="font-bold">{server?.VpsStatus === "up" ? "Running" : server?.VpsStatus === "down" ? "Stopped" : server?.VpsStatus} </span></p>
										</div>

									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-2 text-sm 2xl:text-base">
									<div className="text-left">
										<p className="text-left pt-2">
											Public hostname:{" "}
											<span className="font-bold text-primary">
												{masterCluster?.domain + ".keepsec.cloud"}
											</span>{" "}
											<br />
											Private hostname:{" "}
											<span className="font-bold  text-primary">
												{server?.OpenstackInstanceName}
											</span>{" "}
											<br />
										</p>
									</div>
									<div className="text-left md:text-right">
										<p className="text-left md:text-right pt-2">
											Public IP:{" "}
											<span className="font-bold text-primary">
												{server?.OpenstackInstanceExtIp}
											</span>{" "}
											<br />
											Version:{" "}
											<span className="font-bold text-primary">
												{k8s?.version}
											</span>{" "}
											<br />
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="mt-5">
							<nav className="flex  border-b border-gray-300 dark:border-gray-800 overflow-x-auto">
								{tabs.map(tab => (
									<TabSelector
										isActive={selectedTab === tab}
										onClick={() => setSelectedTab(tab)}
									>
										{tab}
									</TabSelector>
								))}
							</nav>

							<div className="py-5">
								<TabPanel hidden={selectedTab !== "Overview"}>
									<Overview networks={networks} createFile={() => Utils.createK8SFile(k8s)} messageApi={messageApi} masterCluster={masterCluster} k8s={k8s} master={server} instances={listNode} />
								</TabPanel>
								<TabPanel hidden={selectedTab !== "Storage"}>
									<StorageK8s
										messageApi={messageApi}
										masterCluster={masterCluster}
										k8s={k8s}
										volumes={volumes}
										master={server}
										instances={listNode}
										reloadK8s={loadK8}
									/>
								</TabPanel>
								<TabPanel hidden={selectedTab !== "Networking"}>
									<Networking
										listNode={listNode}
										messageApi={messageApi}
										masterCluster={masterCluster}
										k8s={k8s}
										master={server}
										instances={listNode}
										reloadK8s={loadK8}
									/>
								</TabPanel>
								<TabPanel hidden={selectedTab !== "Ressources"}>
									<Ressource
										listNode={listNode}
										messageApi={messageApi}
										masterCluster={masterCluster}
										k8s={k8s}
										master={server}
										instances={listNode || []}
										volumes={volumes}
										reloadK8s={loadK8}
									/>
								</TabPanel>
								{/*<TabPanel hidden={selectedTab !== "Rebuild"}>
                           			<Rebuild listNode={listNode} messageApi={messageApi} masterCluster={masterCluster} k8s={k8s} master={server} instances={listNode} />
	                        	</TabPanel>*/}
								<TabPanel hidden={selectedTab !== "Upgrade"}>
									<Upgrade
										listNode={listNode}
										messageApi={messageApi}
										masterCluster={masterCluster}
										k8s={k8s}
										master={server}
										instances={listNode}
										reloadK8s={loadK8}
									/>
								</TabPanel>
								<TabPanel hidden={selectedTab !== "Delete"}>
									<div className="">
										<div className="flex gap-2 items-center">
											<p className="font-bold text-primary text-xl 2xl:text-2xl">
												Delete this cluster
											</p>
										</div>
										<p className="2xl:text-base text-sm pt-2">
											Deleting this cluster will permanently destroy it including all of its data.
										</p>
										<p className="2xl:text-base text-sm font-bold pt-2">
											Are you sure you want to delete this cluster?
											<br />
											<br />
											{/*<Delete
	                                       id={"Delete" + server?.Id}
	                                       server={server}
	                                    />*/}
											<OkButton
												danger
												loanding={false}
												click
												server={server}
												fun={() => onDelete()}
												title={"Delete"}
											/>
										</p>
									</div>
								</TabPanel>
							</div>
						</div>
					</div>

					<div style={{ display: k8s && "none" }} className='flex h-[400px] items-center justify-center'>
						<Spiner fontSize={50} />
					</div>
				</div>
			</div>
		</>


	)
}