import {  LIST_VULLIFY_SERVERS } from "../action/vullifyAction";

const defaultState = {
    list: null
};
  
  
  export default function vullify(state = defaultState, action) {
    switch (action.type) {
      case LIST_VULLIFY_SERVERS:
        return { ...state, list: action.list };
      default:
        return {...state};
    }
  }
  