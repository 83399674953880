import store from "../reducer"
import UserAPI from './keepsec_auth_service'
import { list_k8s } from "../reducer/action/kubernetesAction"
const {  get, post, put, onDelete } = require("./api")



const SERVICE_URL = process.env.REACT_APP_USER_MANGMENT_API //http://72.10.163.146"
const  apiUser = process.env.REACT_APP_API_USER
const apiPasssword = process.env.REACT_APP_PASSWORD

const header = {'Authorization': 'Basic ' + btoa(apiUser+":"+apiPasssword)}

async function getK8sInstance(){
	try{
		await UserAPI.refrest_token()
		const result = await get(SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/k8s", header)
		store.dispatch(list_k8s(result?.k8s || []))
		return result;
	}catch(e){
		console.error('Error getK8sInstance', e)
		return null
	}

}

async function getUniqueK8s(id){
	try{
		await UserAPI.refrest_token()
		const result = await get(SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/k8s/"+id, header)
		console.log('Result K8S FETCH ', result)
		return result;
	}catch(e){
		console.error('Error getUniqueK8s', e)
		return null
	}

}
async function deleteInstance(id){
	try{
		await UserAPI.refrest_token()
		const result = await onDelete(
			SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/k8s/"+id,
			{}, 
			header
		)
		console.log('Result K8S FETCH ', result)
		return result && result.status;
	}catch(e){
		console.error('Error getUniqueK8s', e)
		return null
	}

}
async function loadNetWork(id){
	try{
		await UserAPI.refrest_token()
		const result = await get(SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/k8s/"+id+"/network", header)
		console.log('Result K8S NETWORK ', result)
		return result;
	}catch(e){
		console.error('Error getUniqueK8s', e)
		return null
	}

}
async function rebuild(ks8id, id){
	try{
		const data = {
			action: "rebuild",
			k8sId: ks8id,
			nodeId: id
		}
		await UserAPI.refrest_token()
		const result = await put(
			SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/k8s/"+ks8id+"/action", 
			data,
			header
		)
		console.log('Result rebuild', result)
		return result;
	}catch(e){
		console.error('Error getUniqueK8s', e)
		return null
	}

}

async function addNode(ks8id, nodes){
	try{
		const data = {
			action: "add_node",
			k8sId: ks8id,
			nodes: nodes
		}
		await UserAPI.refrest_token()
		const result = await put(
			SERVICE_URL + "v1/users/"+store.getState().user.user?.Id+"/k8s/"+ks8id+"/action", 
			data,
			header
		)
		console.log('Result rebuild', result)
		return result;
	}catch(e){
		console.error('Error getUniqueK8s', e)
		return null
	}

}

async function createPlan(k8s, amount){
	try{
		const data = {
			k8s,
			amount
		}
		await UserAPI.refrest_token()
		const result = await post(
			SERVICE_URL + "v1/create-plan", 
			data,
			header
		)
		console.log('Result create plan', result)
		return result;
	}catch(e){
		console.error('Error createPlan', e)
		return null
	}

}


export default {
	createPlan,
	getK8sInstance,
	getUniqueK8s,
	loadNetWork,
	rebuild,
	addNode,
	deleteInstance
}