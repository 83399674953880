import { Button, Modal } from 'antd'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillLightbulbFill } from "react-icons/bs";
import { BsInfoCircle } from "react-icons/bs";
import { useSelector } from 'react-redux';

import { PageTitle } from "components/Bloc";
import { Spiner } from 'components/Bloc';
import { VpsMiniPack, VpsPack } from 'components/vps/Packages';
import { Texto } from 'layouts/TextoLayout';

import { Toast } from '../../../components/Notify';
import { Loader } from '../../../components/OkButton';
import UserMgt from '../../../lib/user_managment';
import VPSapi from '../../../lib/vps_service';

export default function UpgradeVps({server, vpsId, loadVps, isMini}) {

    const { flavors } = useSelector(app => app.vps)
    const { vps_packages } = useSelector(app => app.vps)
    const { t } = useTranslation();
    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [creating, setCreating] = useState(false)
    const [created, setCreated] = useState(false)
    const [openNotifyModal, setOpenNotifyModal] = useState(false)
    const [packSelected, setPack] = useState(null)
    const [paypalLink, setPaypalLink] = useState("")



    function onClaculFlavor(pack) {
        setPack(pack)

    }

    async function loadFlavors() {
        !flavors && VPSapi.flavor_list() 
    }
   
     async function getVpsTypes() {
        if(!vps_packages) {
            const r =  await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        } 
            
        return vps_packages
    }

    const onSleep = (likk) => {
        setTimeout(()=>{
            window.open(likk, '_blank')
            // setOpenNotifyModal(true)
        }, 2000)


    }
    const onUpgrade = async (e) => {
        e.preventDefault()
        setCreating(true)
        let result;
        try{
            const data = {
                vpsId: vpsId,
                plan_id: packSelected.PaypalPackId,
                newPlanId: packSelected?.Id,
                openstackInstanceId: server.OpenstackInstanceId,
            }
            result = await UserMgt.onUpdate(data)
            console.log('result result ', result)
        }catch(e){
            console.log('error on upgrade', e) 
        } 
        setCreating(false)
        if(result && !result?.error){ 
                // Toast.fire({
                //     icon: 'success',
                //     title: result.server
                // })
                if(result?.paypal){
                    const likk = (result?.data?.links.find(l => l.rel == "approve"))?.href
                    setPaypalLink(likk)
                    // await UserMgt.get_user_vps();
                    // loadVps()
                    setCreated(false)
                    onSleep(likk)
                    return setOpenNotifyModal(true)
                }else{
                    loadVps(true)
                    Toast.fire({
                        icon: 'success',
                        title: result.message || "Server upgraded."
                    })

                }
        }else{ 

            Toast.fire({
                icon: 'error',
                title: result?.message || "An error occured, if this persist, please tell us"
            })
            
        }
        
    }



     useEffect(() => {
        loadFlavors()
        getVpsTypes()
       
        
    }, [])

    let actualVpsType = {}
    let resultVPS = []
    if(server && server.VpsTypeId && vps_packages){ 
        actualVpsType = vps_packages.find(p => p.Id === server.VpsTypeId) 
        resultVPS =  vps_packages.filter(p => {
            const shouldTrue = p.service?.task_name === (isMini ? "vpsmini" :  "vps")
            return shouldTrue 
                && 
                parseInt(p.Price) !== parseInt(actualVpsType.Price) 
                &&  
                parseInt(p.Price) > parseInt(actualVpsType.Price)
        }).sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price))
    } 

    return (
        <div className=' '>
            <NoticeModal link2={paypalLink} open={openNotifyModal} setOpenNotifyModal={setOpenNotifyModal} />
            {vps_packages && !created ?
                <form onSubmit={onUpgrade} className="">
                    <PageTitle text={t("compute.vps.upgradeTab.h1")} />
                    
                    <Texto small className="text-center font-semibold mt-7">{t("compute.vps.upgradeTab.pickToUpgrade")}</Texto>
                    <div className="mx-auto gap-2  justify-center  pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2   md:w-11/12 mt-5">
                        {isMini ?
                            <VpsMiniPack
                                concertPack={resultVPS || []}
                                setSelected={setSelected}
                                creating={creating}
                                clicked={clicked}
                                setClicked={setClicked}
                                onClaculFlavor={onClaculFlavor}
                                setPackPaypal={()=>{}}
                                selected={selected} 
                                noMxauto
                            />
                            :
                            <VpsPack
                                concertPack={resultVPS || []}
                                setSelected={setSelected}
                                creating={creating}
                                clicked={clicked}
                                setClicked={setClicked}
                                onClaculFlavor={onClaculFlavor}
                                setPackPaypal={()=>{}}
                                selected={selected}
                                noMxauto
                            />

                        }

                        {resultVPS && resultVPS.length === 0 ? <span className=" text-2xl font-semibold dark:text-darkTextColor">{t("compute.vps.upgradeTab.noPlan")}</span> : null}
                    </div>


                    {resultVPS && resultVPS.length > 0 ? 

                        <div className="mx-auto">
                            <div className=''>
                                <button className="flex mt-4 mx-auto text-xl   w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                    type="submit"
                                    disabled={creating}
                                >
                                        {!creating ?
                                            <span className='mx-auto flex  items-center'>
                                                <BsFillLightbulbFill className='text-2xl' />
                                                {t("compute.vps.upgradeTab.upgradeNow")}
                                            </span>
                                            :
                                            <span className='mx-auto flex  items-center'>
                                                <Loader white />
                                            </span>
                                        }
                                </button>
                            </div>
                        </div>
                        :
                        null
                    }
                </form>
                :
                <div className='flex justify-center pt-48'>
                    <Spiner fontSize={36} />
                </div>
            }
        </div> 
    );
}

function NoticeModal({ open, setOpenNotifyModal}){

    function onCancel(){
        return setOpenNotifyModal(false)
    }

  
    return( 
        <Modal
            destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={450}
            open={open}
        >
            <div className=''>
                <div className=' py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-primary text-base xl:text-xl">
                            <BsInfoCircle className="text-5xl mx-auto" /> 
                        </h4>
                    </div>
                </div>
                <div>
                    <div className="overflow-y-auto ">
                        <div className="w-full px-6 ">	
                            <p className="pt-5">
                            Wait a few seconds to be redirected on PayPal website to approve upgrade.
                                
                                <br/>

                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="py-5 flex gap-2 justify-end  px-6">
                        <Button 
                            size="large" 
                            onClick={onCancel} 
                            type="primary"
                            style={{color: "white"}}
                        >
                            Ok, Understood
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>

    )
}
