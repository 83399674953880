import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Tooltip } from 'antd';
import { Space, Table } from 'antd';
import { Checkbox, Input } from 'antd';
import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DeleteLineButton, ExtLink } from 'components/Bloc';

import setting from '../../../assets/img/networking/setting.png'
import { Spiner } from '../../../components/Bloc'
import { InputSelect, InputText, RemoveLineButton } from '../../../components/Bloc';
import { Toast } from '../../../components/Notify';
import { STANDARD_ERROR_MESSAGE } from '../../../constants/message'
import LB from '../../../lib/loadBalancer';
import UserMgt from '../../../lib/user_managment';

const { Search } = Input;


const portApp = {
	"ssh": {app: "SSH", proto: "TCP", port: "22"},
	"http": {app: "HTTP", proto: "TCP", port: "80"},
	"https": {app: "HTTPS", proto: "TCP", port: "443"},
	"mysql": {app: "MYSQL", proto: "TCP", port: "3306"},
	"dns1": {app: "DNS", proto: "TCP", port: "53"},
	"dns2": {app: "DNS", proto: "UDP", port: "53"},
	"smtp": {app: "SMTP", proto: "TCP", port: "25"},
	"smtps": {app: "SMTP(S)", proto: "TCP", port: "465"},
	"imap": {app: "IMAP", proto: "TCP", port: "143"},
	"imaps": {app: "IMAP(S)", proto: "TCP", port: "993"},
	"pop": {app: "POP", proto: "TCP", port: "110"},
	"pops": {app: "POS(S)", proto: "TCP", port: "995"},	
	"icmp": {app: "PING", proto: "ICMP", port: ""},	
}
const generals = [
	{
		value: 'custom',
		label: 'Custom',
	},
	{
	  label: 'Instance access',
	  options: [
		{ label: 'SSH', value: 'ssh' },
		// { label: 'RDP', value: 'rdp' }
	  ],
	},
	{
	  label: 'Web server',
	  options: [
		{ label: 'HTTP', value: 'http' },
		{ label: 'HTTPS', value: 'https' }
	  ],
	},
	{
	  label: 'Database connections',
	  options: [
		{ label: 'MySQL', value: 'mysql' },
		// { label: 'PostgreSQL', value: 'postgresql' }
	  ],
	},
	{
	  label: 'Networking',
	  options: [
		{ label: 'DNS(TCP)', value: 'dns1' },
		{ label: 'DNS(UDP)', value: 'dns2' },
		{ label: 'PING(ICMP)', value: 'icmp' },
	  ],
	},
	{
	  label: 'Messaging',
	  options: [
		{ label: 'SMTP', value: 'smtp' },
		{ label: 'SMTP(S)', value: 'smtps' },
		{ label: 'POP', value: 'pop' },
		{ label: 'POP(S)', value: 'pops' },
		{ label: 'IMAP', value: 'imap' },
		{ label: 'IMAP(S)', value: 'imaps' }
	  ],
	},
]
const protocols = [
	{
		value: 'tcp',
		label: 'TCP',
	},
	{
		value: 'udp',
		label: 'UDP',
	},
	
]

function FormRuleFiwewall({rule, hideForm, server, loadVps, messageApi, edition, setCurrentEdit}){
	const [ruleObject, setRule] = useState(rule || {})
	const [dynamicArr, setDynamicArr] = useState([1])
	const [dynamicObject, setDynamicObject] = useState({})
	const [creatingRule, setCreatingRule] = useState(false)
	const [shouldDisabled, setShouldDisabled] = useState(false)
	const [destination, setDest] = useState([
			{id: 1},
			{id: 2},
		])
	
	function rangeInitValue(){
		// if()
		const bool = rule.port_range_min !== rule.port_range_max
		const tabs = Object.entries(portApp).filter(item => item[1]?.port == rule.port_range_min)
		const val = bool ? null : tabs?.[0]?.[0];
		setShouldDisabled(val || rule.protocol==="icmp" ? true : false)
		setDynamicObject({"1": rule?.remote_ip_prefix})
		return  setRule(
			{
				...rule, 
				application: val?  val : bool ? "custom"  : rule.protocol || "custom", 
				restricted: !!rule?.remote_ip_prefix,
				port_range_min: bool ? rule.port_range_min +"-"+ rule.port_range_max : rule.port_range_min
			}
		)
	}

	useEffect(()=>{
		if(edition && rule){
			rangeInitValue()
		}
	}, [])

	function onChange(ev){
		setRule({
			...ruleObject,
			[ev.target.name]: ev.target.value
		})
	}
	function onchaneDybject(ev){
		console.log('Dynamic ', ev.target.name, ev.target.value )
		setDynamicObject({
			...dynamicObject,
			[ev.target.name]: ev.target.value,
		})
	}
	function onRemoveDynamic(dynNumber){
		delete dynamicObject[dynNumber]
		setDynamicObject({
			...dynamicObject
		})
		const newDynArr = dynamicArr.filter(dyn => dyn !== dynNumber)
		setDynamicArr(newDynArr)
	}

	const setApplication = (name, value) => {
		let toset = {...ruleObject, [name]: value };
		const item = portApp[value]
		if(item){
			toset = {...toset, port_range_min: item.port, protocol: item?.proto.toLowerCase()}
			setShouldDisabled(true)
		}else{
			setShouldDisabled(false)
		}
		setRule({...toset })
	}
	async function create(ip, ){
		try {
			const port  = ruleObject.port_range_min
			let port1 = port ? port : ruleObject?.application === "icmp" ? "8" : ""
			let port2 = port ? port : ruleObject?.application === "icmp" ? "0" : ""
			if(String(port).includes('-')){
				port1 = String(port).split('-')[0]
				port2 = String(port).split('-')[1]
			}
			let data = {
				"direction": "ingress",
				"port_range_min": (""+port1).trim(),
				"ethertype": "IPv4",
				"port_range_max": (""+port2).trim(),
				"protocol": ruleObject.protocol ? ruleObject.protocol : ruleObject?.application === "icmp" ? "icmp" : null,
				"security_group_id": server?.groups_with_ruls.id,
				"remote_ip_prefix": ip ? ip : null
			}
			return await UserMgt.addVpsGroupRule(server.Id, data)
		} catch (error) {
			return false
		}
	}
	async function onCreate(){
		try {
			const restrictedIp = [...new Set(Object.values(dynamicObject))] 
			console.log('RULE OBject ', ruleObject, restrictedIp)
			
			const application = ruleObject?.application
			if(!application){
					
				Toast.fire({
					icon: 'error',
					title: "Application field is required !",
				});
				return;
			}
			if(application !== "icmp"){
				if(!ruleObject?.protocol){
					
					Toast.fire({
						icon: 'error',
						title: "Protocol field is required !",
					});
					return;
				}
				if(!ruleObject?.port_range_min){
					
					Toast.fire({
						icon: 'error',
						title: "Port field is required !",
					});
					return;
				}
			}
			setCreatingRule(true)
			let result;
			if(rule && edition){
				const restul = await UserMgt.delVpsGroupRule(server.Id, rule.id)
				if(!restul || restul?.error){
					return messageApi.open({
						type: 'error',
						content: "Can't update this rule, please try again or conctact us !",
					});
				}
			}
			
			if(restrictedIp.length){
				for (let i = 0; i < restrictedIp.length; i++) {
					const ip = restrictedIp[i]
					result = await create(ip)
					console.log('result result', result)
				}
			}else{
				result = await create()
			}
			if(result && !result.error){
				messageApi.open({
					type: 'success',
					content: !edition ? 'Rule has been created.' :  "Rule has been updated.",
				});
				if(edition) {
					setCurrentEdit(null)
				}else{
					hideForm()
				}
				loadVps()
			}else{
				messageApi.open({
					type: 'error',
					content: result.message || "An error occured. If this persist, please tell us.",
				});
			}
		} catch (error) {
			console.log('errpr onCreate', error)
			messageApi.open({
				type: 'error',
				content:  "An error occured. If this persist, please tell us.",
			});
		}
		setCreatingRule(false)
	}
	return(
		<div className="p-6 bg-neutral-100">
			<div className="flex flex-wrap">
				<div className="2xl:w-[18%] w-full">
					<div className="flex flex-col w-[180px]">
						<span className="text-sm 2xl:text-base font-medium">Application</span>
						<InputSelect value={ruleObject.application} options={generals} name="application" onChange={setApplication}/>
					</div>
				</div>
				<div className="2xl:w-[44%] w-full mt-5 2xl:mt-0">
					<div className="flex flex-wrap items-center gap-4 w-full">

						<div className="flex flex-col w-[180px]">
							<span className="text-sm 2xl:text-base font-medium">Source</span>
							<InputText disabled={shouldDisabled} name="source" placeholder="" value={ruleObject.port_range_min} onChange={onChange} />
						</div>
						<div className="flex flex-col w-[180px]">
							<span className="text-sm 2xl:text-base font-medium">Port or range</span>
							<InputText disabled={shouldDisabled} name="port_range_min" placeholder="" value={ruleObject.port_range_min} onChange={onChange} />
						</div>
						<div className="flex flex-col pt-5">
							<Checkbox defaultChecked={!!rule?.remote_ip_prefix} className="font-medium" onChange={(e) => setRule({...ruleObject, restricted: e.target.checked})}>Restrict to IP address</Checkbox>
						</div>
					</div>
					<div className="pt-2">
						<div style={{display: ruleObject?.restricted ? "block" : "none"}}>
							<p className="text-sm font-medium"> 
								Specify IPs or IPs blocks to allow
							</p>
							<div>
								<p className="py-1 font-normal text-xs">Source IP addresses eg: (192.168.8.1) or (168.168.8.0/24) </p>
								{dynamicArr.map((dyn, i) => {
									return(
										<div className="flex w-[180px] relative gap-3 justify-center items-center pt-2">
											<InputText key={i} name={dyn} placeholder="127.0.0.1" value={dynamicObject[dyn]} onChange={onchaneDybject} disabled={false}/>
											
											<RemoveLineButton
												placeholder={"Remove line "}
												onFunction={()=> {
													onRemoveDynamic(dyn)
												}}
												outlined
												className="absolute left-[195px]"
											/>
											{/* <Button
												danger
												className=" absolute left-[195px] "
												icon={<MinusCircleOutlined className=""   />}
												onClick={()=>{
													onRemoveDynamic(dyn)
												}}
											/> */}
										</div>
									)
								})
							}
							<div className="pt-1">
								<Button
									icon={<PlusCircleOutlined className=""   />}
									onClick={()=> {
										dynamicArr.push(String(dynamicArr.length+1))
										setDynamicArr([...dynamicArr])
									}}
								/>

							</div>
							</div>
							<div></div>
						</div>
						
					</div>
				</div>

				<div className="2xl:w-[38%] w-full mt-5 2xl:mt-0">
					<div className="flex flex-wrap items-start gap-4 w-full">
						
						<div className="flex flex-col w-[180px]">
							<span className="text-sm 2xl:text-base font-medium">Destination</span>
							{destination && destination.map((d, i) => (
								<div className={`${d.id !== 1 ? "pt-2" : ""} relative flex gap-3`}>
									<InputText 
										key={i}
										name="dest_domain" 
										placeholder=""
										value={destination[i]?.dest_domain} 
										onChange={(ev)=>{
											console.log('d?.id d?.id ', d?.id, destination, i)
											destination[i] = {...d, [ev.target.name]: ev.target.value}
											setDest([...destination])
										}} 
									/>
									{d.id > 2 ?
										<Button
											danger
											className="absolute left-[195px]  "
											icon={<MinusCircleOutlined className=""   />}
											onClick={()=>{
												setDest([...destination.filter(dest => dest.id !== d.id)])
											}}
										/>
										:
										null
									}
								</div>
							))}
						</div>

						<div className="flex flex-col w-[180px]">
							<span className="text-sm 2xl:text-base font-medium">Port</span>
							<InputText name="dest_port" placeholder="" value={ruleObject.dest_port} onChange={onChange} />
						</div>
						<div className="pt-5">
							<Button 
								onClick={() => {
									destination.push({id: destination.length + 1})
									setDest([...destination])
								}} 
								type="primary" 
								title={"Add new distination line"} 
							>
								{"Add destinantion"}
							</Button>
						</div>
					</div>
				</div>

			</div>
			<div className="flex justify-end gap-4 mt-10 w-full">
				<Button onClick={() => !edition ? hideForm() : setCurrentEdit(null)}  title='Cancel' icon={<CloseCircleOutlined />} >
					Cancel
				</Button>
				<Button loading={creatingRule} onClick={() => onCreate()} type="primary" title={!edition ? "Create" : "Update"} icon={<CheckCircleOutlined />} >
					{!edition ? "Create" : "Update"}
				</Button>
			</div>
		</div>
			
	)
}

export function Rules({server, reloadBalance, loadBalancer}){
	const {messageApi} = useSelector(app => app.core )
	const [displayRuleComponent, setOnDisplayRuleComponent] = useState(false)

	async function onDeleteBalance(record){
		const body = {
			ksLbServerId: server?.Id,
			toDeleteId: record?.id,
			data: {
		    	protocol: record?.type,
			}
		}
		if(record?.type === "udp" || record?.type ==="tcp"){
			body.data.port = record.upstreamport
		}
		if(record?.source === "Default domain"){
			body.data.hostendpoint = server?.domain
		}else if(record?.source === "External"){
			body.data.hostendpoint = server?.OpenstackInstanceExtIp
		}else if(record?.source === "VPC"){
			body.data.hostendpoint = server?.addresses[server?.ksNetwork?.name]?.[0]?.addr
		}else if(record?.source === "InterconnectKS"){
			body.data.hostendpoint = server?.netmaker?.[0]?.node?.address?.IP
		}else if(record?.source === "Custom domain"){
			body.data.hostendpoint = record?.customDomain
		}
		const result = await LB.deleteBalance(body)
		console.log('result ', result)
		if(result && result.code === 200){
			messageApi.success("Load balancer deleted !");
			return reloadBalance(server?.Id)
		}
		else{
			messageApi.error(result?.message || STANDARD_ERROR_MESSAGE)
		}
	}

	const columns = [
	  {
	    title: 'Protocol',
	    render: (_,  record) => <span>{record.type}</span>,
	  },
	  {
	    title: 'Port',
	    render: (_, record) => <span>{record.upstreamport}</span>
	  },
	  {
	    title: 'Source',
		render: (_, record) => <div>
	    	{record.source}&nbsp;{record.httpsredirect ?<Badge count={"HTTPS Redirection"} /> : null}
	    	<br/>
	    	{record?.source === "Default domain"?
	    	 <span className="font-bold">{server?.domain}</span>
	    	 :
	    	 record?.source === "External"?
	    	 <span className="font-bold">{server?.OpenstackInstanceExtIp}</span>
	    	 :
	    	 record?.source === "VPC"?
	    	 <span className="font-bold">{server?.addresses[server?.vpc?.find(vpc => !!vpc?.isDefault)?.network?.name]?.[0]?.addr}</span>
	    	 :
	    	 record?.source === "InterconnectKS"?
	    	 <span className="font-bold">{server?.netmaker?.[0]?.node?.address?.IP}</span>
	    	 :
	    	 record?.source === "Custom domain"?
	    	 <span className="font-bold">{record?.customDomain}</span>
	    	 :
	    	 ""
	    	}
	    </div>,

	  },
	  {
	    title: 'Destination',
	    render: (_, record) => <span>{record.destinationType}</span>
	  },
	  {
	    title: 'Action',
	    key: 'action',
	    render: (_, record) => (
	      <Space size="middle">
	        <DeleteLineButton 
				placeholder="Rule"
				record={record}
				onDelete={onDeleteBalance}
			/>
	      </Space>
	    ),
	  },
	];

	return(
		<div>
			<div className="flex justify-between items-end ">
           		<p className="">
	           		<span className="font-bold text-primary text-xl 2xl:text-2xl">
						Load Balancer rules
					</span>	
					<br />	
					<span>	
						Create rules to open ports to the internet or to specific IPv4 or range
					</span>	
				</p>
				{!displayRuleComponent ?
					<Button  
						onClick={() => setOnDisplayRuleComponent(!displayRuleComponent)} 
						type="primary" 
						title={"Create new firewall rule"} 
						icon={<CheckCircleOutlined />} 
					>
						Create
					</Button>
					:
					null
				}
			</div>
			<div className="pt-5">
				{/* <Firewall
					onDisplayrull={displayRuleComponent}
					server={server}
					setOnDisplayRuleComponent={setOnDisplayRuleComponent}
					reload={reload}
				/> */}

				{displayRuleComponent ?
					<FormRuleFiwewall 
						messageApi={messageApi} 
						server={server} 
						hideForm={setOnDisplayRuleComponent} 
						loadVps={()=>{}}
					/>
					:
					null
				}

				<div className="pt-5">
					<Table columns={columns} dataSource={loadBalancer || []} />;
				</div>
			</div>
		</div>
	)
}
export function Services({server, loadBalancer, reloadBalance, loading}){
	const navigate = useNavigate()
	const {messageApi} = useSelector(app => app.core )
	
	async function onDeleteBalance(record){
		const body = {
			ksLbServerId: server?.Id,
			toDeleteId: record?.id,
			data: {
		    	protocol: record?.type,
			}
		}
		if(record?.type === "udp" || record?.type ==="tcp"){
			body.data.port = record.upstreamport
		}
		if(record?.source === "Default domain"){
			body.data.hostendpoint = server?.domain
		}else if(record?.source === "External"){
			body.data.hostendpoint = server?.OpenstackInstanceExtIp
		}else if(record?.source === "VPC"){
			body.data.hostendpoint = server?.addresses[server?.ksNetwork?.name]?.[0]?.addr
		}else if(record?.source === "InterconnectKS"){
			body.data.hostendpoint = server?.netmaker?.[0]?.node?.address?.IP
		}else if(record?.source === "Custom domain"){
			body.data.hostendpoint = record?.customDomain
		}
		const result = await LB.deleteBalance(body)
		console.log('result ', result)
		if(result && result.code === 200){
			messageApi.success("Load balancer deleted !");
			return reloadBalance(server?.Id)
		}
		else{
			messageApi.error(result?.message || STANDARD_ERROR_MESSAGE)
		}
	}
	const columns = [
	  {
	    title: 'Source',
	    render: (_, record) => <div>
	    	{record.source}&nbsp;{record.httpsredirect ?<Badge count={"HTTPS Redirection"} /> : null}
	    	<br/>
	    	{record?.source === "Default domain"?
	    	 <span className="font-bold">{server?.domain}</span>
	    	 :
	    	 record?.source === "External"?
	    	 <span className="font-bold">{server?.OpenstackInstanceExtIp}</span>
	    	 :
	    	 record?.source === "VPC"?
	    	 <span className="font-bold">{server?.addresses[server?.vpc?.find(vpc => !!vpc?.isDefault)?.network?.name]?.[0]?.addr}</span>
	    	 :
	    	 record?.source === "InterconnectKS"?
	    	 <span className="font-bold">{server?.netmaker?.[0]?.node?.address?.IP}</span>
	    	 :
	    	 record?.source === "Custom domain"?
	    	 <span className="font-bold">{record?.customDomain}</span>
	    	 :
	    	 ""
	    	}
	    </div>,
	  },
	  {
	    title: 'Type',
	    render: (_,  record) => <span>{record.type}</span>,
	  },
	  {
	    title: 'Port',
	    render: (_, record) => <span>{record.upstreamport}</span>
	  },
	  {
	    title: 'Destination',
	    render: (_, record) => <span>{record.destinationType}</span>
	  },
	  {
	    title: 'Services',
	    render: (_, record) => <div>
	    	{	
				record.services?.length ? 
					<>
						{record.services?.map((serv, i) =>{
							return(
								<Tooltip key={i} title={`${serv?.domain} (${serv?.OpenstackInstanceExtIp})`} >
									<div className="font-semibold text-xs lg:text-sm 2xl:text-base">
										{serv?.DisplayName}
									</div>
								</Tooltip>
						)})}
					</>
					:
				null
			}
			{	
				record.kubernetes?.length ?
					<>
						{record.kubernetes?.map((k8s, i) =>{
							return(
								<div key={i} className="font-semibold text-xs lg:text-sm 2xl:text-base">
									{k8s?.clusters?.find(x => !!x.isPrimaryInstance)?.name}
								</div>
						)})}
					</>
					:
					null
			}
			{	
				record.customDestination ?
					<>
						{JSON.parse(record.customDestination)?.map((domain, i) =>{
							return(
								<div key={i} className="font-semibold text-xs lg:text-sm 2xl:text-base">
									{domain}
								</div>
						)})}
					</>
					:
					null
			}
	    </div>
	  },
	  {
	    title: 'Action',
	    key: 'action',
	    render: (_, record) => (
	      <Space size="middle">
			<DeleteLineButton 
				placeholder="Load Balancer"
				record={record}
				onDelete={onDeleteBalance}
			/>
	      </Space>
	    ),
	  },
	];

//$fj84-_+3fh4TwTlask prod 3
	return(
		<div>
			<div className="flex justify-between ">
           		<span className="font-bold text-primary text-xl 2xl:text-2xl">
					Services
				</span>	
				<span className="flex gap-4">	
					{loadBalancer?.length ?
						<Button 
							className="mx-auto px-3 font-semibold h-8 2xl:h-10 " 
							style={{borderRadius: "0px"}} 
							type="primary"
							onClick={
								()=>navigate('/networking/kslb/'+server?.Id+'/load-balance/new')
							}
						>
							Choose a service
						</Button>
						:
						null
					}
					<ExtLink 
						href={"javascript:;"}
						isLean
					/>
				</span>	

			</div>
			<div className="lg:flex lg:justify-between items-stretch">
				<div className="w-full lg:w-[75%] mt-5 ">
						{loading?
							<div className="flex items-center justify-center h-36">
								<Spiner fontSize={40} />
							</div>
							:
							loadBalancer?.length ?
								<div className="  ">
									<Table columns={columns} dataSource={loadBalancer} />
								</div>
							:
								<div className=" border border-gray-300 border-1 p-8 text-center h-full  justify-center items-center">

									<div>	
										<img
										src={setting}
										alt="ks lib image"
										className="mx-auto h-20 xl:h-24  2xl:h-28"
										/>

										<p className="mt-5">
											<span className="text-sm lg:text-base text-gray-700 2xl:text-xl font-semibold">
												Select service to load balance
											</span>
											<br/>
											<br/>
											<span className=" text-sm 2xl:text-base text-gray-700">
												The load balancer will distribue trafic between specific services.
												Services can be selected by name or by tag and must be located at the same datacenter
												as the Load balancer or in a VPC
											</span>
											<br/>
											<br/>
											<Button 
												className="mx-auto px-5 font-semibold h-10 2xl:h-12 " 
												style={{borderRadius: "0px"}} 
												type="primary"
												onClick={
													()=>navigate('/networking/kslb/'+server?.Id+'/load-balance/new')
												}
											>
												Choose a service
											</Button>

										</p>
									</div>
								</div>
						}
				</div>
						

				<div className="w-full border mt-5 border-1 border-gray-200 bg-neutral-50 py-3 px-4 h-full lg:w-[24%] ">
				
					<p>
						<span className="text-sm lg:text-base text-gray-700 2xl:text-xl font-semibold">
							Load balancing basics
						</span>
					</p>
					<div>
						<div className="py-3">
							<ExtLink 
								href={"javascript:;"}
								text="Load balancer overview"
							/>
							<br/>
							<span className="text-xs 2xl:text-sm">
								Lean about KeepSec Load balancer or folow
								{" "}
								<ExtLink 
									href={"javascript:;"}
									text={<span className='text-sm'>Our guide to creating one</span>}
								/>
							</span>
						</div>
						<div className="py-4">
							<ExtLink 
								href={"javascript:;"}
								text="API docs"
							/>
							<br/>
							<span className="text-xs 2xl:text-sm">
								Use the KeepSec Api to create and manage Load balancer programmatically.
							</span>
						</div>
						<div className="py-4">
							<ExtLink 
									href={"javascript:;"}
									text="Tell us what your think"
								/>
							<br/>
							<span className="text-xs 2xl:text-sm">
								Submit your feelback on Load balancers.
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}