import { useState, useEffect } from 'react';
import UserMgt from "../../lib/user_managment"
import { Logo } from '../../components/Logo';
import { Toast } from '../../components/Notify';
import {  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import "./Auth.css"
import { useTranslation } from 'react-i18next';

export default function RequestResetPassword() {
    const { t } = useTranslation();
    const [objectRequest, setObject] = useState({})
    const [creating, setCreating] = useState(false)
    const navigate = useNavigate()
    const user = useSelector(app => app?.user?.user)
    
    const handleChange = (ev) => {
        const {value, name} =  ev.target
        setObject({...objectRequest, [name]: value})
    }

    useEffect(() => {
        
        if(user) return navigate('/')
    }, [])


    async function onRequest(e){ 
        e.preventDefault() 
     
        setCreating(true)
        try {
            const result = await UserMgt.reset_password_request({...objectRequest})
            setCreating(false)
            if(result?.status==="success"){
                Toast.fire({
                    icon: 'success',
                    title: "If this email is associated to an account, the reset password link should be emailed."// 'Check your mailbox for your reset password link'
                })

                navigate('/login')
                
            }else{ 

                return Toast.fire({
                    icon: 'error',
                    title: result.message || 'An error occurs, check email address provided !'
                })
                
            }
        } catch (error) {
            setCreating(false)
            console.log('error api', error)
            Toast.fire({
                icon: 'error',
                title: error.message
            })
        }
        //navigate('/')

    }
    return (
        <div className='bgCustom'>
            {/* <video autoplay={true} muted={true} loop={true} className="myVideo">
                <source src={require('../../assets/img/keepsecbackgroud.mp4')} type="video/mp4"/>
            </video> */}

            <div className="flex items-center justify-center min-h-screen  content">
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-2xl w-[400px]">
                    <div>
                        <Logo/>
                        <h2 className="mt-4 text-3xl font-extrabold text-center text-gray-900">
                            {t('Request password reset form')}
                        </h2>
                        <p className="mt-2 text-sm text-center text-gray-600">
                            {t('Not a member yet?')}<br />
                            <NavLink
                                to="/registrations/new"
                                className={"font-medium text-primary hover:text-blue-500 cursor-pointer"}
                            >
                                {t('Sign up')}
                            </NavLink>
                              
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" method="POST" onSubmit={onRequest}>
                        <div className="flex flex-wrap -mx-3 mb-0">
                            <div className="w-full px-3 mb-0 md:mb-0">
                                <input
                                    className="
                                        appearance-none
                                        block
                                        w-full
                                        bg-gray-200
                                        text-gray-700
                                        border
                                        rounded
                                        py-3
                                        px-4
                                        mb-3
                                        leading-tight
                                        focus:outline-none focus:bg-white
                                    "
                                    value={objectRequest.userEmail}
                                    onChange={handleChange}
                                    name='userEmail'
                                    required
                                    type="email"
                                    placeholder={t("Enter the email addres")}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3"></div>

                        {/* <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="
                                        w-4
                                        h-4
                                        text-primary
                                        border-gray-300
                                        rounded
                                        focus:ring-blue-500
                                    "
                                />
                                <label htmlFor="remember-me" className="block ml-2 text-sm text-gray-900"
                                >Remember me</label>
                            </div>

                            <div className="text-sm">

                                <span className="font-medium text-primary hover:text-blue-500 cursor-pointer"
                                >
                                    Forgot your password?
                                </span>
                            </div>
                        </div> */}

                        <div>
                            <button
                                type="submit"
                                className="
                                relative
                                flex
                                justify-center
                                w-full
                                px-4
                                py-2
                                text-sm
                                font-medium
                                text-white
                                bg-primary
                                group
                                rounded-md
                                hover:bg-black hover:border-primary
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-blue-500
                                gap-2
                                "
                            >
                                {!creating ?
                                    <svg
                                        className="w-5 h-5 text-white group-hover:text-indigo-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    :
                                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white"  fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                }
                                {!creating ?
                                    <span>{t('Send now')}</span> :
                                    <span>{t('Ongoing')} ...</span>
                                }
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
}












