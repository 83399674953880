import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageTitle } from "components/Bloc";

import volume from "../../../assets/img/storage/volume.png"
import { Spiner } from '../../../components/Bloc'
import { ModalIncreaseSize, TableFunction } from '../../../components/volume/VolumeSection'
import { useTranslation } from 'react-i18next';
import { Texto } from 'layouts/TextoLayout';

export default function StorageVps({ server, loadVps }) {
   
    const navigate = useNavigate()
    const [editedVolume, setEditVolume] = useState(null)
    const { t } = useTranslation();

    useEffect(() => {
        // listStorageVolume()  
    }, [])

    return (
        <div className='w-full '>
            <div className='flex justify-between items-start w-full'>
                <div className='pb-4'>
                    <div className="">
                        
                        <PageTitle text={t('compute.vps.storageTab.h1')} />
                    </div>
                    {server?.volumes && server?.volumes.length ?
                        <Texto small className="pt-2">
                            {t('volume.desc')}
                        </Texto>
                        :
                        null
                    }
                </div>
                {server?.volumes && server?.volumes.length ?
                    <Button 
                    className="w-32 font-semibold h-10 2xl:h-12 " 
                    style={{borderRadius: "0px"}} 
                    type="primary"
                    onClick={
                        ()=>navigate('/storage/volume/deploy?selected='+server?.Id)
                    }
                    >
                        {t('volume.create')}   
                    </Button>
                    :
                    null
                }
            </div>

            {!false ?
                <div>
                    <ModalIncreaseSize 
                        open={editedVolume} 
                        setOpen={setEditVolume} 
                        vlume={editedVolume} 
                        reloadVolumeList={loadVps} 
                    />
                	{server?.volumes && server?.volumes.length ?
                        <TableFunction 
                            setEditVolume={setEditVolume}  
                            data={server?.volumes}
                            reloadVolumeList={loadVps}
                            isVps
                        />
                         :
                        <div>
                            <div className="border border-primary border-1 py-8 flex flex-col justify-center">
                                <img src={volume} className="w-28 mx-auto" />
                                <div className="text-center py-5">
                                    <Texto className="font-semibold">
                                        {t('volume.lookDOntHave')}
                                    </Texto> 
                                    <br/>
                                    <Texto small>
                                    {t('volume.desc')}
                                    </Texto> 
                                </div>
                                <Button 
                                    className="mx-auto w-32 font-semibold h-10 2xl:h-12 " 
                                    style={{borderRadius: "0px"}} 
                                    type="primary"
                                    onClick={
                                        ()=>navigate('/storage/volume/deploy?selected='+server?.Id)
                                    }
                                >
                                   {t('volume.create')}
                                </Button>
                            </div>
                        </div>
                    }
                </div> 
                :
                <div className='flex justify-center'>
                    <Spiner fontSize={40} />
                </div>
            }
        </div> 
    );
}
