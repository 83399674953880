import React, { useState, useCallback } from 'react'
import { Table, Tag, Space, Button, Tooltip, Modal, Tabs, Input, Switch, InputNumber  } from 'antd';
import { PlusOutlined, QuestionCircleOutlined, LockOutlined, WarningOutlined } from '@ant-design/icons';
import { DownOutlined, SmileOutlined, SettingOutlined  } from '@ant-design/icons';
import { Dropdown, Select, Typography } from 'antd'
import { ReactTags } from 'react-tag-autocomplete'

const { Text  } = Typography;

const { TextArea } = Input;

export const Details = ({setObjectHost, objectHost, host404}) => {
    const onValidate = useCallback((value) => true, [])
    const onAdd = useCallback(
      (newTag) => {
        const d = objectHost.domain_names ||[]
        d.push(newTag)
        objectHost.domain_names = d
        setObjectHost({...objectHost})
      },
      [objectHost.domain_names]
    )

    const onDelete = useCallback(
      (tagIndex) => {
        setObjectHost({
          ...objectHost, 
          domain_names: objectHost?.domain_names.filter((_, i) => i !== tagIndex)
        })
      }
    )

  return(
    <div>
      <div>
        <h5 className="font-semibold">Domain Names <span className="text-red-500">*</span></h5>
        

       <ReactTags
          allowNew
          ariaDescribedBy="custom-tags-description"
          collapseOnSelect
          id="custom-tags-demo"
          placeholderText ="Typ a domain"
          onAdd={onAdd}
          onDelete={onDelete}
          onValidate={onValidate}
          selected={objectHost?.domain_names}
          suggestions={[]}
        />
      </div>

      <div style={{display: host404 && "none"}} className="flex mt-5 items-center justify-between w-full">
        <div className="w-full md:w-[32%]">
          <h5 className="font-semibold">Scheme <span className="text-red-500">*</span></h5>
          <Select
            value={objectHost.forward_scheme}
            style={{
              width: '100%',
            }}
            onChange={(value) =>setObjectHost({...objectHost, forward_scheme: value})}
            options={[
              {
                value: '$scheme',
                label: 'Auto',
              },
              {
                value: 'http',
                label: 'HTTP',
              },
              {
                value: 'https',
                label: 'HTTPS',
              }
            ]}
          />

        </div>
        <div className="w-full md:w-[64%]">
          <h5 className="font-semibold">Forward Domain <span className="text-red-500">*</span></h5>
          <Input
              onChange={ev => {
                setObjectHost({...objectHost, forward_domain_name: ev.target.value})
              }} 
              value={objectHost.forward_domain_name}
              className='rounded w-full' 
              placeholder={`Exp: keepsec.ca`} 
          />
        </div>

      </div>

      <div style={{display: host404 && "none"}} className=" mt-5 items-center w-full">
        <h5 className="font-semibold">HTTP Code <span className="text-red-500">*</span></h5>
        <Select
            value={objectHost.forward_http_code}
            style={{
              width: '100%',
            }}
            onChange={(value) =>setObjectHost({...objectHost, forward_http_code: value})}
            options={[
              {
                value: '300',
                label: '300 Multiple choices',
              },
              {
                value: '301',
                label: '301 Moved permanently',
              },
              {
                value: '302',
                label: '302 Found',
              },
              {
                value: '303',
                label: '303 See other',
              },
              {
                value: '307',
                label: '307 Temporary redirect',
              },
              {
                value: '308',
                label: '308 Permanent redirect',
              }
            ]}
          />
      </div>


      <div style={{display: host404 && "none"}} className="flex flex-wrap justify-between mt-5">
        <div className="flex gap-2 items-center">
          <Switch 
            checked={objectHost.preserve_path} 
            className='bg-gray-200'
            onChange={(checked)=>setObjectHost({...objectHost, preserve_path: checked})} 
          />
          <span className="font-semibold">Preserve path</span>
        </div>
        <div className="flex gap-2 items-center">
          <Switch  
            onChange={(checked)=>setObjectHost({...objectHost, block_exploits: checked})} 
            checked={objectHost.block_exploits} 
            className='bg-gray-200'
          />
          <span className="font-semibold">Block common exploits</span>
        </div>
      </div>

    </div>
  )
}