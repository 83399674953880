export function Texto({className, children, small, verySmall, big, normal}){
    return(
        <p className={` 
                ${className}
                ${normal ? "text-base 2xl:text-xl" : ""}
                ${!normal&&small ? "text-sm 2xl:text-base" : "" } 
                ${!normal&&verySmall ? "text-xs" : ""}  
                ${!normal&&big ? "2xl:text-3xl text-xl xl:text-2xl" : ""}
            `
            }
        >
            {children}
        </p>
    )
}