export const pages = {
	en: {
		"vps": {
			title: "VPS in a minutes.",
			desc: "KeepSec VPS are Linux-based virtual machines (VMs) that run on top of virtualized hardware. Each VPS you create is a new server you can use, either standalone or as part of a larger, cloud-based infrastructure.",
			items: [
				{
					src: "manag.jpg",
					title: "Managed Services",
					text: "Managing self-hosted clusters requires expertise and time. We do the heavy-lifting by managing key services and settings on your behalf."
				},
				{
					src: "color.jpg",
					title: "CPU pinning and supports nested virtualization",
					text: "Nesting Enables complex testing, isolated training, and ability to create multiple virtual environments. CPU Pinning Optimizes performance, reduces latency, ensures stability, and efficient utilization and isolation."
				},
				{
					src: "carr.png",
					title: "No egress fees, ever",
					last: true,
					text: "You get free unlimited data transfer as standard at KeepSec. We'll never charge you ingress or egress fees - whether you stay or ever wish to leave."
				},
			],
			link: "/compute/vps/deploy",
			buttonLabel: "Create a VPS",
			options: [
				"Super-fast launch times",
				"Multi-region support",
				"24/7 technical support",
				"Flexible automation tools",
				"Connect with SSH or our in-app console",
			]
		},
		"vpsmini": {
			title: "Secure and scalable containerized VPS",
			desc: "VPSMini offers an affordable, unique mix of shared and dedicated resources, ideal for small workloads. You always have guaranteed access to dedicated CPU and RAM, with generous amounts of easily accessible extra resources at no additional cost.",
			items: [
				{
					src: "manag.jpg",
					title: "Significant price-to-performance ratio",
					text: "VPSM plans are ideal for development servers, staging servers, low traffic websites, personal blogs, and production applications that may not be affected by resource contention."
				},
				{
					src: "color.jpg",
					title: "File Manager and SSH Web Console",
					text: "Upload file and connect via SSH directly from your web browser, using our web-based SSH clients and file manager for easy, secure server management."
				},
				{
					src: "carr.png",
					title: "Integrated WAF and Security by design",
					last: true,
					text: "Possibility to use your own domain with the integrated WAF, no firewall to manage, the most important port are open 80, 443, 8080, 8443, 3000 are accessible via the keepsec domain our own, SSH and FTP port are accessible directly via the IP."
				},
			],
			link: "/compute/vpsmini/deploy",
			buttonLabel: "Create a VPSM",
			options: [
				"One-click app install",
				"Real-time protection",
				"Lightning speed",
				"Rapid Reconfiguration",
				"Scalable",
			]
		},
		"khks": {
			title: "Business-ready Kubernetes",
			desc: "Harness the power of automation and leave the Kubernetes infrastructure to us so you can focus on building world-changing apps.",
			items: [
				{
					src: "manag.jpg",
					title: "Managed services",
					text: "Managing self-hosted clusters requires expertise and time. We do the heavy-lifting by managing key services and settings on your behalf."
				},
				{
					src: "color.jpg",
					title: "Full Kubernetes API",
					text: "Provision a KeepSec Kubernetes cluster and we provide a control plane and endpoint to use with the kubectl CLI or any Kubernetes ecosystem tool."
				},
				{
					src: "carr.png",
					title: "High Availability",
					last: true,
					text: "It possible to have you cluster in two location to achieve real high availability."
				},
			],
			link: "/kubernetes/kskubhybr/deploy",
			buttonLabel: "Create a Kubernetes Cluster",
			options: [
				"Mulitple control plane",
				"Portable and flexible",
				"Affordable pricing",
				"Automatic scaling",
				"Integrated load balancers and persistent storage",
			]
		},
		"vullify": { 
			title: "Vullify a Vulnerability Scanner.",
			desc: "Vullify is a security diagnosis service that uses weakness detection and intelligent correlation analysis technologies to help discover security risks in your websites or servers",
			items: [
				{
					src: "manag.jpg",
					title: "Website Vulnerability Scan",
					text: "Website vulnerabilities can cripple your business. They can result in tremendous financial losses if not discovered and addressed quickly."
				},
				{
					src: "color.jpg",
					title: "Scans for Common Vulnerabilities",
					text: "All types of websites are scanned based on an extensive vulnerability library, and comprehensive, professional reports are produced."
				},
				{
					src: "carr.png",
					title: "Scans for Most Recent Critical Vulnerabilities",
					last: true,
					text: "Security experts are constantly analyzing the latest critical vulnerabilities and updating scan rules to provide the fastest and most-complete CVE vulnerability scan possible."
				},
			],
			link: "/security/vullify/deploy",
			buttonLabel: "Create a Vullify",
			options: [
				"Asset Discovery",
				"Advanced Detection",
				"Agentless Onboarding",
				"Easy-To-Deploy",
				"Cost Effective",
			]
		},
		"vpsminis": {
			title: "",
			desc: "",
			items: [
				{
					title: "",
					text: ""
				},
				{
					title: "",
					text: ""
				},
				{
					title: "",
					text: ""
				},
			],
			link: "/compute/vps/deploy",
			buttonLabel: "Create a VPS",
			options: [
				"Asset Discovery",
				"Advanced Detection",
				"Agentless Omboarding",
				"Easy-To-Deploy",
				"Cost Effective",
			]
		},
		"kps": {
			title: "Deploy Applications Effortlessly",
			desc: "KPS enables you to deploy containerized applications seamlessly. With our platform, you can focus on development while we handle the infrastructure, offering you the scalability and flexibility needed to grow your business.",
			items: [
				{
					src: "manag.jpg",
					title: "Fully Managed Platform",
					text: "Our PaaS handles all infrastructure management tasks, including automated scaling, efficient load balancing, and robust security, allowing you to concentrate solely on building your application."
				},
				{
					src: "color.jpg",
					title: "Seamless Integration and Deployment",
					text: "Easily integrate with popular CI/CD tools for smooth and continuous deployment. Our platform supports major container orchestration tools, ensuring optimal performance for your applications."
				},
				{
					src: "carr.png",
					title: "Pay-as-you-go Pricing",
					last: true,
					text: "With KeepSec PaaS, you only pay for the resources you use. Our transparent pricing model ensures no hidden fees or unexpected charges."
				}
			],
			link: "/app-platform/kps/deploy",
			buttonLabel: "Create a KPS",
			options: [
				"Quick and easy setup",
				"Scalable infrastructure",
				"Integrated monitoring and logging",
				"200+ One-click app deployment",
				"Custom domain with automated SSL management",
			]
		},
		kwaf: {
			title: "Protect Your Web Applications ",
			desc: "K-WAF provides advanced protection for your web applications using AI-driven security measures. Our firewall ensures that your applications remain safe from malicious attacks while maintaining high performance and reliability.",
			items: [
				{
					src: "manag.jpg",
					title: "AI-Driven Security",
					text: "K-WAF employs cutting-edge AI algorithms to detect and mitigate threats in real-time. This proactive approach ensures that your applications are always protected against the latest vulnerabilities."
				},
				{
					src: "color.jpg",
					title: "Comprehensive Threat Detection",
					text: "Our platform offers extensive coverage against various attack vectors, including SQL injection, cross-site scripting (XSS), and other common web vulnerabilities, ensuring comprehensive protection."
				},
				{
					src: "carr.png",
					title: "High Performance and Reliability",
					last: true,
					text: "With K-WAF, you can achieve top-notch security without compromising on performance. Our firewall is optimized for minimal latency, ensuring a smooth user experience for your applications."
				}
			],
			link: "/security/kwaf/deploy",
			buttonLabel: "Create a K-WAF",
			options: [
				"Real-time threat intelligence",
				"Automated threat mitigation",
				"Customizable security policies",
				"Detailed security analytics",
				"24/7 monitoring and alerts",
				"Scalable protection for growing applications",
				"User-friendly management console",
			]
		}
	},
	fr: {
		"vps": {
			title: "VPS en quelques minutes",
			desc: "Les VPS KeepSec sont des machines virtuelles Linux (VM) qui s'exécutent sur du matériel virtualisé. Chaque VPS que vous créez est un nouveau serveur que vous pouvez utiliser, soit de manière autonome, soit dans le cadre d'une infrastructure plus large basée sur le cloud.",
			items: [
			  {
				src: "manag.jpg",
				title: "Services managés",
				text: "La gestion de clusters auto-hébergés nécessite de l'expertise et du temps. Nous effectuons les tâches les plus lourdes en gérant les services et les paramètres clés en votre nom."
			  },
			  {
				src: "color.jpg",
				title: "Épinglage du CPU et prise en charge de la virtualisation imbriquée",
				text: "L'imbrication permet des tests complexes, une formation isolée et la possibilité de créer plusieurs environnements virtuels. L'épinglage du CPU optimise les performances, réduit la latence, assure la stabilité et une utilisation et un isolement efficaces."
			  },
			  {
				src: "carr.png",
				title: "Pas de frais de sortie, jamais",
				last: true,
				text: "Vous bénéficiez gratuitement d'un transfert de données illimité en standard chez KeepSec. Nous ne vous facturerons jamais de frais d'entrée ou de sortie, que vous restiez ou souhaitiez quitter."
			  }
			],
			link: "/compute/vps/deploy",
			buttonLabel: "Créer un VPS",
			options: [
			  "Temps de lancement ultra-rapides",
			  "Support multi-régional",
			  "Support technique 24/7",
			  "Outils d'automatisation flexibles",
			  "Se connecter avec SSH ou notre console intégrée"
			]
		},
		vpsmini: {
			title: "VPS conteneurisé sécurisé et évolutif",
			desc: "VPSMini offre un mélange abordable et unique de ressources partagées et dédiées, idéal pour les petites charges de travail. Vous avez toujours un accès garanti à un processeur et une RAM dédiés, avec des quantités généreuses de ressources supplémentaires facilement accessibles sans frais supplémentaires.",
			items: [
			  {
				src: "manag.jpg",
				title: "Rapport prix-performances significatif",
				text: "Les plans VPSM sont idéaux pour les serveurs de développement, les serveurs de staging, les sites Web à faible trafic, les blogs personnels et les applications de production qui peuvent ne pas être affectées par la contention des ressources."
			  },
			  {
				src: "color.jpg",
				title: "Gestionnaire de fichiers et console Web SSH",
				text: "Téléchargez des fichiers et connectez-vous via SSH directement depuis votre navigateur Web, en utilisant nos clients SSH et gestionnaires de fichiers basés sur le Web pour une gestion facile et sécurisée du serveur."
			  },
			  {
				src: "carr.png",
				title: "WAF intégré et sécurité par conception",
				last: true,
				text: "Possibilité d'utiliser votre propre domaine avec le WAF intégré, pas de pare-feu à gérer, les ports les plus importants sont ouverts 80, 443, 8080, 8443, 3000 sont accessibles via le domaine KeepSec, les ports SSH et FTP sont accessibles directement via l'IP."
			  }
			],
			link: "/compute/vpsmini/deploy",
			buttonLabel: "Créer un VPSM",
			options: [
			  "Installation d'applications en un clic",
			  "Protection en temps réel",
			  "Vitesse éclair",
			  "Reconfiguration rapide",
			  "Évolutif"
			]
		},
		khks: {
			title: "Kubernetes prêt pour les entreprises",
			desc: "Exploitez la puissance de l'automatisation et laissez-nous nous occuper de l'infrastructure Kubernetes afin que vous puissiez vous concentrer sur la création d'applications révolutionnaires.",
			items: [
			  {
				src: "manag.jpg",
				title: "Services managés",
				text: "La gestion de clusters auto-hébergés nécessite de l'expertise et du temps. Nous effectuons les tâches les plus lourdes en gérant les services et les paramètres clés en votre nom."
			  },
			  {
				src: "color.jpg",
				title: "API Kubernetes complète",
				text: "Provisionnez un cluster KeepSec Kubernetes et nous vous fournirons un plan de contrôle et un point d'accès à utiliser avec l'interface de ligne de commande kubectl ou tout autre outil de l'écosystème Kubernetes."
			  },
			  {
				src: "carr.png",
				title: "Haute disponibilité",
				last: true,
				text: "Il est possible d'avoir votre cluster dans deux emplacements pour atteindre une véritable haute disponibilité."
			  }
			],
			link: "/kubernetes/kskubhybr/deploy",
			buttonLabel: "Créer un cluster Kubernetes",
			options: [
			  "Plans de contrôle multiples",
			  "Portable et flexible",
			  "Tarification abordable",
			  "Mise à l'échelle automatique",
			  "Chargeurs de travail intégrés et stockage persistant"
			]
		},
		"vullify": {
			title: "Vullify, un scanner de vulnérabilités",
			desc: "Vullify est un service de diagnostic de sécurité qui utilise des technologies de détection des faiblesses et d'analyse intelligente des corrélations pour aider à découvrir les risques de sécurité de vos sites Web ou serveurs.",
			items: [
			{
				src: "manag.jpg",
				title: "Analyse des vulnérabilités des sites Web",
				text: "Les vulnérabilités des sites Web peuvent paralyser votre entreprise. Elles peuvent entraîner des pertes financières considérables si elles ne sont pas découvertes et résolues rapidement."
			},
			{
				src: "color.jpg",
				title: "Analyse des vulnérabilités courantes",
				text: "Tous les types de sites Web sont analysés sur la base d'une bibliothèque étendue de vulnérabilités, et des rapports complets et professionnels sont produits."
			},
			{
				src: "carr.png",
				title: "Analyse des vulnérabilités critiques les plus récentes",
				last: true,
				text: "Des experts en sécurité analysent constamment les dernières vulnérabilités critiques et mettent à jour les règles d'analyse pour fournir l'analyse de vulnérabilité CVE la plus rapide et la plus complète possible."
			}
			],
			link: "/security/vullify/deploy",
			buttonLabel: "Créer un Vullify",
			options: [
			"Découverte des actifs",
			"Détection avancée",
			"Intégration sans agent",
			"Facile à déployer",
			"Rentable"
			]
		},
		"vpsminis": {
			title: "",
			desc: "",
			items: [
			{
				title: "",
				text: ""
			},
			{
				title: "",
				text: ""
			},
			{
				title: "",
				text: ""
			}
			],
			link: "/compute/vps/deploy",
			buttonLabel: "Créer un VPS",
			options: [
			"Découverte des actifs",
			"Détection avancée",
			"Intégration sans agent",
			"Facile à déployer",
			"Rentable"
			]
		},
		"kps": {
			title: "Déployer des applications sans effort",
			desc: "KPS vous permet de déployer des applications conteneurisées de manière transparente. Avec notre plateforme, vous pouvez vous concentrer sur le développement tout en nous laissant gérer l'infrastructure, vous offrant l'évolutivité et la flexibilité nécessaires pour développer votre entreprise.",
			items: [
			{
				src: "manag.jpg",
				title: "Plateforme entièrement gérée",
				text: "Notre PaaS gère toutes les tâches de gestion de l'infrastructure, notamment la mise à l'échelle automatisée, l'équilibrage de charge efficace et la sécurité robuste, vous permettant de vous concentrer uniquement sur la création de votre application."
			},
			{
				src: "color.jpg",
				title: "Intégration et déploiement transparents",
				text: "Intégrez facilement avec les outils CI/CD populaires pour un déploiement fluide et continu. Notre plateforme prend en charge les principaux outils d'orchestration de conteneurs, assurant des performances optimales pour vos applications."
			},
			{
				src: "carr.png",
				title: "Tarification à l'utilisation",
				last: true,
				text: "Avec KeepSec PaaS, vous ne payez que pour les ressources que vous utilisez. Notre modèle de tarification transparent garantit l'absence de frais cachés ou de frais inattendus."
			}
			],
			link: "/app-platform/kps/deploy",
			buttonLabel: "Créer un KPS",
			options: [
			"Configuration rapide et facile",
			"Infrastructure évolutive",
			"Suivi et journalisation intégrés",
			"Déploiement d'applications en un clic (plus de 200)",
			"Domaine personnalisé avec gestion SSL automatisée"
			]
		},
		"kwaf": {
			title: "Protégez vos applications Web",
			desc: "K-WAF offre une protection avancée pour vos applications Web grâce à des mesures de sécurité basées sur l'IA. Notre pare-feu garantit la sécurité de vos applications contre les attaques malveillantes tout en maintenant des performances et une fiabilité élevées.",
			items: [
			{
				src: "manag.jpg",
				title: "Sécurité basée sur l'IA",
				text: "K-WAF utilise des algorithmes d'IA de pointe pour détecter et atténuer les menaces en temps réel. Cette approche proactive garantit que vos applications sont toujours protégées contre les dernières vulnérabilités."
			},
			{
				src: "color.jpg",
				title: "Détection complète des menaces",
				text: "Notre plateforme offre une couverture étendue contre divers vecteurs d'attaque, notamment les injections SQL, les scripts intersites (XSS) et autres vulnérabilités Web courantes, garantissant une protection complète."
			},
			{
				src: "carr.png",
				title: "Performances et fiabilité élevées",
				last: true,
				text: "Avec K-WAF, vous pouvez atteindre un niveau de sécurité optimal sans compromettre les performances. Notre pare-feu est optimisé pour une latence minimale, garantissant une expérience utilisateur fluide pour vos applications."
			}
			],
			link: "/security/kwaf/deploy",
			buttonLabel: "Créer un K-WAF",
			options: [
			"Intelligence des menaces en temps réel",
			"Atténuation automatisée des menaces",
			"Politiques de sécurité personnalisables",
			"Analyses de sécurité détaillées",
			"Surveillance et alertes 24/7",
			"Protection évolutive pour les applications en croissance",
			"Console de gestion conviviale"
			]
		}
	}
}