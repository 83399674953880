export default{
    khks: {
        deploy: {
            addNewNode: "Add New Node",
            addNewControlPlane: "Add Control Plane",
        },
    },
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
}