import React, { useState, useEffect } from "react";
import { OkButton } from "../../components/OkButton";
import UserMgt from '../../lib/user_managment';
import { message } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import KSProxy from '../../pages/ksproxy/KSProxy'
import Utils from '../../utils'
import { updateHeadLink } from "reducer/action/coreActions";
import { useDispatch, useSelector } from "react-redux";

export default function KProxyWrap(){
	const { id } = useParams()
	const [server, setvps] = useState(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [messageApi, contextHolder] = message.useMessage()

	const loadVps = async () => {
        const vps = await UserMgt.getUniqueVps(id) //.user_vps
        console.log('Server server ', vps)
        if(!vps?.error){
            setvps(vps?.server)
            return vps?.server
        }else{
            messageApi.open({
                type: 'error',
                content: 'An error occured, if this persist, please tell us',
            });
            return navigate(-1)
        }
    }


    useEffect(()=>{
        loadVps().then((server) => {
            const headLinks = [
                {
                    link: "/security/vullify",
                    item: 'vullify',
                    alt: "Vullify short link"
                },
                {label: server?.DisplayName, arrow: true, link: "security/vullify/"+server?.Id+"/connect"},
                {
                    // link: "/security/vullify/",
                    label: 'KSProxy',
                    // alt: "Vullify short link",
                    arrow: true
                }
            ]
            dispatch(updateHeadLink(headLinks))
                
        })
        return () => dispatch(updateHeadLink([]))
    }, [])

    return(
    	<div className="px-3 md:px-8 pb-10 pt-2">
			<KSProxy server={server} />
		</div>
    )
}
