const {  get, post } = require("./api")

const END_POINTS ="http://72.10.174.19:5000/execute_algox" //'https://zebra-04.api.keepsec.ca/'//"http://72.10.163.146:8889/"
const API_USER = "keepsec_pub-api" // "keepsec_api"
const API_PASSWORD = "keepsec_pub-api"// "2adv4nc3d!"

const header = {'Authorization': 'Basic ' + btoa(API_USER+":"+API_PASSWORD)}
//const end_points = "http://72.10.163.146:8889/tickets/"
const data = {
  "password": "gAAAAABkbRR8-DCuysiYCueULGzuTjgQhZkAtiOP6e6LMeAVDsSvYKMe_cYGzgh6sRphM0jiPMHDbKoeTlxN5yFyFSJQCKMPVW-U6d1uZ2OERPQib3muK2M="
}

const get_ticket = async () => {
    try {
      const data2 = new URLSearchParams({
        'password': data.password,
      })
      const tickets = await post(END_POINTS, data2, header, false)
      console.log('tickets retreived >>', tickets)
      return tickets
    } catch (error) {
      console.error('error wrong when try to retreive tickets >>', error)
      return undefined
    }
};

export default {
    get_ticket
}