export default{
    vpc: {
      h1: "VPC Networks",
      createBtn: 'Create VPC Network',
      description: 'Ressource asssigned to the same VPC Network can communicate securilywith each other via private IP. communicate with the ressource outtside the VPC must use network IP.',
      howToCreateVpc: 'How should I use VPC Network',
      notHavingVpc: "Look like you don't have any network",
      createFirstBtn: 'Create a first VPC Network',
      ressourceInOption: 'Ressources',
      deleteInOption: 'Delete',
      tagDefault: 'Default',
      noRessource: 'No Ressources',
      ressources: 'Ressources',
      manage: 'Manage',
      vpcLinkAlt: 'VPC short link',
      overview: {
        name: 'Overview',
        title: 'Overview',
        description: 'DESCRIPTION',
        defaultDescription: 'Default',
        descriptionText: "This is set as default VPC for the {{locationName}} Region",
        netDetails: 'NETWORK DETAILS',
        ipRange: 'IP Range',
        availAddr: 'Available address',
        ress: 'Ressources',
        vpcNetRess: 'VPC NETWORK RESSOURCES',
        docs: 'Docs',
        apiDoc: 'API Docs',
        tuto: 'Tutoriel',
        _____: '______',
        _____: '______',

      },
      ressources: {
        name: 'Ressources',
        title: 'Ressources',
        addServiceBtn: 'Add Service',
        noRessource: 'You have no ressource in this network',
        allCommunication: 'All communication between these ressources via private IP is secure.',
        traffic: 'Traffic to public IPs must be secured with a',
        firewall: 'Firewall',
        services: 'SERVICES',
        addServiceModal: {
            title: 'Adding new service in Zone',
            subtitle: 'Select new instance to add.',
            selectlabel: 'Select new instance here',
            placeholder: 'Select a Service',
            addServicBtn: 'Add service',
            cancel: 'Cancel',
            _____: '______',

        },
        collumName: 'Name',
        publicIp: 'Public IP',
        notDefined: '(Not defined)',
        privateIp: 'Private Ip',
        deleteServiceInZoneTooltip: 'Remove {{displayName}} in this zone',

        _____: '______',
        _____: '______',

      },
      settings: {
        name: "Settings",
      },
      delete: {
        name: "Delete",
      },
      _____: '______',
      _____: '______',
      _____: '______',
      _____: '______',
      _____: '______',
    }
}